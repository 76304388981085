import {
  IonContent,
  IonToast,
  IonModal,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useState, useEffect } from "react";

import "./TendersPage.css";

import { useSelector, useDispatch } from "react-redux";

import { HomeAction } from "../../ReduxStore/Actions";
import AddTender from "./AddTender";
import TendersList from "./TendersList";
import Footer from "../../components/Footer/Footer";
import { Toast } from "../../Utils/Toast";
import SideBar from "../sidebar/sideNav";
import TendersTab from "./TendersTab";
import EditTender from "./EditTender";

import { TenderActions } from "../../ReduxStore/Actions";
import { filterTenders } from "../../Utils/helpers";
import PaginationControlled from "./Pagination";
import TendersAccessed from "./TendersAccessed";

const TendersPage = (props) => {
  const dispatch = useDispatch();
  const scrollRef = React.useRef();
  const selectRef = React.useRef();
  const selectCategoryRef = React.useRef();

  const [toast, setToast] = React.useState(false);

  const [showForm, setShowForm] = React.useState(false);
  const [showNewTenders, setShowNewTenders] = React.useState(true);
  const [showActiveTenders, setShowActiveTenders] = React.useState(false);
  const [tendersAccessed, setTendersAccessed] = React.useState(false);

  const [newTenders, setNewTenders] = React.useState([]);
  const [activeTenders, setActiveTenders] = React.useState([]);
  const [tenders, setTenders] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [disableCategory, setDisableCategory] = React.useState(false);
  // modal
  const [showEditTender, setShowEditTender] = useState(false);

  // edit tender
  const [editTenderData, setEditTenderData] = useState(null);

  // tab
  const [tabValue, setTabValue] = React.useState(0);

  // pagination State
  const [newTendersCount, setNewTendersCount] = React.useState(0);
  const [activeTendersCount, setActiveTendersCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const itemPerPage = 10;

  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const fetchedTenders = useSelector((state) => state.TenderReducer.tenders);
  let fetchedSectors = useSelector((state) => state.TenderReducer.sectors);
  let fetchedCategories = useSelector(
    (state) => state.TenderReducer.categories
  );

  console.log("tenders page state categories", categories);

  const getTenderData = (tender) => {
    setEditTenderData(tender);
  };

  const handleSectorChange = (event) => {
    console.log("event.target.value sector", event.target.value);
    setCategories(
      fetchedCategories &&
        fetchedCategories.length &&
        fetchedCategories.filter(
          (cat) => cat.sector_name === event.target.value
        )
    );

    const newTendersArray = filterTenders(
      tenders,
      "new",
      event.target.value,
      "sector"
    );
    setNewTenders(newTendersArray);

    const activeTendersArray = filterTenders(
      tenders,
      "active",
      event.target.value,
      "sector"
    );
    // const activeTendersArray = tenders.filter(
    //   (tender) => tender.sector === event.detail.value
    // );
    setActiveTenders(activeTendersArray);
    // setTenders(filteredSector);
    if (
      event.target.value === "Non Sport" ||
      event.target.value === "Sport (Non-Tech)"
    ) {
      setDisableCategory(true);
    } else {
      setDisableCategory(false);
    }
  };

  const handleCategoryChange = (event) => {
    console.log("event.target.value in tenders page", event.target.value);
    if (!event.target.value || event.target.value.length === 0) {
      if (
        selectRef &&
        selectRef.current &&
        selectCategoryRef &&
        selectCategoryRef.current
      ) {
        selectRef.current.value = undefined;
        selectCategoryRef.current.value = undefined;
        dispatch(TenderActions.getAllTenders());
        dispatch(TenderActions.getSectors());
        dispatch(TenderActions.getCategories());
      }
    }
    const findCat = fetchedCategories.find(
      // (cat) => cat.category_name === event.target.value
      (cat) =>
        event.target.value &&
        event.target.value.length &&
        event.target.value.length > 0 &&
        event.target.value.includes(cat.category_name)
    );
    selectRef.current.value =
      findCat && findCat?.sector_name && findCat.sector_name;

    const newTendersArray = filterTenders(
      tenders,
      "new",
      event.target.value,
      "category"
    );
    setNewTenders(newTendersArray);

    const activeTendersArray = filterTenders(
      tenders,
      "active",
      event.target.value,
      "category"
    );
    // const activeTendersArray = tenders.filter(
    //   (tender) => tender.sector === event.detail.value
    // );
    setActiveTenders(activeTendersArray);
    // setTenders(filteredSector);
  };

  const handleClearFilter = () => {
    if (
      selectRef &&
      selectRef.current &&
      selectCategoryRef &&
      selectCategoryRef.current
    ) {
      selectRef.current.value = undefined;
      selectCategoryRef.current.value = undefined;
      dispatch(TenderActions.getAllTenders());
      dispatch(TenderActions.getSectors());
      dispatch(TenderActions.getCategories());
    }
  };
  // pagination Effect hook
  useEffect(() => {
    const displayedData = Math.ceil(newTenders?.length / itemPerPage);
    console.log("new Tenders and tenders length", newTenders.length);
    setNewTendersCount(displayedData);
  }, [newTendersCount, newTenders]);

  useEffect(() => {
    setPage(1);
    if (
      selectRef &&
      selectRef.current &&
      selectCategoryRef &&
      selectCategoryRef.current
    ) {
      selectRef.current.value = undefined;
      selectCategoryRef.current.value = undefined;
      dispatch(TenderActions.getAllTenders());
      dispatch(TenderActions.getSectors());
      dispatch(TenderActions.getCategories());
    }
  }, [tabValue, dispatch]);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    const displayedData = Math.ceil(activeTenders.length / itemPerPage);
    setActiveTendersCount(displayedData);
  }, [activeTendersCount, activeTenders]);

  useEffect(() => {
    if (fetchedTenders.length > 0) {
      setTenders(fetchedTenders);
      const newTendersArray = filterTenders(fetchedTenders, "new", "", "");

      setNewTenders(newTendersArray);
      const activeTendersArray = filterTenders(
        fetchedTenders,
        "active",
        "",
        ""
      );
      setActiveTenders(activeTendersArray);
    }
  }, [fetchedTenders]);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
  }, [dispatch]);

  // fetch all tenders
  useEffect(() => {
    dispatch(TenderActions.getAllTenders());
    dispatch(TenderActions.getSectors());
    dispatch(TenderActions.getCategories());
  }, [dispatch]);

  useEffect(() => {
    setCategories(fetchedCategories);
  }, [fetchedCategories]);

  useEffect(() => {
    setSectors(fetchedSectors);
  }, [fetchedSectors]);

  return (
    <IonContent>
      <IonModal
        isOpen={showEditTender}
        backdropDismiss={false}
        className="editTenderPopup"
      >
        <EditTender
          handleToast={setToast}
          showEditTender
          handleModal={setShowEditTender}
          editTenderData={editTenderData}
          tabValue={tabValue}
          setToast={setToast}
          setTabValue={setTabValue}
          setShowForm={setShowForm}
          setShowActiveTenders={setShowActiveTenders}
          setShowNewTenders={setShowNewTenders}
          sectors={sectors}
        />
      </IonModal>
      {toast === false ? (
        <Toast
          isOpen={errorStatus === false ? true : false}
          message={errorMessage}
          isSuccess={errorStatus}
          isClearSuccess={true}
          isClearError={true}
        />
      ) : (
        <IonToast
          isOpen={toast}
          message="Successfully submitted"
          onDidDismiss={() => setToast(false)}
          buttons={[
            {
              text: "Close",
              role: "cancel",
              handler: () => {
                setToast(false);
              },
            },
          ]}
        />
      )}

      <SideBar />
      <div className="col-md-9 col-lg-9 tenderPageContainer">
        <div className="addtender">
          <h3
            style={{ margin: "48px 0 0" }}
            className="heading"
            ref={scrollRef}
          >
            {" "}
            Tenders
          </h3>
          {/* <IonButton className="gradient-btn-blue" onClick={toggleShowForm}>
            Add Tender
          </IonButton> */}
        </div>
        <TendersTab
          setShowForm={setShowForm}
          setShowActiveTenders={setShowActiveTenders}
          setShowNewTenders={setShowNewTenders}
          setTendersAccessed={setTendersAccessed}
          value={tabValue}
          setValue={setTabValue}
        />

        {tabValue !== 2 && tabValue !== 3 && (
          <>
            <div className="selectContainer tenderSelectContainer">
              <IonItem className="selectSectorContainer">
                <IonSelect
                  ref={selectRef}
                  className="sectorselect"
                  placeholder="select sector"
                  name="sector"
                  onIonChange={(event) => handleSectorChange(event)}
                >
                  {sectors &&
                    sectors.length > 0 &&
                    sectors.map(({ name }, index) => (
                      <IonSelectOption key={index} value={name}>
                        {name}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>

              <IonItem className="selectSectorContainer">
                <IonSelect
                  ref={selectCategoryRef}
                  className="sectorselect"
                  placeholder="select category"
                  name="category"
                  disabled={disableCategory}
                  multiple={true}
                  onIonChange={(event) => handleCategoryChange(event)}
                >
                  {categories &&
                    categories.length > 0 &&
                    categories.map(({ category_name }, index) => (
                      <IonSelectOption key={index} value={category_name}>
                        {category_name}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </div>

            <button onClick={handleClearFilter} className="clearBtn">
              Clear Filter
            </button>
          </>
        )}

        {showForm && (
          <AddTender
            setToast={setToast}
            setTabValue={setTabValue}
            setShowForm={setShowForm}
            setShowActiveTenders={setShowActiveTenders}
            setShowNewTenders={setShowNewTenders}
          />
        )}
        {showNewTenders && (
          <>
            <br />
            <TendersList
              list={newTenders}
              itemsPerPage={itemPerPage}
              page={page}
              setShowEditTender={setShowEditTender}
              getTenderData={getTenderData}
              handleClearFilter={handleClearFilter}
              newTendor={true}
            />
            {newTendersCount > 1 && (
              <PaginationControlled
                count={newTendersCount}
                setPage={setPage}
                page={page}
              />
            )}
          </>
        )}
        {showActiveTenders && (
          <>
            <br />
            <TendersList
              list={activeTenders}
              itemsPerPage={itemPerPage}
              page={page}
              setShowEditTender={setShowEditTender}
              handleClearFilter={handleClearFilter}
              getTenderData={getTenderData}
            />
            {activeTendersCount > 1 && (
              <PaginationControlled
                count={activeTendersCount}
                setPage={setPage}
                page={page}
              />
            )}
          </>
        )}

        {tendersAccessed && (
          <>
            <br />
            <TendersAccessed scrollRef={scrollRef} />
          </>
        )}
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default TendersPage;
