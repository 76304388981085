import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AdminAction } from "../../ReduxStore/Actions";
import { markVendorPaid } from "../../ReduxStore/Actions/adminAction";

const ConfirmMarkVendorModal = (props) => {
  const dispatch = useDispatch();

  const switchVendorPaid = () => {
    const is_paid = props.selectedMarkEvent ? 1 : 0;
    props.setMarkLoading(true);

    dispatch(
      markVendorPaid({ userId: props.userId, is_paid }, () => {
        props.setMarkLoading(false);
        (async () => {
          await dispatch(
            AdminAction.getVedorById({
              userId: props.userId,
              type: "vendor",
            })
            // parseInt(props.match.params.id) })
          );
        })();
      })
    );
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      onHide={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="vendorUserModalContainer"
    >
      {/* <Modal.Header > */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Mark as {props.selectedMarkEvent ? "paid" : "unpaid"} vendor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="customListTop">
          <form>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to mark this vendor as a{" "}
              {props.selectedMarkEvent ? "Paid " : "Unpaid "} Vendor?
            </p>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}> Cancel</Button>
        <Button onClick={switchVendorPaid}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmMarkVendorModal;
