import React from "react";
import { IonContent } from "@ionic/react";
import Footer from "../../components/Footer/Footer";
import "./AboutUs.css";
import { HomeAction } from "../../ReduxStore/Actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";

const AboutUs = () => {
  let dispatch = useDispatch();
  const handleScroll = (e) => {
    console.log("scrolled", e.detail.scrollTop);

    if (e.detail.scrollTop > 10) {
      dispatch(HomeAction.setHeaderClass("blue"));
    } else {
      dispatch(HomeAction.setHeaderClass("trans"));
    }
  };
  return (
    <IonContent scrollEvents={true} onIonScroll={handleScroll}>
      <section className="AboutUs-top">
        <div className="table">
          <div className="table-cell">
            <h1 className="headingAbout mb-4"></h1>
          </div>
        </div>
      </section>
      <section className="AboutUsSetion1">
        <div className="container d-block d-md-flex newContainer">
          <div className=" tab1 col-md-5 text-left ">
            <h4 className="ml-5">About Us</h4>
            <p className="ml-5">
              We are on a mission to simplify sports tech procurement.
            </p>
            <p className="ml-5 mt-4">
              We exist to help sports federations, leagues, clubs, broadcasters,
              brands and venues at all levels to quickly find and connect with
              the right tech vendors to deliver on their requirements.
            </p>
            <p className="ml-5 mt-4">
              We eliminate the time wasted searching for and speaking to the
              wrong vendors and we eliminate spam emails (all searches you run
              through SportsTech Match are conducted anonymously).
            </p>
            <p className="ml-5 mt-4">
              Our growing community already consists of hundreds of the most
              trusted sports tech vendors worldwide who are ready to respond
              immediately to requests for information (RFI's) from buyers across
              sport (as well as fellow vendors looking for strategic partners).
              Since not all good vendors are signed up to SportsTech Match, we
              expand your search onto LinkedIn to ensure all relevant vendors
              participate.
            </p>
            <p className="ml-5 mt-4">
              We are committed to generating meaningful conversations and
              connections between buyers and vendors. To achieve this we ensure
              that all buyers issuing an RFI through SportsTech Match have
              identified a need and have defined their key requirements.
            </p>
            <p className="ml-5 mt-4">
              We are also passionate about supporting our vendors to evolve
              their product offering and we do this by facilitating buyer
              feedback to vendors who are unsuccessful in any RFI process.
            </p>
          </div>
          <div className=" tab3 col-md-7">
            <h3 className="ml-4">Our business model</h3>
            <ol className="gradient-list">
              <li>
                We plan to explore routes to monetisation in 2023 and will keep
                our community of buyers and vendors informed every step of the
                way. Until then we will be running a free service in order to
                learn and build products that meet our customers needs.
                Irrespective of when and how we monetise, we are committed to
                keeping the barriers to entry for any vendor, no matter what
                stage they are at, as low as possible.
              </li>
              {/* <li>
                <b>The only place to track the evolving marketplace:</b>{" "}
                start-ups and sports tech companies create and manage their own
                product listings on SportsTech Match to enable sports executives
                to keep pace as their solutions evolve.
              </li>
              <li>
                <b>The first product-level mapping of sports tech:</b> sports
                executives can search and discover the best sports technology
                solutions on a product-level enabling them to identify solutions
                relevant to their use case.
              </li>
              <li>
                <b>Anonymous shortlisting service:</b> sports executives can use
                our expert team to benchmark relevant vendors against their
                requirements anonymously before directly inviting the right
                vendors to discuss or pitch for a project.
              </li>
              <li>
                <b>Create pilot projects:</b> sports executives can use
                SportsTech Match to find sports technology companies open to
                running a pilot project before committing to a commercial
                relationship.
              </li> */}
            </ol>
          </div>
        </div>
        <div className="col-md-12 mt-5 tab2 text-center">
          <p>
            {" "}
            Contact us today if you would like to become an approved vendor or
            if you would like our support to quickly and painlessly find you the
            right sports tech solution.
          </p>
          <Link to={ROUTES.ContactUs}>
            <button className="mt-5 mb-5">Contact Us</button>
          </Link>
        </div>
      </section>
      <Footer></Footer>
    </IonContent>
  );
};

export default AboutUs;
