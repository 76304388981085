import { ACTION_TYPE } from "../../Services/index";

export const setHeaderClass = (params) => async (dispatch) => {
	console.log("setHeaderClass action");
	return new Promise((resolve, reject) => {
		dispatch({
			type: ACTION_TYPE.SET_HEADER,
			payload: params,
		});

		resolve();
	});
};
