import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import { ProductAction, VendorAction } from "../../ReduxStore/Actions";
import MultipleSelectCheckmarks from "./MutiSelectDropdown";
import { ValidateRequestForm } from "./requestFormValidation";
import {
  addEditRatingReviewRequest,
  getRatingReviewRequest,
} from "../../ReduxStore/Actions/ratingReviewActions";
import SideMenu from "../../components/SideMenu";
import { RATING_REQUEST_STATUS } from "../../Services/Contants";
import { CircularProgress } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import ToolTip from "../tooltip/Tooltip";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateRequest = (props) => {
  const charLimit = 200;
  const history = useHistory();
  const location = useLocation();
  let { id } = props.match.params;

  const headingRef = useRef(null);
  console.log("id in create request", id);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData);
  console.log("auth data in create request", authData);

  const countCharacters = useCallback((text = "") => {
    const charCount = text.length;
    return { charCount, wordCount: text.split(/\S+/).length - 1 };
  }, []);

  const productList = useSelector((state) => state.vendorReducer.list);
  const loadingList = useSelector((state) => state.commonReducer.loadingList);

  const ratingReviewRequest = useSelector(
    (state) => state.RatingReviewReducer.ratingReviewRequest
  );
  console.log("rating review requets in create", ratingReviewRequest);

  const [errorData, setError] = useState();
  const [type, setType] = useState();
  // const [maxLenMessage, setMaxLenMessage] = useState(2000);

  const [formData, setFormData] = useState({
    reviewerName: "",
    reviewerCompanyName: "",
    reviewerEmail: "",
    productIds: isNaN(id[1])
      ? []
      : id[0] === "n"
      ? [Number(id.slice(1, id.length))]
      : [Number(id)],
    messageToReviewer: ``,
  });
  console.log("form data in create request", formData);
  console.log("message to reviewer", formData.messageToReviewer);

  const productInfo = useSelector((state) => state.productReducer.productInfo);

  console.log("product info in create request", productInfo);
  const loading = useSelector((state) => state.commonReducer.loading);
  // get product id from query params
  useEffect(() => {
    (async () => {
      // let id = props.match.params.id;
      console.log("id in createRequest", id);

      if (!isNaN(id)) {
        let params = {
          productId: id,
        };
        await dispatch(ProductAction.getProductById(params));

        await dispatch(VendorAction.ProductbyId({ productId: id }));
      }
    })();
  }, []);

  useEffect(() => {
    if (authData) {
      setFormData({
        ...formData,
        messageToReviewer: `
    <p>You have been invited by ${authData.companyName} to submit a review of [product_name].&nbsp;</p>
    <p><br></p>
    <p>It will take as little as 2 or 3 minutes to submit the review. By providing a review you will help ${authData.companyName} to continue to develop this product as well as other buyers to find the right products.</p>
    <p><br></p>
    <p>SportsTechMatch will never reveal your name or personal information in association with this review and you can choose to keep your organisation name anonymous.</p>
    <p><br></p>
    <p>To submit your customer review, you must have a SportsTech Match account linked to this email address. Sign up here to create an account (it's quick to sign up and free) and then click on the link below to submit your customer review.</p>
    `,
      });
    }
  }, [authData]);

  useEffect(() => {
    (async () => {
      await dispatch(getRatingReviewRequest());
    })();
  }, []);

  useEffect(() => {
    headingRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (ratingReviewRequest) {
      const ratingReview = ratingReviewRequest.find(
        (rating) => rating.product_id === Number(id)
      );

      if (ratingReview) {
        setFormData({
          reviewerName: ratingReview.reviewer_name,
          reviewerCompanyName: ratingReview.reviewer_company_name,
          reviewerEmail: ratingReview.reviewer_email,
          productIds: isNaN(id) ? [] : [Number(id)],
          messageToReviewer: ratingReview.message_to_reviewer,
          ratingRequestedId: ratingReview.rating_requested_id,
        });
      }

      console.log("ratingReview", ratingReview);
    }
  }, [ratingReviewRequest, id, authData]);

  useMemo(() => {
    let vendorId = { vendorId: authData?.id };
    console.log("vendor ID", vendorId, authData);
    (async () => {
      authData &&
        authData.id &&
        (await dispatch(VendorAction.getList(vendorId)));
    })();
  }, [authData]);

  const handleChange = (e) => {
    // if (countCharacters(formData.messageToReviewer).wordCount >= charLimit) {
    //   setMaxLenMessage(countCharacters(formData.messageToReviewer).charCount);
    // }
    console.log("e.target", e.target);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleMessageChange = (e) => {
    setFormData({
      ...formData,
      messageToReviewer: e,
    });
  };

  const handleSaveAsDraft = async () => {
    setType("DRAFT");
    const { isFormValid, error } = ValidateRequestForm(formData);

    if (isFormValid) {
      await dispatch(
        addEditRatingReviewRequest({
          formData: {
            ...formData,
            status: RATING_REQUEST_STATUS.SAVED.enumVal,
          },
          history,
        })
      );
    } else {
      setError(error);
      console.log(
        "error in rating request form validation",
        JSON.stringify(error)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setType("SUBMIT");
    // check validations
    const { isFormValid, error } = ValidateRequestForm(formData);

    if (isFormValid) {
      await dispatch(
        addEditRatingReviewRequest({
          formData: {
            ...formData,
            status: RATING_REQUEST_STATUS.EMAIL_SENT.enumVal,
          },
          history,
        })
      );
    } else {
      setError(error);
      console.log(
        "error in rating request form validation",
        JSON.stringify(error)
      );
    }
  };

  return (
    <>
      <div className="requestReviewContainer">
        <SideMenu location={location.pathname} />

        <div className="mainContent createRatRevForm">
          <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
            <ArrowBack />
          </span>
          <div className="titleContainer">
            <h2 ref={headingRef}>Create a Rating & Review Request</h2>
            <button onClick={handleSaveAsDraft} disabled={loading}>
              {loading && type === "DRAFT" ? (
                <CircularProgress size={20} color="info" />
              ) : (
                "Save as Draft"
              )}
            </button>
          </div>
          <form className="formContainer" onSubmit={handleSubmit}>
            <div className="formRow">
              <div>
                <label>Name of Reviewer</label>
                <input
                  type="text"
                  name="reviewerName"
                  placeholder="Name of Reviewer"
                  value={formData.reviewerName}
                  onChange={handleChange}
                />
                {errorData && errorData?.reviewerName[0] ? (
                  <p className="errorTxt">
                    {errorData && errorData?.reviewerName[0]}
                  </p>
                ) : null}
              </div>
              <div>
                <label>Company Name of Reviewer</label>
                <input
                  type="text"
                  name="reviewerCompanyName"
                  placeholder="Company Name of Reviewer"
                  value={formData.reviewerCompanyName}
                  onChange={handleChange}
                />
                {errorData && errorData?.reviewerCompanyName[0] ? (
                  <p className="errorTxt">
                    {errorData && errorData?.reviewerCompanyName[0]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="formLabelContainer">
                  <label>Email Address of Reviewer</label>
                  {/* <span className="tooltp-vd"> */}
                  <ToolTip message="Please check that you have given the correct email address for the Reviewer. The invitation to submit the customer review will be sent to this email address when you click on Submit." />
                  {/* </span> */}
                </div>
                <input
                  type="text"
                  name="reviewerEmail"
                  placeholder="Email Address of Reviewer"
                  value={formData.reviewerEmail}
                  onChange={handleChange}
                />
                {errorData && errorData?.reviewerEmail[0] ? (
                  <p className="errorTxt">
                    {errorData && errorData?.reviewerEmail[0]}
                  </p>
                ) : null}
              </div>
              {loadingList ? (
                <CircularProgress />
              ) : productList.length > 0 && !loadingList ? (
                <div>
                  <div className="formLabelContainer">
                    <label>Product(s) to be Reviewed</label>
                    {/* <span className="tooltp-vd"> */}
                    <ToolTip message="You can select one or more products to be reviewed (and the Reviewer will receive a separate invitation and review form for each product you select). If you have not yet created a product listing for the product to be reviewed then you can save the review request as a draft and add a new product listing. The customer review will be published once both the new product listing and the customer review are approved by STM." />
                    {/* </span> */}
                  </div>
                  <MultipleSelectCheckmarks
                    productList={productList}
                    formData={formData}
                    setFormData={setFormData}
                  />
                  {errorData && errorData?.productIds[0] ? (
                    <p className="errorTxt">
                      {errorData && errorData?.productIds[0]}
                    </p>
                  ) : null}
                </div>
              ) : productList.length == 0 && !loadingList ? (
                <div style={{ alignSelf: "flex-end" }}>
                  <Link
                    to={{
                      pathname: ROUTES.AddVendor,
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <button className="addProductBtn" type="button">
                      + Add Product
                    </button>
                  </Link>
                </div>
              ) : null}
              {/* {showButton} */}
            </div>

            <div className="formRow">
              <div>
                <div className="formLabelContainer">
                  <label>Message to Reviewer</label>
                  {/* <span className="tooltp-vd"> */}
                  <ToolTip message="When you click on Submit, this message will be sent as an email from SportsTech Match to the Reviewer. You can edit the text up to the maximum word limit. Note that “[product_name]” as it appears in the message in line 1 will be replaced in the email(s) to the reviewer by the name of the product(s) you selected for review." />
                  {/* </span> */}
                </div>
                <ReactQuill
                  theme="snow"
                  name="messageToReviewer"
                  value={formData.messageToReviewer}
                  onChange={handleMessageChange}
                />

                {/* <textarea
                  id="messageToReview"
                  name="messageToReviewer"
                  rows="4"
                  cols="50"
                  // maxLength={maxLenMessage}
                  value={formData.messageToReviewer}
                  onChange={handleChange}
                /> */}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#7a7a7a",
                    margin: "2.5rem 0",
                  }}
                >
                  Maximum word limit is{" "}
                  {countCharacters(formData.messageToReviewer).wordCount}/
                  {charLimit}
                </p>
                {errorData && errorData?.messageToReviewer[0] ? (
                  <p className="errorTxt">
                    {errorData && errorData?.messageToReviewer[0]}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="formBtnContainer">
              <Link
                to={{
                  pathname: `/request-review`,
                }}
              >
                Cancel
              </Link>
              <button type="submit" disabled={loading}>
                {loading && type === "SUBMIT" ? (
                  <CircularProgress size={20} color="info" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateRequest;
