import { IonInput, IonButton, IonLabel } from "@ionic/react";
import { IonCheckbox } from "@ionic/react";
import React, { useState } from "react";
import "../../MultiStepForm.css";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import ToolTip from "../../../tooltip/Tooltip";
import { TextField } from "@material-ui/core";

import { ValidateSignUpForm } from "./Validation";
import { CustomVendorListModal } from "./CustomVendorModal";
import { NavigateBeforeSharp } from "@material-ui/icons";
import { ShowCustomVendorListModal } from "./ShowSelectedCustomVendorModal";

const FormThree = (props) => {
  const [errorData, setError] = useState();
  const [showSelectedVendorModal, setShowSelectedVendorModal] = useState(false);
  const [addVenderInfo, setAddVenderInfo] = useState("");
  const [addVenderInfoBlock, setAddVenderInfoBlock] = useState("");
  console.log("CONSOLEEE", props.state);

  console.log("from props custom vendor form 3", props.customVendor);

  async function onSubmitForm(event) {
    event.preventDefault();

    const result = await ValidateSignUpForm(
      props.state,
      props.customVendor,
      props.selectVendor
    );

    if (!result?.isFormValid) {
      setError(result?.error);
      for (let i = 0; i < Object.keys(result.error).length; i++) {
        console.log("inside", Object.keys(result.error)[i]);
        if (Object.values(result.error)[i].length) {
          console.log("inside");
          document
            .getElementById(Object.keys(result.error)[i])
            .scrollIntoView();
          break;
        }
      }

      console.log("result errorrr", result.error);
      return;
    }

    const formData = new FormData();
    console.log("Svalues", props.state);
    for (var key in props.state) {
      formData.append(key, props.state[key]);
    }
    console.log("state", props.state);
    props.changeCount(4);
  }

  const onAddVendorHandler = () => {
    if (!addVenderInfo) {
      alert("Please add vendor detail");
      return;
    }
    props.setAllowListVendors((values) => [...values, addVenderInfo]);
    setAddVenderInfo("");
  };

  const onAddVendorHandlerBlock = () => {
    if (!addVenderInfoBlock) {
      alert("Please add vendor detail");
      return;
    }
    props.setBlockListVendors((values) => [...values, addVenderInfoBlock]);
    setAddVenderInfoBlock("");
  };

  return (
    <>
      <div className="multiStepFormThreeOuterWrapper">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="stepThreeDescription">
            <h1>Select Vendors</h1>
            <p style={{ fontSize: 15 }}>
              Choose the vendors who will receive your RFI by selecting the
              relevant sector(s) and/or by creating a custom list of vendors.
            </p>
            <div id="sectors" className="stepThreeFirstCheckWrapper">
              <IonCheckbox
                className="custom-checkbox multi-custom-checkbox mainSelect"
                name="sectors"
                // disabled={props.selectCustom ? true : false}
                checked={props.selectVendor}
                onClick={(e) => {
                  if (props.selectVendor) {
                    if (props.state.sectors.includes("Custom")) {
                      props.setState((prev) => ({
                        ...prev,
                        sectors: ["Custom"],
                      }));
                    } else {
                      props.setState((prev) => ({
                        ...prev,
                        sectors: [],
                      }));
                    }
                  }
                  props.setSelectVendor((prev) => !prev);
                  // prev && props.setState((prev)=>({...prev,sectors:[]}))
                  // return !prev});
                }}
              />
              <IonLabel className="labelleded">
                Select the relevant sector(s) and all vendors within those
                sectors will be notified of your RFI.
              </IonLabel>

              <span className="">
                <ToolTip message="Registered vendors with products listed in the sectors you select will be notified of your RFI. We will also promote your search on LinkedIn to attract the best vendors not yet registered on STM." />
              </span>
            </div>
          </div>
          <div className="stepThreeCheckboxes">
            <div className="stepOneCheckboxWrapper">
              <div className="stepOneCheckbox stepThreeCheckboxInner">
                {props?.filters?.Sectors?.filter((data) => {
                  console.log("data.name ===>", data.name);
                  return (
                    data.name.trim() !== "Sport (Non-Tech)" &&
                    data.name.trim() !== "Non Sport"
                  );
                }).map((data, index) => (
                  <div className="d-flex align-items-center " key={index}>
                    <IonCheckbox
                      className="custom-checkbox multi-custom-checkbox"
                      name="sectors"
                      disabled={!props.selectVendor}
                      checked={props?.state?.sectors.includes(data.id)}
                      // checked={props?.state?.sectors.includes("selectVendor")}

                      onClick={(e) => props.handleChange(e, data.id)}
                    />
                    <div className="stepThreeCheckboxWithLink">
                      <div className="msf__checkBoxTooltip">
                        <IonLabel className="labelled">{data.name}</IonLabel>
                        <p className="relative">
                          <span className="toolTips">
                            <ToolTip message={data.toolTip} />
                          </span>
                        </p>
                      </div>
                      <Link
                        to={`/vendor-list/${data.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="linkVendors"
                      >
                        <u>(View approved vendors)</u>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="orPosition">OR</div> */}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IonCheckbox
              className="custom-checkbox multi-custom-checkbox mainSelect"
              name="sectors"
              // disabled={props.selectVendor}
              checked={props?.state?.sectors.includes("Custom")}
              onClick={(e) => {
                props.handleChange(e, "Custom");
                props.setSelectCustom((prev) => !prev);
                // e.target.checked ? setSelect(false) : setSelect(true);
                // props.setModalShow(true);
              }}
            />
            <IonLabel className="labelleded setTooltipMSF">
              I want to create a custom made list of vendors.
            </IonLabel>
            <p className="relative">
              <span>
                <ToolTip message="Select specific vendors and they will receive your RFI." />
              </span>
            </p>
          </div>
          {errorData?.sectors[0] ? (
            <p className="setError">{errorData?.sectors[0]}</p>
          ) : null}
          {props?.state?.sectors.includes("Custom") ? (
            <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
              <Button
                variant="primary"
                onClick={() => props.setModalShow(true)}
              >
                Add Custom Vendors
              </Button>
              {props.selectedVendors.length > 0 && (
                <Button
                  variant="primary"
                  onClick={() => setShowSelectedVendorModal(true)}
                >
                  View Selected Custom Vendors List
                </Button>
              )}
            </div>
          ) : (
            ""
          )}

          <CustomVendorListModal
            show={props.modalShow}
            onHide={() => props.setModalShow(false)}
            customVendor={props.customVendor}
            setCustomVendor={props.setCustomVendor}
            selectedVendors={props.selectedVendors}
            setSelectedVendors={props.setSelectedVendors}
          />

          <ShowCustomVendorListModal
            show={showSelectedVendorModal}
            onHide={() => setShowSelectedVendorModal(false)}
            selectedVendors={props.selectedVendors}
          />

          <div style={{ marginTop: 20 }} className="pl-4 pt-2">
            <div className="setTooltipMSF">
              <IonLabel className="labelleded setTooltipMSF">
                Invite Non-Registered Vendors
              </IonLabel>
              <p className="relative">
                <span>
                  <ToolTip
                    message="Add vendors here that are not yet registered on SportsTech Match that you would like to invite to respond to your RFI. We will invite these vendors on your behalf.
Inside the box: Add the company name or email of each vendor
"
                  />
                </span>
              </p>
            </div>

            <p style={{ fontSize: 15 }}>
              Invite vendors not yet registered on SportsTech Match
            </p>

            <div className="addVendorMainBox">
              <div className="addVendorSecondaryBox">
                <input
                  onChange={(e) => {
                    setAddVenderInfo(e.target.value);
                  }}
                  value={addVenderInfo}
                  name="addVendor"
                  type="text"
                  id="addVendorDetails"
                  placeholder="Please Add Vendor details like name or email"
                />
                <span className="addVendorAddBtn" onClick={onAddVendorHandler}>
                  +Add
                </span>
              </div>

              <div
                className={
                  props.allowListVendors.length > 0
                    ? "addVendorSecondarySecondBox"
                    : "addVendorSecondarySecondBoxEmpty"
                }
              >
                {props.allowListVendors.map((item, i) => (
                  <>
                    <span className="addVendorInfoBox">
                      {item} &nbsp;{" "}
                      <span
                        onClick={() =>
                          props.setAllowListVendors((values) =>
                            values.filter((item, index) => index !== i)
                          )
                        }
                      >
                        X
                      </span>
                    </span>{" "}
                    &nbsp;
                  </>
                ))}
              </div>
            </div>
          </div>
          {/* <div style={{ marginTop: 20 }} className="pl-4 pt-2">
            <div className="setTooltipMSF">
              <IonLabel className="labelleded setTooltipMSF">
                Vendor Blocklist
              </IonLabel>
              <p className="relative">
                <span>
                  <ToolTip message="If a vendor you would like to us to exclude from the RFI." />
                </span>
              </p>
            </div>

            <p style={{ fontSize: 15 }}>
              Tell us about any vendors that you would like exclude from this
              RFI (and any other specific instructions regarding your vendor
              targeting).
            </p>

            <div className="addVendorMainBox">
              <div className="addVendorSecondaryBox">
                <input
                  onChange={(e) => setAddVenderInfoBlock(e.target.value)}
                  value={addVenderInfoBlock}
                  name="addVendor"
                  type="text"
                  id="addVendorDetails"
                  placeholder="Please Add Vendor details like name or email"
                />
                <span
                  className="addVendorAddBtn"
                  onClick={onAddVendorHandlerBlock}
                >
                  +Add
                </span>
              </div>

              <div
                className={
                  props.blockListVendors.length > 0
                    ? "addVendorSecondarySecondBox"
                    : "addVendorSecondarySecondBoxEmpty"
                }
              >
                {props.blockListVendors.map((item, i) => (
                  <>
                    <span className="addVendorInfoBox">
                      {item} &nbsp;{" "}
                      <span
                        onClick={() =>
                          props.setBlockListVendors((values) =>
                            values.filter((item, index) => index !== i)
                          )
                        }
                      >
                        X
                      </span>
                    </span>{" "}
                    &nbsp;
                  </>
                ))}
              </div>
            </div>
          </div> */}
          <div className="stepThreeBlocklist">
            <h1>Anonymous LinkedIn Promotion</h1>
            <p>
              Not all vendors are registered on SportsTech Match. Therefore, to
              increase the chances of finding the right vendor, check the box
              below and we will promote your RFI anonymously on LinkedIn.
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IonCheckbox
              className="custom-checkbox multi-custom-checkbox mainSelect"
              name="linkedInPromotion"
              checked={props?.state?.linkedInPromotion}
              onIonChange={(e) => props.handleChange(e)}
            />
            <div
              style={{ width: "unset" }}
              className="stepThreeCheckboxWithLink"
            >
              <IonLabel className="labelleded">
                Promote my search anonymously on LinkedIn
              </IonLabel>
              <a
                href="https://sportstechmatch.medium.com/going-beyond-sportstech-match-826bc6921de0"
                target="_blank"
                rel="noopener noreferrer"
                className="linkVendors"
              >
                <u>(Click here to read more about our LinkedIn post)</u>
              </a>
            </div>
          </div>

          <div className="stepOneOppDesc">
            <h1>RFI Response Deadline</h1>
            <p>
              In order to give vendors time to respond, please set the response
              deadline at least 5 working days from the date of submission.
            </p>
          </div>

          <div className="stepThreetitleOuterWrapper">
            <div className="stepOnetitleWrapper">
              <div className="stepThreeTitleLeft">
                <strong>
                  <h1>RFI Deadline:</h1>
                </strong>
                <p>at 12:00 pm CET</p>
              </div>
              <div className="stepThreeTitleRight">
                <IonInput
                  type="date"
                  id="rfiDeadline"
                  name="rfiDeadline"
                  className="form-control input-container"
                  value={props?.state?.rfiDeadline}
                  onIonChange={(event) => {
                    props.handleChange(event);
                  }}
                  placeholder="Add RFI DEADLINE"
                ></IonInput>
              </div>
            </div>
          </div>
          {errorData?.rfiDeadline[0] ? (
            <p className="setError">{errorData?.rfiDeadline[0]}</p>
          ) : null}

          <div className="stepThreeBlocklist">
            <h1 style={{ margin: "30px 0px" }}>
              Notes for the SportsTech Match team
            </h1>
            <p>Add comments or clarifications for the SportsTech Match team.</p>
          </div>
          <TextField
            id="outlined-multiline-static"
            className="messageBody"
            multiline
            name="notes"
            value={props.state?.notes}
            onChange={(event) => {
              props.handleChange(event);
            }}
            placeholder="Add any comments or special instructions here for the SportsTech Match team."
            rows={6}
            variant="outlined"
          />
          {errorData?.notes[0] ? (
            <p id="notes" className="setError">
              {errorData?.notes[0]}
            </p>
          ) : null}
          <div className="button-login d-flex justify-content-center">
            <div>
              <IonButton
                color="none"
                className="gradient-btn-blue"
                // onClick={(event) => {
                // 	props.history.goBack();
                // }}
                onClick={(event) => props.changeCount(2)}
              >
                Back
              </IonButton>
            </div>
            <div>
              <IonButton
                color="none"
                className="gradient-btn-blue"
                onClick={(event) => onSubmitForm(event)}
              >
                Next
              </IonButton>
            </div>
          </div>
        </form>
      </div>
      {/* <Footer></Footer> */}
    </>
  );
};

export default FormThree;
