import {
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { APPLICATION_CONSTANTS, ROUTES } from "../../Services/index";
import { useDispatch } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { AuthActions } from "../../ReduxStore/Actions";

import {
  homeOutline,
  bookOutline,
  newspaperOutline,
  logOutOutline,
  logInOutline,
  appsOutline,
  searchOutline,
  mailOutline,
  personCircleOutline,
  person,
  closeSharp,
} from "ionicons/icons";
import { menuController } from "@ionic/core";

import "./Menu.css";

const Menu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const localAuth = JSON.parse(localStorage.getItem("userData"));

  const authData = useSelector((state) => state.authReducer.authData);

  const logoutHandler = async () => {
    if (
      localAuth &&
      localAuth.support &&
      localAuth.support.supportUserData &&
      localAuth.support.supportUserData.role === 4
    ) {
      const status = await dispatch(AuthActions.logoutAsUser(localAuth));
      console.log("status in header for support logout ==>", status);
      if (status?.status) {
        console.log("connectedddddd in iffff", status);

        if (status?.user?.role == 1) history.push(ROUTES.Vendors);
        else if (status?.user?.role == 0) history.push(ROUTES.RfiDashboard);
        else if (status?.user?.role == 2) history.push(ROUTES.AdminProfile);
        else if (status?.user?.role == 4)
          history.push(ROUTES.AdminSupportProfile);
        else history.push("/");
      } else {
        dispatch({ type: "LOGOUT_USER" });
        await localStorage.removeItem(APPLICATION_CONSTANTS.USER_DATA);
        await localStorage.removeItem(APPLICATION_CONSTANTS.TOKEN);
        await history.push("/");
      }
    } else {
      dispatch({ type: "LOGOUT_USER" });
      await localStorage.removeItem(APPLICATION_CONSTANTS.USER_DATA);
      await localStorage.removeItem(APPLICATION_CONSTANTS.TOKEN);
      await props.history.push("/");
    }
  };

  const menuToggle = () => {
    menuController.toggle();
  };
  return (
    <IonMenu contentId="main" type="overlay" className="dashion-menu">
      <IonContent>
        <div className="sidebar-head" style={{ height: 57 }}>
          <div style={{ float: "right" }} className="sidebar-user">
            <IonButtons
              slot="start"
              onClick={(event) => {
                menuToggle();
              }}
            >
              <IonIcon className="barIcon" icon={closeSharp} />
            </IonButtons>
          </div>
        </div>

        <IonList id="inbox-list" className="menulist">
          <IonMenuToggle autoHide={false}>
            <IonItem lines="none" detail={false}>
              <div className="sidebar-middiv" tabindex="0">
                {/* <div className="sidebar-middiv" tabindex="0"> */}
                <Link to={ROUTES.Home}>
                  <IonLabel>
                    {" "}
                    <IonIcon icon={homeOutline}></IonIcon>Home
                  </IonLabel>
                </Link>
                {/* </div>{" "} */}
                <Link to={ROUTES.Search}>
                  <IonLabel>
                    <IonIcon icon={searchOutline}></IonIcon> Marketplace
                  </IonLabel>
                </Link>{" "}
                <Link to={ROUTES.VendorPage}>
                  <IonLabel>
                    <IonIcon icon={appsOutline}></IonIcon>
                    Vendors{" "}
                  </IonLabel>
                </Link>
                <Link to={ROUTES.Buyer}>
                  {" "}
                  <IonLabel>
                    <IonIcon icon={person}></IonIcon>Buyers
                  </IonLabel>{" "}
                </Link>{" "}
                <Link to={ROUTES.About}>
                  {" "}
                  <IonLabel>
                    <IonIcon icon={bookOutline}></IonIcon>About Us
                  </IonLabel>{" "}
                </Link>{" "}
                {/* <Link to=""> */}
                <a
                  href="https://sportstechmatch.medium.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonLabel>
                    <IonIcon icon={bookOutline}></IonIcon>
                    Blog
                  </IonLabel>
                </a>
                {/* </Link>{" "} */}
                <Link to={ROUTES.ContactUs}>
                  {" "}
                  <IonLabel>
                    <IonIcon icon={mailOutline}></IonIcon>Contact Us
                  </IonLabel>{" "}
                </Link>{" "}
                {localStorage.getItem("token") ? (
                  <li>
                    <Link
                      to={
                        authData?.role === 2
                          ? ROUTES.AdminProfile
                          : authData?.role === 1
                          ? ROUTES.Vendors
                          : ROUTES.RfiDashboard
                      }
                    >
                      <IonLabel>
                        <IonIcon icon={personCircleOutline}></IonIcon>
                        Dashboard
                      </IonLabel>{" "}
                    </Link>
                    {authData?.role !== 2 ? (
                      <Link to={ROUTES.VendorProfile}>
                        <IonLabel>
                          <IonIcon icon={newspaperOutline}></IonIcon>
                          Profile
                        </IonLabel>{" "}
                      </Link>
                    ) : null}
                    {/* <Link to={ROUTES.ManageList}>
                      <IonLabel>
                        {" "}
                        <IonIcon icon={cartOutline}></IonIcon>Manage Listings
                      </IonLabel>
                    </Link> */}

                    <a
                      // onClick={async () => {
                      //   dispatch({ type: "LOGOUT_USER" });
                      //   await localStorage.removeItem(
                      //     APPLICATION_CONSTANTS.USER_DATA
                      //   );
                      //   await localStorage.removeItem(
                      //     APPLICATION_CONSTANTS.TOKEN
                      //   );
                      //   await props.history.push("/");
                      // }}

                      onClick={logoutHandler}
                    >
                      <IonLabel>
                        <IonIcon icon={logOutOutline}></IonIcon>
                        {authData &&
                        authData.firstName &&
                        authData.lastName &&
                        localAuth &&
                        localAuth.support &&
                        localAuth.support.supportUserData &&
                        localAuth.support.supportUserData.role === 4
                          ? `Logout from ${authData.firstName} ${authData.lastName}`
                          : "Logout"}
                      </IonLabel>
                    </a>
                  </li>
                ) : (
                  <li>
                    <Link to={ROUTES.Login}>
                      <IonLabel>
                        <IonIcon icon={logInOutline}></IonIcon> Login
                      </IonLabel>{" "}
                    </Link>
                    <Link to={ROUTES.SignUp}>
                      <IonLabel>
                        <IonIcon icon={logOutOutline}></IonIcon> Sign Up
                      </IonLabel>{" "}
                    </Link>
                  </li>
                )}
              </div>

              {/* <div className="sidebar-middiv" tabindex="0">
                  <Link to={ROUTES.Home}>
                    <IonLabel>
                      {" "}
                      <IonIcon icon={homeOutline}></IonIcon>Home
                    </IonLabel>
                  </Link>
                  <Link to={ROUTES.FreeQuote}>
                    <IonLabel>
                      <IonIcon icon={receiptOutline}></IonIcon> Free Quote
                    </IonLabel>
                  </Link>{" "}
                  <Link to={ROUTES.Leads}>
                    <IonLabel>
                      <IonIcon icon={appsOutline}></IonIcon>
                      Leads
                    </IonLabel>
                  </Link>{" "}
                  <Link to={ROUTES.Blog}>
                    <IonLabel>
                      <IonIcon icon={bookOutline}></IonIcon>
                      Blog
                    </IonLabel>
                  </Link>{" "}
                  <Link to={ROUTES.ContactUs}>
                    {" "}
                    <IonLabel>
                      <IonIcon icon={personOutline}></IonIcon> Contact Us
                    </IonLabel>{" "}
                  </Link>{" "}
                  <Link to={ROUTES.Profile}>
                    <IonLabel>
                      {" "}
                      <IonIcon icon={personOutline}></IonIcon>Profile
                    </IonLabel>
                  </Link>
                  <Link to={ROUTES.Cart}>
                    <IonLabel>
                      {" "}
                      <IonIcon icon={cartOutline}></IonIcon>Cart
                    </IonLabel>
                  </Link>
                  <a
                    onClick={async () => {
                      dispatch({ type: "LOGOUT_USER" });
                      await localStorage.removeItem(
                        APPLICATION_CONSTANTS.USER_DATA
                      );
                      await localStorage.removeItem(
                        APPLICATION_CONSTANTS.TOKEN
                      );
                      await props.history.push("/");
                    }}
                  >
                    <IonLabel>
                      <IonIcon icon={logOutOutline}></IonIcon>Logout
                    </IonLabel>
                  </a>
                </div>
              )} */}
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
