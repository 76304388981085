function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData, customVendors, relevantSector) => {
  console.log("FORMDATAA", formData);
  console.log("relevantSector", relevantSector);
  console.log("customVendors", customVendors);
  let { sectors, rfiDeadline, blockAllow, notes } = formData || {};
  let error = {
    sectors: [],
    rfiDeadline: [],
    blockAllow: [],
    notes: [],
  };
  let today = new Date().toISOString().slice(0, 10);

  let isFormValid = true;
  if (isBlank(rfiDeadline) === true) {
    isFormValid = false;
    error.rfiDeadline = ["This field is required."];
  } else if (rfiDeadline) {
    let days = new Date(rfiDeadline.slice(0, 10)) - new Date(today);
    if (days / (1000 * 60 * 60 * 24) < 7) {
      isFormValid = false;
      error.rfiDeadline = [
        "The deadline is earlier than 5 working days from date of submission.",
      ];
    }
  }
  if (sectors.length === 0) {
    isFormValid = false;
    error.sectors = ["Select vendors is a required field."];
  }
  if (sectors.includes("Custom")) {
    if (customVendors.length === 0) {
      isFormValid = false;
      error.sectors = ["Please select custom vendors"];
    }
  }
  if (relevantSector) {
    if (
      sectors.includes(22) ||
      sectors.includes(23) ||
      sectors.includes(24) ||
      sectors.includes(25)
    ) {
    } else {
      isFormValid = false;
      error.sectors = ["Please select a relevant sector"];
    }
  }
  if (blockAllow?.trim().length > 0 && blockAllow?.trim().length > 300) {
    isFormValid = false;
    error.blockAllow = ["Allow or Block List cannot be greater than 300."];
  }

  if (notes?.trim().length > 0 && notes?.trim().length > 300) {
    isFormValid = false;
    error.notes = ["Notes for SPORTSTECH TEAM cannot be greater than 300."];
  }

  return { isFormValid, error };
};
