import { IonGrid, IonRow } from "@ionic/react";
import React, { useEffect } from "react";

import "./TendersAccessed.css";

import { useSelector, useDispatch } from "react-redux";
import { TenderActions } from "../../ReduxStore/Actions";

import { CircularProgress } from "@material-ui/core";
import PaginationControlled from "./Pagination";
import { CSVLink } from "react-csv";

const TendersAccessed = ({ scrollRef }) => {
  const dispatch = useDispatch();

  // pagination State
  const [tendersAccessedCount, setTendersAccessedCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const itemPerPage = 10;
  const [csvData, setCsvData] = React.useState([]);
  const loading = useSelector((state) => state.commonReducer.loading);
  const accessedTenders = useSelector(
    (state) => state.TenderReducer.accessedTenders
  );

  console.log("accessedTenders", accessedTenders, accessedTenders.length);
  useEffect(() => {
    const displayedData = Math.ceil(accessedTenders.length / itemPerPage);
    setTendersAccessedCount(displayedData);
  }, [tendersAccessedCount, accessedTenders]);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [page, scrollRef]);

  useEffect(() => {
    dispatch(TenderActions.fetchAccessedTenders());
  }, [dispatch]);

  useEffect(() => {
    if (accessedTenders && accessedTenders?.length) {
      const data = accessedTenders.map((item, index) => {
        return {
          "S.No.": index + 1,
          Name: `${item?.firstName || ""} ${item?.lastName || ""}`,
          Email: item.email,
          "Company Name": item.companyName,
          "Tender Title": item.title,
          "Tender Organization Name": item.organization_name,
          Sector:
            item &&
            item.sector &&
            JSON.parse(item.sector).map((sec, index) => {
              if (
                JSON.parse(item.sector).length - 1 === index ||
                JSON.parse(item.sector).length === 1
              ) {
                return ` ${sec}`;
              } else {
                return ` ${sec}`;
              }
            }),
          Category:
            item &&
            item.category &&
            JSON.parse(item.category).map((cat, index) => {
              if (
                JSON.parse(item.category).length - 1 === index ||
                JSON.parse(item.category).length === 1
              ) {
                return ` ${cat}`;
              } else {
                return ` ${cat}`;
              }
            }),
          "Accessed Timestamp": dateFormatter(item.TIMESTAMP),
        };
      });
      setCsvData(data);
    }
  }, [accessedTenders]);

  const dateFormatter = (date) => {
    date = new Date(date).toLocaleString("en-GB", {
      timeZone: "Europe/Paris",
    });

    const dateStr = date.replaceAll("/", ":").replace(",", " -");
    return dateStr;
  };

  return (
    <div className="signup">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : accessedTenders.length > 0 ? (
        <>
          <form>
            <IonGrid>
              <IonRow>
                {accessedTenders && accessedTenders.length > 0 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginBottom: 10,
                        width: "100%",
                      }}
                    >
                      <CSVLink
                        className="btn btn-primary"
                        filename={"tenders_accessed.csv"}
                        data={csvData}
                      >
                        Download to Excel
                      </CSVLink>
                    </div>
                    <div
                      style={{ padding: "10px", borderRadius: "0" }}
                      className="rfi-table tenderAccessed"
                    >
                      <table>
                        <thead>
                          <tr className="rfi-table-head">
                            <th style={{ fontWeight: 400 }}>S.No.</th>
                            <th style={{ fontWeight: 400 }}>Name</th>
                            <th style={{ fontWeight: 400 }}>Email</th>
                            <th style={{ fontWeight: 400 }}>Company Name</th>
                            <th style={{ fontWeight: 400 }}>Tender Title</th>
                            <th style={{ fontWeight: 400 }}>
                              Tender Organisation Name
                            </th>
                            <th style={{ fontWeight: 400 }}>Sector</th>
                            <th style={{ fontWeight: 400 }}>Category</th>
                            <th style={{ fontWeight: 400 }}>
                              Accessed Timestamp
                            </th>
                          </tr>
                        </thead>
                        {accessedTenders
                          .slice((page - 1) * itemPerPage, page * itemPerPage)
                          .map((tender, index) => (
                            <tbody key={tender?.accessed_tender_id}>
                              <tr>
                                <td className="pl-3" size={6}>
                                  {(page - 1) * itemPerPage + index + 1}
                                </td>
                                <td size={4} className="pl-3">
                                  {`${tender?.firstName} ${tender?.lastName}`}
                                </td>
                                <td size={4} className="pl-3">
                                  {tender?.email}
                                </td>
                                <td size={4} className="pl-3">
                                  {tender?.companyName}
                                </td>

                                <td className="pl-3">{tender?.title}</td>
                                <td className="pl-3">
                                  {tender?.organization_name}
                                </td>
                                {/* <td className="pl-3">{tender?.sector}</td> */}
                                <td className="pl-3">
                                  {tender &&
                                  tender.sector &&
                                  tender.sector[0] !== "["
                                    ? `${tender?.sector}`
                                    : (tender &&
                                        tender.sector &&
                                        tender.sector[0] === "[" &&
                                        JSON.parse(tender.sector).map(
                                          (cat, index) => {
                                            if (
                                              JSON.parse(tender.sector).length -
                                                1 ===
                                                index ||
                                              JSON.parse(tender.sector)
                                                .length === 1
                                            ) {
                                              return cat;
                                            } else {
                                              return `${cat}, `;
                                            }
                                          }
                                        )) ||
                                      "N/A"}
                                </td>
                                <td className="pl-3">
                                  {tender &&
                                  tender.category &&
                                  tender.category[0] !== "["
                                    ? `${tender?.category}`
                                    : (tender &&
                                        tender.category &&
                                        tender.category[0] === "[" &&
                                        JSON.parse(tender.category).map(
                                          (cat, index) => {
                                            if (
                                              JSON.parse(tender.category)
                                                .length -
                                                1 ===
                                                index ||
                                              JSON.parse(tender.category)
                                                .length === 1
                                            ) {
                                              return cat;
                                            } else {
                                              return `${cat}, `;
                                            }
                                          }
                                        )) ||
                                      "N/A"}
                                </td>

                                <td className="pl-3">
                                  {/* {`${
                                  new Date(tender?.TIMESTAMP).getMonth() + 1
                                }/${new Date(
                                  tender?.TIMESTAMP
                                ).getDate()}/${new Date(
                                  tender?.TIMESTAMP
                                ).getFullYear()} - ${new Date(
                                  tender?.TIMESTAMP
                                ).getHours()}:${new Date(
                                  tender?.TIMESTAMP
                                ).getMinutes()}:${new Date(
                                  tender?.TIMESTAMP
                                ).getSeconds()}`} */}
                                  {dateFormatter(tender.TIMESTAMP)}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </div>
                  </>
                )}
              </IonRow>
            </IonGrid>
          </form>
          {tendersAccessedCount > 1 && (
            <PaginationControlled
              count={tendersAccessedCount}
              setPage={setPage}
              page={page}
            />
          )}
        </>
      ) : (
        <h5 style={{ textAlign: "center" }}>No Data Found</h5>
      )}
    </div>
  );
};

export default TendersAccessed;
