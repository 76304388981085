import { ACTION_TYPE } from "../../Services/index";

export const initialState = {
  addEditReqStatus: {},
  ratingReviewRequest: [],
  myRatingsAndReview: [],
  allRatingsAndReview: [],
  buyerRatingsAndReview: [],
  pendingRatingReview: [],
  ratingReviewInfo: {},
  topRatingsAndReview: [],
  updatedStatus: false,
  approvedRatingReview: [],
  ratingReviewGivenInfo: {},
  ratingReviewRequestById: [],
};

export const RatingReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.ADD_EDIT_RATING_REVIEW_REQUEST:
      console.log("get rating review req in reducer", action.payload);
      return {
        ...state,
        addEditReqStatus: action.payload,
      };

    case ACTION_TYPE.GET_RATING_REVIEW_REQUEST:
      return {
        ...state,
        ratingReviewRequest: action.payload.data,
      };

    case ACTION_TYPE.GET_RATING_REVIEW_REQUEST_BY_ID:
      return {
        ...state,
        ratingReviewRequestById: action.payload.data,
      };

    case ACTION_TYPE.GET_MY_RATING_REVIEW:
      return {
        ...state,
        myRatingsAndReview: action.payload.data,
      };

    case ACTION_TYPE.GET_ALL_RATING_REVIEW:
      return {
        ...state,
        allRatingsAndReview: action.payload.data,
      };

    case ACTION_TYPE.GET_BUYER_RATING_REVIEW:
      return {
        ...state,
        buyerRatingsAndReview: action.payload.data,
      };

    case ACTION_TYPE.GET_PENDING_RATING_REVIEW:
      return {
        ...state,
        pendingRatingReview: action.payload.data,
      };

    case ACTION_TYPE.GET_RATING_REVIEW_INFO:
      return {
        ...state,
        ratingReviewInfo: action.payload.data,
      };

    case ACTION_TYPE.GET_RATING_REVIEW_GIVEN_INFO:
      return {
        ...state,
        ratingReviewGivenInfo: action.payload.data,
      };

    case ACTION_TYPE.GET_TOP_RATING_REVIEW:
      return {
        ...state,
        topRatingsAndReview: action.payload.data,
      };

    case ACTION_TYPE.UPDATE_RATING_REVIEW_STATUS:
      return {
        ...state,
        updatedStatus: action.payload.data,
      };

    case ACTION_TYPE.GET_APPROVED_RATING_REVIEW:
      return {
        ...state,
        approvedRatingReview: action.payload.data,
      };

    default:
      return { ...state };
  }
};
