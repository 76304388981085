import * as React from "react";
import Box from "@mui/material/Box";
import "./Modal.css";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  fontSize: "larger",
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
};

export default function Modal2(props) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const loading = useSelector((state) => state.commonReducer.loading);
  const open = props.open;
  const handleClose = props.handleClose;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {props.message ? (
          <Box sx={style} className="messageBox">
            <h1>{props.message}</h1>
            <div className="closeDiv" onClick={(e) => props.handleClose(false)}>
              <IonIcon icon={closeOutline} className="modalCloseIcon" />
            </div>
            {props && props.subtitle && (
              <div className="subtitle">{props.subtitle}</div>
            )}
            {props.button && (
              <div className="modalBtnOptions">
                <button
                  className="rfi-modal-yes-button"
                  onClick={(e) => {
                    props.handleClose(false);
                    props.handleSubmit();
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Yes"}
                </button>
                <button
                  className="rfi-foot-back-button"
                  onClick={(e) => props.handleClose(false)}
                >
                  NO, Go Back
                </button>
              </div>
            )}
          </Box>
        ) : props.image1 ? (
          <Box sx={style} className="Image2Box">
            <img
              alt="email"
              src={require("../../../images/CommonImages/Approve.png")}
            />
            <div className="closeDiv" onClick={(e) => props.handleClose(false)}>
              <IonIcon icon={closeOutline} className="modalCloseIcon" />
            </div>
          </Box>
        ) : props.image2 ? (
          <Box sx={style} className="Image2Box">
            <img
              alt="email"
              src={require("../../../images/CommonImages/reject.png")}
            />
            <div className="closeDiv" onClick={(e) => props.handleClose(false)}>
              <IonIcon icon={closeOutline} className="modalCloseIcon" />
            </div>
          </Box>
        ) : null}
      </Modal>
    </div>
  );
}
