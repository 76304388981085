import React, { useEffect } from "react";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getRatingReviewGivenInfo } from "../../ReduxStore/Actions/ratingReviewActions";
import { formatDate, formatDateYearMonth } from "../../Utils/helpers";
import { RATING_REQUEST_STATUS } from "../../Services/Contants";
import { Link, useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";

const ProductRating = (props) => {
  let { id } = props.match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  console.log("id in url product rating", id);

  const { ratingReviewGivenInfo } = useSelector(
    (state) => state.RatingReviewReducer
  );

  console.log("ratingReview info in product Rating", ratingReviewGivenInfo);

  const authData = useSelector((state) => state.authReducer.authData);
  console.log("authData in product rating a", authData);

  const loading = useSelector((state) => state.commonReducer.loading);

  useEffect(() => {
    dispatch(getRatingReviewGivenInfo(id));
  }, [dispatch, id]);

  const showRatingReview = () => {
    const {
      anonymity,
      firstName,
      lastName,
      isTncAccepted,
      name: productName,
      rating,
      rating_given_at,
      requested_at,
      review,
      review_headline,
      reviewer_company_name,
      reviewerCompanyName,
      status,
      product_id,
      reviewer_name,
      vendor_company_name,
      organisationType,
      companyHq,
    } = ratingReviewGivenInfo;
    return (
      <div className="mainContent">
        <div className="titleContainer">
          <h2>{productName} Review</h2>
        </div>
        <div className="productReviewContainer">
          <div className="reviewRow">
            <div>
              <p>Product Name</p>
            </div>
            <div>
              <Link to={`/product/${product_id}`}>
                <p>{productName}</p>
              </Link>
            </div>
          </div>
          {/* {(authData.role === 1 || authData.role === 2) && (
            <div className="reviewRow">
              <div>
                <p>Reviewer Name</p>
              </div>
              <div>
                <p>{reviewer_name}</p>
              </div>
            </div>
          )} */}
          {(authData.role === 0 || authData.role === 2) && (
            <div className="reviewRow">
              <div>
                <p>Vendor Name</p>
              </div>
              <div>
                <p>{vendor_company_name}</p>
              </div>
            </div>
          )}
          <div className="reviewRow">
            <div>
              <p>Reviewer Company Name</p>
            </div>
            <div>
              <p>
                {anonymity === "true"
                  ? `${organisationType}, ${companyHq}`
                  : reviewerCompanyName || reviewer_company_name}
              </p>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Date Requested</p>
            </div>
            <div>
              <p>{formatDate(requested_at)}</p>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Date Submitted</p>
            </div>
            <div>
              <p>{formatDate(rating_given_at)}</p>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Review Headline</p>
            </div>
            <div>
              <p>{review_headline}</p>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Review</p>
            </div>
            <div>
              <p
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {review}
              </p>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Rating</p>
            </div>
            <div>
              <div className="rate">
                <input
                  type="radio"
                  id="star5"
                  name="rate"
                  value="5"
                  checked={rating === 5}
                />
                <label htmlFor="star5" title="text"></label>
                <input
                  type="radio"
                  id="star4"
                  name="rate"
                  value="4"
                  checked={rating === 4}
                />
                <label htmlFor="star4" title="text"></label>
                <input
                  type="radio"
                  id="star3"
                  name="rate"
                  value="3"
                  checked={rating === 3}
                />
                <label htmlFor="star3" title="text"></label>
                <input
                  type="radio"
                  id="star2"
                  name="rate"
                  value="2"
                  checked={rating === 2}
                />
                <label htmlFor="star2" title="text"></label>
                <input
                  type="radio"
                  id="star1"
                  name="rate"
                  value="1"
                  checked={rating === 1}
                />
                <label htmlFor="star1" title="text"></label>
              </div>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Keep Organisation Anonymous</p>
            </div>
            <div>
              <p>{anonymity === "true" ? "Yes" : "No"}</p>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Marketing Permissions</p>
            </div>
            <div>
              <p>{isTncAccepted === 1 ? "Yes" : "No"}</p>
            </div>
          </div>
          <div className="reviewRow">
            <div>
              <p>Status</p>
            </div>
            <div>
              <p>{status && RATING_REQUEST_STATUS[status].text}</p>
              {/* <p>{status}</p> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="requestReviewContainer reqReviewFlexContainer">
        <div className="arrowBackIcon">
          <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
            <ArrowBack />
          </span>
        </div>
        {loading && <CircularProgress />}
        {!loading && ratingReviewGivenInfo && showRatingReview()}
      </div>
      <Footer />
    </>
  );
};

export default ProductRating;
