function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}
function tofindDuplicates(questions) {
  return questions.filter((item, index) => questions.indexOf(item) !== index);
}
export const ValidateSignUpForm = (formData) => {
  // console.log(" questions FORMDATAA questions", formData.questions);
  const { questions, openQuestion } = formData || {};
  console.log(" questions FORMDATAA questions", questions);
  let duplicateElementa = tofindDuplicates(questions);

  let error = {
    questions: [],
    openQuestion: [],
  };
  let isFormValid = true;
  if (questions.length === 0) {
    isFormValid = false;
    error.questions = ["This field is required."];
  } else if (
    (questions.length > 0 && questions.length < 5) ||
    questions.includes(undefined) ||
    questions.includes(null) ||
    questions.includes("")
  ) {
    isFormValid = false;
    error.questions = [" Add at least five questions"];
  } else if (duplicateElementa.length > 0) {
    isFormValid = false;
    error.questions = ["Remove Duplicate values"];
  }

  if (isBlank(openQuestion) === true) {
    isFormValid = false;
    error.openQuestion = ["This field is required."];
  } else if (
    openQuestion?.trim().length > 0 &&
    openQuestion?.trim().length > 1000
  ) {
    isFormValid = false;
    error.openQuestion = [
      "Open question cannot be greater than 1000 characters.",
    ];
  }
  return { isFormValid, error };
};
