import { IonCol, IonGrid, IonInput, IonLabel, IonRow } from "@ionic/react";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { matchToken } from "../../ReduxStore/Actions/authActions";
import copy from "copy-to-clipboard";
import {
  addEditVendorUser,
  getVendorUsers,
} from "../../ReduxStore/Actions/vendorAction";
import "./ManageVendorAdmin.css";

const VendorUserModal = (props) => {
  const authData = useSelector((state) => state.authReducer.authData);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [vendorUserId, setVendorUserId] = useState("");
  const [changePassword, setChangePassword] = useState(false);

  const [toolTipTitleEmail, setToolTipTitleEmail] = useState("Copy Email");
  const [toolTipTitlePassword, setToolTipTitlePassword] =
    useState("Copy Password");

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const onAddEditVendorUser = () => {
    if (/\S+@\S+\.\S+/.test(email) === false) {
      setError("Please enter a valid email");
      return;
    }
    // if (confirmPassword !== password) {
    //   setError("Password does not match");
    //   return;
    // }
    if (vendorUserId && !email && !changePassword) {
      setError("Please enter email");
      return;
    }
    if (vendorUserId && changePassword && (!password || !email)) {
      setError("Please enter email and password");
      return;
    }
    if (!vendorUserId && (!password || !email)) {
      setError("Please enter email and password");
      return;
    }
    dispatch(
      addEditVendorUser(
        {
          email,
          password,
          vendorUserId,
          fromEdit: props.selectedVendor?.email
            ? props.selectedVendor.email
            : "",
          userId: authData?.attachedUserId
            ? authData?.attachedUserId
            : authData?.id,
        },
        () => {
          dispatch(
            getVendorUsers(props.mainUserId ? props.mainUserId : authData?.id)
          );
          dispatch(matchToken());
          props.onHide();
        }
      )
    );
  };

  useEffect(() => {
    if (props.selectedVendor) {
      setEmail(props.selectedVendor.email);
      setVendorUserId(props.selectedVendor.id);
    }
  }, []);

  console.log("selectedVendor===>", vendorUserId);

  useEffect(() => {
    if (toolTipTitleEmail === "Copied!") {
      setTimeout(() => {
        setToolTipTitleEmail("Copy Email");
      }, 2500);
    }
  }, [toolTipTitleEmail]);

  useEffect(() => {
    if (toolTipTitlePassword === "Copied!") {
      setTimeout(() => {
        setToolTipTitlePassword("Copy Password");
      }, 2500);
    }
  }, [toolTipTitlePassword]);

  return (
    <Modal
      {...props}
      size="md"
      onHide={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="vendorUserModalContainer"
    >
      {/* <Modal.Header > */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {vendorUserId ? "Edit " : "Add "}User Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="customListTop">
          <form className="vendorUserFormContainer">
            <div className="formInputContainer emailContainer">
              <div className="emailInputContainer">
                <IonLabel>Email</IonLabel>

                <IonInput
                  type="email"
                  name="email"
                  className="form-control"
                  value={email}
                  onIonChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder="Enter email"
                ></IonInput>
              </div>
              <div className="emailCopyContainer">
                <Tooltip title={toolTipTitleEmail}>
                  <IconButton
                    aria-label="copy"
                    onClick={() => {
                      copy(email);
                      setToolTipTitleEmail("copied!");
                    }}
                  >
                    <FileCopyRoundedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            {vendorUserId && !changePassword ? (
              <Button onClick={() => setChangePassword(true)}>
                Change Password
              </Button>
            ) : (
              <div className="formInputContainer passwordContainer">
                <div className="passwordInputContainer">
                  <IonLabel>Password</IonLabel>
                  <IonInput
                    type="text"
                    name="password"
                    value={password}
                    className="form-control"
                    onIonChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    placeholder="Enter password"
                  ></IonInput>
                </div>
                <div className="passwordCopyContainer">
                  <Tooltip title={toolTipTitlePassword}>
                    <IconButton
                      aria-label="copy"
                      onClick={() => {
                        copy(password);
                        setToolTipTitlePassword("copied!");
                      }}
                    >
                      <FileCopyRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                {/* <br />
                <IonLabel>Confirm Password</IonLabel>
                <IonInput
                  type="text"
                  name="confirmPassword"
                  value={confirmPassword}
                  className="form-control"
                  onIonChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                  placeholder="Confirm password"
                ></IonInput> */}
              </div>
            )}
            {error && (
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            )}
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}> Close</Button>
        <Button onClick={onAddEditVendorUser}>
          {vendorUserId ? "Save" : "Add User"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VendorUserModal;
