import { Card, Button, CardContent, Typography } from "@material-ui/core";
import React, { useState } from "react";
import "../Approve/aprove.css";
import SideNav from "../sidebar/sideNav";
import { useHistory, useParams } from "react-router";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  HomeAction,
  AdminAction,
  SearchAction,
  RFIAction,
} from "../../ReduxStore/Actions/index";
import { useLocation } from "react-router-dom";
import FormFour from "../../DashboardModules/multiStepForm/Components/FormFour/FormFour";
import { ROUTES } from "../../Services";
import { IonContent } from "@ionic/react";

const Rfi_details = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  let { rfiData } = useSelector((state) => state.AdminReducer);
  console.log("rfiData", rfiData);
  const { authData } = useSelector((state) => state.authReducer);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [allowListVendors, setAddAllowListVendors] = useState([]);
  const [blockListVendors, setBlockListVendors] = useState([]);
  const [state, setState] = useState();
  let { approvedVendors } = useSelector((state) => state.RFIReducer);
  let filters = useSelector((state) => state.searchReducer.filters);
  let history = useHistory();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(SearchAction.getFilters());

      await dispatch(AdminAction.getRFIbyId(id));
    })();
    return () => {
      dispatch({ type: "GET_RFI_BY_ID", payload: [] });
    };
  }, []);

  useEffect(() => {
    if (
      approvedVendors &&
      approvedVendors?.athlete &&
      rfiData &&
      rfiData.length > 0
    ) {
      if (rfiData[0].customVendors) {
        const { athlete, fanDigitalMedia, commercial, managementOperation } =
          approvedVendors;
        const allVendors = [
          ...athlete,
          ...fanDigitalMedia,
          ...commercial,
          ...managementOperation,
        ];

        console.log("all vendors", allVendors);

        const vendors = rfiData[0].customVendors.split(",");
        console.log("vendors===.", vendors);
        vendors.forEach((vendorId) => {
          const selectedData = allVendors.find(
            (item) => item.vendor_id === Number(vendorId)
          );
          console.log("selectedData", selectedData);
          setSelectedVendors((prev) =>
            !prev.includes(selectedData?.companyName)
              ? [...prev, selectedData?.companyName]
              : [...prev]
          );
          setAddAllowListVendors(
            rfiData[0].allowList ? rfiData[0].allowList.split(",") : []
          );
          setBlockListVendors(
            rfiData[0].blockList ? rfiData[0].blockList.split(",") : []
          );
        });
      }
    }
  }, [approvedVendors, rfiData]);

  useEffect(() => {
    (async () => {
      await dispatch(RFIAction.getApprovedVendors());
    })();
  }, []);

  useEffect(() => {
    if (rfiData && rfiData[0]) {
      console.log("RFI3", rfiData);
      // // rfiData[0].level_of_sport = rfiData[0]?.level_of_sport?.split(",");
      const level_of_sport = rfiData[0]?.level_of_sport?.split(",");
      // // console.log("RFI2", rfiData[0]);
      // // rfiData[0].sectors = rfiData[0]?.sectors.split(",");
      let sectors = rfiData[0]?.sectors.split(",");
      // rfiData[0].sectors = rfiData[0]?.sectors.map((i) => Number(i));
      sectors = sectors.map((i) => Number(i));
      // // console.log("SECTOR", typeof rfiData[0].sectors[0]);
      // // rfiData[0].questions = rfiData[0]?.questions.split("|");
      const questions = rfiData[0]?.questions.split("|");
      setState({ ...rfiData[0], level_of_sport, sectors, questions });
    }
  }, [rfiData]);

  return (
    <IonContent>
      <div className="top1 rfi-Details">
        <div className="mainBody ">
          <div className="">
            <SideNav />

            <div className="col-sm-12 col-md-9 viewApprove  vendor-list ">
              <div className="productHead">
                <h3 style={{ margin: "48px 0 0" }} className="heading">
                  {" "}
                  RFI Details
                </h3>

                <div className="rfi-approve-reject">
                  {authData &&
                  authData?.role === 2 &&
                  rfiData &&
                  rfiData?.length > 0 &&
                  rfiData[0]?.isApproved === 0 ? (
                    <>
                      <Button
                        className="col-md-4 col-sm-4 mr-1 text-right Viewbtn"
                        onClick={() => {
                          dispatch(
                            AdminAction.approveRFI({
                              userId: rfiData[0].userId,
                              rfiId: rfiData[0].rfi_id,
                            })
                          );
                          history.push(ROUTES.ApproveRFI);
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        className="col-md-4 col-sm-4 text-right Viewbtn"
                        onClick={() => {
                          dispatch(
                            AdminAction.rejectRFI({
                              userId: rfiData[0].userId,
                              rfiId: rfiData[0].rfi_id,
                            })
                          );
                          history.push(ROUTES.ApproveRFI);
                        }}
                      >
                        Reject
                      </Button>
                    </>
                  ) : null}
                  {authData &&
                    authData?.role === 2 &&
                    rfiData &&
                    rfiData?.length > 0 &&
                    rfiData[0].isExpired === 0 && (
                      <Button
                        className="col-md-4 col-sm-4 text-right Viewbtn"
                        onClick={() => {
                          history.push(ROUTES.UpdateRFI_AdminNotId + id);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                </div>
              </div>
              {rfiData && rfiData[0] && state && (
                <div className="rfi-details-bottom">
                  <FormFour
                    state={state}
                    filters={filters}
                    selectedVendors={selectedVendors}
                    blockListVendors={blockListVendors}
                    allowListVendors={allowListVendors}
                    type="hide"
                  ></FormFour>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default Rfi_details;
