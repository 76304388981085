import { ACTION_TYPE } from "../../Services/index";

export const initialState = {
  tender: [],
  tenders: [],
  sectors: [],
  categories: [],
  publishedTenders: [],
  accessedTenders: [],
};

export const TenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.ADD_EDIT_TENDER:
      console.log("get tender in reducer", action.payload);
      return {
        ...state,
        tender: action.payload,
      };
    case ACTION_TYPE.GET_ALL_TENDERS:
      console.log("get all tender in reducer", action.payload);
      return {
        ...state,
        tenders: action.payload.data,
      };

    case ACTION_TYPE.GET_SECTORS:
      console.log("sectors in reducer", action.payload);
      return {
        ...state,
        sectors: action.payload.data,
      };

    case ACTION_TYPE.GET_CATEGORIES:
      console.log("categories in reducer", action.payload);
      return {
        ...state,
        categories: action.payload.data,
      };

    case ACTION_TYPE.GET_PUBLISHED_TENDERS:
      console.log("get published tender in reducer", action.payload);
      return {
        ...state,
        publishedTenders: action.payload.data,
      };

    case ACTION_TYPE.GET_ACCESSED_TENDERS:
      console.log("get accessed tender in reducer", action.payload);
      return {
        ...state,
        accessedTenders: action.payload.data,
      };
    default:
      return state;
  }
};
