import React, { useEffect, useState } from "react";
import SideBar from "../../adminPanel/sidebar/sideNav";
import { Link, useHistory } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import { IonToggle } from "@ionic/react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SideMenu from "../../components/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getRatingReviewInfo,
  updateRatingReviewStatus,
} from "../../ReduxStore/Actions/ratingReviewActions";
import { CircularProgress } from "@material-ui/core";

const AdminRatingInfo = (props) => {
  let { id } = props.match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  const [status, setStatus] = useState(null);
  const { ratingReviewInfo } = useSelector(
    (state) => state.RatingReviewReducer
  );
  console.log("ratingReviwe info in admin rating info", ratingReviewInfo);

  const loading = useSelector((state) => state.commonReducer.loading);
  const { updatedStatus } = useSelector((state) => state.RatingReviewReducer);
  console.log("udpated status in admin rating info", updatedStatus);
  if (updatedStatus) {
    history.replace("/approve-review");
  }

  const handleApproveReject = (data) => {
    if (data.status === "APPROVED") {
      setStatus("APPROVED");
    } else if (data.status === "REJECTED") {
      setStatus("REJECTED");
    }
    dispatch(updateRatingReviewStatus(data));
  };
  useEffect(() => {
    dispatch(getRatingReviewInfo(id));
  }, [dispatch, id]);
  console.log("id in admin rating info", id);
  return (
    <>
      <div className="requestReviewContainer newRatingParent newContainerStyle reviewInfoBlock">
        <SideBar />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>New Rating & Review Info</h2>
            <div>
              <div className="reviewBtnContainer">
                <button
                  disabled={loading}
                  onClick={() =>
                    handleApproveReject({
                      status: "APPROVED",
                      id: ratingReviewInfo.rating_given_id,
                      rating_requested_id: ratingReviewInfo.rating_requested_id,
                    })
                  }
                >
                  Approve
                  {loading && status === "APPROVED" ? (
                    <CircularProgress
                      size={20}
                      sx={{ marginLeft: "1rem", color: "#FFFFFF" }}
                    />
                  ) : null}
                </button>
                <button
                  disabled={loading}
                  onClick={() =>
                    handleApproveReject({
                      status: "REJECTED",
                      id: ratingReviewInfo.rating_given_id,
                      rating_requested_id: ratingReviewInfo.rating_requested_id,
                    })
                  }
                >
                  Reject
                  {loading && status === "REJECTED" ? (
                    <CircularProgress
                      size={20}
                      color="success"
                      sx={{ marginLeft: "1rem" }}
                    />
                  ) : null}
                </button>
              </div>
            </div>
          </div>
          <form className="formContainer">
            <div className="formRow">
              <div>
                <label>Review</label>
                <textarea
                  id="ratingAndReview"
                  name="ratingAndReview"
                  rows="4"
                  cols="50"
                  disabled
                  value={ratingReviewInfo.review}
                />
              </div>
            </div>
            <div className="formRow">
              <div>
                <label>Rating</label>
                <div>
                  <div className="rate">
                    <input
                      type="radio"
                      id="star5"
                      name="rate"
                      value="5"
                      checked={ratingReviewInfo.rating === 5}
                    />
                    <label htmlFor="star5" title="text"></label>
                    <input
                      type="radio"
                      id="star4"
                      name="rate"
                      value="4"
                      checked={ratingReviewInfo.rating === 4}
                    />
                    <label htmlFor="star4" title="text"></label>
                    <input
                      type="radio"
                      id="star3"
                      name="rate"
                      value="3"
                      checked={ratingReviewInfo.rating === 3}
                    />
                    <label htmlFor="star3" title="text"></label>
                    <input
                      type="radio"
                      id="star2"
                      name="rate"
                      value="2"
                      checked={ratingReviewInfo.rating === 2}
                    />
                    <label htmlFor="star2" title="text"></label>
                    <input
                      type="radio"
                      id="star1"
                      name="rate"
                      value="1"
                      checked={ratingReviewInfo.rating === 1}
                    />
                    <label htmlFor="star1" title="text"></label>
                  </div>
                  {/* <div className="reviewContainer approveReview">
                  </div> */}
                </div>
              </div>
            </div>
            <div className="formRow">
              <div>
                <label>Review Headline</label>
                <input
                  type="text"
                  name="ReviewHeadline"
                  value={ratingReviewInfo.review_headline}
                  disabled
                />
              </div>
            </div>
            <div className="formRow">
              <div>
                <label>Anonymity</label>
                <fieldset id="group2" className="radioGrp">
                  <div>
                    <input
                      type="radio"
                      value="Associate my organisation name to the review"
                      name="group2"
                      checked={ratingReviewInfo.anonymity !== "true"}
                    />
                    <label>Associate my organisation name to the review</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="Keep my organisation name anonymous"
                      name="group2"
                      checked={ratingReviewInfo.anonymity === "true"}
                    />
                    <label>Keep my organisation name anonymous</label>
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminRatingInfo;
