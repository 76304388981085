import React from "react";
import { Switch } from "react-router-dom";
import WebsiteModule from "../WebsiteModule/WebsiteModule";
import {
  Login,
  SignUpNew,
  EmailVerify,
  ForgetPassword,
  ResetPassword,
} from "../DashboardModules/index";
// import {UsersList,UserDetails} from "../AdminModules/index"
import PrivateRoute from "./PrivateRoutes";
import { ROUTES } from "../Services/index";
import ManageList from "../DashboardModules/manageList/ManageList";

import VendorProfile from "../DashboardModules/VendorProfile/VendorProfile";
import Approve from "../adminPanel/Approve/approve";
import ApproveRFI from "../adminPanel/approveRFI/approveRFI";

import ViewVendor from "../adminPanel/Approve/ViewVendor";
import ViewVendors from "../adminSupportPanel/viewUser/ViewVendor";

import ViewRFI from "../adminPanel/approveRFI/ViewRFI";

import ViewProduct from "../adminPanel/viewProduct/viewProduct";

import TendersPage from "../adminPanel/Tenders/TendersPage";

import Vendor from "../DashboardModules/adminPages/Vendor";
import Product from "../DashboardModules/adminPages/Product";

import FormOne from "../DashboardModules/multiStepForm/MultiStepForm";

import RfiDashboard from "../DashboardModules/Dashboard/RfiDashboard/RfiDashboard";
import RfiResponse from "../DashboardModules/VendorJourneyOne/VendorJourneyOne";
import Rfi_details from "../adminPanel/rfiDetails/rfiDetails";
import ManageVendorAdmin from "../DashboardModules/manageVendorAdmin/ManageVendorAdmin";
import ViewBuyers from "../adminSupportPanel/viewUser/ViewBuyers";
import PublishedTendersPage from "../DashboardModules/publishedTendersPage/PublishedTendersPage";
import RequestReview from "../DashboardModules/requestReview/RequestReview";
import CreateRequest from "../DashboardModules/createRequest/CreateRequest";
import ViewRating from "../DashboardModules/viewAllRating/ViewRating";
import ApproveNewReview from "../DashboardModules/approveNewReview/ApproveNewReview";
import BuyerReview from "../DashboardModules/buyerReview/BuyerReview";
import ViewAllReview from "../DashboardModules/viewAllReview/ViewAllReview";
import RatingInfo from "../DashboardModules/ratingInfo/RatingInfo";
import PublishedReviews from "../DashboardModules/publishedReviews/PublishedReviews";
import ProductRating from "../DashboardModules/productRating/ProductRating";
import AdminRatingInfo from "../DashboardModules/adminRatingInfo";
import BuyersLanding from "../WebsiteModule/buyersLanding/BuyersLanding";
import ReviewSubmitted from "../WebsiteModule/buyersLanding/ReviewSubmitted";
import InvalidUserReview from "../WebsiteModule/buyersLanding/InvalidUserReview";

// import Chat from "../DashboardModules/Chat/Chat";

const PublicRoutes = () => {
  return (
    // <React.Fragment>
    <Switch>
      <PrivateRoute
        path={ROUTES.ForgetPassword}
        component={ForgetPassword}
        exact
      />
      <PrivateRoute
        path={ROUTES.BuyerLanding}
        component={BuyersLanding}
        exact
      />
      <PrivateRoute
        path={ROUTES.ReviewSubmitted}
        component={ReviewSubmitted}
        exact
      />
      <PrivateRoute
        path={ROUTES.InvalidUserReview}
        component={InvalidUserReview}
        exact
      />
      {/* <PrivateRoute path={ROUTES.SignUp} component={SignUp} exact /> */}
      <PrivateRoute path={ROUTES.SignUp} component={SignUpNew} exact />
      <PrivateRoute path={ROUTES.Login} component={Login} exact />
      <PrivateRoute path={ROUTES.EmailVerify} component={EmailVerify} />
      <PrivateRoute
        path={ROUTES.ResetPassword}
        component={ResetPassword}
        exact
      />
      <PrivateRoute
        path={ROUTES.VendorProfile}
        component={VendorProfile}
        exact
      />
      <PrivateRoute path={ROUTES.Vendors} component={ManageList} exact />
      <PrivateRoute
        path={ROUTES.ManagePaidVendors}
        component={ManageVendorAdmin}
        exact
      />
      <PrivateRoute path={ROUTES.Approve} component={Approve} />
      <PrivateRoute path={ROUTES.ApproveRFI} component={ApproveRFI} />

      {/* this is for support */}
      <PrivateRoute path={ROUTES.ViewVendors} component={ViewVendors} />
      <PrivateRoute path={ROUTES.ViewBuyers} component={ViewBuyers} />
      <PrivateRoute path={ROUTES.ViewVendor} component={ViewVendor} />
      <PrivateRoute path={ROUTES.ViewRFI} component={ViewRFI} />
      <PrivateRoute path={ROUTES.ViewProduct} component={ViewProduct} />
      <PrivateRoute path={ROUTES.Vendor} component={Vendor} />
      <PrivateRoute path={ROUTES.Product} component={Product} />
      <PrivateRoute path={ROUTES.formOne} component={FormOne} exact />
      <PrivateRoute path={ROUTES.RfiDashboard} component={RfiDashboard} exact />
      <PrivateRoute path={ROUTES.Tenders} component={TendersPage} exact />
      <PrivateRoute
        path={ROUTES.ViewTenders}
        component={PublishedTendersPage}
        exact
      />
      <PrivateRoute
        path={`${ROUTES.RfiResponse}/:rfiId`}
        component={RfiResponse}
        exact
      />
      <PrivateRoute path={`/rfi-details/:id`} component={Rfi_details} exact />
      <PrivateRoute path={`/saved-rfi/:id`} component={FormOne} exact />
      <PrivateRoute path={ROUTES.UpdateRFI_Admin} component={FormOne} exact />
      <PrivateRoute
        path={ROUTES.RequestReview}
        component={RequestReview}
        exact
      />
      <PrivateRoute
        path={`${ROUTES.CreateRequest}/:id`}
        component={CreateRequest}
        exact
      />
      <PrivateRoute path={ROUTES.ViewRating} component={ViewRating} exact />
      <PrivateRoute
        path={ROUTES.ApproveNewReview}
        component={ApproveNewReview}
        exact
      />
      <PrivateRoute path={ROUTES.BuyerReview} component={BuyerReview} exact />
      <PrivateRoute
        path={ROUTES.ViewAllReview}
        component={ViewAllReview}
        exact
      />
      <PrivateRoute path={ROUTES.RatingInfo} component={RatingInfo} exact />
      <PrivateRoute
        path={ROUTES.AllReviews}
        component={PublishedReviews}
        exact
      />
      <PrivateRoute
        path={`${ROUTES.ProductRating}/:id`}
        component={ProductRating}
        exact
      />
      <PrivateRoute
        path={`${ROUTES.AdminRatingInfo}/:id`}
        component={AdminRatingInfo}
        exact
      />
      <WebsiteModule></WebsiteModule>
    </Switch>
    // </React.Fragment>
  );
};

export default PublicRoutes;
