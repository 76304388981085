import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { commonReducer } from "./commonReducer";

import { ProfileReducer } from "./profileReducer";

import { pageReducer } from "./pageReducer";
import { blogReducer } from "./blogReducer";

import { contactReducer } from "./contactReducer";

import { searchReducer } from "./searchReducer";
import { productReducer } from "./productReducer";
import { vendorReducer } from "./vendorReducer";
import { homeReducer } from "./homeReducer";
import { AdminReducer } from "./AdminReducer";
import { TenderReducer } from "./tenderReducer";
import { RatingReviewReducer } from "./ratingReviewReducer";

import { RFIReducer } from "./RFIReducer";
const appReducer = combineReducers({
  authReducer,
  commonReducer,
  AdminReducer,
  ProfileReducer,

  pageReducer,
  blogReducer,

  contactReducer,
  searchReducer,
  productReducer,
  vendorReducer,
  homeReducer,

  RFIReducer,
  TenderReducer,

  RatingReviewReducer,
});

// export default  appReducer;
// export default (state, action) =>
// appReducer(action.type === 'LOGOUT_USER' ? undefined : state, action);

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    state = initialState;
  }

  return appReducer(state, action);
};
export default rootReducer;
