import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core";

const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  // anchorOrigin: {
  //   vertical: "bottom",
  //   horizontal: "center",
  // },
  // transformOrigin: {
  //   vertical: "top",
  //   horizontal: "center",
  // },
  // variant: "menu",
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

export default function MultipleSelectCheckmarks({
  productList,
  formData,
  setFormData,
}) {
  const classes = useStyles();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log("value in multi selec", value);
    setFormData({
      ...formData,
      productIds: typeof value === "string" ? value.split(",") : value,
    });
  };

  return (
    <div className="multiSelectDrop">
      <FormControl className={classes.formControl}>
        <InputLabel id="mutiple-select-label">Select Products</InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          id="multipleSelect"
          value={formData.productIds}
          onChange={handleChange}
          input={<OutlinedInput label="Select Product" />}
          renderValue={(selected) =>
            selected
              .map((el) => {
                const product = productList.find((prod) => prod.id === el);
                return product.productName;
              })
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {productList.map(({ id, productName }) => (
            <MenuItem key={id} value={id}>
              <Checkbox checked={formData.productIds.indexOf(id) > -1} />
              <ListItemText primary={productName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
