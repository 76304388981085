import Api from "../../Api";
import { handleError } from "./commonAction";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";

export const contactVendor = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("CONTACT", body);
    await Api.post(ENDPOINTS.CONTACT_VENDOR, body);

    // dispatch({
    //   type: ACTION_TYPE.CONTACT_US,
    //   payload: { data: res.data },
    // });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const Newsletter = (params) => async (dispatch) => {
  try {
    console.log("emaillllllllllllll", params);
    const res = await Api.post(ENDPOINTS.NEWSLETTER, params);

    dispatch({
      type: ACTION_TYPE.NEWSLETTER,
      payload: "data",
    });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const contactUs = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("CONTACT", body);
    const res = await Api.post(ENDPOINTS.CONTACT_US, body);

    console.log("CONTACT", res);
    dispatch({
      type: ACTION_TYPE.CONTACT_US,
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
