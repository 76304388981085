import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
  questionList: [],
  userRfis: [],
  rfiData: {},
  respondRfi: [],
  products: [],
  savedData: [],
  buyerData: [],
  approvedVendors: [],
  approvedVendorsCompany: [],
};

export const RFIReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.QUESTION_LIST:
      console.log("QUESTION_LIST", action.payload);
      return {
        ...state,
        questionList: action.payload.data,
      };
    case ACTION_TYPE.GET_RFI:
      console.log("rfiiiiii SSSS", action.payload);
      return {
        ...state,
        userRfis: action.payload.data,
      };
    case ACTION_TYPE.GET_RFI_RESPONSE:
      console.log("rfiiiiii SSSS", action.payload);
      return {
        ...state,
        rfiData: action.payload.data,
      };
    case ACTION_TYPE.RESPOND_RFI:
      console.log("rfiiiiii SSSS", action.payload);
      return {
        ...state,
        respondRfi: action.payload.data,
      };
    case ACTION_TYPE.GET_ALL_PRODUCTS:
      console.log("rfiiiiii SSSS", action.payload);
      return {
        ...state,
        products: action.payload.data,
      };
    case ACTION_TYPE.GET_SAVED_RESPONSE:
      return {
        ...state,
        savedData: action.payload.data,
      };
    case ACTION_TYPE.GET_BUYER_EVALUATION_DATA:
      return {
        ...state,
        buyerData: action.payload.data,
      };
    case ACTION_TYPE.GET_APPROVED_VENDORS:
      return {
        ...state,
        approvedVendors: action.payload.data,
      };
    case ACTION_TYPE.GET_APPROVED_VENDORS_COMPANY:
      return {
        ...state,
        approvedVendorsCompany: action.payload.data,
      };
    default:
      return state;
  }
};
