import React, { useEffect, useState } from "react";
import SideBar from "../../adminPanel/sidebar/sideNav";
import { Link, useHistory } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovedRatingReview,
  updateRatingReviewStatus,
} from "../../ReduxStore/Actions/ratingReviewActions";
import { formatDate } from "../../Utils/helpers";
import { CircularProgress } from "@material-ui/core";
import Search from "../../adminPanel/SearchBar/searchbar";
import logoStm from "../../images/logoStm.png";

const ViewAllReview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { approvedRatingReview } = useSelector(
    (state) => state.RatingReviewReducer
  );

  console.log("approvedRatingReview", approvedRatingReview);

  const loading = useSelector((state) => state.commonReducer.loading);

  const handleChangeStatus = async (data) => {
    await dispatch(updateRatingReviewStatus(data));
    await dispatch(getApprovedRatingReview());
    // history.go(0);
  };
  useEffect(() => {
    dispatch(getApprovedRatingReview());
  }, [dispatch]);

  return (
    <>
      <div className="requestReviewContainer newContainerStyle">
        <SideBar />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>View All Rating & Review</h2>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Search type="ratingReview" />
            {approvedRatingReview &&
              approvedRatingReview.length > 0 &&
              approvedRatingReview.map((review) => (
                <div key={review.rating_given_id} className="productList">
                  <div className="productImg">
                    <img
                      style={{ objectFit: "contain" }}
                      src={
                        review?.logoUrl.includes("vendor-placeholder-logo.png")
                          ? logoStm
                          : review.logoUrl
                      }
                      alt="logo"
                    />
                  </div>
                  <div className="productInfoContainer">
                    <h2>{review.name}</h2>
                    <div className="productInfoTable">
                      <div>
                        <p>Name of Reviewer</p>
                        <p>:</p>
                        <p>{review.reviewer_name}</p>
                      </div>
                      <div>
                        <p>Company Name of Reviewer</p>
                        <p>:</p>
                        <p>{review.reviewer_company_name}</p>
                      </div>
                      <div>
                        <p>Email of Reviewer</p>
                        <p>:</p>
                        <p>{review.reviewer_email}</p>
                      </div>
                      <div>
                        <p>Date of Creation</p>
                        <p>:</p>
                        <p>{formatDate(review.rating_given_at)}</p>
                      </div>
                      <div>
                        <p>Status</p>
                        <p>:</p>
                        <p style={{ color: "green" }}>{review.given_status}</p>
                      </div>
                    </div>
                  </div>
                  <div className="btnContainers">
                    <button>
                      <Link to={`/product-rating/${review.rating_given_id}`}>
                        View Rating & Review
                      </Link>
                    </button>
                    <button
                      onClick={() =>
                        handleChangeStatus({
                          status: "HIDDEN",
                          id: review.rating_given_id,
                          rating_requested_id: review.rating_requested_id,
                        })
                      }
                      disabled={loading}
                    >
                      Hide Rating & Review{" "}
                    </button>
                  </div>
                </div>
              ))}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "30px",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!loading &&
              approvedRatingReview &&
              approvedRatingReview.length <= 0 && (
                <h3>No Rating & Review Found</h3>
              )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewAllReview;
