import React, { useState } from "react";
import { IonSearchbar, IonIcon } from "@ionic/react";
import "./searchBar.css";
import { useDispatch } from "react-redux";

import { searchOutline } from "ionicons/icons";
import { Button } from "@material-ui/core";
import { AdminAction } from "../../ReduxStore/Actions";

const Search = (props) => {
  let dispatch = useDispatch();
  console.log("search props", props);
  const [search, setSearch] = useState("");
  const handleSearchClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await dispatch(AdminAction.searchAdmin(search, props.type));
  };
  const handleSearchTextChange = async (value) => {
    setSearch(value);
    console.log("value search", value, typeof value);
    if (value === "") {
      console.log("value search inside");

      await dispatch(AdminAction.searchAdmin("", props.type));
    }
  };
  return (
    <form onSubmit={handleSearchClick}>
      <div className="searchBar SearchBar d-flex m-auto text-center">
        <IonSearchbar
          className=" SearchBar2"
          value={search}
          onIonChange={(e) => handleSearchTextChange(e.detail.value)}
          // showCancelButton="always"
          // debounce={1000}
          animated
          placeholder="Search here?
          "
        />

        <div className="d-flex flex-row">
          <Button
            className="searchBarButton"
            type="submit"

            // onClick={this.handleSearchClick}
          >
            <IonIcon icon={searchOutline} style={{ marginRight: "5px" }} />
            Search
          </Button>
        </div>

        {/* <div className="d-flex flex-row">
          <IonButton
            className="searchBarButton"
            type="submit"

      
          >
            <IonIcon
          
              style={{ marginRight: "5px" }}
            />
            Search
          </IonButton>
        </div> */}
      </div>
    </form>
  );
};
export default Search;
