export const APPLICATION_CONSTANTS = {
  //Local Storage Constant
  TOKEN: "token",
  USER_DATA: "userData",
  REMEMBER_ME: "rememberme",

  //KEYS
  REMEMBER_ME_SECRETE_KEY: "remembermesecret$#",
  REMEMBER_USER_SECRETE_KEY: "UserSecretKey",

  //Profile Tab View Constants
  ALL_POST_TAB: 1,
  CONNECTIONS_TAB: 2,
  INTERESTS_TAB: 3,

  //profile description

  BEST_DESCRIBES_YOU: "Add",
  DOB: "Add",
  INDUSTRY: "Add",
  AREA_OF_STUDY: "Add",
  SCHOOL_CITY: "Add",
  CURRENTLY_INVOLVED: "Add",
  TITLE: "Add",
  BIO: "Add",

  //Roles Management
  USER_ROLE_STUDENT: 1,
  USER_ROLE_PROFESSIONAL: 2,
};
