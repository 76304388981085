import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonToast,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./VendorProfile.css";
import Tooltip from "../tooltip/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { AuthActions, ProfileAction } from "../../ReduxStore/Actions/index";
import { Countries } from "../../Services/Contants";
import { ValidateSignUpForm } from "./Validation";

const VendorProfile = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [profileImagePreview, setProfileImagePreview] = useState();
  const [errorData, setError] = React.useState();
  const [yearDrop, setYearDrop] = React.useState([]);

  const authData = useSelector((state) => state.authReducer.authData);
  console.log(
    "authDta in buyer profile",
    authData,
    Object.keys(authData).includes("support") && authData.role === 0
  );
  const ProfileData = useSelector(
    (state) => state.ProfileReducer.userProfileUpdated
  );

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastError, setToastError] = useState();
  useEffect(() => {
    let years = [];
    var d = new Date();
    var n = d.getFullYear();

    for (let i = 1950; i <= n; i++) {
      years.push(i);
    }
    console.log(years);
    setYearDrop(years);
  }, []);

  useEffect(() => {
    let profile = authData;
    if (ProfileData === undefined) {
      profile = authData;
    } else {
      profile = ProfileData;
    }
    const {
      id,
      firstName,
      lastName,
      email,
      companyName,
      companyHq,
      yearFounded,
      website,
      logoUrl,
      organisationType,
    } = profile;
    console.log("authData", profile);
    setState({
      id,
      firstName,
      lastName,
      email,
      companyName,
      companyHq,
      yearFounded,
      website,
      logoUrl,
      organisationType,
    });
    setProfileImagePreview(authData?.logoUrl);
  }, [authData]);

  //   useEffect(() => {
  //     if (ProfileData !== undefined) {
  //       const {
  //         id,
  //         firstName,
  //         lastName,
  //         email,
  //         companyName,
  //         companyHq,
  //         yearFounded,
  //         website,
  //         logoUrl,
  //         organisationType,
  //       } = ProfileData;
  //       console.log("ProfileData", ProfileData);
  //       setState({
  //         id,
  //         firstName,
  //         lastName,
  //         email,
  //         companyName,
  //         companyHq,
  //         yearFounded,
  //         website,
  //         logoUrl,
  //         organisationType,
  //       });
  //       setProfileImagePreview(ProfileData?.logoUrl);
  //     }
  //   }, [ProfileData]);

  const handleChange = (event, type, data) => {
    console.log(event.target.name);
    const { name, value } = event.target;
    console.log({ name, value });

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const result = await ValidateSignUpForm(state);

    if (!result?.isFormValid) {
      console.log("error profile", result?.error);
      setError(result?.error);

      return;
    }
    const fileToUpload = selectedFile;
    console.log("fileToUpload", fileToUpload);
    const formData = new FormData();
    formData.append("image", fileToUpload);

    for (var key in state) {
      formData.append(key, state[key]);
    }
    formData.append("user_id", authData.id);
    console.log("formData", formData, state);
    const data = await dispatch(ProfileAction.updateProfileDetails(formData));
    await dispatch(AuthActions.matchToken());
    console.log("data", data);
    setProfileImagePreview(data.user[0].logoUrl);
    setState({
      firstName: data.user[0].firstName,
      lastName: data.user[0].lastName,
      email: data.user[0].email,
      companyName: data.user[0].companyName,
      companyHq: data.user[0].companyHq,
      yearFounded: data.user[0].yearFounded,
      website: data.user[0].website,
      organisationType: data.user[0].organisationType,
    });

    setToastMessage(data.message);
    setToastError(data.status);
    setToast(true);
  };

  const showPreview = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(authData?.logoUrl);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedFile) {
      setProfileImagePreview(authData?.logoUrl);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setProfileImagePreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <IonContent>
      <div className="container vendorProfile">
        <div className="innervendorprofile">
          <div className="vendorprofilehead">
            <h2>Profile</h2>
          </div>
          {toast === true ? (
            <IonToast
              isOpen={toast}
              message={toastMessage}
              onDidDismiss={() => setToast(false)}
              buttons={[
                {
                  text: "Close",
                  role: "cancel",
                  handler: () => {
                    setToast(false);
                  },
                },
              ]}
            />
          ) : null}

          <div className="vendorprofilebody">
            <div className="formname">
              <h4>Company Details:</h4>
            </div>
            <form className="vendrprofilediv">
              <div className="center">
                <div className="form-input">
                  <div className="">
                    <div className="companyLogoText">
                      <p>Company Logo</p>
                    </div>
                    <div className="preview">
                      <IonImg src={profileImagePreview} />
                    </div>
                    {/* <Tooltip message="Please upload a logo suitable for [dark or light] white backgrounds" /> */}
                  </div>
                  <label htmlFor="imgfile">Upload Logo</label>
                  <input
                    type="file"
                    id="imgfile"
                    accept="image/*"
                    onChange={showPreview}
                  />
                </div>
              </div>

              <IonGrid className="body">
                <IonRow>
                  <IonCol size="6" size-md="6">
                    <div className="formgroup">
                      <IonLabel className="labletext">First Name*</IonLabel>
                      <IonInput
                        type="text"
                        name="firstName"
                        value={state?.firstName}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.firstName[0] ? (
                        <p className="errormsg">{errorData?.firstName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="6" size-md="6">
                    <div className="formgroup">
                      <IonLabel className="labletext">Last Name*</IonLabel>
                      <IonInput
                        type="text"
                        name="lastName"
                        value={state?.lastName}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.lastName[0] ? (
                        <p className="errormsg">{errorData?.lastName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="formgroup">
                      <div className=" d-flex">
                        <IonLabel className="labletext">
                          Email address*
                        </IonLabel>
                        <Tooltip message="please enter a valid email" />
                      </div>
                      <IonInput
                        type="email"
                        name="email"
                        value={state?.email}
                        disabled
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.email[0] ? (
                        <p className="errormsg">{errorData?.email[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="formgroup">
                      <IonLabel className="labletext">Company Name*</IonLabel>
                      <IonInput
                        type="text"
                        name="companyName"
                        value={state?.companyName}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.companyName[0] ? (
                        <p className="errormsg">{errorData?.companyName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="6" size-md="6">
                    <div className="formgroup">
                      <IonLabel className="labletext">
                        Organisation Type*
                      </IonLabel>
                      <select
                        name="organisationType"
                        id="organisationType"
                        value={state?.organisationType}
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        disabled={
                          !(
                            Object.keys(authData).includes("support") &&
                            authData.role === 0
                          )
                        }
                      >
                        <option value="" disabled selected>
                          Select Organisation type
                        </option>

                        <option value={"Technology Vendor"}>
                          Technology Vendor
                        </option>
                        <option value={"League / Club / Team"}>
                          League / Club / Team
                        </option>
                        <option value={"Governing Body / Federation"}>
                          Governing Body / Federation
                        </option>
                        <option value={"Broadcaster / Media Company"}>
                          Broadcaster / Media Company
                        </option>
                        <option value={"Event Organizer"}>
                          Event Organizer
                        </option>
                        <option value={"Brand / Sponsor"}>
                          Brand / Sponsor
                        </option>
                        <option value={"Stadium / Venue"}>
                          Stadium / Venue
                        </option>
                        <option value={"Athlete"}>Athlete</option>
                        <option value={"Agency / Consultant"}>
                          Agency / Consultant
                        </option>
                        <option value={"None"}>None Of The Above</option>
                      </select>
                      {errorData?.organisationType[0] ? (
                        <p className="errormsg">
                          {errorData?.organisationType[0]}
                        </p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="formgroup">
                      <IonLabel className="labletext">Country HQ*</IonLabel>
                      <select
                        name="companyHq"
                        value={state?.companyHq}
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      >
                        <option
                          value={state?.companyHq}
                          disabled
                          selected="selected"
                        >
                          {state?.companyHq}
                        </option>
                        {Countries.map((country) => {
                          return (
                            <option key={country.name} value={country.name}>
                              {country.name}
                            </option>
                          );
                        })}
                      </select>{" "}
                      {errorData?.companyHq[0] ? (
                        <p className="errormsg">{errorData?.companyHq[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="formgroup">
                      <IonLabel className="labletext">Year Founded</IonLabel>
                      <select
                        name="yearFounded"
                        value={state?.yearFounded}
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      >
                        <option
                          value={state?.yearFounded}
                          disabled
                          selected="selected"
                        >
                          {state?.yearFounded !== "undefined"
                            ? state?.yearFounded
                            : "N/A"}
                        </option>
                        {yearDrop &&
                          yearDrop.map((year) => {
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                      </select>{" "}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="12">
                    <div className="formgroup">
                      <IonLabel className="labletext">Website</IonLabel>

                      <IonInput
                        type="text"
                        name="website"
                        value={
                          state?.website === "undefined"
                            ? "N/A"
                            : state?.website
                        }
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <div className="button-name text-center">
                <IonButton
                  color="none"
                  className="btnsave"
                  onClick={onSubmitForm}
                >
                  save
                  {/* {(() => {
                    if (loading) {
                      return (
                        <IonSpinner name="bubbles" className="spinner-loader" />
                      );
                    }
                  })()} */}
                </IonButton>

                {/* <IonButton
                  color="none"
                  className="btncancel"
                  // onClick={(event) => {
                  //   onSubmitForm(event);
                  // }}
                >
                  cancel
                </IonButton> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default VendorProfile;
