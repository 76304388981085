import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonIcon,
  IonCheckbox,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { Link, useHistory } from "react-router-dom";
import "./SignUp.css";
import { ValidateSignUpForm } from "./Validation";
import { ROUTES } from "../../Services/index";
import { AuthActions, HomeAction } from "../../ReduxStore/Actions/index";
import { Toast } from "../../Utils/Toast";
import { useSelector, useDispatch } from "react-redux";

import Footer from "../../components/Footer/Footer";

import { Countries } from "../../Services/Contants";
import ToolTip from "../tooltip/Tooltip";
import { VendorAction } from "../../ReduxStore/Actions/index";

const Signup = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    TaC: { value: 67, checked: "false" },
    PrivacyPolicy: { value: 69, checked: "false" },
    Newsletter: { value: 71, checked: "false" },
  });
  const [yearDrop, setYearDrop] = React.useState([]);

  const [selectedFile, setSelectedFile] = useState();

  const [errorData, setError] = React.useState();
  const [isShowConfirmPassword, setConfirmPasswordType] = React.useState(false);
  const [isShowPassword, setPasswordType] = React.useState(false);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const authData = useSelector((state) => state.authReducer);
  const insertId = useSelector((state) => state.authReducer);
  const loading = useSelector((state) => state.commonReducer.loading);
  let history = useHistory();

  const tac = useSelector(
    (state) => state.vendorReducer.filter["Terms of Use"]
  );
  const poc = useSelector(
    (state) => state.vendorReducer.filter["Pilot Project"]
  );
  const newsletter = useSelector(
    (state) => state.vendorReducer.filter["Newsletter Opt In"]
  );
  const privacypolicy = useSelector(
    (state) => state.vendorReducer.filter["Privacy Policy"]
  );

  console.log("PP", privacypolicy);

  useEffect(() => {
    (async () => {
      await dispatch(VendorAction.getfilter());
      dispatch(HomeAction.setHeaderClass("headerBlue"));
    })();
  }, []);

  useEffect(() => {
    let years = [];
    var d = new Date();
    var n = d.getFullYear();
    years.push("Pre-1950");
    for (let i = 1950; i <= n; i++) {
      years.push(i);
    }
    console.log(years);
    setYearDrop(years);
  }, []);

  const handleCheckbox2 = (event, e) => {
    let value;
    let eventcheck;
    console.log(e.target.name, "newsletter checkbox");
    event === true ? (eventcheck = "Yes") : (eventcheck = "No");
    newsletter &&
      newsletter.map(
        (user) => (
          console.log("mao worked2"),
          console.log(eventcheck, "eventcheck", user.name, "username"),
          eventcheck === user.name ? (value = user.id) : null
        )
      );
    console.log("NEWSSSSSS", newsletter);
    setState({
      ...state,
      // TaC: { value: 67, checked: "false" },
      Newsletter: { value: 71, checked: "false" },
      [e.target.name]: { value: value, checked: event },
    });
  };
  const handleCheckbox3 = (event, e) => {
    let value;
    let eventcheck;
    event === true ? (eventcheck = "Yes") : (eventcheck = "No");
    console.log("event", event, tac);

    tac &&
      tac.map(
        (user) => (
          console.log("mao handleCheckbox3", user),
          console.log(eventcheck, "eventcheck", user.name, "username"),
          eventcheck === user.name ? (value = user.id) : console.log(user.id)
        )
      );
    // console.log("event", event, "e", e);
    console.log(value, "value", e.target.name);
    setState({
      ...state,
      TaC: { value: 67, checked: "false" },
      // PrivacyPolicy: { value: 69, checked: "false" },
      [e.target.name]: { value: value, checked: event },
    });
  };
  const handleCheckbox4 = (event, e) => {
    e.preventDefault();

    let value;
    let eventcheck = "";
    event === true ? (eventcheck = "Yes") : (eventcheck = "No");
    privacypolicy &&
      privacypolicy.map(
        (user) => (
          console.log("mao worked4"),
          console.log(eventcheck, "eventcheck", user.name, "username"),
          eventcheck === user.name ? (value = user.id) : null,
          console.log(value, event)
        )
      );
    console.log("event", event, "e", e);
    console.log(privacypolicy, "value", e.target.name);
    setState({
      ...state,
      // PrivacyPolicy: { value: 69, checked: "false" },
      PrivacyPolicy: { value: 69, checked: "false" },

      [e.target.name]: { value: value, checked: event },
    });
  };

  const handleChange = (event, type, data) => {
    const { name, value } = event.target;
    console.log({ name, value });

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  async function onSubmitForm(event) {
    event.preventDefault();

    const result = await ValidateSignUpForm(state);

    if (!result?.isFormValid) {
      console.log(
        "result errorrr",
        Object.keys(result.error),
        Object.values(result.error)
      );
      setError(result?.error);
      for (let i = 0; i < Object.keys(result.error).length; i++) {
        console.log("inside", Object.keys(result.error)[i]);
        if (Object.values(result.error)[i].length) {
          console.log("inside");
          document
            .getElementById(Object.keys(result.error)[i])
            .scrollIntoView();
          break;
        }
      }

      return;
    }

    const formData = new FormData();
    console.log("Svalues", state);
    state.Newsletter = state.Newsletter.checked;
    if (typeof selectedFile != "undefined") {
      const extension =
        selectedFile.name.split(".")[selectedFile.name.split(".").length - 1];
      const fileName = new Date().getTime() + "." + extension;
      console.log("filename", fileName);
      formData.append("profileImg", selectedFile, fileName);
    }

    for (var key in state) {
      formData.append(key, state[key]);
    }
    // formData.append("firstName", "ssss");

    console.log("formData", formData, state);

    console.log("state", state);
    const status = await dispatch(AuthActions.signUpUser(formData));
    console.log("STATESsssssssssssssssss", status);
    if (status) {
      setState({});
      setTimeout(() => {
        history.push(ROUTES.Login);
      }, 8000);
    }
    // } else {
    // setError(result?.error);
    // }
  }
  // console.log("authData in signup", authData);

  return (
    <IonContent>
      <section
        style={{ marginTop: "110px", overflow: "hidden" }}
        className="HomeSection44 notdisplayMobile"
      ></section>
      <div className="signupNew">
        <div className="login-inner">
          <div className="login-head">
            <h2>SIGN UP</h2>
          </div>
          {(errorStatus === false || errorStatus === true) && errorMessage ? (
            <Toast
              isOpen={errorStatus === false ? true : false}
              message={errorMessage}
              isSuccess={errorStatus}
              isClearSuccess={true}
              isClearError={true}
            />
          ) : authData?.status ? (
            <Toast
              isOpen={authData?.status}
              message={authData?.message}
              isSuccess={authData?.status}
              duration={10000}
            />
          ) : null}

          <div className="login-body">
            <form>
              <IonGrid>
                <IonRow className="d-flex justify-content-between">
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>First Name*</IonLabel>
                      <IonInput
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="form-control"
                        value={state?.firstName}
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.firstName[0] ? (
                        <p>{errorData?.firstName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>Last Name*</IonLabel>
                      <IonInput
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="form-control"
                        value={state?.lastName}
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.lastName[0] ? (
                        <p>{errorData?.lastName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>
                        <span className="d-flex">
                          <span>Email Address*</span>
                          <span>
                            <ToolTip message="Please use a valid work email address" />
                          </span>
                        </span>
                      </IonLabel>

                      <IonInput
                        type="email"
                        id="email"
                        name="email"
                        value={state?.email}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.email[0] ? (
                        <p>{errorData?.email[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>
                        <span className="d-flex">
                          <span>Organisation Type*</span>
                          <span>
                            <ToolTip message="If you are a tech vendor, it is important to identify as such. This enables you to create a product listing which is the key to getting discovered on STM. As a vendor you will also be able to create RFI's to find other vendors." />
                          </span>
                        </span>
                      </IonLabel>
                      <select
                        name="organisationType"
                        id="organisationType"
                        value={state?.organisationType}
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      >
                        <option value="" disabled selected>
                          Select Organisation type
                        </option>

                        <option value={"Technology Vendor"}>
                          Technology Vendor
                        </option>
                        <option value={"League / Club / Team"}>
                          League / Club / Team
                        </option>
                        <option value={"Governing Body / Federation"}>
                          Governing Body / Federation
                        </option>
                        <option value={"Broadcaster / Media Company"}>
                          Broadcaster / Media Company
                        </option>
                        <option value={"Event Organizer"}>
                          Event Organizer
                        </option>
                        <option value={"Brand / Sponsor"}>
                          Brand / Sponsor
                        </option>
                        <option value={"Stadium / Venue"}>
                          Stadium / Venue
                        </option>
                        <option value={"Athlete"}>Athlete</option>
                        <option value={"Agency / Consultant"}>
                          Agency / Consultant
                        </option>
                        <option value={"None"}>None Of The Above</option>
                      </select>
                      {errorData?.organisationType[0] ? (
                        <p>{errorData?.organisationType[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>Company Name*</IonLabel>
                      <IonInput
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={state?.companyName}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.companyName[0] ? (
                        <p>{errorData?.companyName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>Company HQ*</IonLabel>
                      <select
                        name="companyHq"
                        id="companyHq"
                        value={state?.companyHq}
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      >
                        <option value="" disabled selected>
                          Select Country HQ
                        </option>
                        {Countries.map((country) => {
                          return (
                            <option value={country.name}>{country.name}</option>
                          );
                        })}
                      </select>{" "}
                      {errorData?.companyHq[0] ? (
                        <p>{errorData?.companyHq[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>Website</IonLabel>
                      <IonInput
                        type="text"
                        id="website"
                        name="website"
                        value={state?.website}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.website[0] ? (
                        <p>{errorData?.website[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>Year Founded</IonLabel>
                      <select
                        name="yearFounded"
                        value={state?.yearFounded}
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      >
                        <option value="" disabled selected>
                          Select Year
                        </option>
                        {yearDrop &&
                          yearDrop.map((year) => {
                            return <option value={year}>{year}</option>;
                          })}
                      </select>{" "}
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="6" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>Password*</IonLabel>
                      <div className="showpassword-input">
                        <IonInput
                          type={isShowPassword == true ? "text" : "password"}
                          name="password"
                          id="password"
                          className="form-control"
                          value={state?.password}
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                        {isShowPassword === true ? (
                          <a onClick={() => setPasswordType(false)}>
                            <IonIcon icon={eyeOutline} />
                          </a>
                        ) : (
                          <a onClick={() => setPasswordType(true)}>
                            <IonIcon icon={eyeOffOutline} />
                          </a>
                        )}
                      </div>

                      {errorData?.password[0] ? (
                        <p style={{ bottom: "-22px" }}>
                          {errorData?.password[0]}
                        </p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6" size-xs="12">
                    <div className="form-group">
                      <IonLabel>Confirm Password*</IonLabel>
                      <div className="showpassword-input">
                        <IonInput
                          type={
                            isShowConfirmPassword == true ? "text" : "password"
                          }
                          value={state?.confirmPassword}
                          name="confirmPassword"
                          id="confirmPassword"
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                        {isShowConfirmPassword === true ? (
                          <a onClick={() => setConfirmPasswordType(false)}>
                            <IonIcon icon={eyeOutline} />
                          </a>
                        ) : (
                          <a onClick={() => setConfirmPasswordType(true)}>
                            <IonIcon icon={eyeOffOutline} />
                          </a>
                        )}
                      </div>

                      {errorData?.confirmPassword[0] ? (
                        <p style={{ bottom: "-22px" }}>
                          {errorData?.confirmPassword[0]}
                        </p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <div
                id="checkboxforios"
                className="d-flex flex-column newCheckBoxPadding"
              >
                {" "}
                <div className="d-flex align-items-center">
                  <IonCheckbox
                    className="custom-checkbox"
                    name="Newsletter"
                    checked={state?.Newsletter?.checked}
                    onIonChange={(e) => handleCheckbox2(e.detail.checked, e)}
                  />

                  <div
                    className="d-flex flex-row align-items-center "
                    style={{ width: "250px" }}
                  >
                    <IonLabel className="labelled">Newsletter opt in</IonLabel>
                    <p className="relative newPilotTooltip">
                      <span className="tooltp-vd">
                        <ToolTip message="Get marketplace updates and insights from SportsTech Match direct to your inbox." />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <IonCheckbox
                    className="custom-checkbox"
                    name="TaC"
                    checked={state?.TaC?.checked}
                    onIonChange={(e) => handleCheckbox3(e.detail.checked, e)}
                  />
                  <Link
                    to={ROUTES.TERMS_AND_CONDITION}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IonLabel style={{ color: "blue" }} className="labelled">
                      <u>Terms of Use*</u>
                    </IonLabel>{" "}
                  </Link>
                </div>
                {errorData?.TaC[0] ? (
                  <p className="errormsg">{errorData?.TaC[0]}</p>
                ) : null}
                <div className="d-flex align-items-center">
                  <IonCheckbox
                    className="custom-checkbox"
                    name="PrivacyPolicy"
                    checked={state?.PrivacyPolicy?.checked}
                    onIonChange={(e) => handleCheckbox4(e.detail.checked, e)}
                  />
                  <Link
                    to={ROUTES.PRIVATE_POLICY}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IonLabel style={{ color: "blue" }} className="labelled">
                      <u> Privacy Policy* </u>
                    </IonLabel>
                  </Link>
                </div>
                {errorData?.PrivacyPolicy[0] ? (
                  <p className="errormsg">{errorData?.PrivacyPolicy[0]}</p>
                ) : null}
              </div>

              <div className="button-login">
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Sign Up
                  {(() => {
                    if (loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default Signup;
