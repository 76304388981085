import React from "react";

import "./sideNav.css";
import { ROUTES } from "../../Services/index";
import { IonIcon } from "@ionic/react";
import { caretForwardCircleOutline } from "ionicons/icons";
import { Link, useLocation } from "react-router-dom";

const SideBar = (props) => {
  const location = useLocation();

  return (
    <div className="col-sm-12 col-md-3 msb" id="msb">
      <nav className="navbar navbar-default" role="navigation">
        <div className="side-menu">
          <h2 style={{ textAlign: "center", color: "#ddfd51" }}>Admin Menu</h2>
          <ul className="nav navbar-nav navblist">
            <div className="admin-list">
              <li className="num">
                <Link to={ROUTES.AdminProfile}>
                  <div
                    className={
                      location.pathname === ROUTES.AdminProfile
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Admin Profile</p>
                    <IonIcon
                      // style={{ padding: "3px 115px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.Approve}>
                  {" "}
                  <div
                    className={
                      location.pathname === ROUTES.Approve
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Approve New Vendors & Buyers</p>
                    <IonIcon
                      // style={{ padding: "3px 40px" }}
                      icon={caretForwardCircleOutline}
                    ></IonIcon>
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.ViewVendor}>
                  {" "}
                  <div
                    className={
                      location.pathname === ROUTES.ViewVendor
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>View Vendors & Buyers</p>
                    <IonIcon
                      // style={{ padding: "3px 127px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.ViewProduct}>
                  <div
                    className={
                      location.pathname === ROUTES.ViewProduct
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Approve New Products</p>
                    <IonIcon icon={caretForwardCircleOutline} />
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.ApproveRFI}>
                  {" "}
                  <div
                    className={
                      location.pathname === ROUTES.ApproveRFI
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Approve New RFI's</p>
                    <IonIcon
                      // style={{ padding: "3px 40px" }}
                      icon={caretForwardCircleOutline}
                    ></IonIcon>
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.ViewRFI}>
                  {" "}
                  <div
                    className={
                      location.pathname === ROUTES.ViewRFI
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>View RFI's</p>
                    <IonIcon
                      // style={{ padding: "3px 40px" }}
                      icon={caretForwardCircleOutline}
                    ></IonIcon>
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.Tenders}>
                  <div
                    className={
                      location.pathname === ROUTES.Tenders
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Tenders</p>
                    <IonIcon
                      // style={{ padding: "3px 115px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.ApproveNewReview}>
                  <div
                    className={
                      location.pathname === ROUTES.ApproveNewReview ||
                      location.pathname.includes(ROUTES.AdminRatingInfo)
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Approve New Rating & Review</p>
                    <IonIcon
                      // style={{ padding: "3px 115px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.ViewAllReview}>
                  <div
                    className={
                      location.pathname === ROUTES.ViewAllReview
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>View All Ratings & Reviews</p>
                    <IonIcon
                      // style={{ padding: "3px 115px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </div>
                </Link>
              </li>
              {/* <!-- Dropdown-->
            <li className="panel panel-default" id="dropdown">
              <a data-toggle="collapse" href="#dropdown-lvl1">
                <i className="fa fa-diamond"></i> Apps
                <span className="caret"></span>
              </a>
              <!-- Dropdown level 1 -->
              <div id="dropdown-lvl1" class="panel-collapse collapse">
                <div className="panel-body">
                  <ul className="nav navbar-nav">
                    <li>
                      <a href="#">Mail</a>
                    </li>
                    <li>
                      <a href="#">Calendar</a>
                    </li>
                    <li>
                      <a href="#">Ecommerce</a>
                    </li>
                    <li>
                      <a href="#">User</a>
                    </li>
                    <li>
                      <a href="#">Social</a>
                    </li> */}
              {/* 
                    <!-- Dropdown level 2 -->
                    <li className="panel panel-default" id="dropdown">
                      <a data-toggle="collapse" href="#dropdown-lvl2">
                        <i className="glyphicon glyphicon-off"></i> Sub Level{" "}
                        <span className="caret"></span>
                      </a>
                      <div id="dropdown-lvl2" class="panel-collapse collapse">
                        <div className="panel-body">
                          <ul className="nav navbar-nav">
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li> */}
              {/* </ul>
                </div>
              </div>
            </li>
            <li>
              <a href="#">
                <span className="glyphicon glyphicon-signal"></span> Link
              </a>
            </li>
             */}
            </div>
          </ul>
        </div>
        {/* // <!-- /.navbar-collapse --> */}
      </nav>
    </div>
  );
};
export default SideBar;
