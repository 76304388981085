function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export const ValidateVendorForm = (formData) => {
  console.log("formData", formData);
  const {
    name,
    description,
    additional_info,
    productType,
    category,
    softwaredev,
    Sports,
    levelOfSports,
    targetCustomer,
    productStage,
    sector,
    timeToImplement,
    // TaC,
    // PrivacyPolicy,
  } = formData || {};
  let error = {
    name: [],
    description: [],
    additional_info: [],
    productType: [],
    category: [],
    softwaredev: [],
    Sports: [],
    levelOfSports: [],
    targetCustomer: [],
    productStage: [],
    sector: [],
    timeToImplement: [],
    // TaC: [],
    // PrivacyPolicy: [],
  };

  console.log("form data", formData);
  let isFormValid = true;
  console.log(name, description, productType, category, "jhon");
  // if (TaC.value == 67) {
  //   isFormValid = false;
  //   error.TaC = ["Please check this box to agree to our Terms of Use."];
  // }
  // if (PrivacyPolicy.value == 69) {
  //   isFormValid = false;
  //   error.PrivacyPolicy = [
  //     "Please check this box to agree to our Privacy Policy",
  //   ];
  // }
  if (isBlank(name) === true) {
    isFormValid = false;
    error.name = ["This field is required."];
  } else if (name?.trim().length > 0 && name?.trim().length > 50) {
    isFormValid = false;
    error.name = ["Name cannot be greater than 50."];
  }
  if (isBlank(description) === true) {
    isFormValid = false;
    error.description = ["This field is required."];
  } else if (
    description?.trim().length > 0 &&
    description?.trim().length > 500
  ) {
    isFormValid = false;
    error.description = ["Description cannot be greater than 500 characters."];
  }
  if (additional_info?.trim().length > 1000) {
    isFormValid = false;
    error.additional_info = ["You have exceeded the character limit"];
  }
  if (productType.length === 0) {
    isFormValid = false;
    error.productType = ["Select at least one option."];
  } else if (productType.length > 1) {
    isFormValid = false;
    error.productType = ["Cannot select more than one option."];
  }
  if (category.length === 0) {
    isFormValid = false;
    error.category = ["Select at least one option."];
  } else if (category.length > 3) {
    isFormValid = false;
    error.category = ["only 3 option can be selected"];
  }
  if (sector.length === 0) {
    isFormValid = false;
    error.sector = ["Select at least one option."];
  }
  if ((productType == 19 || productType == 18) && softwaredev.length === 0) {
    isFormValid = false;
    error.softwaredev = ["Select at least one option."];
  }
  if (Sports.length === 0) {
    isFormValid = false;
    error.Sports = ["Select at least one option."];
  }
  if (levelOfSports.length === 0) {
    isFormValid = false;
    error.levelOfSports = ["Select at least one option."];
  }
  if (targetCustomer.length === 0) {
    isFormValid = false;
    error.targetCustomer = ["Select at least one option."];
  }
  if (timeToImplement.length === 0) {
    isFormValid = false;
    error.timeToImplement = ["Select at least one option."];
  } else if (timeToImplement.length > 1) {
    isFormValid = false;
    error.timeToImplement = ["Cannot select more than one option."];
  }
  if (productStage.length > 1) {
    isFormValid = false;
    error.productStage = ["Select only one option"];
  }
  if (productStage.length === 0) {
    isFormValid = false;
    error.productStage = ["Select an option"];
  }

  return { isFormValid, error };
};
