import { IonInput, IonButton, IonLabel } from "@ionic/react";
import { IonCheckbox } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./FormOne.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useHistory } from "react-router-dom";

import ToolTip from "../../../tooltip/Tooltip";
import { TextField } from "@material-ui/core";

import { ValidateSignUpForm } from "./Validation";
import { ArrowBack } from "@material-ui/icons";

const FormOne = (props) => {
  const history = useHistory();
  const [errorData, setError] = React.useState();
  console.log("props.state opportunity desc", props.state.opportunity_desc);

  async function onSubmitForm(event) {
    event.preventDefault();

    const result = await ValidateSignUpForm(props.state);

    if (!result?.isFormValid) {
      setError(result?.error);

      for (let i = 0; i < Object.keys(result.error).length; i++) {
        console.log("inside", Object.keys(result.error)[i]);
        if (Object.values(result.error)[i].length) {
          console.log("inside");

          document
            .getElementById(Object.keys(result.error)[i])
            .scrollIntoView();
          break;
        }
      }

      return;
    }

    const formData = new FormData();
    console.log("Svalues", props.state);
    for (var key in props.state) {
      formData.append(key, props.state[key]);
    }
    console.log("state", props.state);
    props.changeCount(2);
  }

  return (
    <>
      <div>
        <>
          <div className="stepOneDescription">
            <h1>Our commitment to you:</h1>
            <p>
              Our commitment to our growing community of buyers and vendors is
              to generate meaningful conversations that lead to valuable
              partnerships. You are ready to create an RFI if you have:
            </p>
            <p>
              1. Identified a need for a solution and have the necessary
              resource to make it a success.
            </p>
            <p>
              2. Defined your key requirements (read our{" "}
              <a
                href="https://sportstechmatch.medium.com/tutorial-finding-the-technology-solutionv-d441cd4de83a"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "#4242ff" }}
              >
                tutorial
              </a>{" "}
              for help).
            </p>
          </div>

          <div className="stepOnetitleOuterWrapper">
            <div className="stepOnetitleWrapper">
              <div className="stepOneTitleLeft">
                <strong>
                  <h1>RFI Title:</h1>
                  <p className="relative ">
                    <span className="tooltp-vd-form-onee relative">
                      <ToolTip message="The title is the first thing that vendors will see so it is important to create a title that accurately describes what you need to attract the attention of the right vendors." />
                    </span>
                  </p>
                </strong>
                <p>Give your RFI a title (max 100 characters)</p>
              </div>
              <div className="stepTwoTitleRight">
                <IonInput
                  type="text"
                  name="rfi_title"
                  id="rfi_title"
                  className="form-control"
                  value={props.state?.rfi_title}
                  onIonChange={(event) => {
                    props.handleChange(event);
                  }}
                  placeholder="Add RFI Title"
                ></IonInput>
                {errorData?.rfi_title[0] ? (
                  <p className="setError">{errorData?.rfi_title[0]}</p>
                ) : null}
              </div>
            </div>
          </div>

          {/* <p> {errorData?.password && errorData.password[0]}</p> */}
          <div className="stepOneLevelOfSport">
            <h3>Level of Sport:</h3>
            <p>
              At what level of sport will the solution you are looking for be
              used?
            </p>
            <div className="stepOneCheckboxWrapper">
              <div className="stepOneCheckbox">
                <div className="d-flex align-items-center">
                  <IonCheckbox
                    className="custom-checkbox"
                    name="level_of_sport"
                    checked={props.state?.level_of_sport.includes("Elite Pro")}
                    onClick={(e) => {
                      props.handleChange(e, "Elite Pro");
                    }}
                  />
                  <IonLabel className="labelled">Elite Pro</IonLabel>
                </div>

                <div className="d-flex align-items-center">
                  <IonCheckbox
                    className="custom-checkbox"
                    name="level_of_sport"
                    checked={props?.state?.level_of_sport.includes("Sub-Elite")}
                    onClick={(e) => {
                      props.handleChange(e, "Sub-Elite");
                    }}
                  />
                  <IonLabel className="labelled">Sub-Elite</IonLabel>
                </div>

                <div className="d-flex align-items-center">
                  <IonCheckbox
                    className="custom-checkbox"
                    name="level_of_sport"
                    checked={props?.state?.level_of_sport.includes(
                      "Grassroots / Amateur"
                    )}
                    onClick={(e) =>
                      props.handleChange(e, "Grassroots / Amateur")
                    }
                  />
                  <IonLabel className="labelled">Grassroots / Amateur</IonLabel>
                </div>
              </div>
              {errorData?.level_of_sport[0] ? (
                <p id="level_of_sport" className="setError">
                  {errorData?.level_of_sport[0]}
                </p>
              ) : null}
            </div>
            <div className="stepOneOrgDesc">
              <h1>Organisation Description:</h1>
              <p>
                Below is how your organisation will be presented to vendors (you
                can edit this description if you wish).
              </p>
              <TextField
                id="outlined-multiline-static"
                className="messageBody"
                multiline
                name="organisation_desc"
                value={props.state?.organisation_desc}
                onChange={(event) => {
                  props.handleChange(event);
                }}
                placeholder="*Organization type* looking for a solution to be implemented at the *Level of sport* level of sport."
                rows={4}
                // defaultValue=""
                variant="outlined"
              />
              {errorData?.organisation_desc[0] ? (
                <p className="setError">{errorData?.organisation_desc[0]}</p>
              ) : null}
            </div>

            <div className="stepOneOppDesc">
              <h1>Opportunity Description:</h1>
              <p>
                Describe the problem, the end user of the solution and the scale
                of the opportunity for vendors.
              </p>
              <p>
                Try to include the following three elements in your description:
              </p>
              <ul>
                <li>What the problem is</li>
                <li>Who the end user of the solution is</li>
                <div className="d-flex">
                  <li>The scale of the opportunity for vendors</li>
                  <span className="relative">
                    <ToolTip message="Describe the size of the opportunity for vendors e.g. how many fixtures must be managed by the competition management system or how many customers must be managed by the CRM system. It is important for vendors to get a sense of the volumes that the solution needs to handle so that they can properly assess whether they are suited to respond to your RFI." />
                  </span>
                </div>
              </ul>
            </div>
            <ReactQuill
              id="outlined-multiline-static"
              className="messageBody"
              name="opportunity_desc"
              value={props.state?.opportunity_desc}
              onChange={(event) => {
                console.log("change event", event);
                props.handleChange(event);
              }}
              placeholder="Describe the problem, the end user of the solution and the scale of the opportunity for vendors."
              style={{ height: "200px", marginBottom: "2rem" }}
            />
            {/* <TextField
              id="outlined-multiline-static"
              className="messageBody"
              multiline
              name="opportunity_desc"
              value={props.state?.opportunity_desc}
              onChange={(event) => {
                props.handleChange(event);
              }}
              placeholder="Describe the problem, the end user of the solution and the scale of the opportunity for vendors."
              rows={6}
              variant="outlined"
            /> */}
            {errorData?.opportunity_desc[0] ? (
              <p id="opportunity_desc" className="setError">
                {errorData?.opportunity_desc[0]}
              </p>
            ) : null}
            <div className="button-login d-flex justify-content-center">
              <div>
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={(e) => {
                    onSubmitForm(e);
                  }}
                >
                  Next
                </IonButton>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default FormOne;
