import Api from "../../Api";
import { handleError } from "./commonAction";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";

export const getPageDetails = (pageId) => async (dispatch) => {
  try {
    const res = await Api.get(ENDPOINTS.GET_PAGES_CONTENT + pageId);
    console.log("GET DETAILS", res);
    await dispatch({
      type: ACTION_TYPE.GET_DYNAMIC_PAGE_CONTENT,
      payload: { data: res.data },
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
