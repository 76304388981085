import { Divider, Button, Typography } from "@material-ui/core";
import React from "react";
import "./ViewRFI.css";
import SideNav from "../sidebar/sideNav";
import Search from "../SearchBar/searchbar";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction, AdminAction } from "../../ReduxStore/Actions/index";

const ViewRFI = (props) => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.AdminReducer.approvedRFI);

  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(AdminAction.getApprovedRFI());
    })();
  }, []);
  return (
    <div className="top">
      <div className="mainBodyProduct">
        <SideNav />

        <div className="col-md-9 col-sm-12 viewApprove vendor-list">
          <h3 className="heading"> View RFI's </h3>
          {/* <Search type="vendor" /> */}
          <ul style={{ color: "black", listStyle: "none" }}>
            {list &&
              list.map((user, i) => {
                return (
                  <li className="vendor-Card">
                    <div className="d-flex ">
                      <h3>{i + 1}.</h3>
                      <Typography className="content" variant="h6" gutterBottom>
                        <span style={{ color: "#062c56" }}> RFI Title : </span>
                        {`${user.title}`}
                      </Typography>
                      <Link
                        to={{
                          pathname: `/rfi-details/${user.rfi_id}`,
                        }}
                        className="desktopBtn"
                      >
                        <Button className="text-right Viewbtn">
                          View RFI details
                        </Button>
                      </Link>
                    </div>
                    <div className="viewRfiBtn">
                      <Link
                        to={{
                          pathname: `/rfi-details/${user.rfi_id}`,
                        }}
                      >
                        <Button className="text-right Viewbtn">
                          View RFI details
                        </Button>
                      </Link>
                    </div>
                    <Typography
                      className="content1"
                      variant="body1"
                      gutterBottom
                    ></Typography>
                    <Divider variant="middle" />
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ViewRFI;
