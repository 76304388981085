import React from "react";

import "./sideNav.css";
import { ROUTES } from "../../Services/index";
import { IonIcon } from "@ionic/react";
import { caretForwardCircleOutline } from "ionicons/icons";
import { Link, useLocation } from "react-router-dom";

const SideBar = (props) => {
  const location = useLocation();
  return (
    <div className="col-sm-12 col-md-3 msb" id="msb">
      <nav className="navbar navbar-default" role="navigation">
        <div className="side-menu">
          <h2 style={{ textAlign: "center", color: "#ddfd51" }}>
            Admin Support Menu
          </h2>
          <ul className="nav navbar-nav navblist">
            <div className="admin-list">
              <li className="num">
                <Link to={ROUTES.AdminSupportProfile}>
                  <a
                    className={
                      location.pathname === ROUTES.AdminSupportProfile
                        ? "list-value active"
                        : "list-value"
                    }
                    href="#"
                  >
                    <i className="fa fa-heart"></i> Admin Support Profile
                    <IonIcon
                      // style={{ padding: "3px 115px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </a>
                </Link>
              </li>

              <li className="num">
                <Link to={ROUTES.ViewVendors}>
                  {" "}
                  <div
                    className={
                      location.pathname === ROUTES.ViewVendors
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {" "}
                    <p>View Paid Vendors</p>
                    <IonIcon
                      // style={{ padding: "3px 127px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </div>
                </Link>
              </li>
              <li className="num">
                <Link to={ROUTES.ViewBuyers}>
                  {" "}
                  <div
                    className={
                      location.pathname === ROUTES.ViewBuyers
                        ? "list-value active"
                        : "list-value"
                    }
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>View Buyers</p>
                    <IonIcon
                      // style={{ padding: "3px 127px" }}
                      icon={caretForwardCircleOutline}
                    />
                  </div>
                </Link>
              </li>
            </div>
          </ul>
        </div>
        {/* // <!-- /.navbar-collapse --> */}
      </nav>
    </div>
  );
};
export default SideBar;
