import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../index.css";
import { Button } from "@material-ui/core";
import { IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import Tooltip from "../../tooltip/Tooltip";

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: "#ffffff",
    //   borderBottom: "1px solid rgba(0, 0, 0, .125)",
  },
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  hideBorder: {},
  arrowLabel: {
    "&.MuiIconButton-label": {
      backgroundColor: "red",
      borderRadius: 25,
    },
  },

  checkbox: {
    "&.PrivateSwitchBase-root-10": {
      padding: 0,
      paddingRight: 10,
    },
  },
}));

const Filters = ({
  filters,
  handleFilterChange,
  toggleCheckBox,
  checkFilter,
  handleClear,
}) => {
  const [filtersToShow, setFilterToShow] = useState(true);
  const [expand, setExpand] = useState({});

  console.log("filters", filters);

  useEffect(() => {
    console.log("checkbox filter toggle changed", toggleCheckBox, checkFilter);
    // Get reference to current models object in state
    console.log("filters in useeffect", filters);

    // Compose new state object by..

    let newCheckFilter = Object.keys(checkFilter) // Get list of keys in model
      .reduce((result, k) => {
        // Reduce keys to object of key/value pairs
        // where key match is set to true
        return { ...result, [k]: false };
      }, {});
    // const showMore = (e, value) => {
    // 	console.log("expand", expand);
    // 	setFilterToShow(!filtersToShow);
    // 	let name = e.target.getAttribute("name");
    // 	console.log("eeee", e.target.getAttribute("name"));

    // 	setExpand((prevState) => ({
    // 		...prevState,
    // 		[name]: filtersToShow,
    // 	}));
    // };

    // setCheckFilter(newCheckFilter);
    // setCheckFilter({});
    // showMore();
  }, [toggleCheckBox]);

  const classes = useStyles();

  const showMore = (e, value) => {
    console.log("expand", expand);
    // setFilterToShow(!filtersToShow);
    setFilterToShow(true);
    // if (filtersToShow) {
    // 	setFilterToShow(!filtersToShow);
    // }
    // else {
    // setFilterToShow(true);
    // }
    // setFilterToShow(false);
    // if (filtersToShow) {
    // 	setFilterToShow(false);
    // }
    let name = e.target.getAttribute("name");
    console.log("eeee", e.target.getAttribute("name"));
    // setExpand((prevState) => ({
    // 	...prevState,
    // 	[name]: filtersToShow,
    // }));
    setExpand(() => ({
      [name]: filtersToShow,
    }));

    // setExpand(filtersToShow);
  };

  // const showLess = (e, value) => {
  // 	console.log("expand", expand);
  // 	// setFilterToShow(!filtersToShow);
  // 	if (!filtersToShow) {
  // 		setFilterToShow(!filtersToShow);
  // 	}
  // 	// else {
  // 	// 	setFilterToShow(true);
  // 	// }
  // 	// setFilterToShow(true);
  // 	// if (filtersToShow) {
  // 	// 	setFilterToShow(false);
  // 	// }
  // 	let name = e.target.getAttribute("name");
  // 	console.log("eeee", e.target.getAttribute("name"));
  // 	// setExpand((prevState) => ({
  // 	// 	...prevState,
  // 	// 	[name]: filtersToShow,
  // 	// }));
  // 	setExpand(() => ({
  // 		[name]: filtersToShow,
  // 	}));

  // 	// setExpand(filtersToShow);
  // };

  // setExpand();

  return (
    <div>
      <div className="filterBy">Filter By :</div>

      <button
        className="clear"
        onClick={(e) => {
          handleClear();
        }}
      >
        <IonIcon icon={trashOutline}></IonIcon>Clear Filters
      </button>

      {Object.keys(filters).length > 0 ? (
        Object.keys(filters).map((filter, i) => {
          if (filters[filter][0].isFilter === "0") {
            return;
          }
          return (
            <Accordion defaultExpanded={true} className="accordianRoot">
              <AccordionSummary
                className="acc"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {filter === "Pilot Project" ? (
                  <Typography className="filterName">
                    {" "}
                    <a
                      style={{ color: "blue" }}
                      href="https://sportstechmatch.medium.com/sportstech-match-pilot-proj ect-programme-47980578f62c"
                      target="_blank"
                      rel="noopener noreferer"
                    >
                      <u>{filter}:</u>
                    </a>
                  </Typography>
                ) : (
                  <Typography className="filterName">{filter}:</Typography>
                )}
                {filter === "Pilot Project" ? (
                  <span style={{ right: "22px" }} className="tooltp-vd">
                    <Tooltip message="Filter by vendors that have opted in to run pilot projects based on our best practice guidelines." />
                  </span>
                ) : filter === "Stage" ? (
                  <span style={{ right: "22px" }} className="tooltp-vd">
                    <Tooltip message="Filter by vendors with products or services at a development stage or at a commercial stage." />
                  </span>
                ) : null}
              </AccordionSummary>
              <AccordionDetails>
                <FormControl
                  component="fieldset"
                  className="formControl formControl1"
                >
                  <FormGroup>
                    {filter === "Sports" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            classes={{
                              root: classes.checkbox,
                            }}
                            // className="muicheckbox"
                            checked={
                              (checkFilter[119] && checkFilter[119]) === true
                                ? true
                                : false
                            }
                            name={119}
                            value={119}
                            onChange={(e) =>
                              handleFilterChange(e, {
                                id: 119,
                                isFilter: "1",
                                name: "All Sports",
                                parentCategory: "sports",
                                parentId: 6,
                                slug: "aal-sports",
                              })
                            }
                          />
                        }
                        label={"All Sports"}
                      />
                    ) : null}
                    {filters[filter].length > 0 &&
                      filters[filter]
                        .slice(
                          0,
                          expand[`filter${i}`] === true
                            ? filters[filter].length
                            : 3
                        )
                        .map((value) => {
                          console.log("Value of life", value);
                          if (value.id === 119) {
                          } else
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    classes={{
                                      root: classes.checkbox,
                                    }}
                                    // className="muicheckbox"
                                    checked={
                                      (checkFilter[value.id] &&
                                        checkFilter[value.id]) === true
                                        ? true
                                        : false
                                    }
                                    name={value.id}
                                    value={value.id}
                                    onChange={(e) =>
                                      handleFilterChange(e, value)
                                    }
                                  />
                                }
                                label={value.name}
                              />
                            );
                        })}
                    <Button className="showMoreLess">
                      {filters[filter].length > 3 ? (
                        expand[`filter${i}`] ? (
                          <span
                            name={`filter${i}`}
                            // onClick={(e) => showMore(e, filters[filter].length)}
                            // onClick={(e) => showMore(e, filters[filter].length)}
                            onClick={() => setExpand(false)}
                          >
                            Show less
                          </span>
                        ) : (
                          <span
                            name={`filter${i}`}
                            // onClick={(e) => {
                            // 	showMore(e, filters[filter].length);
                            // }}
                            onClick={(e) => showMore(e, filters[filter].length)}
                          >
                            Show more
                          </span>
                        )
                      ) : null}
                    </Button>
                    {/* {expand && (
											<Button>
												<span
													name={`filter${i}`}
													// onClick={(e) => {
													// 	showMore(e, filters[filter].length);
													// }}
													onClick={() => setExpand(false)}
												>
													Collapse
												</span>
											</Button>
										)} */}
                  </FormGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Filters;
