import React from "react";
import { Pagination } from "@mui/material";

export default function PaginationControlled({ count, setPage, page }) {
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <Pagination
        count={count}
        page={page}
        onChange={handleChange}
        className="tenderPagination"
      />
    </div>
  );
}
