import React, { useEffect } from "react";
import SideBar from "../../adminPanel/sidebar/sideNav";
import { Link, useLocation } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getRatingReviewRequest } from "../../ReduxStore/Actions/ratingReviewActions";
import RequestCard from "./RequestCard";
import { VendorAction } from "../../ReduxStore/Actions";
import SideMenu from "../../components/SideMenu";
import { CircularProgress } from "@material-ui/core";

const RequestReview = () => {
  const location = useLocation();
  console.log("location ===> ", location);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData);
  const productList = useSelector((state) => state.vendorReducer.list);
  const loading = useSelector((state) => state.commonReducer.loading);
  const ratingReviewRequest = useSelector(
    (state) => state.RatingReviewReducer.ratingReviewRequest
  );
  console.log("ratingReviewRequest", ratingReviewRequest);
  useEffect(() => {
    (async () => {
      await dispatch(getRatingReviewRequest());
    })();
  }, []);

  useEffect(() => {
    let vendorId = { vendorId: authData?.id };
    console.log("vendor ID", vendorId);
    // (async () => {
    //   authData && (await dispatch(VendorAction.getList(vendorId)));
    // })();

    (async () => {
      authData &&
        authData.id &&
        (await dispatch(VendorAction.getList(vendorId)));
    })();
  }, [authData]);

  return (
    <>
      <div className="requestReviewContainer ">
        <SideMenu location={location.pathname} />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>Ratings & Reviews Requested</h2>
            <Link className="ratingLink" to={`${ROUTES.CreateRequest}/null`}>
              + Create Request
            </Link>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {productList.length > 0 &&
            ratingReviewRequest &&
            ratingReviewRequest.length > 0 &&
            !loading ? (
              ratingReviewRequest.map((rating) => (
                <RequestCard
                  key={rating.rating_requested_id}
                  {...rating}
                  productList={productList}
                />
              ))
            ) : !loading &&
              ratingReviewRequest &&
              ratingReviewRequest.length === 0 ? (
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                No Ratings & Reviews Request Found
              </p>
            ) : null}
          </div>
          {ratingReviewRequest &&
          ratingReviewRequest.length === 0 &&
          loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RequestReview;
