import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import { ContactAction } from "../../ReduxStore/Actions";
import Divider from "@material-ui/core/Divider";
import { Button, TextField } from "@material-ui/core";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonToast,
} from "@ionic/react";
import { HomeAction } from "../../ReduxStore/Actions/index";
import { Link, useHistory } from "react-router-dom";
import { chevronBackOutline } from "ionicons/icons";

import Footer from "../../components/Footer/Footer";

const ContactVendor = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [state, setState] = useState({
    selector: "client",
  });
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  //to Change the colour of header
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
    })();
  }, []);
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    console.log("proppps", new URLSearchParams(props.location.search));

    let companyName = new URLSearchParams(props.location.search).get(
      "vendorName"
    );
    let productName = new URLSearchParams(props.location.search).get(
      "productName"
    );
    setState({
      productName,
      companyName,
    });
    // let id = props.match.params.id;
    // let params = {
    //   productId: id,
    // };
    // dispatch(ProductAction.getProductById(params));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("statehereee ", state);
    if (state.selector === undefined || state.selector === null) {
      document.getElementById("selector").innerText =
        "Please select one option";
    } else {
      document.getElementById("selector").innerText = "";
      await dispatch(ContactAction.contactVendor(state));
      setToastMessage("Message has been sent Successfully");

      setToast(true);
      setTimeout(() => {
        history.goBack();
      }, 6000);
    }
  };

  return (
    <IonContent>
      {/* <h3 className="edithead text-center">Edit Profile</h3> */}
      {/* <div className="editBody"></div> */}
      {toast === true ? (
        <IonToast
          isOpen={toast}
          message={toastMessage}
          onDidDismiss={() => setToast(false)}
          buttons={[
            {
              text: "Close",
              role: "cancel",
              handler: () => {
                setToast(false);
              },
            },
          ]}
        />
      ) : null}
      <div className="contactVendor">
        <div className="innercontactvendor">
          <div className="vendorhead">
            <div className="mb-2">
              <div onClick={() => history.goBack()}>
                <Button>
                  <IonIcon icon={chevronBackOutline} />{" "}
                  <span style={{ fontWeight: 700 }}> Go back</span>{" "}
                </Button>
              </div>
            </div>{" "}
            <h2> Get More Info</h2>
          </div>
          <Divider variant="middle" />
          <div className="text-center sub-heading0">
            <h2>
              Contact us and we will help you collect the information
              <br /> you need to check if this solution is right for you
            </h2>
          </div>
          <div className="vendorbody">
            <form onSubmit={handleSubmit}>
              <div className="">
                <IonGrid>
                  <IonRow>
                    <IonCol size="6" size-md="6" sizeSm="12" sizeXs="12">
                      <div className="formgroup">
                        <IonLabel className="labletext">Company Name*</IonLabel>
                        <IonInput
                          type="text"
                          name="companyName"
                          className="form-control"
                          value={state?.companyName}
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                          disabled
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size="6" size-md="6" sizeSm="12" sizeXs="12">
                      <div className="formgroup">
                        <IonLabel className="labletext">Product name*</IonLabel>
                        <IonInput
                          type="text"
                          name="productName"
                          className="form-control"
                          value={state?.productName}
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                          disabled
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size-sm="12" sizeSm="12" size-md="6" sizeXs="12">
                      <IonLabel className="labletext">First Name*</IonLabel>
                      <IonInput
                        type="text"
                        name="firstName"
                        required
                        className="form-control"
                        value={state?.firstName}
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                    </IonCol>
                    <IonCol size="6" size-md="6" sizeSm="12" sizeXs="12">
                      <div className="formgroup">
                        <IonLabel className="labletext">Last name*</IonLabel>
                        <IonInput
                          type="text"
                          name="lastName"
                          required
                          className="form-control"
                          value={state?.lastName}
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size="6" size-md="6" sizeSm="12" sizeXs="12">
                      <div className="formgroup">
                        <IonLabel className="labletext">Email*</IonLabel>
                        <IonInput
                          type="email"
                          name="email"
                          required
                          className="form-control"
                          value={state?.email}
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size="6" size-md="6" sizeSm="12" sizeXs="12">
                      <div className="formgroup"></div>
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <div className="formgroup">
                        <IonLabel className="labletext">
                          Why would you like to get more info about this
                          solution? *
                        </IonLabel>
                        <div onChange={(e) => handleChange(e)}>
                          <input
                            type="radio"
                            value={state.selector}
                            name="selector"
                            onChange={(e) => handleChange(e)}
                          />
                          <lable className="lable-round">
                            I am a potential client.
                          </lable>
                          <br />

                          <input
                            type="radio"
                            value="supplier"
                            name="selector"
                          />
                          <lable className="lable-round">
                            I am a potential supplier.
                          </lable>
                          <br />
                          <input
                            type="radio"
                            value="invester"
                            name="selector"
                          />
                          <lable className="lable-round">
                            I am a potential invester.
                          </lable>
                          <br />
                          <input type="radio" value="Other" name="selector" />
                          <lable className="lable-round">Other</lable>
                        </div>
                        <p style={{ color: "red" }} id="selector">
                          {" "}
                        </p>
                        {/* <div class="round">
                          <input
                            type="radio"
                            id="checkbox"
                            name="client"
                            onChange={(e) => handleChange(e)}
                          />
                          <label className="lable-round" for="checkbox">
                            I am a potential client.
                          </label>
                          <br />
                          <input
                            type="radio"
                            id="checkbox"
                            name="supplier"
                            onChange={(e) => handleChange(e)}
                          />
                          <label className="lable-round" for="checkbox">
                            I am a potential supplier.
                          </label>
                          <br />
                          <input
                            type="radio"
                           id="checkbox"
                            name="investor"
                            onChange={(e) => handleChange(e)}
                          />
                          <label className="lable-round" for="checkbox">
                            I am a potential invester.
                          </label>
                          <br />
                          <input
                            type="radio"
                            id="checkbox"
                            name="other"
                            onChange={(e) => handleChange(e)}
                          />
                          <label className="lable-round" for="checkbox">
                            Other.
                          </label>
                        </div> */}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="12">
                      <div className="text-left">
                        <IonLabel className="labletext">Message *</IonLabel>

                        <div className="edithead text-center">
                          {" "}
                          <TextField
                            id="outlined-multiline-static"
                            className="messageBody"
                            multiline
                            rows={4}
                            name="message"
                            required
                            defaultValue=""
                            inputProps={{ maxLength: 300 }}
                            value={state?.message}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="12">
                      <div className="text-center">
                        <IonLabel className="labletext">
                          Click Submit And We Will Be In Touch Soon!
                        </IonLabel>

                        <IonButton className="submitbtn" type="submit">
                          Submit
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </IonContent>
  );
};
export default ContactVendor;
