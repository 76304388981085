import { Button, Modal } from "react-bootstrap";
import React from "react";

import "./CustomVendorModal.css";

export function ShowCustomVendorListModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      onHide={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header > */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          View Selected Custom Vendors List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="customListTop" style={{ margin: 20 }}>
          {" "}
          {props.selectedVendors &&
            props?.selectedVendors.map((item, index) => (
              <p className="allowListCompany">
                <b>{index + 1}.</b> {item}
              </p>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

//           <div className="tabAlign">
//             <Tabs
//               defaultActiveKey="fans-&-digital-media"
//               activeKey={activeTab}
//               id="uncontrolled-tab-example"
//               className="mt-8 "
//               onSelect={(key) => setActiveTab(key)}
//             >
//               <Tab eventKey="fans-&-digital-media" title="Fans & Digital Media">
//                 <SingleColMultiRowTable
//                   letter="Fans & Digital Media"
//                   className={"customList"}
//                   isCustom={true}
//                   listSectors={approvedVendors.fanDigitalMedia}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//               <Tab eventKey="commercial" title="Commercial">
//                 <SingleColMultiRowTable
//                   letter="Commercial"
//                   isCustom={true}
//                   listSectors={approvedVendors?.commercial}
//                   searchText={searchText}
//                   className={"customList"}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//               <Tab
//                 eventKey="athletes-&-performance"
//                 title="Athletes & Performance"
//               >
//                 <SingleColMultiRowTable
//                   letter="Athletes & Performance"
//                   isCustom={true}
//                   className={"customList"}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   listSectors={approvedVendors.athlete}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//               <Tab
//                 eventKey="management-&-operations"
//                 isCustom={true}
//                 title="Management & Operations"
//               >
//                 <SingleColMultiRowTable
//                   letter="Management & Operations"
//                   isCustom={true}
//                   className={"customList"}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   listSectors={approvedVendors.managementOperation}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//             </Tabs>
//           </div>
