import { IonInput, IonLabel, IonGrid, IonRow, IonCol } from "@ionic/react";
import { Button } from "@material-ui/core";

import React, { useEffect } from "react";

import "./Product.css";

import { useSelector, useDispatch } from "react-redux";

import { ROUTES } from "../../Services";

import { AdminAction } from "../../ReduxStore/Actions/index";
import SideNav from "../../adminPanel/sidebar/sideNav";
import { useHistory } from "react-router";

const Product = (props) => {
  const [product, setProduct] = React.useState({});
  const [state, setState] = React.useState({});
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.AdminReducer.product.productDetails
  );
  const list = useSelector(
    (state) => state.AdminReducer.product.categoryDetails
  );
  // state.AdminReducer.vendor.data.category);
  console.log(list, data, "llll");
  useEffect(() => {
    (async () => {
      await dispatch(
        AdminAction.getProductById({
          userId: props.match.params.id,
        })
      );
    })();
  }, [props.match.params.id]);

  useEffect(() => {
    setProduct({
      additional_info: data?.additional_info,
      description: data?.description,
      product_name: data?.productName,
      client: data?.selected_clients,
    });
  }, [data]);
  let categories = [];
  let type = [];
  let stage = [];
  let sector = [];
  let softwareDeployment = [];
  let sports = [];
  let levelOfSports = [];
  let targetCustomer = [];
  let timeToImplement = [];
  let history = useHistory();
  useEffect(() => {
    list &&
      list["Categories"] &&
      list["Categories"].map(
        (user) => (categories = [...categories, user.filterValue])
      );
    list &&
      list["Stage"] &&
      list["Stage"].map((user) => (stage = [...stage, user.filterValue]));
    list &&
      list["Sectors"] &&
      list["Sectors"].map((user) => (sector = [...sector, user.filterValue]));
    list &&
      list["Product Type"] &&
      list["Product Type"].map((user) => (type = [...type, user.filterValue]));
    list &&
      list["Sports"] &&
      list["Sports"].map((user) => (sports = [...sports, user.filterValue]));
    list &&
      list["Software Deployment"] &&
      list["Software Deployment"].map(
        (user) =>
          (softwareDeployment = [...softwareDeployment, user.filterValue])
      );
    list &&
      list["Sports Tier"] &&
      list["Sports Tier"].map(
        (user) => (levelOfSports = [...levelOfSports, user.filterValue])
      );
    list &&
      list["Target Customer"] &&
      list["Target Customer"].map(
        (user) => (targetCustomer = [...targetCustomer, user.filterValue])
      );
    list &&
      list["Development & Implementation time"] &&
      list["Development & Implementation time"].map(
        (user) => (timeToImplement = [...timeToImplement, user.filterValue])
      );
    setState({
      category: categories,
      timeToImplement,
      sector,
      stage,
      sports,
      levelOfSports,
      type,
      softwareDeployment,
      targetCustomer,
    });
  }, [list]);
  //to accept or reject the product
  const handleChange = async (e, data, value) => {
    console.log("handlechange", data, value);
    let params = {
      id: data.product_id,
      productName: data.productName,
      isApproved: value,
      email: data.email,
      is_fake: data.is_fake,
    };
    await dispatch(AdminAction.approveProduct(params));
    await dispatch(AdminAction.getProduct());
    history.push(ROUTES.ViewProduct);
  };
  return (
    <div className="top">
      <div className="mainBodyProduct admin-vendor">
        <SideNav />
        <div className="col-md-9 col-sm-12 product-view">
          <div className="login-inner productDetailInner">
            <div className="login-head productHead">
              <h2>Product details</h2>{" "}
              {data?.isApproved == 0 ? (
                <div className=" button-vendor ">
                  <Button
                    className="btn"
                    onClick={(e) => handleChange(e, data, 1)}
                  >
                    Accept
                  </Button>
                  <Button
                    className="btn"
                    onClick={(e) => handleChange(e, data, 2)}
                  >
                    Reject
                  </Button>
                </div>
              ) : null}
            </div>
            <div className="login-body">
              <div className="form-name">
                <h4>Basic Product/service Information</h4>
              </div>
              <form>
                <IonGrid>
                  <div style={{ display: "flex" }}>
                    <IonRow>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel> Name</IonLabel>
                          <IonInput
                            type="text"
                            name="name"
                            disabled
                            className="form-control"
                            value={product.product_name}
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Description</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            value={product.description}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Stage</IonLabel>
                          <IonInput
                            type="text"
                            name="description"
                            className="form-control"
                            value={state.stage}
                            disabled
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Type</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            value={state.type}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Category</IonLabel>
                          <IonInput
                            type="text"
                            name="description"
                            disabled
                            className="form-control"
                            value={state.category}
                          />

                          {/* <select name="cars" id="cars" value={state.category}>
                          {state &&
                            state.category &&
                            state.category.map((name) => (
                              <option disable value="name">
                                {name}
                              </option>
                            ))}
                        </select> */}
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Sector</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            value={state.sector}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-name advance-info ">
                          <h4>Advanced Product/Service Information:</h4>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Software Deployment</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            value={state.softwareDeployment}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Sports</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            value={state.sports}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Level Of Sports</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            value={state.levelOfSports}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Target Customer</IonLabel>
                          <IonInput
                            type="text"
                            name="description"
                            disabled
                            value={state.targetCustomer}
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Clients (Selection)</IonLabel>

                          <IonInput
                            type="text"
                            name="selected_clients"
                            className="form-control"
                            disabled
                            value={product.client}
                          ></IonInput>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Time To Implement</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            value={state.timeToImplement}
                            name="description"
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Additional Information</IonLabel>
                          <IonInput
                            type="text"
                            name="additional_info"
                            disabled
                            value={product.additional_info}
                            className="form-control"
                          />
                        </div>
                      </IonCol>
                      <IonCol
                        size="12"
                        size-md="12
                    "
                      ></IonCol>
                      {/* <div className="d-flex">
                      <IonCheckbox
                        className="custom-checkbox"
                        onChange={(e) => handleCheckbox4(e.detail.checked, e)}
                      />
                      <IonLabel className="labelled">
                        Proof Of Concepts
                      </IonLabel>

                      <IonCheckbox className="custom-checkbox" />
                      <IonLabel className="labelled">
                        NewsLetter opt in
                      </IonLabel>
                    </div>

                    <IonCol size="12" size-md="12">
                      <IonLabel>Is Active?</IonLabel>
                      <div>
                        <input type="radio" name="selector" />
                        <lable className="lable-round">No</lable>
                        <br />
                        <input type="radio" value="supplier" name="selector" />
                        <lable className="lable-round">Yes</lable>
                      </div>
                    </IonCol> */}
                    </IonRow>
                  </div>
                  {/* {list?.isApproved == 0 ? (
                  <div className="text-center button-vendor ">
                    <Button
                      className="btn"
                      onClick={(e) => handleChange(e, list.id, 1)}
                    >
                      Accept
                    </Button>
                    <Button
                      className="btn"
                      onClick={(e) => handleChange(e, list.id, 2)}
                    >
                      Reject
                    </Button>
                  </div>
                ) : null} */}
                </IonGrid>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
