import React, { useEffect } from "react";
import { Divider, Button, Typography } from "@material-ui/core";

import "./approveRFI.css";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction, AdminAction } from "../../ReduxStore/Actions/index";
import SideNav from "../sidebar/sideNav";

import { Link } from "react-router-dom";

const ApproveRFI = () => {
  const dispatch = useDispatch();
  //to get data from redux
  const list = useSelector((state) => state.AdminReducer.rfi);
  //to change the color of header
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(AdminAction.getRFI());
    })();
  }, []);
  // to handle clickon accept reject button

  return (
    <div className="topProduct">
      <div className="mainBodyProduct">
        <SideNav />

        <div className="col-md-9 col-sm-12 viewApprove  RFI-list-Product">
          <h3 className="heading"> Approve New RFI's </h3>
          {/* <Search type="product" /> */}
          <ul style={{ listStyle: "none" }} className="approveNewVendorList">
            {list &&
              list.map((product, index) => {
                let date = new Date(product.created_ts).toDateString().slice(3);
                console.log(product, "pppp");
                return (
                  // <li className="vendor-Card-Product">
                  //   <div className="d-lg-flex d-block card-shade">
                  //     <div className="col-md-8 col-sm-12">
                  //       <Typography
                  //         className="col-md-12 col-sm-12 contentProduct"
                  //         variant="h6"
                  //         gutterBottom
                  //       >
                  //         <span style={{ color: "#062c56" }}>RFI Title: </span>
                  //         {`${product.title}`}
                  //       </Typography>

                  //       <Typography
                  //         className="col-md-12 col-sm-12 content1Product"
                  //         variant="body1"
                  //         gutterBottom
                  //       >
                  //         <span style={{ color: "#062c56" }}> Buyer Name:</span>{" "}
                  //         {`${product.firstName} ${product.lastName}`}
                  //       </Typography>

                  //       <Typography
                  //         className="col-md-12 col-sm-12 content1Product"
                  //         variant="body1"
                  //         gutterBottom
                  //       >
                  //         <span style={{ color: "#062c56" }}> Date:</span>{" "}
                  //         {product.rfiDeadline.split("T")[0]}
                  //       </Typography>
                  //     </div>

                  //     <div
                  //       className="col-md-3 col-sm-12 text-right"
                  //       // style={{ border: "1px solid" }}
                  //     >
                  //       <Link
                  //         to={{
                  //           pathname: `/rfi-details/${product.rfi_id}`,
                  //           state: { type: "Approve" },
                  //         }}
                  //       >
                  //         <Button className="col-md-4 col-sm-4 text-right Viewbtn">
                  //           View Info
                  //         </Button>
                  //       </Link>
                  //     </div>
                  //   </div>

                  //   <Divider></Divider>
                  // </li>
                  <li>
                    <div className="approveNewVendorInfo">
                      <h6 style={{ fontSize: "1.25rem", fontWeight: "600" }}>
                        <span style={{ color: "#062c56" }}>RFI Title: </span>
                        {`${product.title}`}
                      </h6>

                      <p style={{ marginBottom: "10px" }}>
                        <span style={{ color: "#062c56" }}> Buyer Name:</span>{" "}
                        {`${product.firstName} ${product.lastName}`}
                      </p>

                      <p style={{ marginBottom: "0px" }}>
                        <span style={{ color: "#062c56" }}> Date:</span>{" "}
                        {product.rfiDeadline.split("T")[0]}
                      </p>
                    </div>
                    <div className="viewVendorInfo">
                      <Link
                        to={{
                          pathname: `/rfi-details/${product.rfi_id}`,
                          state: { type: "Approve" },
                        }}
                      >
                        <Button className="col-md-4 col-sm-4 text-right Viewbtn">
                          View Info
                        </Button>
                      </Link>
                    </div>
                  </li>
                );
              })}
            {list && list.length === 0 ? (
              <li className="noNew">No new RFI added </li>
            ) : null}
          </ul>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
export default ApproveRFI;
