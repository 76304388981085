import { IonContent, IonSearchbar } from "@ionic/react";
import React, { Component } from "react";
import Filters from "./Components/Filters";
import Results from "./Components/Results";
import { connect } from "react-redux";
import * as Actions from "../../ReduxStore/Actions/index";
import "./index.css";
import { Button } from "@material-ui/core";
import { chevronForwardOutline, searchOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";
import { savePrevState } from "../../ReduxStore/Actions/searchActions";
import { Login } from "..";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryNames: [],
      offset: 25,
      category: {},
      searchText: "",
      dropDownCategory: "Categories",
      toggleCheckBox: true,
      checkFilter: {},
      showResults: false,
    };

    // this.dispatch = useDispatch();

    console.log("props in search", props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleViewMore = this.handleViewMore.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleClear(e) {
    this.setState({
      category: {},
      checkFilter: {},
      categoryNames: [],
    });
    let params = {
      categoryNames: [],
      searchText: "",
      category: {},
    };
    this.props.getProducts(params);
  }
  handleScroll(e) {
    if (e.detail.scrollTop > 10) {
      this.props.setHeaderClass("blue");
    } else {
      this.props.setHeaderClass("trans");
    }
  }

  componentDidMount() {
    this.props.setHeaderClass("trans");
    const { getFilters, getProducts } = this.props;

    // console.log(
    //   "this.props.history.location.pathname",
    //   window.history.state?.prevUrl
    // );
    console.log("prevData", this.props.prevData);
    if (
      this.props.prevData &&
      (this.props.prevData.searchText ||
        this.props.prevData.categoryNames ||
        this.props.prevData.category)
    ) {
      console.log("Insideeee");
      if (this.props.match.path.split("/")[1] === "category") {
      }
      console.log("before filters", this.props.match.path.split("/"));
      getFilters();
      let params = {
        categoryNames: this.props.prevData.categoryNames,
        searchText: this.props.prevData.searchText,
        category: this.props.prevData.category,
      };
      getProducts(params);
      this.setState({
        categoryNames: this.props.prevData.categoryNames,
        // categoryIds: [],
        category: this.props.prevData.category,
        searchText: this.props.prevData.searchText,
        dropDownCategory: this.props.prevData.dropDownCategory,
        toggleCheckBox: this.props.prevData.toggleCheckBox,
        checkFilter: this.props.prevData.checkFilter,
      });
    } else {
      if (this.props.match.path.split("/")[1] === "category") {
      }
      console.log("before filters", this.props.match.path.split("/"));
      getFilters();
      let params = {
        categoryNames: this.state.categoryNames,
        searchText: this.state.searchText,
        category: this.state.category,
      };
      getProducts(params);
    }

    // this.props.dispatch(HomeAction.setHeaderClass("blue"));
  }
  componentWillUnmount() {
    // window.history.pushState({ prevUrl: window.location.pathname }, null, "");
    console.log("statee unmount", this.state);
    this.props.savePrevState(this.state);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log("this.props.route.path == nextProps.route.path", nextProps);
  //   // if (this.props.route.path == nextProps.route.path) return false;
  //   // return true;
  // }

  handleCategoryClick(e, value) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showResults: true });
    const { getProductsByCategory } = this.props;
    console.log("eee", value);

    this.props.history.push(`/category/${value.slug}`);

    this.setState(
      (prevState) => ({
        category: {
          ...prevState.category,
          name: value.name,
          parentId: value.parentId,
        },
        searchText: "",
        toggleCheckBox: !prevState.toggleCheckBox,
        checkFilter: {},
        categoryNames: [],
      }),
      () => {
        let params = {
          category: { name: value.name, parentId: value.parentId },
        };

        getProductsByCategory(params);
      }
    );
  }
  handleViewMore() {
    const { getProducts } = this.props;
    console.log("offsettttttttt", this.state.offset);
    this.setState({ offset: this.state.offset + 25 }, () => {
      let params = {
        categoryNames: this.state.categoryNames,
        searchText: this.state.searchText,
        category: this.state.category,
        offset: this.state.offset,
      };
      getProducts(params);
    });
  }
  handleFilterChange(e, value) {
    console.log("in handle change", value, e);
    this.setState({ showResults: true });
    let checked = e.target.checked;
    let name = e.target.name;

    const { getProducts } = this.props;

    // var params = new URLSearchParams(this.props.location.search);
    // if (checked === true)
    //   params.set(value.parentCategory + "_" + value.id, value.slug);
    // else params.delete(value.parentCategory + "_" + value.id);

    // this.props.history.push({
    //   pathname: this.props.location.pathname,
    //   search: "?" + params.toString(),
    // });
    console.log("catname", this.state.categoryNames);
    this.setState(
      (prevState) => ({
        categoryNames:
          checked === true
            ? [
                ...prevState.categoryNames,
                { name: value.name, parentId: value.parentId },
              ]
            : prevState.categoryNames.filter(
                (name) => name.name !== value.name
              ),
        checkFilter: {
          ...prevState.checkFilter,
          [name]: checked,
        },
      }),
      () => {
        let params = {
          categoryNames: this.state.categoryNames,
          searchText: this.state.searchText,
          category: this.state.category,
        };
        getProducts(params);
      }
    );
  }

  handleSearchTextChange(value) {
    console.log("search Value", value);

    this.setState({
      searchText: value,
    });
    console.log("search Value", this.state.searchText);
  }

  handleDropdownChange(event) {
    const { name, value } = event.target;
    console.log("handleDropdownChange e", event, name, value);
    this.setState({
      dropDownCategory: value,
    });
  }

  handleSearchClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showResults: true });
    var elmntToView = document.getElementById("results");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      alignToTop: false,
    });
    const { getProducts } = this.props;

    // var urlParams = new URLSearchParams(this.props.location.search);

    // // urlParams.set("searchText", this.state.searchText);

    this.props.history.push({
      pathname: "/marketplace",
      // search: "?" + urlParams.toString(),
    });
    this.setState({
      category: {},
      checkFilter: {},
      categoryNames: [],
    });
    console.log("handleSearchClick");

    let params = {
      categoryNames: [],
      searchText: this.state.searchText,
      category: {},
    };
    getProducts(params);
  }

  render() {
    const { filters, products } = this.props;
    const { searchText } = this.state;
    return (
      <IonContent scrollEvents={true} onIonScroll={this.handleScroll}>
        <div className="parentDiv snd ">
          <div className="container">
            <div className="table">
              <div className="table-cell">
                <p className="headingSearch">
                  SEARCH AND DISCOVER THE RIGHT SPORTS TECH SOLUTIONS FOR YOU
                </p>
              </div>
            </div>
            <form onSubmit={this.handleSearchClick}>
              <div className="searchBar1 d-flex m-auto">
                <IonSearchbar
                  className="p-0 ionSearchBar"
                  value={searchText}
                  onIonChange={(e) =>
                    this.handleSearchTextChange(e.detail.value)
                  }
                  showCancelButton="always"
                  // debounce={1000}
                  cancelButtonText=""
                  animated
                  placeholder="What are you looking for?"
                />
                <div className="d-flex flex-row">
                  <button
                    className="searchButton"
                    type="submit"
                    // onClick={this.handleSearchClick}
                  >
                    <IonIcon
                      icon={searchOutline}
                      style={{ marginRight: "5px" }}
                    />
                    Search
                  </button>
                </div>
              </div>
            </form>
            <p
              style={{
                fontSize: "14px",
                color: "white",
                textAlign: "center",
              }}
            >
              ( Use an underscore between search terms to narrow down your
              search results. )
            </p>
          </div>
        </div>
        <div className="">
          <div>
            <ul className="categoryStrip">
              {filters.Categories &&
                filters.Categories.slice(0, 3).map((data, index) => {
                  return (
                    <Button
                      value={data.id}
                      onClick={(e) => this.handleCategoryClick(e, data)}
                    >
                      {" "}
                      <li
                        value={data.id}
                        className="stripCategory"
                        // onClick={(e) => this.handleCategoryClick(e, data)}
                      >
                        {data.name}{" "}
                      </li>
                    </Button>
                  );
                })}
              <div className="dropdownHover browseHover">
                <li>
                  Browse Categories
                  <IonIcon icon={chevronForwardOutline}></IonIcon>
                </li>
                <div className="row dropdownHover-content">
                  <div className="flex">
                    {filters.Categories &&
                      filters.Categories.slice(3, 12).map((data, index) => {
                        console.log("FILTERS", filters);
                        return (
                          <Button
                            value={data.id}
                            onClick={(e) => this.handleCategoryClick(e, data)}
                          >
                            {" "}
                            <p
                              value={data.id}
                              // onClick={(e) => this.handleCategoryClick(e, data)}
                            >
                              {data.name}{" "}
                            </p>
                          </Button>
                        );
                      })}
                    {filters.Categories &&
                      filters.Categories.slice(
                        12,
                        filters.Categories.length
                      ).map((data, index) => {
                        return (
                          <Button
                            value={data.id}
                            onClick={(e) => this.handleCategoryClick(e, data)}
                          >
                            {" "}
                            <p
                              value={data.id}
                              // onClick={(e) => this.handleCategoryClick(e, data)}
                            >
                              {data.name}{" "}
                            </p>
                          </Button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="homeMainClass parent">
          <div className="container  d-flex text-center flex-column align-items-center newContainer">
            <div className="contactSec">
              <h2>
                Do you have a problem to solve? Create a request for information
                (RFI) and get quickly to the right vendor for your use case.
              </h2>
              <Link
                to={
                  localStorage.getItem("token") ? ROUTES.formOne : ROUTES.Login
                }
              >
                <button className="clear">Create RFI </button>
              </Link>
            </div>
            {this.state.showResults ? (
              <div>
                <div className="searchResultsText hidden-xs">
                  <h2>Search Results</h2>
                </div>
                <h3>Check out these solutions in our marketplace</h3>
              </div>
            ) : null}
          </div>
          <div className="container d-block d-md-flex d-lg-flex newContainer">
            <div
              style={{ backgroundColor: "#c3bebe2b" }}
              className="col-12 col-sm-12 col-md-3"
            >
              <Filters
                filters={filters}
                handleFilterChange={this.handleFilterChange}
                toggleCheckBox={this.state.toggleCheckBox}
                checkFilter={this.state.checkFilter}
                handleClear={this.handleClear}
              />
            </div>
            <div id="results"></div>
            {this.state.showResults ? (
              <div className="col-12 col-sm-12 col-md-9">
                <div className="searchResultsText visible-xs">
                  <h2>Search Results</h2>
                </div>
                <Results
                  products={products}
                  handleViewMore={this.handleViewMore}
                  offset={this.state.offset}
                />
              </div>
            ) : (
              <div className="noNew">
                <h2>Start searching the right sports tech solutions for you</h2>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </IonContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // user: state.auth.user,
    filters: state.searchReducer.filters,
    products: state.searchReducer.products,
    prevData: state.searchReducer.prevData,
    headerClass: state.homeReducer.headerClass,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //   enableDisableQuestion: params =>
    //     dispatch(TASKS.enableDisableQuestion(params)),
    savePrevState: (params) => dispatch(savePrevState(params)),

    getFilters: (params) => dispatch(Actions.SearchAction.getFilters(params)),
    getProducts: (params) => dispatch(Actions.SearchAction.getProducts(params)),
    getProductsByCategory: (params) =>
      dispatch(Actions.SearchAction.getProductsByCategory(params)),
    setHeaderClass: (params) =>
      dispatch(Actions.HomeAction.setHeaderClass(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
