export const ACTION_TYPE = {
  LOADING_LIST: "LOADING_LIST",
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  GET_LATEST: "GET_LATEST",
  QUESTION_LIST: "QUESTION_LIST",
  TABS: "TABS",
  GET_SAVED_RESPONSE: "GET_SAVED_RESPONSE",
  CREATE_RFI: "CREATE_RFI",
  GET_RFI: "GET_RFI",
  GET_RFI_BY_ID: "GET_RFI_BY_ID",
  GET_RFI_RESPONSE: "GET_RFI_RESPONSE",
  GET_APPROVED_VENDORS: "GET_APPROVED_VENDORS",
  GET_APPROVED_VENDORS_COMPANY: "GET_APPROVED_VENDORS_COMPANY",
  RESPOND_RFI: "RESPOND_RFI",
  GET_BUYER_EVALUATION_DATA: "GET_BUYER_EVALUATION_DATA",
  GET_RFI_ADMIN: "GET_RFI_ADMIN",
  GET_APPROVED_RFI_ADMIN: "GET_APPROVED_RFI_ADMIN",

  RFI_PAGE_ONE: "RFI_PAGE_ONE",
  RFI_PAGE_TWO: "RFI_PAGE_TWO",
  RFI_REVIEW_SUBMIT: "RFI_REVIEW_SUBMIT",
  USER_POSTS: "USER_POSTS",
  DELETE_POST: "DELETE_POST",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  CONTACT_US: "CONTACT_US",
  FREE_QUOTE_PRICE: "FREE_QUOTE_PRICE",
  ALL_STATES: "ALL_STATES",
  SET_PREVDATA: "SET_PREVDATA",
  //Profile Actions
  COUNTIES_AND_CITIES_LIST: "COUNTIES_AND_CITIES_LIST",
  USER_PROFILE_DETAILS: "USER_PROFILE_DETAILS",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  CLEAR_PROFILE_STATE: "CLEAR_PROFILE_STATE",
  UPLOAD_COVER_IMAGE: "UPLOAD_COVER_IMAGE",
  UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
  CART: "CART",
  ORDER_DETAILS: "ORDER_DETAILS",
  CART_DATA: "CART_DATA",
  CART_REMOVE: "CART_REMOVE",
  MY_LEADS: "MY_LEADS",
  MY_ORDERS: " MY_Orders",

  UPLOAD_POST: "UPLOAD_POST",
  Property_Type: "Property_Type",

  //Connection API

  GET_MY_CONNECTION: "GET_MY_CONNECTION",
  SEARCH_LEADS: "SEARCH_LEADS",
  ADD_CONNECTION: "ADD_CONNECTION",

  SEARCH_TEXT: "SEARCH_TEXT",
  CLEAR_SEARCH_LIST: "CLEAR_SEARCH_LIST",
  GET_INDUSTRY: "GET_INDUSTRY",

  //Dynamic Pages API
  GET_BLOG: "BLOG",
  GET_DYNAMIC_PAGE_CONTENT: "GET_DYNAMIC_PAGE_CONTENT",
  CLEAR_DYNAMIC_PAGE_CONTENT: "CLEAR_DYNAMIC_PAGE_CONTENT",

  //Search API
  GET_FILTERS: "GET_FILTERS",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
  GET_BUYER_PRODUCT_BY_ID: "GET_BUYER_PRODUCT_BY_ID",
  //manage listing
  GET_LIST: "GET_LIST",
  GET_VENDOR_USERS: "GET_VENDOR_USERS",
  DEL_ID: "DEL_ID",
  GET_FILTER: "GET_FILTER",
  ADD_LIST: "ADD_LIST",
  UPDATE_LIST: "UPDATE_LIST",
  PRODUCT_BY_ID: "PRODUCT_BY_ID",
  // header
  SET_HEADER: "SET_HEADER",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  //AdminPanel
  GET_VENDOR_LIST: "GET_VENDOR_LIST",
  GET_PRODUCT: "GET_PRODUCT",
  GET_CONTACT: "GET_CONTACT",
  GET_APPROVED_VENDOR: "GET_APPROVED_VENDOR",
  GET_APPROVED_BUYER: "GET_APPROVED_BUYER",
  GET_VENDOR: "GET_VENDOR",
  CHANGE_VENDOR_STATUS: "CHANGE_VENDOR_STATUS",
  UPDATE: "UPDATE",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  GET_PRODUCT_ID: "GET_pRODUCT_iD",
  MARK_VENDOR_PAID: "MARK_VENDOR_PAID",

  // support login
  LOGIN_AS_USER: "LOGIN_AS_USER",

  // tenders
  ADD_EDIT_TENDER: "ADD_EDIT_TENDER",
  GET_ALL_TENDERS: "GET_ALL_TENDERS",
  PUBLISH_TENDER: "PUBLISH_TENDER",
  GET_SECTORS: "GET_SECTORS",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_PUBLISHED_TENDERS: "GET_PUBLISHED_TENDERS",
  GET_ACCESSED_TENDERS: "GET_ACCESSED_TENDERS",

  // Rating & Review
  ADD_EDIT_RATING_REVIEW_REQUEST: "ADD_EDIT_RATING_REVIEW_REQUEST",
  GET_RATING_REVIEW_REQUEST: "GET_RATING_REVIEW_REQUEST",
  GET_MY_RATING_REVIEW: "GET_MY_RATING_REVIEW",
  GET_ALL_RATING_REVIEW: "GET_ALL_RATING_REVIEW",
  GET_BUYER_RATING_REVIEW: "GET_BUYER_RATING_REVIEW",
  GET_PENDING_RATING_REVIEW: "GET_PENDING_RATING_REVIEW",
  GET_RATING_REVIEW_INFO: "GET_RATING_REVIEW_INFO",
  GET_RATING_REVIEW_GIVEN_INFO: "GET_RATING_REVIEW_GIVEN_INFO",
  GET_RATINGS_BY_PRODUCT_ID: "GET_RATINGS_BY_PRODUCT_ID",
  GET_TOP_RATING_REVIEW: "GET_TOP_RATING_REVIEW",
  UPDATE_RATING_REVIEW_STATUS: "UPDATE_RATING_REVIEW_STATUS",
  GET_APPROVED_RATING_REVIEW: "GET_APPROVED_RATING_REVIEW",
  GET_RATING_REVIEW_REQUEST_BY_ID: "GET_RATING_REVIEW_REQUEST_BY_ID",
};
