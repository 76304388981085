import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";
const RenderRfiListItems = (props) => {
  console.log("DATATATATTATATAT RFI", props.type, props.rfiListItems);
  return props.rfiListItems.map((rfiListItem) => {
    return (
      <tr>
        <td className="rfi-title-description" size={6}>
          {" "}
          {rfiListItem?.title}
        </td>
        <td size={4} className="pl-3">
          {rfiListItem.rfiDeadline.split("T")[0]}
        </td>
        <td>
          {" "}
          {props.type === "create" && rfiListItem?.isapproved === 1 ? (
            <Link
              to={`${ROUTES.BUYER_RFI_EVALUATION}/${rfiListItem.rfi_id}`}
              className="makeAnchor"
            >
              View Responses
            </Link>
          ) : props.type === "create" && rfiListItem?.status === 1 ? (
            <Link to={`saved-rfi/${rfiListItem.rfi_id}`} className="makeAnchor">
              {" "}
              Continue Drafting
            </Link>
          ) : props.type === "create" &&
            rfiListItem?.isapproved !== 1 &&
            rfiListItem?.rfiDeadline !== "Expired" ? (
            "Pending Approval"
          ) : props.type === "create" &&
            rfiListItem?.isapproved !== 1 &&
            rfiListItem?.rfiDeadline === "Expired" ? (
            "No Response"
          ) : props.type === "respond" && rfiListItem?.status === 2 ? (
            "Response Submitted"
          ) : props.type === "respond" &&
            rfiListItem?.status === 1 &&
            rfiListItem?.rfiDeadline !== "Expired" ? (
            <Link
              to={`${ROUTES.RfiResponse}/${rfiListItem.rfi_id}`}
              className="makeAnchor"
            >
              Continue Drafting
            </Link>
          ) : props.type === "respond" &&
            rfiListItem?.status === 0 &&
            rfiListItem?.rfiDeadline !== "Expired" ? (
            <Link
              to={`${ROUTES.RfiResponse}/${rfiListItem.rfi_id}`}
              className="makeAnchor"
            >
              Submit Response
            </Link>
          ) : (
            <div>No Response</div>
          )}
        </td>
      </tr>
    );
  });
};
export default RenderRfiListItems;
