import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonSpinner,
  IonIcon,
  IonCard,
} from "@ionic/react";
import { IonCheckbox } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";

import { ValidateLoginForm } from "./Validation";
import "./Login.css";
import { withRouter, Link } from "react-router-dom";
import { ROUTES, APPLICATION_CONSTANTS } from "../../Services/index";
import CryptoJS from "react-native-crypto-js";
import { useSelector, useDispatch } from "react-redux";
import { AuthActions } from "../../ReduxStore/Actions/index";
import { Toast } from "../../Utils/Toast";
import Footer from "../../components/Footer/Footer";

const Login = (props) => {
  //   console.log(
  //     "in login props",
  //     props.location.state.from.state.from.search.split("=")[1]
  //   );
  const searchParams =
    props.location?.state?.from?.state?.from?.search?.split("=")[1];
  console.log("search params in login", props.location?.state?.from?.search);
  const dispatch = useDispatch();
  const [state, setState] = React.useState();
  const [errorData, setError] = React.useState();
  const [checked, setChecked] = useState();

  const [isShowPassword, setPasswordType] = React.useState(false);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const loading = useSelector((state) => state.commonReducer.loading);

  useEffect(() => {
    (async () => {
      let rememberme = await localStorage.getItem(
        APPLICATION_CONSTANTS.REMEMBER_ME
      );
      if (rememberme) {
        setChecked(true);
        rememberme = CryptoJS.AES.decrypt(
          rememberme,
          APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
        );
        rememberme = rememberme.toString(CryptoJS.enc.Utf8);
        rememberme = JSON.parse(rememberme);
        setState(rememberme);
        // setUserData(rememberme);
      } else {
        setChecked(false);
      }
    })();
  }, []);

  const handleChange = (event) => {
    console.log(
      "event in login ",
      event,
      event.target.hasAttribute("autocompleted")
    );
    const { name, value } = event.target;

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  async function onSubmitForm(event) {
    event.preventDefault();
    const result = await ValidateLoginForm(state);

    if (result?.isFormValid) {
      setError({});
      if (checked) {
        const rememberme = CryptoJS.AES.encrypt(
          JSON.stringify(state),
          APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
        ).toString();
        localStorage.setItem(APPLICATION_CONSTANTS.REMEMBER_ME, rememberme);
      } else {
        localStorage.removeItem(APPLICATION_CONSTANTS.REMEMBER_ME);
      }

      const status = await dispatch(AuthActions.loginUser(state, props));

      if (status?.status) {
        console.log("connectedddddd in iffff", status);
        if (status?.user?.role == 1) {
          if (searchParams === "tenderemail") {
            console.log("in if condition view tenders");
            props.history.push(ROUTES.ViewTenders);
          } else {
            props.history.push(ROUTES.Vendors);
          }
        } else if (
          status?.user?.role == 0 &&
          props.location?.state?.from?.search.includes("requestId")
        ) {
          dispatch({ type: "LOADING_COMPLETED" });
          props.history.push(
            `${ROUTES.BuyerLanding}${props.location?.state?.from?.search}`
          );
        } else if (status?.user?.role == 0)
          props.history.push(ROUTES.RfiDashboard);
        else if (status?.user?.role == 2)
          props.history.push(ROUTES.AdminProfile);
        else if (status?.user?.role == 4)
          props.history.push(ROUTES.AdminSupportProfile);
      }
    } else {
      setError(result?.error);
    }
  }

  return (
    <IonContent>
      <div className="login">
        <div className="login-inner">
          <Toast
            isOpen={errorStatus === false ? true : false}
            message={errorMessage}
            isSuccess={errorStatus}
            isClearSuccess={true}
            isClearError={true}
          />
          <div className="login-head">
            <h2>Log In</h2>
          </div>
          <div className="login-body">
            <form>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  value={state?.email ? state?.email : ""}
                  name="email"
                  className="form-control"
                  // onIonChange={(event) => {
                  //   handleChange(event);
                  // }}
                  onChange={handleChange}
                ></input>
                <p> {errorData?.email && errorData.email[0]}</p>
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="showpassword-input">
                  <input
                    type={isShowPassword === true ? "text" : "password"}
                    name="password"
                    value={state?.password}
                    // onIonChange={(event) => {
                    //   handleChange(event);
                    // }}
                    onChange={handleChange}
                    className="form-control"
                  ></input>
                  {isShowPassword === true ? (
                    <a
                      onClick={() => {
                        setPasswordType(false);
                      }}
                    >
                      <IonIcon icon={eyeOutline} />
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        setPasswordType(true);
                      }}
                    >
                      <IonIcon icon={eyeOffOutline} />
                    </a>
                  )}
                </div>

                <p> {errorData?.password && errorData.password[0]}</p>
              </div>

              <div className="check-forgot">
                <div className="custom-checkbox-inline">
                  <IonCheckbox
                    className="custom-checkbox"
                    checked={checked}
                    onIonChange={(e) => setChecked(e.detail.checked)}
                  />
                  <IonLabel>Remember Me</IonLabel>
                </div>

                <div className="forgot-password">
                  <Link to={ROUTES.ForgetPassword} className="forgot-text">
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <div className="button-login">
                <button
                  className="gradient-btn-blue"
                  type="submit"
                  color="none"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Log In
                  {(() => {
                    if (loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </button>
              </div>

              <div className="haveaccount">
                <p>
                  Don't have an account? <Link to={ROUTES.SignUp}>Sign Up</Link>
                </p>
              </div>

              <div className="private-policy">
                <p>
                  By logging in, you agree to our<br></br>
                  {/* <a href="#">  */}
                  <Link
                    to={ROUTES.PRIVATE_POLICY}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                  {/* </a>  */} and {/* <a href="#"> */}
                  <Link
                    to={ROUTES.TERMS_AND_CONDITION}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms Of Use
                    {/* </a> */}
                  </Link>
                  .
                </p>
              </div>
            </form>
            {/* <PrivatePolicy showPrivatePolicy={privatePolicy} setPrivatePolicy={() => { setPrivatePolicy(false) }} />
            <TNC showPrivatePolicy={tnc} setPrivatePolicy={() => { setTnc(false) }} /> */}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default withRouter(Login);
