import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	message: "",
	status: null,
	data: {},
};

export const pageReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.GET_DYNAMIC_PAGE_CONTENT:
			console.log();
			return {
				...state,
				message: action.payload.data.message,
				status: action.payload.data.status,
				data: action.payload.data.data,
			};
		case ACTION_TYPE.CLEAR_DYNAMIC_PAGE_CONTENT:
			return {
				message: "",
				status: null,
				data: {},
			};
		default:
			return state;
	}
};
