import { IonButton } from "@ionic/react";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { MultiStepFormLabels, ROUTES } from "../../Services/Contants";
import "./MultiStepFormTabs.css";
import { ArrowBack } from "@material-ui/icons";

const MultiStepFormTabs = ({ count, id }) => {
  useEffect(() => {
    (async () => {
      setTimeout(() => {
        document.body.scrollTop = 0;
      }, 100);
    })();
  }, []);
  const match = useRouteMatch();
  const history = useHistory();
  return (
    <>
      {match.path === ROUTES.UpdateRFI_Admin && (
        <div className="editLabel">
          <Button
            color="none"
            className="gradient-btn-blue goBackBtn"
            onClick={() => {
              id && history.push(ROUTES.RFI_DETAILSnotId + id);
            }}
          >
            <ArrowBack />
            Go Back
          </Button>
          <h1 style={{ margin: 0 }}>Edit RFI</h1>
        </div>
      )}

      <div
        className={
          match.path === ROUTES.UpdateRFI_Admin
            ? "multistep-tabs-wrapper-edit"
            : "multistep-tabs-wrapper"
        }
      >
        <div className={count === 1 ? "label selectedLabel" : "label"}>
          Background
        </div>
        <div className={count === 2 ? "label selectedLabel" : "label"}>
          Requirements
        </div>
        <div className={count === 3 ? "label selectedLabel" : "label"}>
          Select Vendors
        </div>
        <div className={count === 4 ? "label selectedLabel" : "label"}>
          Review and Submit
        </div>
      </div>
    </>
  );
};
export default MultiStepFormTabs;