import React, { useEffect, useState } from "react";
import SideBar from "../../adminPanel/sidebar/sideNav";
import { Link, useHistory } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getPendingRatingReview,
  updateRatingReviewStatus,
} from "../../ReduxStore/Actions/ratingReviewActions";
import { formatDate } from "../../Utils/helpers";
import { CircularProgress } from "@mui/material";
import logoStm from "../../images/logoStm.png";

const ApproveNewReview = () => {
  const [status, setStatus] = useState(null);
  const [ratingGivenId, setRatingGivenId] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pendingRatingReview } = useSelector(
    (state) => state.RatingReviewReducer
  );
  const loading = useSelector((state) => state.commonReducer.loading);
  console.log("pending rating review", pendingRatingReview);

  const handleApproveReject = async (data) => {
    if (data.status === "APPROVED") {
      setStatus("APPROVED");
    } else if (data.status === "REJECTED") {
      setStatus("REJECTED");
    }
    setRatingGivenId(data.id);
    await dispatch(updateRatingReviewStatus(data));
    await dispatch(getPendingRatingReview());
  };

  useEffect(() => {
    if (loading === false) {
      setRatingGivenId(null);
    }
  }, [loading]);

  useEffect(() => {
    dispatch(getPendingRatingReview());
  }, [dispatch]);

  return (
    <>
      <div className="requestReviewContainer newContainerStyle">
        {/* approveNewReviewPage */}
        <SideBar />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>Approve New Rating & Review</h2>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {pendingRatingReview.length > 0 &&
              pendingRatingReview.map((pending) => (
                <div className="productList">
                  <div className="productImg">
                    <img
                      style={{ objectFit: "contain" }}
                      src={
                        pending?.logoUrl.includes("vendor-placeholder-logo.png")
                          ? logoStm
                          : pending?.logoUrl
                      }
                      alt="logo"
                    />
                  </div>
                  <div className="productInfoContainer">
                    <h2>{pending.name}</h2>
                    <div className="productInfoTable">
                      <div>
                        <p>Name of Reviewer</p>
                        <p>:</p>
                        <p>{pending.reviewer_name}</p>
                      </div>
                      <div>
                        <p>Company Name of Reviewer</p>
                        <p>:</p>
                        <p>{pending.reviewer_company_name}</p>
                      </div>
                      <div>
                        <p>Email of Reviewer</p>
                        <p>:</p>
                        <p>{pending.reviewer_email}</p>
                      </div>
                      <div>
                        <p>Date of Creation</p>
                        <p>:</p>
                        <p>{formatDate(pending.rating_given_at)}</p>
                      </div>
                      <div>
                        <p>Status</p>
                        <p>:</p>
                        <p>{pending.given_status}</p>
                      </div>
                    </div>
                  </div>
                  <div className="btnContainers">
                    <button
                      className="approveBtn"
                      disabled={loading}
                      onClick={() =>
                        handleApproveReject({
                          status: "APPROVED",
                          id: pending.rating_given_id,
                          rating_requested_id: pending.rating_requested_id,
                        })
                      }
                    >
                      Approve
                      {loading &&
                      status === "APPROVED" &&
                      ratingGivenId === pending.rating_given_id ? (
                        <CircularProgress
                          size={20}
                          sx={{ marginLeft: "1rem", color: "#FFFFFF" }}
                        />
                      ) : null}
                    </button>
                    <button
                      className="rejectBtn"
                      disabled={loading}
                      onClick={() =>
                        handleApproveReject({
                          status: "REJECTED",
                          id: pending.rating_given_id,
                          rating_requested_id: pending.rating_requested_id,
                        })
                      }
                    >
                      Reject
                      {loading &&
                      status === "REJECTED" &&
                      ratingGivenId === pending.rating_given_id ? (
                        <CircularProgress
                          size={20}
                          color="success"
                          sx={{ marginLeft: "1rem" }}
                        />
                      ) : null}
                    </button>
                    <button>
                      <Link
                        to={{
                          pathname: `/admin-rating-info/${pending.rating_given_id}`,
                        }}
                      >
                        View Rating & Review
                      </Link>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {!loading && pendingRatingReview.length <= 0 && (
            <h4>No Ratings & Reviews to approve</h4>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ApproveNewReview;
