import React, { useEffect } from "react";
import Divider from "@material-ui/core/Divider";

import { IonContent } from "@ionic/react";
import Footer from "../../components/Footer/Footer";
import "./Index.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";
import { useDispatch } from "react-redux";
import { HomeAction } from "../../ReduxStore/Actions";
import ForwardIcon from "@material-ui/icons/Forward";
import { useSelector } from "react-redux";
const Index = () => {
  let dispatch = useDispatch();

  const handleScroll = (e) => {
    console.log("scrolled", e.detail.scrollTop);

    if (e.detail.scrollTop > 10) {
      dispatch(HomeAction.setHeaderClass("blue"));
    } else {
      dispatch(HomeAction.setHeaderClass("trans"));
    }
  };
  const authData = useSelector((state) => state.authReducer.authData);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("trans"));
  }, []);
  return (
    <IonContent scrollEvents={true} onIonScroll={handleScroll}>
      <section className="vendorTop">
        <div className="table">
          <div className="table-cell">
            <p className="headingMain">
              CONNECTING YOU WITH THE SPORTS MARKETPLACE
            </p>
            <div className="subTagline">
              <h4>
                LIST YOUR PRODUCTS IN OUR MARKETPLACE AND GET DISCOVERED TODAY!
              </h4>
            </div>
            <Link
              to={
                localStorage.getItem("token") && authData && authData.role === 1
                  ? ROUTES.Vendors
                  : localStorage.getItem("token") &&
                    authData &&
                    authData.role === 0
                  ? ROUTES.Buyer
                  : ROUTES.Login
              }
            >
              {" "}
              <button className="marketplace">Get Listed</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="buyerSection3 ">
        <h2 className="headingv" style={{ textAlign: "center" }}>
          {/* Testimonials */}
        </h2>
        <div className="testimonialSec">
          <h2>
            “SportsTech Match provides vendors like us the opportunity to get in
            front of decision makers who are coming in the right state of mind
            and looking for innovative solutions.”
          </h2>

          <img
            alt="image"
            src={require("../../images/CommonImages/pico.png")}
          />
          <h5> (Asaf Nevo, CEO & Founder, Pico Get Personal)</h5>
        </div>
      </section>

      <section className="buyerSection3">
        <h2 className="headingv" style={{ textAlign: "center" }}>
          How it works
        </h2>

        <div className="fourStep d-flex ">
          <div className="step">
            <div className="step-content">
              <h3>1</h3>
              <Divider />
              <div className="d-flex">
                <p>List your solution(s) at no cost </p>
              </div>
            </div>
            <ForwardIcon className="arrow-forward" />
          </div>

          <div className="step">
            <div className="step-content">
              <h3>2</h3>
              <Divider />
              <div className="d-flex">
                <p>Update your listing(s) anytime</p>
              </div>
            </div>
            <ForwardIcon className="arrow-forward" />
          </div>
          <div className="step">
            <div className="step-content">
              <h3>3</h3>
              <Divider />
              <div className="d-flex">
                <p>Get notified of RFI's</p>
              </div>
            </div>
            <ForwardIcon className="arrow-forward" />
          </div>
          <div className="step">
            <div className="step-content">
              <h3>4</h3>
              <Divider />
              <div className="d-flex">
                <p>Win new business </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <p className="testimonialSec">
        “We are committed to deliver meaningful connections and create
        long-lasting partnerships.” <br /> Read more about our commitment in our
        about us page.
      </p>

      <section className="HomeSection2 vendor2div">
        <div className="container newContainer">
          <div className="text-center">
            <h2 className="headingv">Manage your listing</h2>
          </div>
          <div className="col-md-4 col-sm-12 col1">
            <img
              alt="image"
              className="vendorImg"
              src={require("../../images/CommonImages/vendorPage.png")}
            />
          </div>
          <div className="col-md-8 col-sm-12 col2">
            <p className="vendorPv">
              Is your solution already listed in our marketplace? Want to update
              it to improve your chances of getting discovered?
            </p>
            <div className="text-center">
              <Link
                to={
                  localStorage.getItem("token") &&
                  authData &&
                  authData.role === 1
                    ? ROUTES.Vendors
                    : localStorage.getItem("token") &&
                      authData &&
                      authData.role === 0
                    ? ROUTES.Buyer
                    : ROUTES.Login
                }
              >
                <button className="theme">Manage Listings</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="HomeSection2 mb-5 vendor2div">
        <div className="container newContainer">
          <div className="text-center">
            <h2 className="headingv">Find the right strategic partner</h2>
          </div>
          <div className="col-md-8 col-sm-12 col1">
            <p style={{ textAlign: "left" }} className="vendorPv">
              Developing the right strategic partnerships with fellow vendors is
              one of the most important go to market strategies available to
              tech vendors. Tell fellow vendors what you are looking for from a
              strategic partner and we will help you find the right one. Your
              search will be anonymous until you're ready to connect with the
              right vendors.
            </p>
            <div className="mb-3 text-center">
              <Link
                to={
                  localStorage.getItem("token") ? ROUTES.formOne : ROUTES.Login
                }
              >
                <button className="theme">Create RFI</button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col2">
            <img
              alt="image"
              className="vendorImg"
              src={require("../../images/CommonImages/hands.png")}
            />
          </div>
        </div>
      </section>

      <Footer></Footer>
    </IonContent>
  );
};
export default Index;
