import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { IonButton, IonContent } from "@ionic/react";
import "./ManageVendorAdmin.css";
import { useDispatch, useSelector } from "react-redux";
import { VendorAction } from "../../ReduxStore/Actions/index";
import { Link, useHistory } from "react-router-dom";
import VendorUserModal from "./VendorUserModal";
import VendorDeleteModal from "./VendorDeleteModal";
import { ROUTES } from "../../Services";
import { ArrowBack } from "@material-ui/icons";
import { Toast } from "../../Utils/Toast";
import { stopLoading } from "../../ReduxStore/Actions/commonAction";

const ManageVendorAdmin = () => {
  const [vendorUserModal, setVendorUserModal] = useState(false);
  const [vendorDeleteModal, setVendorDeleteModal] = useState(false);
  let dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData);

  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);

  let { vendorUsers } = useSelector((state) => state.vendorReducer);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const history = useHistory();
  useEffect(() => {
    dispatch(VendorAction.getVendorUsers());
  }, []);

  console.log("vendorUsers", vendorUsers);

  const parent =
    vendorUsers.length > 0
      ? [
          {
            ...vendorUsers[0].parent,
          },
        ]
      : [
          {
            ...authData,
          },
        ];

  vendorUsers = parent.concat(vendorUsers);

  const swap = (arr, idx1, idx2) => {
    if (idx1 !== idx2 && arr.length > 2) {
      [arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]];
    }
  };
  console.log("vendor USers===>", vendorUsers);
  const indexOfVendorAdmin = vendorUsers.findIndex(
    (rec) => rec.is_vendor_admin === 1
  );

  indexOfVendorAdmin !== 0 && swap(vendorUsers, 1, 0);
  indexOfVendorAdmin !== 0 && swap(vendorUsers, 0, indexOfVendorAdmin);

  console.log("vendorUsers===>", vendorUsers);

  useEffect(() => {
    console.log("authData vendor", authData);
    if (authData && Object.keys(authData).length && !authData.is_vendor_admin) {
      history.push(ROUTES.Vendors);
    }
  }, [authData]);

  useEffect(() => {
    if (errorMessage) {
      setVendorUserModal(false);
    }
  }, [errorMessage]);

  return (
    <IonContent>
      <div className="container vendorAdminPanelContainer">
        <div className="vendorAdminPanelInner">
          <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
            <ArrowBack />
          </span>
          <h4 className="rfi-heading">Vendor Admin Panel</h4>
          <br />
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <p>
              <b>User Accounts Remaining: </b>
              {authData?.user_creation_count || 0}
            </p>
            {authData?.user_creation_count ? (
              <IonButton
                onClick={() => setVendorUserModal(true)}
                className="edit-btn"
              >
                Add User
              </IonButton>
            ) : null}
          </div>
          <div className="rfi-table">
            <table>
              <tr className="rfi-table-head">
                <th>S.No.</th>
                {/* <th>ID</th> */}
                <th>Email</th>
                <th>Actions</th>
              </tr>

              {vendorUsers.map((vendor, index) => (
                <tr key={vendor.id}>
                  <td className="pl-3" size={6}>
                    {index + 1}
                  </td>
                  {/* <td size={4} className="pl-3">
                    {vendor.id}
                  </td> */}
                  <td size={4} className="pl-3">
                    {vendor.email}
                  </td>
                  <td className="pl-3">
                    {authData &&
                    (!authData?.attachedUserId ||
                      (authData?.attachedUserId &&
                        authData?.attachedUserId != vendor.id)) ? (
                      <>
                        {!vendor.is_assigned_to ? (
                          `${
                            vendor.is_vendor_admin
                              ? "Vendor Admin"
                              : "Paid Vendor"
                          }`
                        ) : (
                          <>
                            <IonButton
                              onClick={() => {
                                setVendorUserModal(true);
                                setSelectedVendor(vendor);
                              }}
                              className="vendorEditBtn"
                            >
                              Edit
                            </IonButton>

                            <IonButton
                              onClick={() => {
                                dispatch(stopLoading());
                                setVendorDeleteModal(true);
                                setSelectedVendor(vendor);
                              }}
                              className="vendorDeleteBtn"
                            >
                              Delete
                            </IonButton>
                          </>
                        )}
                      </>
                    ) : vendor.is_vendor_admin ? (
                      "Vendor Admin"
                    ) : null}
                  </td>
                </tr>
              ))}
            </table>
          </div>
          {vendorUserModal && (
            <VendorUserModal
              show={vendorUserModal}
              selectedVendor={selectedVendor}
              onHide={() => {
                setVendorUserModal(false);
                setSelectedVendor(null);
              }}
            />
          )}
          {vendorDeleteModal && (
            <VendorDeleteModal
              show={vendorDeleteModal}
              selectedVendor={selectedVendor}
              onHide={() => {
                setVendorDeleteModal(false);
                setSelectedVendor(null);
              }}
            />
          )}
        </div>
        {(errorStatus === false || errorStatus === true) && errorMessage && (
          <Toast
            isOpen={errorStatus === false ? true : false}
            message={errorMessage}
            isSuccess={errorStatus}
            isClearSuccess={true}
            isClearError={true}
          />
        )}
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default ManageVendorAdmin;
