function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export const ValidateContactForm = (formData) => {
  console.log("formData", formData);
  const { title, url, responseDeadline, sector, category, organization } =
    formData || {};
  let error = {
    title: [],
    url: [],
    responseDeadline: [],
    organization: [],
    sector: [],
    category: [],
  };
  let isFormValid = true;

  if (!category) {
    isFormValid = false;
    error.category = ["Please select a category!"];
  }

  if (category && category.length <= 0) {
    isFormValid = false;
    error.category = ["Please select a category!"];
  }

  if (sector === "Non Sport" || sector === "Sport (Non-Tech)") {
    isFormValid = true;
    error.category = [];
  }

  if (isBlank(organization) === true) {
    isFormValid = false;
    error.organization = ["This field is required!"];
  }
  if (isBlank(title) === true) {
    isFormValid = false;
    error.title = ["This field is required!"];
  }
  if (isBlank(url) === true) {
    isFormValid = false;
    error.url = ["This field is required!"];
  } else if (
    url.trim().length > 0 &&
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      formData.url
    ) === false
  ) {
    isFormValid = false;
    error.url = ["Please enter a valid URL!"];
  }
  if (isBlank(responseDeadline) === true) {
    isFormValid = false;
    error.responseDeadline = ["This field is required!"];
  }

  if (
    new Date(responseDeadline).toDateString() !== new Date().toDateString() &&
    new Date(responseDeadline).getTime() < new Date().getTime()
  ) {
    isFormValid = false;
    error.responseDeadline = ["Response Deadline Cannot Be Of Past Date!"];
  }

  if (!sector) {
    isFormValid = false;
    error.sector = ["Please select a Sector!"];
  }

  return { isFormValid, error };
};
