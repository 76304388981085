import React, { useState, useEffect, useRef } from "react";
import {
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonSpinner,
} from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { TenderActions } from "../../ReduxStore/Actions";

import { ValidateContactForm } from "./TendersValidation";
import {
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import Tooltip from "../../DashboardModules/tooltip/Tooltip";
import { formatArrayToString } from "../../Utils/helpers";

const AddTender = ({
  setToast,
  setTabValue,
  setShowForm,
  setShowActiveTenders,
  setShowNewTenders,
}) => {
  const addSectorSelectRef = useRef();
  const addCategorySelectRef = useRef();

  const [errorData, setError] = useState();
  const [state, setState] = React.useState({
    organization: "",
    title: "",
    url: "",
    responseDeadline: "",
    category: [],
    sector: [],
  });

  const [validUrl, setValidUrl] = React.useState();
  const [categories, setCategories] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [disableCategory, setDisableCategory] = React.useState(false);

  const [sectorData, setSectorData] = React.useState();
  console.log("sectorData in add tender page", sectorData);

  // selectors
  let fetchedSectors = useSelector((state) => state.TenderReducer.sectors);
  let fetchedCategories = useSelector(
    (state) => state.TenderReducer.categories
  );
  const loading = useSelector((state) => state.commonReducer.loading);
  const dispatch = useDispatch();

  console.log("categories===>", fetchedCategories && fetchedCategories);
  console.log("sectors===>", fetchedSectors && fetchedSectors);

  const onCancelForm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setTabValue(0);
    setShowForm(false);
    setShowActiveTenders(false);
    setShowNewTenders(true);
  };

  async function onSubmitForm(event) {
    event.preventDefault();
    event.stopPropagation();
    const result = ValidateContactForm(state);
    console.log("on submit form state", state);

    if (result?.isFormValid) {
      console.log("AAA", result, state);

      // successfull validation and you can dispatch here.
      console.log("AAA", result, state);
      // dispatch add tender action
      await dispatch(
        TenderActions.addEditTender({
          ...state,
          dateOfAddition: new Date(),
          category: state.category.map((cat) => {
            const foundCat =
              categories &&
              categories.find((category) => category.category_id === cat);
            return foundCat && foundCat.category_name;
          }),
        })
      );
      setTabValue(0);
      setShowForm(false);
      setShowActiveTenders(false);
      setShowNewTenders(true);
      setToast(true);
      setState((prevState) => ({
        ...prevState,

        dateOfAddition: new Date(),
      }));
      await dispatch(TenderActions.getAllTenders());
    } else {
      setError(result?.error);
      console.log("error in tenders validation", JSON.stringify(result));
    }
  }

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const handleChange = (event) => {
    console.log("event in handle change", event.target.value);
    if (event.target.name === "sector") {
      setCategories(
        fetchedCategories.filter(
          // (cat) => cat.sector_name === event.target.value
          (cat) => cat.sector_name.includes(event.target.value)
        )
      );
      console.log("state category", state);
      setState((prevState) => ({ ...prevState, category: null }));

      console.log("state category after", state);
      if (
        event.target.value === "Non Sport" ||
        event.target.value === "Sport (Non-Tech)"
      ) {
        setDisableCategory(true);
      } else {
        setDisableCategory(false);
      }
    }
    if (event.target.name === "category") {
      const findCat = fetchedCategories.find(
        // (cat) => cat.category_name === event.target.value
        (cat) => event.target.value.includes(cat.category_name)
      );

      if (findCat) {
        addSectorSelectRef.current.value = findCat.sector_name;
      } else {
        console.log(" state in else of find cat", state);
      }
    }
    const { name, value } = event.target;
    if (name === "url") {
      const isTrueVal = !value || urlPatternValidation(value);
      console.log("IsztrueVal", isTrueVal);
      setValidUrl(isTrueVal);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeSelection = (event) => {
    if (event.target.value.includes(119)) {
      return;
    }
    let k = 9;
    event.target.name === "category"
      ? state.category.length < 3
        ? setState({
            ...state,
            [event.target.name]: event.target.value,
          })
        : event.target.value.length === 2
        ? setState({
            ...state,
            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 4);

    event.target.name === "productType"
      ? state.productType.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length === 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "productStage"
      ? state.productStage.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "timeToImplement"
      ? state.timeToImplement.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);

    if (
      event.target.name !== "productType" &&
      event.target.name !== "timeToImplement" &&
      event.target.name !== "productStage" &&
      event.target.name !== "category"
    ) {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleFocus = () => {
    let sectorArray = {
      value: [],
      name: [],
    };
    let j = 0;
    state.category?.includes(72) ||
    state.category?.includes(73) ||
    state.category?.includes(74) ||
    state.category?.includes(75)
      ? (sectorArray = {
          name: [...sectorArray.name, "Athletes & Performance"],
          value: [...sectorArray.value, 22],
        })
      : (j = 5);
    state.category?.includes(76) ||
    state.category?.includes(77) ||
    state.category?.includes(78) ||
    state.category?.includes(79) ||
    state.category?.includes(80)
      ? (sectorArray = {
          name: [...sectorArray.name, "Fans & Digital Media"],
          value: [...sectorArray.value, 23],
        })
      : (j = 7);

    state.category?.includes(81) ||
    state.category?.includes(82) ||
    state.category?.includes(83) ||
    state.category?.includes(84)
      ? (sectorArray = {
          name: [...sectorArray.name, "Commercial"],
          value: [...sectorArray.value, 24],
        })
      : (j = 8);
    state.category?.includes(88) ||
    state.category?.includes(87) ||
    state.category?.includes(86)
      ? (sectorArray = {
          name: [...sectorArray.name, "Management & Operations"],
          value: [...sectorArray.value, 25],
        })
      : (j = 7);
    state.category?.includes(122)
      ? (sectorArray = {
          name: [...sectorArray.name, "Non Sport"],
          value: [...sectorArray.value, 120],
        })
      : (j = 8);
    state.category?.includes(123)
      ? (sectorArray = {
          name: [...sectorArray.name, "Sport (Non-Tech)"],
          value: [...sectorArray.value, 121],
        })
      : (j = 8);
    console.log(sectorArray, "sectorArrayjjjjjjjjjjjjjjjjjjjjjjj");
    setSectorData(sectorArray.name);
    setState({
      ...state,
      sector: sectorArray.value,
    });

    setState((prevState) => ({
      ...state,
      sector: prevState.sector.map((sec) => {
        const foundSec =
          categories &&
          categories.find((category) => category.sector_id === sec);
        return foundSec && foundSec.sector_name;
      }),
    }));

    // setState((prevState) => ({
    //   ...state,
    //   category: prevState.category.map((cat) => {
    //     const foundCat =
    //       categories &&
    //       categories.find((category) => category.category_id === cat);
    //     return foundCat && foundCat.category_name;
    //   }),
    // }));
  };

  useEffect(() => {
    setCategories(fetchedCategories);
  }, [fetchedCategories]);

  useEffect(() => {
    setSectors(fetchedSectors);
  }, [fetchedSectors]);

  return (
    <div>
      <div className="login-head">
        <h2>Add Tender</h2>
        <div className="errormsg">{/* <p>{props.errorMsg}</p> */}</div>
      </div>

      <div className="login-body">
        <form id="client" className="addTenderForm">
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-md="6">
                <div className="form-group">
                  <div className="d-flex">
                    <IonLabel id="category">Category* </IonLabel>
                    <div style={{ width: 25 }}>
                      <Tooltip message="Please select a maximum of 3 categories per Tender" />
                    </div>
                  </div>

                  <Select
                    multiple
                    className="category"
                    name="category"
                    onClose={handleFocus}
                    value={state.category}
                    onChange={(e) => handleChangeSelection(e)}
                    input={<Input />}
                    renderValue={(selected) => {
                      console.log("hooo", selected);
                      const l =
                        selected.length === 1
                          ? `${selected.length} Category Selected`
                          : `${selected.length} Categories Selected`;
                      return l;
                    }}
                  >
                    {categories &&
                      categories?.map((name) => (
                        <MenuItem
                          className="menutype"
                          key={name.id}
                          id={name.id}
                          value={name.category_id}
                        >
                          <Checkbox
                            checked={
                              state &&
                              state.category &&
                              state.category.indexOf(name.category_id) > -1
                            }
                          />
                          <ListItemText primary={name.category_name} />
                        </MenuItem>
                      ))}
                  </Select>
                  {errorData?.category[0] ? (
                    <p>{errorData?.category[0]}</p>
                  ) : null}
                </div>
              </IonCol>

              <IonCol size="12" size-md="6">
                <div className="form-group">
                  <div className="d-flex">
                    <IonLabel>Sector* </IonLabel>
                    <div style={{ width: 25 }}>
                      <Tooltip message="Sector is pre-defined based on your category choices" />
                    </div>
                  </div>
                  <input
                    className="form-control"
                    value={sectorData && formatArrayToString(sectorData)}
                    disabled
                  />
                </div>
              </IonCol>
              {/* <IonCol size="12" size-md="12">
                <div className="form-group">
                  <IonList>
                    <IonItem className="selectSectorContainer">
                      <IonSelect
                        ref={addSectorSelectRef}
                        className="sectorselect"
                        placeholder="select sector"
                        name="sector"
                        onIonChange={(event) => handleChangeSelection(event)}
                      >
                        {sectors &&
                          sectors.length > 0 &&
                          sectors.map(({ slug, name }, index) => (
                            <IonSelectOption key={index} value={name}>
                              {name}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                  </IonList>
                  {errorData &&
                  errorData?.sector &&
                  errorData?.sector?.length &&
                  errorData?.sector[0] ? (
                    <p className="errorTxt">
                      {errorData && errorData?.sector[0]}
                    </p>
                  ) : null}
                </div>
              </IonCol>

              <IonCol size="12" size-md="12">
                <div className="form-group">
                  <IonList>
                    <IonItem>
                      <IonSelect
                        ref={addCategorySelectRef}
                        className="sectorselect"
                        disabled={disableCategory}
                        multiple={true}
                        placeholder="select categories"
                        selectedText={state && state?.category}
                        name="category"
                        onIonChange={(event) => handleChange(event)}
                      >
                        {categories &&
                          categories.length > 0 &&
                          categories.map(({ id, category_name }) => (
                            <IonSelectOption key={id} value={category_name}>
                              {category_name}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                  </IonList>
                  {errorData &&
                  errorData?.category &&
                  errorData?.category?.length &&
                  errorData?.category[0] ? (
                    <p className="errorTxt">
                      {errorData && errorData?.category[0]}
                    </p>
                  ) : null}
                </div>
              </IonCol> */}

              <IonCol size="12" size-md="12">
                <div className="form-group">
                  <IonLabel>Organisation Name</IonLabel>
                  <IonInput
                    type="text"
                    name="organization"
                    className="form-control"
                    onIonChange={(event) => {
                      handleChange(event);
                    }}
                  ></IonInput>
                  {errorData && errorData?.organization[0] ? (
                    <p className="errorTxt">
                      {errorData && errorData?.organization[0]}
                    </p>
                  ) : null}
                </div>
              </IonCol>

              <IonCol size="12" size-md="12">
                <div className="form-group">
                  <IonLabel>Title</IonLabel>
                  <IonInput
                    type="text"
                    name="title"
                    className="form-control"
                    onIonChange={(event) => {
                      handleChange(event);
                    }}
                  ></IonInput>
                  {errorData && errorData?.title[0] ? (
                    <p className="errorTxt">
                      {errorData && errorData?.title[0]}
                    </p>
                  ) : null}
                </div>
              </IonCol>
              <IonCol size="12" size-md="12">
                <div className="form-group">
                  <IonLabel>URL</IonLabel>
                  <IonInput
                    type="url"
                    name="url"
                    className="form-control"
                    onIonChange={(event) => {
                      handleChange(event);
                    }}
                  ></IonInput>
                  {(errorData && errorData?.url[0]) || validUrl ? (
                    <p className="errorTxt">{errorData && errorData?.url[0]}</p>
                  ) : null}
                  {/* {validUrl ? (
                    <p className="errorTxt">URL is not valid.</p>
                  ) : null} */}
                </div>
              </IonCol>
              <IonCol size="12" size-md="12">
                <div className="form-group">
                  <IonLabel>Response Deadline</IonLabel>
                  <IonInput
                    type="date"
                    name="responseDeadline"
                    className="form-control"
                    onIonChange={(event) => {
                      handleChange(event);
                    }}
                  ></IonInput>
                  {errorData &&
                  errorData?.responseDeadline?.length &&
                  errorData?.responseDeadline[0] ? (
                    <p className="errorTxt">
                      {errorData && errorData?.responseDeadline[0]}
                    </p>
                  ) : null}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div className="button-login addTenderBtn">
            <IonButton
              color="none"
              className="gradient-btn-blue"
              onClick={onSubmitForm}
            >
              Add{" "}
              {(() => {
                if (loading) {
                  return (
                    <IonSpinner name="crescent" className="spinner-loader" />
                  );
                }
              })()}
            </IonButton>
            <IonButton
              color="none"
              className="gradient-btn-blue"
              onClick={onCancelForm}
            >
              Cancel{" "}
              {/* {(() => {
                        if (props.loading) {
                          return <IonSpinner name="crescent" className="spinner-loader" />
                        }
                      })()} */}
            </IonButton>
          </div>

          <div className="private-policy"></div>
        </form>
      </div>
    </div>
  );
};

export default AddTender;
