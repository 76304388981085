function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData) => {
  console.log("FORMDATAA", formData);
  const { open_question, productName } = formData || {};
  let error = {
    open_question: [],
    productName: [],
  };
  let isFormValid = true;

  if (isBlank(open_question) === true) {
    isFormValid = false;
    error.open_question = ["This field is required."];
  } else if (
    open_question?.trim().length > 0 &&
    open_question?.trim().length > 1000
  ) {
    isFormValid = false;
    error.open_question = [
      "Open question cannot be greater than 1000 characters.",
    ];
  }
  if (isBlank(productName) === true) {
    isFormValid = false;
    error.productName = ["This field is required."];
  }

  return { isFormValid, error };
};
