import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	blogData: [],
};

export const blogReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.GET_BLOG:
			console.log("blogData");
			return {
				...state,
				blogData: action.payload,
			};
		default:
			return state;
	}
};
