import { IonIcon } from "@ionic/react";
import { arrowBackCircle } from "ionicons/icons";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../../../Services";
import "./DashboardLink.css";
const DashboardLink = () => {
  let history = useHistory();
  return (
    <div onClick={() => history.goBack()}>
      <div className="dashboard-link-parent">
        <IonIcon icon={arrowBackCircle}></IonIcon>
        <span>Dashboard</span>
      </div>
    </div>
  );
};

export default DashboardLink;
