import axios from "axios";
import { BASE_URL } from "./Services/index";

const axiosInstance = axios.create({ baseURL: BASE_URL });

const errHandling = (error) => Promise.reject(error);

const reqHandling = (config) => {
  const obj = { ...config };
  const token = localStorage.getItem("token");

  if (token) obj.headers["Authorization"] = token;
  return obj;
};

const resHandling = (response) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
