import React, { useEffect } from "react";
import { Divider, Button, Typography } from "@material-ui/core";

import "./viewProduct.css";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction, AdminAction } from "../../ReduxStore/Actions/index";
import SideNav from "../sidebar/sideNav";

import Search from "../SearchBar/searchbar";
import { Link } from "react-router-dom";

const ViewProduct = () => {
  const dispatch = useDispatch();
  //to get data from redux
  const list = useSelector((state) => state.AdminReducer.productList);
  //to change the color of header
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(AdminAction.getProduct());
    })();
  }, []);
  // to handle clickon accept reject button
  const handleChange = async (e, id, value) => {
    let params = {
      id,
      isApproved: value,
    };

    await dispatch(AdminAction.approveProduct(params));
    await dispatch(AdminAction.getProduct());
  };
  return (
    <div className="topProduct">
      <div className="mainBodyProduct">
        <SideNav />

        <div className="col-md-9 col-sm-12 viewApprove  vendor-list-Product">
          <h3 className="heading"> Approve New Products </h3>
          <Search type="product" />
          <ul style={{ listStyle: "none" }} className="approveNewVendorList">
            {list &&
              list.map((product, index) => {
                let date = new Date(product.created_ts).toDateString().slice(3);
                console.log(product, "pppp");
                return (
                  // <li className="vendor-Card-Product">
                  // 	<div className="d-flex">
                  // 		<h3 className="">{index + 1}</h3>
                  // 		<Typography
                  // 			className="col-md-8 col-sm-8 contentProduct"
                  // 			variant="h6"
                  // 			gutterBottom
                  // 		>
                  // 			<span style={{ color: "#062c56" }}>Vendor Name: </span>
                  // 			{`${product.firstName} ${product.lastName}`}
                  // 		</Typography>

                  // 		<Link
                  // 			to={{
                  // 				pathname: `/admin-product/${product.product_id}`,
                  // 			}}
                  // 		>
                  // 			<Button className="col-md-4 col-sm-4 text-right Viewbtn">
                  // 				View Product Info
                  // 			</Button>
                  // 		</Link>
                  // 	</div>
                  // 	<Typography
                  // 		className="col-md-8 col-sm-8 content1Product"
                  // 		variant="body1"
                  // 		gutterBottom
                  // 	>
                  // 		<span style={{ color: "#062c56" }}> Product:</span>{" "}
                  // 		{product.product_name}
                  // 	</Typography>
                  // 	<Typography
                  // 		className="col-md-8 col-sm-8 content1Product"
                  // 		variant="body1"
                  // 		gutterBottom
                  // 	>
                  // 		<span style={{ color: "#062c56" }}> Description:</span>{" "}
                  // 		{product.description}
                  // 	</Typography>
                  // 	<Typography
                  // 		className="col-md-8 col-sm-8 content1Product"
                  // 		variant="body1"
                  // 		gutterBottom
                  // 	>
                  // 		<span style={{ color: "#062c56" }}> Date:</span> {date}
                  // 	</Typography>
                  // 	<Divider></Divider>
                  // </li>
                  <li>
                    <div className="approveNewVendorInfo">
                      <h6 style={{ fontSize: "1.25rem", fontWeight: "600" }}>
                        <span style={{ color: "#062c56" }}>Vendor Name: </span>
                        {`${product.firstName} ${product.lastName}`}
                      </h6>
                      <p>
                        <span style={{ color: "#062c56" }}> Product:</span>{" "}
                        {product.product_name}
                      </p>
                      <p>
                        <span style={{ color: "#062c56" }}> Description:</span>{" "}
                        {product.description}
                      </p>
                      <p>
                        <span style={{ color: "#062c56" }}> Date:</span> {date}
                      </p>
                    </div>
                    <div className="viewVendorInfo">
                      <Link
                        to={{
                          pathname: `/admin-product/${product.product_id}`,
                        }}
                      >
                        <Button className="col-md-4 col-sm-4 text-right Viewbtn">
                          View Product Info
                        </Button>
                      </Link>
                    </div>
                  </li>
                );
              })}
            {list && list.length === 0 ? (
              <li className="noNew">No new product added </li>
            ) : null}
          </ul>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
export default ViewProduct;
