import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonModal,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./AdminSupportPage.css";
import { useSelector, useDispatch } from "react-redux";
import {
  AdminAction,
  HomeAction,
  ProfileAction,
} from "../../ReduxStore/Actions/index";
import SideNav from "../../adminSupportPanel/sideBar/sideNav";
import { CircularProgress } from "@material-ui/core";
import { Redirect, useLocation } from "react-router-dom";
import { ROUTES } from "../../Services";
import EditProfile from "../../adminPanel/AdminPage/changePassword";
import { matchToken } from "../../ReduxStore/Actions/authActions";

const VendorProfile = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [profileImagePreview, setProfileImagePreview] = useState();
  const authData = useSelector((state) => state.authReducer.authData);
  const credData = useSelector((state) => state.AdminReducer.CredContact);

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastError, setToastError] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    const {
      id,
      firstName,
      lastName,
      email,
      companyName,
      companyHq,
      yearFounded,
      website,
      logoUrl,
    } = authData;
    setState({
      id,
      firstName,
      lastName,
      email,
      companyName,
      companyHq,
      yearFounded,
      website,
      logoUrl,
      contactEmail: credData.email,
      contactEmailPassword: credData.password,
    });
    setProfileImagePreview(authData?.logoUrl);
  }, [authData, credData]);

  const handleChange = (event, type, data) => {
    const { name, value } = event.target;

    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    (async () => {
      await dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(AdminAction.getCreds());
    })();
  }, []);

  const location = useLocation();

  const onSubmitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const fileToUpload = selectedFile;

    const formData = new FormData();
    formData.append("image", fileToUpload);

    for (var key in state) {
      formData.append(key, state[key]);
    }
    formData.append("user_id", authData.id);

    const data = await dispatch(
      ProfileAction.updateProfileDetails(formData, true, () => {
        dispatch(matchToken());
      })
    );

    setToastMessage(data.message);
    setToastError(data.status);
    setToast(true);
  };

  const showPreview = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(authData?.logoUrl);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedFile) {
      setProfileImagePreview(authData?.logoUrl);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setProfileImagePreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const redirectTo = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return (
      <Redirect
        to={
          userData?.role === 1
            ? `${ROUTES.Vendors}`
            : userData?.role === 0
            ? `${ROUTES.RfiDashboard}`
            : userData?.role === 2
            ? `${ROUTES.AdminProfile}`
            : `${location.pathname}`
        }
      />
    );
  };

  return (
    <IonContent>
      <IonModal isOpen={showChangePassword} backdropDismiss={false}>
        <EditProfile
          handleToast={setToast}
          handleToastMsg={setToastMessage}
          showChangePassword
          handleModal={setShowChangePassword}
        />
      </IonModal>
      <div className=" vendorProfile1">
        {authData && authData.role === 4 ? (
          <>
            <SideNav />

            <div className="col-md-9 col-sm-12 innerAdminprofile">
              {toast === true ? (
                <IonToast
                  isOpen={toast}
                  message={toastMessage}
                  onDidDismiss={() => setToast(false)}
                  buttons={[
                    {
                      text: "Close",
                      role: "cancel",
                      handler: () => {
                        setToast(false);
                      },
                    },
                  ]}
                />
              ) : null}
              <div className="vendorprofilehead">
                <h2>Admin Support Profile</h2>
              </div>

              <div className="vendorprofilebody">
                <div className="formname">
                  <h4> Details:</h4>
                </div>
                <form>
                  <IonGrid className="body">
                    <IonRow>
                      <IonCol size="12" size-md="6">
                        <div className="formgroup">
                          <IonLabel className="labletext">First Name*</IonLabel>
                          <IonInput
                            type="text"
                            name="firstName"
                            value={state?.firstName}
                            className="form-control"
                            onIonChange={(event) => {
                              handleChange(event);
                            }}
                          ></IonInput>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="6">
                        <div className="formgroup">
                          <IonLabel className="labletext">Last name*</IonLabel>
                          <IonInput
                            type="text"
                            name="lastName"
                            value={state?.lastName}
                            className="form-control"
                            onIonChange={(event) => {
                              handleChange(event);
                            }}
                          ></IonInput>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="12">
                        <div className="formgroup">
                          <div className=" d-flex">
                            <IonLabel className="labletext">
                              Email address
                            </IonLabel>
                          </div>
                          <IonInput
                            type="email"
                            name="email"
                            disabled
                            value={state?.email}
                            className="form-control"
                            onIonChange={(event) => {
                              handleChange(event);
                            }}
                          ></IonInput>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <div className="button-name text-center">
                    <IonButton
                      color="none"
                      className="btnsave"
                      onClick={() => {
                        setShowChangePassword(true);
                      }}
                    >
                      Change Password
                    </IonButton>
                    <IonButton
                      color="none"
                      className="btnsave"
                      onClick={onSubmitForm}
                    >
                      save
                    </IonButton>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50vh",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            {redirectTo()}
          </div>
        )}
      </div>
    </IonContent>
  );
};

export default VendorProfile;
