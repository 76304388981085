import { IonContent } from "@ionic/react";
import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction } from "../../ReduxStore/Actions";

const Home = () => {
  let dispatch = useDispatch();

  const handleScroll = (e) => {
    if (e.detail.scrollTop > 10) {
      dispatch(HomeAction.setHeaderClass("blue"));
    } else {
      dispatch(HomeAction.setHeaderClass("trans"));
    }
  };
  let latestProdData = useSelector((state) => state.vendorReducer.latestProd);
  console.log("latestProdData", latestProdData);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("trans"));
  }, []);

  return (
    <IonContent scrollEvents={true} onIonScroll={handleScroll}>
      <section className="HomeTop">
        <div className="table">
          <div className="table-cell">
            <p className="headingMain">
              DON'T WASTE TIME <br />
              SPEAKING TO THE WRONG VENDORS.
            </p>
            <div className="subTagline">
              <h4>
                Tell our growing community of trusted vendors what solution you
                <br />
                are looking for. You remain anonymous until you are
                <br /> ready to connect with the right vendors.
              </h4>
            </div>
            <Link
              to={localStorage.getItem("token") ? ROUTES.formOne : ROUTES.Login}
            >
              {" "}
              <button className="marketplace">Create RFI</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="HomeSection40 ">
        <h2 className="heading" style={{ textAlign: "center" }}>
          {/* Testimonials */}
        </h2>
        <div className="testimonialSec">
          <h2>
            “SportsTech Match saved us a huge amount of time in terms of
            sourcing and identifying the best vendors for the solution required
            by one of our clients (a regional sports body based in the UK). The
            process was quick and easy from creating the RFI through to
            connecting with the right vendors.”
          </h2>

          <img alt="image" src={require("../../images/CommonImages/GW.png")} />
        </div>
      </section>

      <section className="HomeSection32 ">
        <h2 className="heading" style={{ textAlign: "center" }}>
          4 Easy Steps
        </h2>
        <div className="col-md-12 col col-sm-12 Benefits d-block d-sm-flex  d-md-flex d-lg-flex mb-5">
          <div className="text-center col-md-3 col-sm-3 smallCard">
            <img
              className=" ben"
              src={require("../../images/HomeImages/Icon_4.png")}
            ></img>
            <h3>1. Create an account</h3>
            <p style={{ marginBottom: "0px" }}>It's free and takes 2 minutes</p>
          </div>
          <div className="text-center col-md-3 col-sm-3 smallCard">
            <img
              className=" ben"
              src={require("../../images/HomeImages/Icon_2.png")}
            ></img>
            <h3>2. Create your RFI</h3>
            <p style={{ marginBottom: "0px" }}>
              Add questions to filter out the wrong vendors.
            </p>
          </div>
          <div className="text-center col-md-3 col-sm-3 smallCard">
            <img
              className=" ben"
              src={require("../../images/HomeImages/Icon_3.png")}
            ></img>
            <h3>3. Select vendors</h3>
            <p style={{ marginBottom: "0px" }}>
              Choose which vendors should receive your RFI.
            </p>
          </div>
          <div className="text-center col-md-3 col-sm-3 smallCard">
            <img
              className=" ben"
              src={require("../../images/HomeImages/Icon_1.png")}
            ></img>
            <h3>4. Contact vendors</h3>
            <p style={{ marginBottom: "0px" }}>
              Evaluate responses and contact the right vendors.
            </p>
          </div>
        </div>
      </section>
      <section className="HomeSection4">
        <div className="container4">
          <Link
            to={localStorage.getItem("token") ? ROUTES.formOne : ROUTES.Login}
          >
            <div className="container4__button">
              <button className="headingVendor">Find the Right Vendor</button>
            </div>
          </Link>

          <div className="container newContainer">
            <div className="col-md-12 col col-sm-12 info justify-content-space-between d-flex">
              <div className="latest_info text-center">
                <div className="block vendorCard">
                  <div className="blockhead">
                    <img
                      className=" ben"
                      src={require("../../images/HomeImages/ContactUs.png")}
                    ></img>
                  </div>
                  <div className="blockbody">
                    <div className="cardText">
                      <p>
                        Rather speak to <br />
                        one of our team?
                      </p>
                    </div>
                    <Link to={ROUTES.ContactUs}>
                      <button className="boxBtn">Contact Us</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="latest_info text-center">
                <div className="block vendorCard">
                  <div className="blockhead">
                    <img
                      className=" ben"
                      src={require("../../images/HomeImages/SearchMarketplace.png")}
                    ></img>
                  </div>
                  <div className="blockbody">
                    <div className="cardText">
                      <p>
                        Are you looking
                        <br />
                        for solutions to <br />
                        run pilot <br />
                        projects with you?
                      </p>
                    </div>
                    <Link to={ROUTES.Search}>
                      <button className="boxBtn">Search Marketplace</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="latest_info text-center">
                <div className="block vendorCard">
                  <div className="blockhead">
                    <img
                      className=" ben"
                      src={require("../../images/HomeImages/AboutUs.png")}
                    ></img>
                  </div>

                  <div className="blockbody">
                    <div className="cardText">
                      <p>
                        Want to find out
                        <br /> more about us?
                      </p>
                    </div>
                    <Link to={ROUTES.About}>
                      <button className="boxBtn">About Us</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="HomeSection2 abtusdiv"></section>

      <Footer></Footer>
    </IonContent>
  );
};

export default Home;
