function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData) => {
  console.log("FORMDATAA", formData);
  const {
    firstName,
    lastName,
    email,
    organisationType,
    companyHq,
    password,
    confirmPassword,
    website,
    companyName,
    TaC,
    PrivacyPolicy,
  } = formData || {};
  let error = {
    firstName: [],
    lastName: [],
    email: [],
    organisationType: [],
    companyHq: [],
    website: [],
    password: [],
    confirmPassword: [],
    companyName: [],
    TaC: [],

    PrivacyPolicy: [],
  };
  let isFormValid = true;
  console.log("TERMS OF USE", formData);

  if (isBlank(firstName) === true) {
    isFormValid = false;
    error.firstName = ["This field is required."];
  } else if (firstName?.trim().length > 0 && firstName?.trim().length > 50) {
    isFormValid = false;
    error.firstName = ["Name cannot be greater than 50."];
  }
  if (isBlank(lastName) === true) {
    isFormValid = false;
    error.lastName = ["This field is required."];
  } else if (lastName?.trim().length > 0 && lastName?.trim().length > 50) {
    isFormValid = false;
    error.lastName = ["Last Name cannot be greater than 50."];
  }
  if (isBlank(website) === false) {
    var urlregex = new RegExp(
      "^(http|https|ftp)://([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&amp;%$-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.([a-zA-Z]{2,10}|[a-zA-Z]{10}))(:[0-9]+)*(/($|[a-zA-Z0-9.,?'\\+&amp;%$#=~_-]+))*$"
    );
    // let check = website.match(
    //   /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    // );
    let check = urlregex.test(website);
    console.log("check", check);
    if (check === false) {
      isFormValid = false;
      console.log("check", check);

      error.website = [
        "Url doesn't match standard format i.e https://www.website.com ",
      ];
    }
  }
  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  } else if (
    email.trim().length > 0 &&
    /\S+@\S+\.\S+/.test(formData.email) === false
    // /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,8})$/.test(
    //   formData.email
    // ) === false
  ) {
    isFormValid = false;
    error.email = ["Email must be a valid email address."];
  }

  //  else if( parseInt(role) !== 1 || parseInt(role) !== 2){
  //     isFormValid = false
  //     error.role = ["Please select a valid role."]
  // }
  if (isBlank(companyHq) === true) {
    isFormValid = false;
    error.companyHq = ["This field is required."];
  }
  if (isBlank(companyName) === true) {
    isFormValid = false;
    error.companyName = ["This field is required."];
  }
  if (isBlank(organisationType) === true) {
    isFormValid = false;
    error.organisationType = ["This field is required."];
  }
  if (isBlank(password) === true) {
    isFormValid = false;
    error.password = ["This field is required."];
  } else if (
    password?.trim().length > 0 &&
    (password?.trim().length < 6 || password?.trim().length > 15)
  ) {
    isFormValid = false;
    error.password = ["Password length must be between 6 to 15 characters."];
  }
  if (isBlank(confirmPassword) === true) {
    isFormValid = false;
    error.confirmPassword = ["This field is required."];
  } else if (
    confirmPassword?.trim().length > 0 &&
    (confirmPassword?.trim().length < 6 || confirmPassword?.trim().length > 15)
  ) {
    isFormValid = false;
    error.confirmPassword = [
      "Confirm Password length must be between 6 to 15 characters.",
    ];
  } else if (
    confirmPassword?.trim().length > 0 &&
    confirmPassword?.trim().length > 0 &&
    password?.trim() !== confirmPassword?.trim()
  ) {
    isFormValid = false;
    error.confirmPassword = ["Passwords Don’t Match!"];
  }
  if (TaC.value == 67) {
    isFormValid = false;
    // console.log("TERMS OF USE", termsOfUse);
    error.TaC = ["Please check this box to agree to our Terms of Use."];
  }
  if (PrivacyPolicy.value == 69) {
    isFormValid = false;
    error.PrivacyPolicy = [
      "Please check this box to agree to our Privacy Policy",
    ];
  }

  return { isFormValid, error };
};

export const FormatInterestedCounty = (obj, totalIntrestedCountry) => {
  let formattedResponse = [];

  totalIntrestedCountry.map((data, i) => {
    if (obj.hasOwnProperty(data.county) && obj.hasOwnProperty(data.state)) {
      let valCont = data.county;
      let valState = data.state;
      formattedResponse.push({ county: obj[valCont], state: obj[valState] });
    }
  });
  console.log("formattedResponse", formattedResponse);
  return formattedResponse;
};
