import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	headerClass: "trans",
};

export const homeReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.SET_HEADER:
			console.log("SET_HEADER", action.payload);
			return {
				...state,
				headerClass: action.payload,
			};

		default:
			return state;
	}
};
