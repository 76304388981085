import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services/index";
import ToolTip from "../../DashboardModules/tooltip/Tooltip";
import { useSelector } from "react-redux";

const SideMenu = ({ location }) => {
  const authData = useSelector((state) => state.authReducer.authData);
  console.log("auth data in side menu", authData);
  return (
    <div className="vendorSidebar">
      <ul>
        <li>
          <Link to={ROUTES.Vendors}>Product Listings & RFI’s</Link>
          <div className="noteText">
            <p>
              You will not be notified of RFIs until you create at least one
              product listing.
              <span>
                <ToolTip
                  message="It is important to ensure that
your product(s) is listed in the
correct sector so that you
receive relevant RFI's."
                />
              </span>
            </p>
          </div>
        </li>
        {/* {authData?.is_paid && authData?.is_vendor_admin ? ( */}
        {(authData?.is_paid && authData?.is_vendor_admin) ||
        (authData?.is_vendor_admin && authData?.is_assigned_to) ? (
          <li>
            <Link to={ROUTES.ManagePaidVendors}>Vendor Admin Panel</Link>
            <div className="noteText">
              <p>
                Manage the user accounts of your colleagues.
                <span>
                  <ToolTip
                    message="As the Vendor Admin, you can add new users,
               delete users or edit users names and login details."
                  />
                </span>
              </p>
            </div>
          </li>
        ) : null}
        {/* ) : null} */}
        <li>
          <Link to={ROUTES.ViewTenders}>External Tenders</Link>
          <div className="noteText">
            <p>
              Discover relevant Tenders / RFPs / RFIs from 3rd party sources.
              <span>
                <ToolTip
                  message="Tenders are sourced by STM directly
               from buyers as well as discovered by
               STM's own watch service which covers hundreds
                of relevant websites worldwide."
                />
              </span>
            </p>
          </div>
        </li>
        {authData?.is_paid ||
        authData?.is_vendor_admin ||
        (authData?.is_vendor_admin && authData?.is_assigned_to) ? (
          <li
            className={
              (location === "/request-review" ||
                location.includes("/create-request")) &&
              "active"
            }
          >
            <Link to={ROUTES.RequestReview}>Request Rating & Review</Link>
            <div className="noteText">
              <p>
                Request a rating & review from your customers to enrich your
                profile.
              </p>
            </div>
          </li>
        ) : null}
        <li className={location === "/all-reviews" && "active"}>
          <Link to={ROUTES.AllReviews}>View All Ratings & Reviews</Link>
          <div className="noteText">
            <p>
              View the latest published ratings & reviews given to vendors
              across the platform.
              {/* <span>
                <ToolTip
                  message="Tenders are sourced by STM directly
               from buyers as well as discovered by
               STM's own watch service which covers hundreds
                of relevant websites worldwide."
                />
              </span> */}
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
