export const initialState = {
  loading: false,
  errorData: {},
  errorMsg: "",
  errorFrom: null,
  status: null,
  errorMessage: "",
  // isLogin: localStorage.getItem("isLogin")
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_STARTED":
      return {
        ...state,
        loading: true,
      };
    case "LOADING_COMPLETED":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_FAILURE":
      return {
        ...state,
        loading: false,
      };
    case "ERROR":
      return {
        ...state,
        status: action.payload?.status,
        errorMessage: action.payload?.error,
      };
    case "CLEAN_ERROR":
      return {
        ...state,
        status: null,
        errorMessage: "",
      };
    default:
      return state;
  }
};
