import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductAction, VendorAction } from "../../ReduxStore/Actions";
import Divider from "@material-ui/core/Divider";

import { chevronBackOutline, mailOutline, globeOutline } from "ionicons/icons";
import { IonIcon, IonImg } from "@ionic/react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./index.css";
import Footer from "../../components/Footer/Footer";
import Tooltip from "../tooltip/Tooltip";
import { HomeAction } from "../../ReduxStore/Actions/index";
import { useHistory } from "react-router";
import Slider from "react-slick";
import logoStm from "../../images/logoStm.png";

const ProductDetails = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  // to change the colour of the header
  console.log(props);
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
    })();
  }, []);

  const { productRatings } = useSelector((state) => state.productReducer);
  console.log("product Ratings in product details page==>", productRatings);

  var settings = {
    dots: true,
    infinite: productRatings && productRatings.length > 3 && true,
    autoplay: true,
    speed: 400,

    slidesToShow: 3,

    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: productRatings && productRatings.length > 2 && true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const productInfo = useSelector((state) => state.productReducer.productInfo);
  console.log("product info in product details", productInfo);

  const CategoryDetails = useSelector(
    (state) => state.vendorReducer.categoryDetails
  );
  console.log("CategoryDetails", CategoryDetails);

  useEffect(() => {
    console.log("proppps", props);

    (async () => {
      let id = props.match.params.id;
      let params = {
        productId: id,
      };
      await dispatch(ProductAction.getProductById(params));

      await dispatch(
        VendorAction.ProductbyId({ productId: props.match.params.id })
      );

      await dispatch(ProductAction.getRatingsByProductId(id));
    })();
  }, []);
  // useEffect(() => {}, [CategoryDetails]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var urlParams = new URLSearchParams();

    urlParams.set("vendorName", productInfo[0]?.companyName);
    urlParams.set("productName", productInfo[0]?.name);
    props.history.push({
      pathname: "/contact-vendor",
      search: "?" + urlParams.toString(),
    });
  };

  console.log("productInfo", productInfo);

  if (productInfo.length === 0) return null;

  return (
    <>
      <div className="productListingPageOuter">
        <div className="productListingContainer">
          <div>
            <div onClick={() => history.goBack()}>
              <Button>
                <IonIcon icon={chevronBackOutline} />{" "}
                <span style={{ fontWeight: 700 }}> Go Back</span>{" "}
              </Button>
            </div>
            <div className="productLogo">
              <IonImg
                className="productLogo"
                src={
                  productInfo[0]?.logoUrl.includes(
                    "vendor-placeholder-logo.png"
                  )
                    ? logoStm
                    : productInfo[0]?.logoUrl
                }
                alt="company-img"
              />
            </div>
            <div className="productInfoBtnContainer">
              <div className="toolBtn toolFlex">
                <Button onClick={handleClick} className="infoo">
                  <IonIcon icon={mailOutline} />
                  <span>Get More Info</span>
                </Button>
                <Tooltip
                  message={
                    "Contact us and we will help you collect the information you need to check if this solution is right for you"
                  }
                />
              </div>
              <div className="toolBtn toolFlex">
                <Button
                  className="infoo visitWebsite"
                  onClick={() => window.open(productInfo[0]?.website)}
                >
                  <IonIcon icon={globeOutline} />
                  <span>Visit Website</span>
                </Button>
              </div>
              <div className="productOverview">
                <div>
                  <div className="lable-c">Company Name:</div>
                  <div className="value-c">{productInfo[0]?.companyName}</div>
                </div>
                <div>
                  <div className="lable-c">Country HQ:</div>
                  <div className="value-c">{productInfo[0]?.companyHq}</div>
                </div>
                <div>
                  <div className="lable-c">Year Founded:</div>
                  <div className="value-c">{productInfo[0]?.yearFounded}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="col-sm-12">
              <h1 className="listingHead">Listing Details</h1>
            </div>
            <div className="productInformation">
              <div className="d-flex">
                <h4 className="col-sm-12 col-md-5 heading2">Product Name:</h4>{" "}
                <h4
                  className="col-sm-12 col-md-7 heading2"
                  style={{ color: "black" }}
                >
                  {productInfo[0]?.name}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2"> Description: </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {productInfo[0]?.productDescription}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2">Categories: </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Categories"] &&
                    CategoryDetails["Categories"].map((data, i) => {
                      if (i === 0) return data.filterValue;
                      else return `, ${data.filterValue}`;
                    })}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2">Sectors: </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Sectors"] &&
                    CategoryDetails["Sectors"].map((data, i) => {
                      console.log("DATA>FILTERVA", data);
                      if (i === 0) return data.filterValue;
                      else return `, ${data.filterValue}`;
                    })}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2 relative">
                  {" "}
                  Stage:
                  <span className="tooltp-vl">
                    <Tooltip message="Product / service can be in a development or commercial stage (single response)." />
                  </span>
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails.Stage &&
                    CategoryDetails.Stage[0]?.filterValue}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2 relative">
                  {" "}
                  Type:
                  <span className="tooltp-vl">
                    <Tooltip message="Software (Off The Shelf), Software (Custom-Built), Hardware or Service Provider / System Integrator (single response)." />
                  </span>
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Product Type"] &&
                    CategoryDetails["Product Type"][0].filterValue}
                </h4>
              </div>

              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2 relative">
                  Software Deployment:
                  <span className="tooltp-vl">
                    <Tooltip message="Software-based products can be Installed on a Mac or Windows PC, Web-Based/ Cloud/ SaaS, and/or available for iOS and/or Android devices (multiple choice)." />
                  </span>
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Software Deployment"] &&
                    CategoryDetails["Software Deployment"].map((data, i) => {
                      if (i === 0) return data.filterValue;
                      else return `, ${data.filterValue}`;
                    })}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2">
                  Implementation Time:{" "}
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Development & Implementation time"] &&
                    CategoryDetails["Development & Implementation time"].map(
                      (data, i) => {
                        if (i === 0) return data.filterValue;
                        else return `, ${data.filterValue}`;
                      }
                    )}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2">Sports: </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Sports"] &&
                    CategoryDetails["Sports"].map((data, i) => {
                      if (i === 0) return data.filterValue;
                      else return `, ${data.filterValue}`;
                    })}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2 relative">
                  {" "}
                  Level of Sports:
                  <span className="tooltp-vl">
                    <Tooltip message="Product / service can be Elite Pro, Sub Elite and/or Grassroots / Amateur (multiple choice)." />
                  </span>
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Sports Tier"] &&
                    CategoryDetails["Sports Tier"].map((data, i) => {
                      if (i === 0) return data.filterValue;
                      else return `, ${data.filterValue}`;
                    })}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2 relative">
                  Target Customer:
                  <span className="tooltp-vl">
                    <Tooltip message="Target customers can be Broadcasters / Media, Governing Bodies / Federations, Event Organisers, Leagues / Clubs / Teams, Brands / Sponsors, Athletes and/or Stadia / Venues (multiple choice)." />
                  </span>
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Target Customer"] &&
                    CategoryDetails["Target Customer"].map((data, i) => {
                      if (i === 0) return data.filterValue;
                      else return `, ${data.filterValue}`;
                    })}
                </h4>
              </div>

              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2 relative">
                  <a
                    style={{ color: "blue" }}
                    href="https://sportstechmatch.medium.com/sportstech-match-pilot-proj ect-programme-47980578f62c"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <u>Pilot Project:</u>
                  </a>
                  <span className="tooltp-vl">
                    <Tooltip message=" Filter by vendors that have opted in to run pilot projects based on our best practice guidelines" />
                  </span>
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {CategoryDetails &&
                    CategoryDetails["Pilot Project"] &&
                    CategoryDetails["Pilot Project"][0] &&
                    CategoryDetails["Pilot Project"][0].filterValue}
                </h4>
              </div>

              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2 relative">
                  {" "}
                  Clients:
                  <span className="tooltp-vl">
                    <Tooltip message="A selection of existing clients using this product / service." />
                  </span>
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {" "}
                  {productInfo[0]?.selected_clients
                    ? productInfo[0]?.selected_clients
                    : "None"}
                </h4>
              </div>
              <div className="d-flex">
                <h4 className="col-md-5 col-sm-12 heading2">
                  {" "}
                  Additional Info:
                </h4>
                <h4
                  className="col-md-7 col-sm-12 heading2"
                  style={{ color: "black" }}
                >
                  {" "}
                  {productInfo[0]?.additional_info
                    ? productInfo[0]?.additional_info
                    : "None"}
                </h4>
              </div>
            </div>
          </div>
        </div>
        {productRatings && productRatings.length > 0 && (
          <div className="col-lg-12">
            <section
              className="homeSectionFive"
              style={{ marginBottom: "50px" }}
            >
              <div className="container">
                {
                  <Slider {...settings}>
                    {productRatings.map((data, index) => {
                      return (
                        <div key={data.rating_given_id}>
                          <div className="reviewBox" id="reviewBoxPage">
                            <div className="innerReviewContainer">
                              <IonImg
                                src={
                                  data &&
                                  !data.logoUrl.includes(
                                    "vendor-placeholder-logo.png"
                                  )
                                    ? data.logoUrl
                                    : logoStm
                                }
                                alt="company-img"
                              />
                              <h2>
                                <Link
                                  to={{
                                    pathname: `/product/${data?.product_id}`,
                                  }}
                                >
                                  {data?.product_name}
                                </Link>
                              </h2>
                              <div className="reviewContainer">
                                <div className="reviewStar">
                                  {Array.from(Array(data?.rating).keys()).map(
                                    (d, idx) => {
                                      return (
                                        <div key={d.toString()}>
                                          <svg
                                            width="20"
                                            height="19"
                                            viewBox="0 0 20 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                              // fill="#D9D9D9"
                                              fill="yellow"
                                            />
                                          </svg>
                                        </div>
                                      );
                                    }
                                  )}
                                  {Array.from(
                                    Array(5 - data?.rating).keys()
                                  ).map((d) => {
                                    return (
                                      <div key={d.toString()}>
                                        <svg
                                          width="20"
                                          height="19"
                                          viewBox="0 0 20 19"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                            fill="#D9D9D9"
                                          />
                                        </svg>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="verifiedContainer">
                                <div>
                                  <div>
                                    <img src="/assets/verified.png" />
                                  </div>
                                  <p>Verified by</p>
                                </div>
                                <div>
                                  <img src="/assets/stmLogo.png" />
                                </div>
                              </div>
                              <div className="btmPart">
                                {/* <img src="/assets/quotesIcon.png" /> */}
                                {/* <h2>{data?.review_headline}</h2> */}

                                <h2 style={{ fontSize: "1rem" }}>
                                  {data ? data.review_headline : "Anonymous"}
                                </h2>
                                <p
                                  style={{
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {data?.review}
                                </p>
                                <p>
                                  {data?.anonymity !== "true"
                                    ? data?.buyer_company_name
                                    : `${data?.organisationType}, ${data?.companyHq}`}{" "}
                                  (
                                  {(() => {
                                    const date = new Date(
                                      data?.rating_given_at
                                    );
                                    return `${date.toLocaleString("en-GB", {
                                      year: "numeric",
                                      month: "short",
                                      day: "2-digit",
                                    })}`;
                                  })()}
                                  )
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                }
              </div>
            </section>
          </div>
        )}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ProductDetails;
