import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
  list: [],
  filter: [],
  productDetails: {},
  categoryDetails: {},
  status: null,
  latestProd: [],
  vendorUsers: [],
  loadingList: false,
};
export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.LOADING_LIST:
      console.log("laoding list", action.payload);
      return {
        ...state,
        loadingList: action.payload,
      };
    case ACTION_TYPE.GET_LIST:
      console.log("Get_list", action.payload);
      return {
        ...state,
        list: action.payload,
      };
    case ACTION_TYPE.GET_LATEST:
      console.log("GET_LATEST", action.payload);
      return {
        ...state,
        latestProd: action.payload,
      };
    case ACTION_TYPE.DEL_ID:
      console.log("del_id", action.payload);
      return {
        ...state,
      };
    case ACTION_TYPE.GET_FILTER:
      console.log("getfilter", action.payload);
      return {
        ...state,
        filter: action.payload,
      };
    case ACTION_TYPE.ADD_LIST:
      console.log("action.payload", action.payload);
      return {
        ...state,
        list: action.payload,
      };
    case ACTION_TYPE.GET_VENDOR_USERS:
      return {
        ...state,
        vendorUsers: action.payload,
      };
    case ACTION_TYPE.UPDATE_LIST:
      return {
        ...state,
        status: action.payload.status,
      };
    case ACTION_TYPE.PRODUCT_BY_ID:
      console.log(action.payload.categoryDetails, "uppp");
      return {
        ...state,
        categoryDetails: action.payload.categoryDetails,
        productDetails: action.payload.productDetails,
      };
    default:
      return state;
  }
};
