import React, { useEffect } from "react";
import { IonContent } from "@ionic/react";
import { PagesAction } from "../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import "./Dynamic.css";
import { ACTION_TYPE } from "../../Services";

const SlugPage = (props) => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.pageReducer.data);

	useEffect(() => {
		return () => {
			dispatch({ type: ACTION_TYPE.CLEAR_DYNAMIC_PAGE_CONTENT });
		};
	}, []);

	useEffect(() => {
		(async () => {
			await dispatch(PagesAction.getPageDetails(props?.match?.params?.pageId));
		})();
	}, [props?.match?.params?.pageId]);

	return (
		<IonContent>
			<main className="mainContainer">
				<section className="home-product-section pb-5 mt-4">
					<div className="container">
						<div className="editor-page">
							<div className="editor-page-logo">
								{/* <img src={Logo} alt="" /> */}
							</div>

							<h1 className="editor-page-title">
								<b>{data?.title}</b>
							</h1>

							<div>
								<div
									dangerouslySetInnerHTML={{
										__html: data?.content ? data?.content : `Loading...`,
									}}
								></div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</IonContent>
	);
};

export default React.memo(SlugPage);
