import Api from "../../Api";
import { handleError } from "./commonAction";
import { ENDPOINTS, ACTION_TYPE, ROUTES } from "../../Services/index";

export const addEditRatingReviewRequest = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("rating review request in actions", body);
    const res = await Api.post(
      ENDPOINTS.ADD_EDIT_RATING_REVIEW_REQUEST,
      body.formData
    );

    console.log("rating review request res=>", res);

    if (res.data.status) {
      body.history.push(`${ROUTES.RequestReview}`);
    } else {
      dispatch({
        type: ACTION_TYPE.ADD_EDIT_RATING_REVIEW_REQUEST,
        payload: { data: res.data },
      });
    }

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getRatingReviewRequest = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_RATING_REVIEW_REQUEST);

    console.log("rating review res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_RATING_REVIEW_REQUEST,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getRatingReviewRequestById = (id) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.GET_RATING_REVIEW_REQUEST_BY_ID, {
      id,
    });

    console.log("rating review res by id=>", res);
    dispatch({
      type: ACTION_TYPE.GET_RATING_REVIEW_REQUEST_BY_ID,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getMyRatingsAndReview =
  ({ OFFSET, LIMIT }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.get(
        ENDPOINTS.GET_MY_RATING_REVIEW + `?OFFSET=${OFFSET}&LIMIT=${LIMIT}`
      );

      console.log("rating review res=>", res);
      dispatch({
        type: ACTION_TYPE.GET_MY_RATING_REVIEW,
        payload: { data: res.data.data },
      });

      dispatch({ type: "LOADING_COMPLETED" });
    } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(err));
    }
  };

export const getAllRatingsAndReview =
  ({ OFFSET, LIMIT }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.get(
        ENDPOINTS.GET_ALL_RATING_REVIEW + `?OFFSET=${OFFSET}&LIMIT=${LIMIT}`
      );

      console.log("rating review res=>", res);
      dispatch({
        type: ACTION_TYPE.GET_ALL_RATING_REVIEW,
        payload: { data: res.data.data },
      });

      dispatch({ type: "LOADING_COMPLETED" });
    } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(err));
    }
  };

export const getBuyerRatingsAndReview =
  ({ OFFSET, LIMIT, id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.get(
        ENDPOINTS.GET_BUYER_RATING_REVIEW +
          `?OFFSET=${OFFSET}&LIMIT=${LIMIT}&ID=${id}`
      );

      console.log("rating review res buery=>", res);
      dispatch({
        type: ACTION_TYPE.GET_BUYER_RATING_REVIEW,
        payload: { data: res.data.data },
      });

      dispatch({ type: "LOADING_COMPLETED" });
    } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(err));
    }
  };

export const buyerSubmitReview = (payload, history) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.SUBMIT_RATING_REVIEW, payload);
    console.log("buyerSubmitReview res=>", res);
    if (res && res.data && res.data.status) {
      return history.push(ROUTES.ReviewSubmitted);
    }

    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getTopReviews = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_TOP_RATING_REVIEW);

    console.log("rating review res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_TOP_RATING_REVIEW,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getPendingRatingReview = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_PENDING_RATING_REVIEW);

    console.log("get admin approve review res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_PENDING_RATING_REVIEW,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getRatingReviewInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(`${ENDPOINTS.GET_RATING_REVIEW_INFO}/${id}`);

    console.log("get review info res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_RATING_REVIEW_INFO,
      payload: { data: res.data.data[0] },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getRatingReviewGivenInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(
      `${ENDPOINTS.GET_RATING_REVIEW_GIVEN_INFO}/${id}`
    );

    console.log("get review info res given=>", res);
    dispatch({
      type: ACTION_TYPE.GET_RATING_REVIEW_GIVEN_INFO,
      payload: { data: res.data.data[0] },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const updateRatingReviewStatus = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.UPDATE_RATING_REVIEW_STATUS, data);
    console.log("update rating review res", res);
    dispatch({
      type: ACTION_TYPE.UPDATE_RATING_REVIEW_STATUS,
      payload: { data: res.data.status },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getApprovedRatingReview = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_APPROVED_RATING_REVIEW);
    console.log("approved rating review res", res);
    dispatch({
      type: ACTION_TYPE.GET_APPROVED_RATING_REVIEW,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
