import Api from "../../Api";
import { handleError } from "./commonAction";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";

export const updateProfileDetails =
  (params, shouldNotUpdate, cb) => async (dispatch) => {
    console.log("updateProfileDetails type hittt", params);
    return new Promise((resolve, reject) => {
      dispatch({ type: "LOADING_STARTED" });
      Api.post(ENDPOINTS.UPDATE_PROFILE, params)
        .then((res) => {
          console.log("profile ", res);
          cb && cb();
          !shouldNotUpdate &&
            dispatch({
              type: ACTION_TYPE.UPDATE_PROFILE,
              payload: res.data,
            });
          resolve(res?.data);
          dispatch({ type: "LOADING_COMPLETED" });
        })
        .catch((err) => {
          dispatch({ type: "LOADING_FAILURE" });
          reject(err);
          // dispatch(handleError(error));
        });
    });
  };

// export const updateProfileDetails = (details) => async (dispatch) => {
//   let userData = await JSON.parse(
//     localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
//   );
//   // console.log("cartttttttttt hiiiitttt fettcchhhh");

//   let userId = userData.Customer_ID;
//   try {
//     dispatch({ type: "LOADING_STARTED" });
//     const res = await Api.post(ENDPOINTS.EDIT_PROFILE, { userId, details });

//     dispatch({
//       type: ACTION_TYPE.UPDATE_USER_PROFILE,
//       payload: { data: res.data },
//     });
//     dispatch({ type: "LOADING_COMPLETED" });
//     return true;
//   } catch (error) {
//     dispatch({ type: "LOADING_FAILURE" });
//     dispatch(handleError(error));
//   }
// };

export const updateProfileImage = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.CHANGE_PROFILE_IMAGE + id, body);
    console.log("RESSSSSSSSSSSSSS", res);
    dispatch({
      type: ACTION_TYPE.UPLOAD_PROFILE_IMAGE,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};
