function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData) => {
  console.log("FORMDATAA", formData);
  const { rfi_title, level_of_sport, organisation_desc, opportunity_desc } =
    formData || {};
  let error = {
    rfi_title: [],
    level_of_sport: [],
    organisation_desc: [],
    opportunity_desc: [],
  };
  let isFormValid = true;

  if (isBlank(rfi_title) === true) {
    isFormValid = false;
    error.rfi_title = ["This field is required."];
  } else if (rfi_title?.trim().length > 0 && rfi_title?.trim().length > 100) {
    isFormValid = false;
    error.rfi_title = ["RFI Title cannot be greater than 100."];
  }

  if (level_of_sport.length === 0) {
    isFormValid = false;
    error.level_of_sport = ["This field is required."];
  }

  if (isBlank(organisation_desc) === true) {
    isFormValid = false;
    error.organisation_desc = ["This field is required."];
  } else if (
    organisation_desc?.trim().length > 0 &&
    organisation_desc?.trim().length > 300
  ) {
    isFormValid = false;
    error.organisation_desc = [
      "Organisation Description cannot be greater than 300.",
    ];
  }

  if (isBlank(opportunity_desc) === true) {
    isFormValid = false;
    error.opportunity_desc = ["This field is required."];
  } else if (
    opportunity_desc?.trim().length > 0 &&
    opportunity_desc?.trim().length > 1000
  ) {
    isFormValid = false;
    error.opportunity_desc = [
      "Opportunity description cannot be greater than 1000.",
    ];
  }

  return { isFormValid, error };
};
