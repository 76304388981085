import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	filters: [],
	products: [],
	prevData: [],
};

export const searchReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.GET_FILTERS:
			console.log("GET_FILTERS", action.payload);
			return {
				...state,
				filters: action.payload,
			};
		case ACTION_TYPE.GET_PRODUCTS:
			console.log("GET_PRODUCTS", action.payload);
			return {
				...state,
				products: action.payload,
			};
		case ACTION_TYPE.SET_PREVDATA:
			console.log("SET_PREVDATA", action.payload);
			return {
				...state,
				prevData: action.payload,
			};

		default:
			return state;
	}
};
