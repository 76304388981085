import Api from "../../Api";
import { handleError } from "./commonAction";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";

export const addEditTender = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("tenders in actions", body);
    const res = await Api.post(ENDPOINTS.ADD_EDIT_TENDER, body);

    console.log("tenders res=>", res);
    dispatch({
      type: ACTION_TYPE.ADD_EDIT_TENDER,
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getAllTenders = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_ALL_TENDERS);

    console.log("tenders res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_ALL_TENDERS,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const updateVisitedTenders =
  ({ userId, tenderId, timestamp }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(ENDPOINTS.UPDATE_VISITED_TENDERS, {
        userId,
        tenderId,
        timestamp,
      });

      console.log("tenders res=>", res);
      // dispatch({
      //   type: ACTION_TYPE.GET_ALL_TENDERS,
      //   payload: { data: res.data.data },
      // });

      dispatch({ type: "LOADING_COMPLETED" });
    } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(err));
    }
  };

export const getPublishedTenders = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_PUBLISHED_TENDERS);

    console.log("tenders res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_PUBLISHED_TENDERS,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const publishTender = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("publish tender body", body);
    const res = await Api.post(ENDPOINTS.PUBLISH_TENDER, body);

    console.log("publish tenders res=>", res);
    // dispatch({
    //   type: ACTION_TYPE.PUBLISH_TENDER,
    //   payload: { data: res.data },
    // });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getSectors = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_SECTORS);

    console.log("sectors res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_SECTORS,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getCategories = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_CATEGORIES);

    console.log("categories res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_CATEGORIES,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const fetchAccessedTenders = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_ACCESSED_TENDERS);

    console.log("accessed tenders res=>", res);
    dispatch({
      type: ACTION_TYPE.GET_ACCESSED_TENDERS,
      payload: { data: res.data.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
