import React from "react";
import SideBar from "../../adminPanel/sidebar/sideNav";
import { Link } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import { IonToggle } from "@ionic/react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SideMenu from "../../components/SideMenu";

const RatingInfo = () => {
  return (
    <>
      <div className="requestReviewContainer reviewInfoBlock">
        <SideMenu />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>New Rating & Review Info</h2>
          </div>
          <form className="formContainer">
            <div className="formRow">
              <div>
                <label>Write a Review</label>
                <textarea
                  id="ratingAndReview"
                  name="ratingAndReview"
                  rows="4"
                  cols="50"
                />
                <p style={{ fontSize: "12px", color: "#7a7a7a", margin: "0" }}>
                  Maximum word limit is 500
                </p>
              </div>
            </div>
            <div className="formRow">
              <div>
                <label>Rate the Product</label>
                <div>
                  <div class="rate">
                    <input type="radio" id="star5" name="rate" value="5" />
                    <label for="star5" title="text"></label>
                    <input type="radio" id="star4" name="rate" value="4" />
                    <label for="star4" title="text"></label>
                    <input type="radio" id="star3" name="rate" value="3" />
                    <label for="star3" title="text"></label>
                    <input type="radio" id="star2" name="rate" value="2" />
                    <label for="star2" title="text"></label>
                    <input type="radio" id="star1" name="rate" value="1" />
                    <label for="star1" title="text"></label>
                  </div>
                  {/* <div className="reviewContainer approveReview">
                  </div> */}
                </div>
              </div>
            </div>
            <div className="formRow">
              <div>
                <label>Review Headline</label>
                <input type="text" name="ReviewHeadline" />
                <p style={{ fontSize: "12px", color: "#7a7a7a", margin: "0" }}>
                  Maximum word limit is 75
                </p>
              </div>
            </div>
            <div className="formRow">
              <div>
                <label>Anonymity</label>
                <fieldset id="group2" className="radioGrp">
                  <div>
                    <input
                      type="radio"
                      value="Associate my organisation name to the review"
                      name="group2"
                    />
                    <label>Associate my organisation name to the review</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="Keep my organisation name anonymous"
                      name="group2"
                    />
                    <label>Keep my organisation name anonymous</label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="formRow">
              <div>
                <label>
                  Finally, please give us your permission to use your Customer
                  Review: I give my permission for the Customer Review that I
                  provided, including all text, as well as the Rating given, to
                  be used in marketing and promotions of the Vendor and
                  SportsTech Match.
                </label>
                <fieldset id="group2" className="radioGrp">
                  <div>
                    <input
                      type="checkbox"
                      value="I have read, understood, and agree to all of the above."
                      name="group2"
                    />
                    <label>
                      I have read, understood, and agree to all of the above.
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="formBtnContainer">
              <button>Cancel</button>
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RatingInfo;
