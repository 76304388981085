import React, { useState, useRef, useEffect } from "react";
import "./EditTender.css";
import "./TendersList.css";
import { IonInput, IonButton, IonLabel, IonSpinner } from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { TenderActions } from "../../ReduxStore/Actions";

import { ValidateContactForm } from "./TendersValidation";
import {
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";

import Tooltip from "../../DashboardModules/tooltip/Tooltip";
import { formatArrayToString } from "../../Utils/helpers";

const EditTender = ({
  setToast,
  setTabValue,
  setShowForm,
  setShowActiveTenders,
  setShowNewTenders,
  handleModal,
  editTenderData,
  tabValue,
}) => {
  const editSectorSelectRef = useRef();
  const editCategorySelectRef = useRef();

  const [errorData, setError] = useState();
  const [state, setState] = React.useState({
    ...editTenderData,
    sector: [],
    category: [],
    organization: editTenderData.organization_name,
    responseDeadline: editTenderData.response_deadline,
    dateOfAddition: editTenderData.date_of_addition,
  });

  console.log("state in edit tender", state);
  const [categories, setCategories] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [validUrl, setValidUrl] = React.useState();
  const [disableCategory, setDisableCategory] = React.useState(false);

  const [sectorData, setSectorData] = React.useState();

  // selectors
  let fetchedSectors = useSelector((state) => state.TenderReducer.sectors);
  let fetchedCategories = useSelector(
    (state) => state.TenderReducer.categories
  );
  const loading = useSelector((state) => state.commonReducer.loading);
  const dispatch = useDispatch();
  console.log("edit tender data", editTenderData);

  async function onSubmitForm(event) {
    console.log("1122", state);
    event.preventDefault();
    event.stopPropagation();
    const result = ValidateContactForm(state);

    if (result?.isFormValid) {
      // successfull validation and you can dispatch here.
      console.log("AAA", result, state);
      // dispatch add tender action
      await dispatch(
        TenderActions.addEditTender({
          ...state,
          category: state.category.map((cat) => {
            const foundCat =
              fetchedCategories &&
              fetchedCategories.find(
                (category) => category.category_id === cat
              );
            return foundCat && foundCat.category_name;
          }),
        })
      );
      //   setTabValue(1);
      setShowForm(false);
      if (tabValue === 0) {
        setShowActiveTenders(false);
        setShowNewTenders(true);
      } else if (tabValue === 1) {
        setShowActiveTenders(true);
        setShowNewTenders(false);
      }
      setToast(true);

      handleModal(false);
      await dispatch(TenderActions.getAllTenders());
    } else {
      setError(result?.error);
      console.log("error in tenders validation", JSON.stringify(result));
      return;
    }
  }
  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const handleChange = (event) => {
    if (event.target.name === "sector") {
      setCategories(
        fetchedCategories.filter(
          // (cat) => cat.sector_name === event.target.value
          (cat) => cat.sector_name.includes(event.target.value)
        )
      );
      console.log("state in edit", state);
      setState((prevState) => ({ ...prevState, category: null }));
      console.log("state in edit after", state);
      if (
        event.target.value === "Non Sport" ||
        event.target.value === "Sport (Non-Tech)"
      ) {
        setDisableCategory(true);
      } else {
        setDisableCategory(false);
      }
    }
    if (event.target.name === "category") {
      const findCat = fetchedCategories.find(
        // (cat) => cat.category_name === event.target.value
        (cat) => event.target.value.includes(cat.category_name)
      );
      if (findCat) {
        editSectorSelectRef.current.value = findCat.sector_name;
      } else {
        console.log(" state in else of find cat", state);
      }
    }

    const { name, value } = event.target;
    if (name === "url") {
      const isTrueVal = !value || urlPatternValidation(value);
      console.log("IsztrueVal", isTrueVal);
      setValidUrl(isTrueVal);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeSelection = (event) => {
    if (event.target.value.includes(119)) {
      return;
    }
    let k = 9;
    event.target.name === "category"
      ? state.category.length < 3
        ? setState({
            ...state,
            [event.target.name]: event.target.value,
          })
        : event.target.value.length === 2
        ? setState({
            ...state,
            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 4);

    event.target.name === "productType"
      ? state.productType.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length === 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "productStage"
      ? state.productStage.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "timeToImplement"
      ? state.timeToImplement.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);

    if (
      event.target.name !== "productType" &&
      event.target.name !== "timeToImplement" &&
      event.target.name !== "productStage" &&
      event.target.name !== "category"
    ) {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleFocus = () => {
    let sectorArray = {
      value: [],
      name: [],
    };
    let j = 0;
    state.category?.includes(72) ||
    state.category?.includes(73) ||
    state.category?.includes(74) ||
    state.category?.includes(75)
      ? (sectorArray = {
          name: [...sectorArray.name, "Athletes & Performance"],
          value: [...sectorArray.value, 22],
        })
      : (j = 5);
    state.category?.includes(76) ||
    state.category?.includes(77) ||
    state.category?.includes(78) ||
    state.category?.includes(79) ||
    state.category?.includes(80)
      ? (sectorArray = {
          name: [...sectorArray.name, "Fans & Digital Media"],
          value: [...sectorArray.value, 23],
        })
      : (j = 7);

    state.category?.includes(81) ||
    state.category?.includes(82) ||
    state.category?.includes(83) ||
    state.category?.includes(84)
      ? (sectorArray = {
          name: [...sectorArray.name, "Commercial"],
          value: [...sectorArray.value, 24],
        })
      : (j = 8);
    state.category?.includes(88) ||
    state.category?.includes(87) ||
    state.category?.includes(86)
      ? (sectorArray = {
          name: [...sectorArray.name, "Management & Operations"],
          value: [...sectorArray.value, 25],
        })
      : (j = 7);
    state.category?.includes(122)
      ? (sectorArray = {
          name: [...sectorArray.name, "Non Sport"],
          value: [...sectorArray.value, 120],
        })
      : (j = 8);
    state.category?.includes(123)
      ? (sectorArray = {
          name: [...sectorArray.name, "Sport (Non-Tech)"],
          value: [...sectorArray.value, 121],
        })
      : (j = 8);
    console.log(sectorArray, "sectorArrayjjjjjjjjjjjjjjjjjjjjjjj");
    setSectorData(sectorArray.name);
    setState({
      ...state,
      sector: sectorArray.value,
    });

    setState((prevState) => ({
      ...state,
      sector: prevState.sector.map((sec) => {
        const foundSec =
          categories &&
          categories.find((category) => category.sector_id === sec);
        return foundSec && foundSec.sector_name;
      }),
    }));

    // setState((prevState) => ({
    //   ...state,
    //   category: prevState.category.map((cat) => {
    //     const foundCat =
    //       categories &&
    //       categories.find((category) => category.category_id === cat);
    //     return foundCat && foundCat.category_name;
    //   }),
    // }));
  };

  useEffect(() => {
    console.log("edit tender data in edit tender", editTenderData);
    setState((prevState) => {
      return {
        ...prevState,
        sector: JSON.parse(editTenderData.sector).map((sec) =>
          sec.replaceAll("\\", "")
        ),
        category: JSON.parse(editTenderData.category)
          .map((sec) => sec.replaceAll("\\", ""))
          .map((cat) => {
            const found = fetchedCategories.find(
              (cate) => cate.category_name === cat
            );
            if (found) {
              return found.category_id;
            } else return cat;
          }),
      };
    });
    setSectorData(
      JSON.parse(editTenderData.sector).map((sec) => sec.replaceAll("\\", ""))
    );
  }, [editTenderData, fetchedCategories]);

  useEffect(() => {
    setCategories(fetchedCategories);
    // setCategories(
    //   fetchedCategories.filter(
    //     // (cat) => cat.sector_name === event.target.value
    //     (cat) => cat.sector_name.includes(state.sector)
    //   )
    // );
    if (state.sector === "Non Sport" || state.sector === "Sport (Non-Tech)") {
      setDisableCategory(true);
    } else {
      setDisableCategory(false);
    }
  }, [fetchedCategories]);

  useEffect(() => {
    setSectors(fetchedSectors);
  }, [fetchedSectors]);

  return (
    <div className="editTenderContainer">
      <div className="login-head" style={{ marginBottom: "1rem" }}>
        <h2>Edit Tender</h2>
      </div>

      <div className="login-body">
        <form id="client" className="editTenderForm">
          <div className="multipleSelectContainer">
            <div className="multipleSelectLabel">
              <label>Category*</label>
              <div style={{ width: 25 }}>
                <Tooltip message="Please select a maximum of 3 categories per Tender" />
              </div>
            </div>
            <div className="multipleSelectBlock">
              <Select
                multiple
                className="multipleSelectBox"
                name="category"
                onClose={handleFocus}
                value={state.category}
                onChange={(e) => handleChangeSelection(e)}
                input={<Input />}
                renderValue={(selected) => {
                  console.log("hooo", selected);
                  const l =
                    selected.length === 1
                      ? `${selected.length} Category Selected`
                      : `${selected.length} Categories Selected`;
                  return l;
                }}
              >
                {categories &&
                  categories?.map((name) => (
                    <MenuItem
                      className="menutype"
                      key={name.id}
                      id={name.id}
                      value={name.category_id}
                    >
                      <Checkbox
                        checked={
                          state &&
                          state.category &&
                          state.category.indexOf(name.category_id) > -1
                        }
                      />
                      <ListItemText primary={name.category_name} />
                    </MenuItem>
                  ))}
              </Select>
              {errorData?.category[0] ? <p>{errorData?.category[0]}</p> : null}
            </div>
            {errorData &&
            errorData?.sector?.length > 0 &&
            errorData?.sector[0] ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData && errorData?.sector[0]}
              </p>
            ) : null}
          </div>

          <div className="multipleSelectContainer">
            <div className="multipleSelectLabel">
              <label>Sector*</label>
              <div style={{ width: 25 }}>
                <Tooltip message="Sector is pre-defined based on your category choices" />
              </div>
            </div>
            <div className="multipleSelectBlock">
              <input
                className="form-control"
                value={sectorData && formatArrayToString(sectorData)}
                disabled
              />
            </div>
            {errorData &&
            errorData?.category?.length > 0 &&
            errorData?.category[0] ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData && errorData?.category[0]}
              </p>
            ) : null}
          </div>
          {/* <div className="form-group">
            <IonList>
              <IonItem>
                <div>
                  <div className="d-flex">
                    <IonLabel id="category">Category* </IonLabel>
                    <div style={{ width: 25 }}>
                      <Tooltip message="Please select a maximum of 3 categories per product / service" />
                    </div>
                  </div>

                  <Select
                    multiple
                    className="category"
                    name="category"
                    onClose={handleFocus}
                    value={state.category}
                    onChange={(e) => handleChangeSelection(e)}
                    input={<Input />}
                    renderValue={(selected) => {
                      console.log("hooo", selected);
                      const l = selected.length + " category Selected";
                      return l;
                    }}
                  >
                    {categories &&
                      categories?.map((name) => (
                        <MenuItem
                          className="menutype"
                          key={name.id}
                          id={name.id}
                          value={name.category_id}
                        >
                          <Checkbox
                            checked={
                              state &&
                              state.category &&
                              state.category.indexOf(name.category_id) > -1
                            }
                          />
                          <ListItemText primary={name.category_name} />
                        </MenuItem>
                      ))}
                  </Select>
                  {errorData?.category[0] ? (
                    <p>{errorData?.category[0]}</p>
                  ) : null}
                </div>
              </IonItem>
            </IonList>
            {errorData &&
            errorData?.sector?.length > 0 &&
            errorData?.sector[0] ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData && errorData?.sector[0]}
              </p>
            ) : null}
          </div> */}

          {/* <div className="form-group">
            <IonList>
              <IonItem className="selectSectorContainer editSelectSector">
                <div className="form-group">
                  <div className="d-flex">
                    <IonLabel>Sector* </IonLabel>
                    <div style={{ width: 25 }}>
                      <Tooltip message="Sector is pre-defined based on your category choices" />
                    </div>
                  </div>
                  <input className="form-control" value={sectorData} disabled />
                </div>
              </IonItem>
            </IonList>
            {errorData &&
            errorData?.category?.length > 0 &&
            errorData?.category[0] ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData && errorData?.category[0]}
              </p>
            ) : null}
          </div> */}

          <div className="form-group">
            <IonLabel>Organisation Name</IonLabel>
            <IonInput
              type="text"
              name="organization"
              className="form-control"
              value={state.organization}
              onIonChange={(event) => {
                handleChange(event);
              }}
            ></IonInput>
            {errorData && errorData?.organization[0] ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData && errorData?.organization[0]}
              </p>
            ) : null}
          </div>

          <div className="form-group">
            <IonLabel>Title</IonLabel>
            <IonInput
              type="text"
              name="title"
              className="form-control"
              value={state.title}
              onIonChange={(event) => {
                handleChange(event);
              }}
            ></IonInput>
            {errorData && errorData?.title[0] ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData && errorData?.title[0]}
              </p>
            ) : null}
          </div>
          <div className="form-group">
            <IonLabel>URL</IonLabel>
            <IonInput
              type="text"
              name="url"
              className="form-control"
              value={state.url}
              onIonChange={(event) => {
                handleChange(event);
              }}
            ></IonInput>
            {(errorData && errorData?.url[0]) || validUrl ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData && errorData?.url[0]}
              </p>
            ) : null}
          </div>
          <div>
            <IonLabel>Response Deadline</IonLabel>
            <input
              type="date"
              name="responseDeadline"
              className="form-control"
              defaultValue={`${new Date(state.response_deadline)
                .toISOString()
                .substring(0, 10)}`}
              onChange={(event) => {
                console.log("event data targetvalue", event.target.value);
                handleChange(event);
              }}
            ></input>
            {errorData &&
            errorData.responseDeadline.length &&
            errorData?.responseDeadline[0] ? (
              <p style={{ margin: 0, color: "red", fontSize: "14px" }}>
                {errorData?.responseDeadline[0]}
              </p>
            ) : null}
          </div>

          <div className="button-login" style={{ marginBottom: "30px" }}>
            <IonButton
              color="none"
              className="gradient-btn-blue"
              onClick={onSubmitForm}
            >
              Update{" "}
              {(() => {
                if (loading) {
                  return (
                    <IonSpinner name="crescent" className="spinner-loader" />
                  );
                }
              })()}
            </IonButton>
            <IonButton
              color="none"
              className="gradient-btn-blue"
              onClick={() => {
                handleModal(false);
              }}
            >
              Cancel{" "}
            </IonButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTender;
