function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData) => {
  const { First_Name, Last_Name, Email } = formData || {};
  let error = { First_Name: [], Last_Name: [], Email: [] };
  let isFormValid = true;

  if (isBlank(First_Name) === true) {
    isFormValid = false;
    error.First_Name = ["This field is required."];
  } else if (First_Name?.trim().length > 0 && First_Name?.trim().length > 50) {
    isFormValid = false;
    error.First_Name = ["Name cannot be greater than 50."];
  }
  if (isBlank(Last_Name) === true) {
    isFormValid = false;
    error.Last_Name = ["This field is required."];
  } else if (Last_Name?.trim().length > 0 && Last_Name?.trim().length > 50) {
    isFormValid = false;
    error.Last_Name = ["Last Name cannot be greater than 50."];
  }

  if (isBlank(Email) === true) {
    isFormValid = false;
    error.Email = ["This field is required."];
  } else if (
    Email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.Email
    ) === false
  ) {
    isFormValid = false;
    error.Email = ["Email must be a valid Email address."];
  }

  //  else if( parseInt(role) !== 1 || parseInt(role) !== 2){
  //     isFormValid = false
  //     error.role = ["Please select a valid role."]
  // }

  return { isFormValid, error };
};

export const FormatInterestedCounty = (obj, totalIntrestedCountry) => {
  let formattedResponse = [];

  totalIntrestedCountry.map((data, i) => {
    if (obj.hasOwnProperty(data.county) && obj.hasOwnProperty(data.state)) {
      let valCont = data.county;
      let valState = data.state;
      formattedResponse.push({ county: obj[valCont], state: obj[valState] });
    }
  });
  console.log("formattedResponse", formattedResponse);
  return formattedResponse;
};

export const ValidateChangePassword = (formData) => {
  const { oldPassword, newPassword, confirmPassword } = formData || {};
  let error = { oldPassword: [], newPassword: [], confirmPassword: [] };
  let isFormValid = true;

  if (isBlank(oldPassword) === true) {
    isFormValid = false;
    error.oldPassword = ["This field is required."];
  } else if (
    oldPassword?.trim().length > 0 &&
    (oldPassword?.trim().length < 6 || oldPassword?.trim().length > 15)
  ) {
    isFormValid = false;
    error.oldPassword = [
      "Old Password length must be between 6 to 15 characters.",
    ];
  }
  if (isBlank(newPassword) === true) {
    isFormValid = false;
    error.newPassword = ["This field is required."];
  } else if (
    newPassword?.trim().length > 0 &&
    (newPassword?.trim().length < 6 || newPassword?.trim().length > 15)
  ) {
    isFormValid = false;
    error.newPassword = [
      "Old Password length must be between 6 to 15 characters.",
    ];
  }
  if (isBlank(confirmPassword) === true) {
    isFormValid = false;
    error.confirmPassword = ["This field is required."];
  } else if (
    confirmPassword?.trim().length > 0 &&
    (confirmPassword?.trim().length < 6 || confirmPassword?.trim().length > 15)
  ) {
    isFormValid = false;
    error.confirmPassword = [
      "Confirm Password length must be between 6 to 15 characters.",
    ];
  } else if (
    confirmPassword?.trim().length > 0 &&
    confirmPassword?.trim().length > 0 &&
    newPassword?.trim() !== confirmPassword?.trim()
  ) {
    isFormValid = false;
    error.confirmPassword = ["Passwords Don’t Match!"];
  }

  return { isFormValid, error };
};
