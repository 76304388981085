import React from "react";
import "./Modal.css";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const Modal = (props) => {
  return (
    <>
      {props.message ? (
        <div className="modalBackground">
          <div className="modalMessageBox">
            <h1>{props.message}</h1>
            <div className="closeDiv" onClick={(e) => props.handleClose(false)}>
              <IonIcon icon={closeOutline} className="modalCloseIcon" />
            </div>
            {props && props.subtitle && (
              <div className="subtitle">{props.subtitle}</div>
            )}
            {props.button && (
              <div className="modalBtnOptions">
                <button
                  className="rfi-modal-yes-button"
                  onClick={(e) => {
                    props.handleClose(false);
                    props.handleSubmit();
                  }}
                >
                  Yes
                </button>
                <button
                  className="rfi-foot-back-button"
                  onClick={(e) => props.handleClose(false)}
                >
                  NO, Go Back
                </button>
              </div>
            )}
          </div>
        </div>
      ) : props.image1 ? (
        <div className="modalBackground">
          <div className="modalImageBox">
            <img
              alt="email"
              src={require("../../../images/CommonImages/Approve.png")}
            />
            <div className="closeDiv" onClick={(e) => props.handleClose(false)}>
              <IonIcon icon={closeOutline} className="modalCloseIcon" />
            </div>
          </div>
        </div>
      ) : props.image2 ? (
        <div className="modalBackground">
          <div className="modalImageBox">
            <img
              alt="email"
              src={require("../../../images/CommonImages/reject.png")}
            />
            <div className="closeDiv" onClick={(e) => props.handleClose(false)}>
              <IonIcon icon={closeOutline} className="modalCloseIcon" />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
