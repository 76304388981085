import { IonContent } from "@ionic/react";
import React from "react";
import { menuController } from "@ionic/core";
import "../Login/Login.css";
import { withRouter, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AuthActions } from "../../ReduxStore/Actions/index";
import { Toast } from "../../Utils/Toast";
import queryString from "query-string";
import { ROUTES } from "../../Services";

const EmailVerify = (props) => {
	const dispatch = useDispatch();

	const authReducer = useSelector((state) => state.authReducer);
	const loading = useSelector((state) => state.commonReducer.loading);

	React.useEffect(() => {
		(async () => {
			menuController.enable(false);
			const value = await queryString.parse(props.location.search);
			await dispatch(AuthActions.verifyEmail(value));
		})();
	}, []);

	return (
		<IonContent>
			<div className="login">
				<div className="login-inner">
					<Toast
						isOpen={
							authReducer?.status === false || authReducer?.status === true
								? true
								: null
						}
						message={authReducer?.status === true ? "Success" : "Failed"}
						isSuccess={authReducer?.status}
						isClearSuccess={true}
						isClearError={false}
						duration={10000}
						isRedirect={authReducer?.status}
						redirectProps={props}
						redirectTo={ROUTES.Login}
					/>
					<div className="login-head">
						<h2>Email Verification</h2>
					</div>
					<div className="login-body">
						{loading === true ? (
							<h1>loading</h1>
						) : authReducer?.status === true ? (
							<p style={{ color: "green" }}>{authReducer?.message}</p>
						) : (
							<p style={{ color: "red" }}>{authReducer?.message}</p>
						)}
						<div className="haveaccount">
							<p>
								<Link to={ROUTES.Login}>Login</Link>
							</p>
							<p>
								Don't have an account? <Link to={ROUTES.SignUp}>Signup</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</IonContent>
	);
};

export default withRouter(EmailVerify);
