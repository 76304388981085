import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
export const getVendorList = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2");

    Api.get(ENDPOINTS.GET_VENDOR_LIST)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_VENDOR_LIST,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};
export const searchAdmin = (search, type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("searchAdmin");

    Api.post(ENDPOINTS.SEARCH_ADMIN, { search, type })
      .then((res) => {
        console.log("SEARCH_ADMIN", res);
        if (res.data.type === "vendor") {
          dispatch({
            type: ACTION_TYPE.GET_APPROVED_VENDOR,

            payload: res.data.data,
          });
        } else if (res.data.type === "product") {
          dispatch({
            type: ACTION_TYPE.GET_PRODUCT,

            payload: res.data.data,
          });
        } else if (res.data.type === "ratingReview") {
          console.log("res data ratingReview search ", res.data.data);
          dispatch({
            type: ACTION_TYPE.GET_APPROVED_RATING_REVIEW,
            payload: res.data,
          });
        }

        resolve();
      })
      .catch((err) => {
        // dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
  //
};
export const getApprovedVendorList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2");
    dispatch({ type: "LOADING_STARTED" });

    Api.get(ENDPOINTS.GET_APPROVED_VENDOR_LIST)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_APPROVED_VENDOR,

          payload: res.data.data,
        });
        dispatch({ type: "LOADING_COMPLETED" });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
  //
};

export const getApprovedPaidVendorList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2");

    Api.get(ENDPOINTS.GET_APPROVED_PAID_VENDOR_LIST)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_APPROVED_VENDOR,

          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
  //
};
export const getApprovedBuyersList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2");

    Api.get(ENDPOINTS.GET_APPROVED_BUYER_LIST)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_APPROVED_BUYER,

          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
  //
};

export const getVedorById = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2", userId);
    console.log(ENDPOINTS.SEARCH_ID, "searchId");
    Api.post(ENDPOINTS.SEARCH_ID, userId)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_VENDOR,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};
export const changeUserState = (userId, is_fake) => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2", userId);
    dispatch({
      type: ACTION_TYPE.CHANGE_VENDOR_STATUS,
      payload: is_fake,
    });
    // console.log(ENDPOINTS.SEARCH_IDCHANGE_USER_STATE, "searchId");
    Api.post(ENDPOINTS.CHANGE_USER_STATE, { userId, is_fake })
      .then((res) => {
        console.log("list", res);

        resolve();
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPE.CHANGE_VENDOR_STATUS,
          payload: is_fake === 0 ? 1 : 0,
        });
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};
export const getRFIbyId = (rfiId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.GET_RFI_BY_ID, { rfiId })
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.GET_RFI_BY_ID,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};
export const accept = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log(params);
    Api.post(ENDPOINTS.ACCEPT, params)
      .then((res) => {
        console.log(res, "lop");
        dispatch({
          type: ACTION_TYPE.UPDATE,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_GET_FAIL",
        });
        reject(err);
      });
  });
};
export const getProduct = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get(ENDPOINTS.GET_PRODUCT)
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.GET_PRODUCT,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_FAIL",
        });
        reject(err);
      });
  });
};
export const getCreds = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get(ENDPOINTS.GET_CONTACT)
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.GET_CONTACT,
          payload: res.data.data[0],
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_FAIL",
        });
        reject(err);
      });
  });
};
export const approveProduct = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log(params);
    Api.post(ENDPOINTS.APPROVE_PRODUCT, params)
      .then((res) => {
        console.log(res, "lop");
        dispatch({
          type: ACTION_TYPE.UPDATE_PRODUCT,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_GET_FAIL",
        });
        reject(err);
      });
  });
};
export const getProductById = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2", userId);
    console.log(ENDPOINTS.GET_PRODUCT_BY_ID, "searchId");
    Api.post(ENDPOINTS.GET_PRODUCT_BY_ID, userId)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_PRODUCT_ID,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};

//action to get the list of rfis with isapproved 0
export const getRFI = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get(ENDPOINTS.GET_RFI_ADMIN)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_RFI_ADMIN,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};

//action to get the list of rfis with isapproved 1
export const getApprovedRFI = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get(ENDPOINTS.GET_APPROVED_RFI_ADMIN)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_APPROVED_RFI_ADMIN,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};

//action to approve the rfis
export const approveRFI = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.APPROVE_RFI_ADMIN, { ...payload })
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_RFI_ADMIN,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};

//action to reject the rfis
export const rejectRFI = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.REJECT_RFI_ADMIN, { ...payload })
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_RFI_ADMIN,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};

//action to mark vendor paid
export const markVendorPaid = (payload, callback) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.MARK_VENDOR_PAID, payload)
      .then((res) => {
        console.log("res for markVendorPaid==>", res);
        // dispatch({
        //   type: ACTION_TYPE.MARK_VENDOR_PAID,
        //   payload: res.data.data,
        // });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      })
      .finally(() => {
        callback();
      });
  });
};

export const changeVendorAdmin = (payload, callback) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.CHANGE_VENDOR_ADMIN, payload)
      .then((res) => {
        console.log("res for markVendorPaid==>", res);
        // dispatch({
        //   type: ACTION_TYPE.MARK_VENDOR_PAID,
        //   payload: res.data.data,
        // });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      })
      .finally(() => {
        callback();
      });
  });
};
