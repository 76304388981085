function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export const ValidateContactForm = (formData) => {
    
    const {fullName,email,message} = formData || {};
    let error = { fullName: [],  email: [], message: []}
    let isFormValid = true;
    
    if ((isBlank(fullName) === true)) {
        isFormValid = false
        error.fullName = ["This field is required."]
    }
    else if (fullName?.trim().length > 0 && fullName?.trim().length > 50) {
        isFormValid = false
        error.fullName = ["Name cannot be greater than 50."]
    }
    if (isBlank(email) === true) {
        isFormValid = false
        error.email = ["This field is required."]
    }
    else if (email.trim().length > 0 && /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(formData.email) === false) {
        isFormValid = false
        error.email = ["Email must be a valid email address."]
    }
    if (isBlank(message) === true) {
        isFormValid = false
        error.message = ["This field is required."]
    }

 

    return { isFormValid, error }
}