import {
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { addCircle, removeCircle } from "ionicons/icons";
import React, { useState } from "react";
import "./FormTwo.css";
import { useDispatch } from "react-redux";

import ToolTip from "../../../tooltip/Tooltip";

import { ValidateSignUpForm } from "./Validation";
import { KeyboardArrowDown } from "@material-ui/icons";
import { TextField } from "@material-ui/core";

const FormTwo = (props) => {
  const dispatch = useDispatch();
  // const [props.inputList, setInputList] = useState([]);

  const [errorData, setError] = React.useState();

  // const handleAddQuestions = (e, type) => {
  //   e.preventDefault();

  //   const values = [...props.inputList];
  //   if (type === "remove") {
  //     values.pop();
  //     setInputList(values);
  //     return;
  //   }
  //   values.push({ value: null });
  //   setInputList(values);
  // };

  async function onSubmitForm(event) {
    event.preventDefault();
    // console.log("props.state questions", props.state);
    const result = await ValidateSignUpForm(props.state);

    if (!result?.isFormValid) {
      setError(result?.error);
      for (let i = 0; i < Object.keys(result.error).length; i++) {
        console.log("inside", Object.keys(result.error)[i]);
        if (Object.values(result.error)[i].length) {
          console.log("inside");
          document
            .getElementById(Object.keys(result.error)[i])
            .scrollIntoView();
          break;
        }
      }

      return;
    }

    const formData = new FormData();
    console.log("Svalues", props.state);
    for (var key in props.state) {
      formData.append(key, props.state[key]);
    }
    console.log("state", props.state);
    props.changeCount(3);
  }

  console.log("props.inputlist", props.inputList);

  return (
    <div className="formTwo">
      <div>
        <div>
          <form>
            <IonGrid>
              <IonCol className="d-flex justify-content-between flex-column">
                <IonCol size="12" size-md="12">
                  <div className="form-group d-flex align-items-center ">
                    <div className="number"></div>
                    <div className="selectTab formSecond">
                      <div className="login-head lineSpace">
                        <div className="d-flex align-items-center">
                          <div className="font-weight-bold">
                            <h1>Yes / No Filter Questions</h1>
                          </div>
                          <span className=" tooltp-vd ">
                            <ToolTip
                              message={`Your questions should focus on your "must-have" requirements in terms of, for example, the key features that you require, the budget that you have and the time to implement the solution.`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="login-head lineSpace">
                        <span>
                          Add filter questions in order of priority to qualify
                          vendors (minimum 5 and maximum 10).
                        </span>
                      </div>
                      <div className="login-head lineSpace">
                        <span>
                          The more “yes” responses you receive from a vendor,
                          the higher that vendor will be ranked.
                        </span>
                      </div>
                    </div>
                  </div>
                </IonCol>
                {props.listQuestions.map((data, index) => {
                  return (
                    <IonCol size="12" size-md="12">
                      <div className="form-group d-flex align-items-center ">
                        <div id="questions" className="number">
                          <IonLabel>
                            <span>{index + 1}.</span>
                          </IonLabel>
                        </div>
                        {Object.values(props?.typeQuestion)[index] === true ? (
                          <div className="selectTab d-flex">
                            <IonInput
                              name="questions"
                              className="form-control belowDataIonInput"
                              value={props?.state?.questions[index]}
                              onIonChange={(event) => {
                                props.handleChange(event, index);
                              }}
                            ></IonInput>
                            <IonButton
                              className="exchange"
                              onClick={() =>
                                props.setTypeQuestion({
                                  ...props.typeQuestion,
                                  [`${
                                    Object.keys(props?.typeQuestion)[index]
                                  }`]: false,
                                })
                              }
                            >
                              {" "}
                              <KeyboardArrowDown></KeyboardArrowDown>
                            </IonButton>
                          </div>
                        ) : (
                          <div className="selectTab">
                            <select
                              name="questions"
                              className="form-control"
                              value={props?.state?.questions[index]}
                              onChange={(event) => {
                                if (event.target.value !== "No")
                                  props.handleChange(event, index);
                                else
                                  props.handleQuestions(
                                    Object.keys(props?.typeQuestion)[index],
                                    index,
                                    "oldQuestions"
                                  );
                              }}
                            >
                              <option value="" disabled selected>
                                Add filter question or select from our most
                                popular questions
                              </option>
                              <option style={{ fontWeight: 600 }} value="No">
                                {" "}
                                + Add your own question
                              </option>
                              {props.questions.map((question) => {
                                return (
                                  <option value={question.question}>
                                    {` ${question.question}`}
                                  </option>
                                );
                              })}
                            </select>{" "}
                          </div>
                        )}
                      </div>
                    </IonCol>
                  );
                })}

                {errorData?.questions[0] ? (
                  <p className="setError">{errorData?.questions[0]}</p>
                ) : null}
                {props.inputList.map((list, index) => {
                  // console.log("QUESTION NEW",Object.keys(props.typeQuestion)[index+5],Object.values(props.typeQuestion)[index+5])
                  return (
                    <IonCol size="12" size-md="12">
                      <div className="form-group d-flex align-items-center ">
                        <div className="number">
                          <IonLabel>
                            <span>{index + 6}.</span>
                          </IonLabel>
                        </div>
                        {Object.values(props?.typeQuestion)[index + 5] ===
                        true ? (
                          <div className="selectTab d-flex">
                            <IonInput
                              name="questionsNew"
                              className="form-control belowDataIonInput"
                              value={props?.state?.questionsNew[index]}
                              onIonChange={(event) => {
                                props.handleChange(event, index);
                              }}
                            ></IonInput>
                            <IonButton
                              className="exchange"
                              onClick={() => {
                                props.setTypeQuestion({
                                  ...props.typeQuestion,
                                  [`${
                                    Object.keys(props.typeQuestion)[index + 5]
                                  }`]: false,
                                });
                              }}
                            >
                              {" "}
                              <KeyboardArrowDown></KeyboardArrowDown>
                            </IonButton>
                          </div>
                        ) : (
                          <div className="selectTab">
                            <select
                              name="questionsNew"
                              className="form-control"
                              value={props?.state?.questionsNew[index]}
                              onChange={(event) => {
                                if (event.target.value !== "No")
                                  props.handleChange(event, index);
                                else
                                  props.handleQuestions(
                                    Object.keys(props.typeQuestion)[index + 5],
                                    index,
                                    "newQuestions"
                                  );
                              }}
                            >
                              <option value="" disabled selected>
                                Add filter question or select from our most
                                popular questions
                              </option>
                              <option style={{ fontWeight: 600 }} value="No">
                                {" "}
                                + Add your own question
                              </option>
                              {props.questions.map((question) => {
                                return (
                                  <option value={question.question}>
                                    {` ${question.question}`}
                                  </option>
                                );
                              })}
                            </select>{" "}
                          </div>
                        )}

                        <IonButton
                          className="exchange"
                          onClick={(e) =>
                            props.handleAddQuestions(e, "remove", index)
                          }
                        >
                          <IonIcon
                            icon={removeCircle}
                            className="plusIcon"
                          ></IonIcon>
                        </IonButton>
                      </div>
                    </IonCol>
                  );
                })}
                <IonCol size="12" size-md="12">
                  <div className="form-group d-flex align-items-center ">
                    <div className="number d-flex align-items-center">
                      <button
                        onClick={props.handleAddQuestions}
                        disabled={props.inputList.length >= 5 ? true : false}
                        className={
                          props.inputList.length >= 5 && "disableAddButton"
                        }
                      >
                        <IonIcon
                          icon={addCircle}
                          className="plusIcon"
                        ></IonIcon>
                      </button>
                    </div>
                    <div className=" font-weight-bold ">
                      Add a filter question (max. 10 questions)
                    </div>
                  </div>
                </IonCol>
                <IonCol size="12" size-md="12">
                  <div className="form-group d-flex align-items-center ">
                    <div className="number"></div>
                    <div className="selectTab">
                      <div className="font-weight-bold belowData">
                        Open Question
                      </div>
                      <div className="belowData">
                        By default, vendors will be asked if they want to add
                        any further information to support their response to the
                        RFI (vendor responses are restricted to 1,000
                        characters).
                      </div>

                      <div className="belowData">
                        You can edit the open question if you prefer.
                      </div>
                      <div className="belowDataInput">
                        <TextField
                          id="outlined-multiline-static"
                          className="messageBody"
                          multiline
                          name="openQuestion"
                          rows={6}
                          variant="outlined"
                          className="form-control belowDataIonInput"
                          value={props.state?.openQuestion}
                          onChange={(event) => {
                            props.handleChange(event);
                          }}
                          placeholder=""
                        />
                        {errorData?.openQuestion[0] ? (
                          <p id="openQuestion" className="setError">
                            {errorData?.openQuestion[0]}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </IonCol>
              </IonCol>
            </IonGrid>

            <div className="button-login mt-3 d-flex justify-content-center">
              <div>
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={(event) => props.changeCount(1)}
                >
                  Back
                </IonButton>
              </div>
              <div>
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={(event) => onSubmitForm(event)}
                >
                  Next
                </IonButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormTwo;
