import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";

export const getProducts = (params) => async (dispatch) => {
  console.log("property type hittt", params);
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.GET_PRODUCTS, params)
      .then((res) => {
        console.log("product list", res);

        dispatch({
          type: ACTION_TYPE.GET_PRODUCTS,
          payload: res.data.data,
        });
        resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
        // dispatch(handleError(error));
      });
  });
};
export const savePrevState = (params) => async (dispatch) => {
  console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
  return new Promise((resolve, reject) => {
    dispatch({
      type: ACTION_TYPE.SET_PREVDATA,
      payload: params,
    });

    resolve();
  });
};

export const getProductsByCategory = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.GET_PRODUCTS_BY_CATEGORIES, params)
      .then((res) => {
        console.log("product list", res);

        dispatch({
          type: ACTION_TYPE.GET_PRODUCTS,
          payload: res.data.data,
        });
        resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const getFilters = () => async (dispatch) => {
  console.log("resss of list");
  return new Promise((resolve, reject) => {
    Api.get(ENDPOINTS.GET_FILTERS)
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.GET_FILTERS,
          payload: res.data.data,
        });
        resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
