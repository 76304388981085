import { Card, Button, CardContent, Typography } from "@material-ui/core";
import React, { useState } from "react";
import "./aprove.css";
import SideNav from "../sidebar/sideNav";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HomeAction, AdminAction } from "../../ReduxStore/Actions/index";
import { Link } from "react-router-dom";
import { formatDateBritish } from "../../Utils/helpers";

const Aprove = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.AdminReducer.vendorList);
  console.log("list", list);

  //to change the color of header
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));

      await dispatch(AdminAction.getVendorList());
    })();
  }, []);
  //to update the page when accepted or rejected
  // useEffect(() => {
  //   (async () => {
  //     await dispatch(AdminAction.getVendorList());
  //   })();
  // }, []);
  //to live the accept button

  return (
    <div>
      <div className="top1">
        <div className="mainBody ">
          <div className="">
            <SideNav />

            <div className="col-sm-12 col-md-9 viewApprove  vendor-list">
              <h3 className="heading"> Approve New Vendors & Buyers </h3>
              <div>
                <ul
                  style={{ listStyle: "none" }}
                  className="approveNewVendorList"
                >
                  {list?.map((vendor, index) => {
                    if (vendor.isApproved === "0") {
                      let date = new Date(vendor.created_ts)
                        .toDateString()
                        .slice(3);

                      // let date = formatDateBritish(vendor.created_ts);
                      return (
                        <li>
                          {/* <Card className="vendor-Card text-right">
                            <CardContent className="vendor-card-Content">
                              <div className="d-flex flex-row">
                                <h3
                                  style={{
                                    color: "#062c56",
                                    marginTop: "6px",
                                    marginLeft: 0,
                                  }}
                                ></h3>
                                <div className="text-left text">
                                  <Typography
                                    className="content-text"
                                    variant="h6"
                                    gutterBottom
                                  >
                                    <span style={{ color: "#062c56" }}>
                                      {" "}
                                      Name:{" "}
                                    </span>
                                    {`${vendor.firstName} ${vendor.lastName}`}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                  ></Typography>
                                  <Typography
                                    className="content1Product"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    <span style={{ color: "#062c56" }}>
                                      {" "}
                                      Company Name:{" "}
                                    </span>{" "}
                                    {vendor.companyName}
                                  </Typography>
                                  <Typography
                                    className="content1Product"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    <span style={{ color: "#062c56" }}>
                                      {" "}
                                      Date:{" "}
                                    </span>{" "}
                                    {date}
                                  </Typography>
                                </div>
                                <Link
                                  to={{
                                    pathname: `/admin-vendor/${vendor.id}`,
                                  }}
                                  className="desktopBtn"
                                >
                                  <Button className="Viewbtn">View Info</Button>
                                </Link>
                              </div>
                              <div className="viewRfiBtn">
                                <Link
                                  to={{
                                    pathname: `/admin-vendor/${vendor.id}`,
                                  }}
                                >
                                  <Button className="Viewbtn">View Info</Button>
                                </Link>
                              </div>
                            </CardContent>
                          </Card> */}
                          <div className="approveNewVendorInfo">
                            <h3
                              style={{
                                color: "#062c56",
                                marginTop: "6px",
                                marginLeft: 0,
                              }}
                            ></h3>
                            <Typography
                              className="content-text"
                              variant="h6"
                              gutterBottom
                            >
                              <span style={{ color: "#062c56" }}> Name: </span>
                              {`${vendor.firstName} ${vendor.lastName}`}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                            ></Typography>
                            <Typography
                              className="content1Product"
                              variant="body1"
                              gutterBottom
                            >
                              <span style={{ color: "#062c56" }}>
                                {" "}
                                Company Name:{" "}
                              </span>{" "}
                              {vendor.companyName}
                            </Typography>
                            <Typography
                              className="content1Product"
                              variant="body1"
                              gutterBottom
                            >
                              <span style={{ color: "#062c56" }}> Date: </span>{" "}
                              {date}
                            </Typography>
                          </div>
                          <div className="viewVendorInfo">
                            <Link
                              to={{
                                pathname: `/admin-vendor/${vendor.id}`,
                              }}
                            >
                              <Button className="Viewbtn">View Info</Button>
                            </Link>
                          </div>
                        </li>
                      );
                    }
                  })}
                  {list && list.length === 0 ? (
                    <li className="noNew">No new vendor added </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="col-sm-12 col-md-9 vendor-list rejected"
            >
              <div>
                <h3 className="heading">Rejected Vendors</h3>
              </div>
              <div style={{ marginTop: "0px" }}>
                <ul
                  style={{ listStyle: "none" }}
                  className="approveNewVendorList"
                >
                  {list?.map((vendor, index) => {
                    if (vendor.isApproved === "2") {
                      let date = new Date(vendor.created_ts)
                        .toDateString()
                        .slice(3);
                      return (
                        <li>
                          {/* <Card className="col-md-12 vendor-Card text-right">
                            <CardContent className="vendor-card-Content">
                              <div className="">
                                <h3
                                  style={{
                                    color: "#062c56",
                                    marginTop: "6px",
                                    marginLeft: 0,
                                  }}
                                ></h3>
                                <div className="text-left text">
                                  <Typography
                                    className="content-text"
                                    variant="h6"
                                    gutterBottom
                                  >
                                    <span style={{ color: "#062c56" }}>
                                      {" "}
                                      Name:{" "}
                                    </span>
                                    {`${vendor.firstName} ${vendor.lastName}`}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                  ></Typography>
                                  <Typography
                                    className="content1Product"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    <span style={{ color: "#062c56" }}>
                                      {" "}
                                      Company Name:{" "}
                                    </span>{" "}
                                    {vendor.companyName}
                                  </Typography>
                                  <Typography
                                    className="content1Product"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    <span style={{ color: "#062c56" }}>
                                      {" "}
                                      Date:{" "}
                                    </span>{" "}
                                    {date}
                                  </Typography>
                                </div>
                                <Link
                                  to={{
                                    pathname: `/admin-vendor/${vendor.id}`,
                                  }}
                                >
                                  <Button className="Viewbtn">View Info</Button>
                                </Link>
                              </div>
                            </CardContent>
                          </Card> */}
                          <div className="approveNewVendorInfo">
                            <h3
                              style={{
                                color: "#062c56",
                                marginTop: "6px",
                                marginLeft: 0,
                              }}
                            ></h3>
                            <Typography
                              className="content-text"
                              variant="h6"
                              gutterBottom
                            >
                              <span style={{ color: "#062c56" }}> Name: </span>
                              {`${vendor.firstName} ${vendor.lastName}`}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                            ></Typography>
                            <Typography
                              className="content1Product"
                              variant="body1"
                              gutterBottom
                            >
                              <span style={{ color: "#062c56" }}>
                                {" "}
                                Company Name:{" "}
                              </span>{" "}
                              {vendor.companyName}
                            </Typography>
                            <Typography
                              className="content1Product"
                              variant="body1"
                              gutterBottom
                            >
                              <span style={{ color: "#062c56" }}> Date: </span>{" "}
                              {date}
                            </Typography>
                          </div>
                          <div className="viewVendorInfo">
                            <Link
                              to={{
                                pathname: `/admin-vendor/${vendor.id}`,
                              }}
                            >
                              <Button className="Viewbtn">View Info</Button>
                            </Link>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="approvefooter">
        <Footer />
      </div> */}
    </div>
  );
};

export default Aprove;
