import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import React from "react";
import "./tooltip.css";
const Tooltip = (props) => {
  return (
    <div className="dropdownHover1 d-flex">
      {/* <button className="dot">i</button> */}
      <IonIcon title="" icon={alertCircleOutline} />
      {/* <span className="dot">i</span> */}
      <div className="dropdownHover1-content">
        {/* <div className ="bubble1"> */}
        {props.message}
      </div>
    </div>
  );
};
export default Tooltip;
