import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
  vendorList: [],
  approvedVendorList: [],
  approvedBuyerList: [],
  vendor: [],
  productList: [],
  product: [],
  CredContact: [],
  rfi: [],
  approvedRFI: [],
  rfiData: [],
};
export const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_VENDOR_LIST:
      console.log("Get_vendor_list", action.payload);
      return {
        ...state,
        vendorList: action.payload,
      };

    case ACTION_TYPE.GET_CONTACT:
      console.log("credDat", action.payload);
      return {
        ...state,
        CredContact: action.payload,
      };

    case ACTION_TYPE.GET_APPROVED_VENDOR:
      return {
        ...state,
        approvedVendorList: action.payload,
      };
    case ACTION_TYPE.GET_APPROVED_BUYER:
      return {
        ...state,
        approvedBuyerList: action.payload,
      };
    case ACTION_TYPE.GET_RFI_BY_ID:
      return {
        ...state,
        rfiData: action.payload.data,
      };
    case ACTION_TYPE.GET_VENDOR:
      return {
        ...state,
        vendor: action.payload,
      };
    case ACTION_TYPE.CHANGE_VENDOR_STATUS:
      return {
        ...state,
        vendor: { ...state.vendor, is_fake: action.payload },
      };
    case ACTION_TYPE.UPDATE:
      return {
        ...state,
      };
    case ACTION_TYPE.GET_PRODUCT:
      return {
        ...state,
        productList: action.payload,
      };

    case ACTION_TYPE.UPDATE_PRODUCT:
      return {
        ...state,
      };
    case ACTION_TYPE.GET_PRODUCT_ID:
      return {
        ...state,
        product: action.payload,
      };
    case ACTION_TYPE.GET_RFI_ADMIN:
      return {
        ...state,
        rfi: action.payload,
      };
    case ACTION_TYPE.GET_APPROVED_RFI_ADMIN:
      return {
        ...state,
        approvedRFI: action.payload,
      };
    case ACTION_TYPE.MARK_VENDOR_PAID:
      return {
        ...state,
        vendor: { ...state.vendor, is_paid: action.payload.is_paid },
      };
    default:
      return state;
  }
};
