import React from "react";
import "./review.css";
import { createPortal } from 'react-dom';
import { IonIcon } from '@ionic/react';
import { star, close } from 'ionicons/icons';
import {Rating,Stack} from '@mui/material';

const ReviewPopup = (props) => {
    const showHideClassName = props.closeModal ? "zhigh" : "zlow";
  console.log(showHideClassName, "showHideClassName")
    console.log(props, " props")

    return (
        <div className={showHideClassName}>
        <div className="containerModal">
            <div className="reviewModal">
                <div className="modalheader">
                    <h2>View Rating & Review</h2>
                    <button className="closeBtn"
                        onClick={() => {
                            props.closeModal(false);
                            console.log('clicked');
                        }}>
                            <IonIcon
                            icon={close} />
                        </button>
                </div>
                <div className="modalBody">
                    <p className="boldP">Rating</p>
                    <p className="textModal">
                        <div className="reviewContainer approveReview">
                            <div className="reviewStar">
                                <div>
                                <svg
                                    width="20"
                                    height="19"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                    fill="#D9D9D9"
                                    />
                                </svg>
                                </div>
                                <div>
                                <svg
                                    width="20"
                                    height="19"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                    fill="#D9D9D9"
                                    />
                                </svg>
                                </div>
                                <div>
                                <svg
                                    width="20"
                                    height="19"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                    fill="#D9D9D9"
                                    />
                                </svg>
                                </div>
                                <div>
                                <svg
                                    width="20"
                                    height="19"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                    fill="#D9D9D9"
                                    />
                                </svg>
                                </div>
                                <div>
                                <svg
                                    width="20"
                                    height="19"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                    fill="#D9D9D9"
                                    />
                                </svg>
                                </div>
                            </div>
                        </div>
                    </p>
                    <p className="boldP">Review Heading</p>
                    <p className="textModal">Great engagement tools with a great customer support and team behind it!</p>
                    <p className="boldP">Review Description</p>
                    <p className="textModal">The engagement approach is highly valuable, as well as in big stadiums but even more in indoor sports events.</p>
                </div>
            </div>
        </div>
        </div>
    )
}
const ReviewModal = (props) => {
    return (
        <>
            {createPortal(
                <ReviewPopup closeModal={props.closeModal}/>,
                document.getElementById("viewmodal")
            )}
        </>
    )
}

export default ReviewModal;