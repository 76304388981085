import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	freeQuotePrice: [],
};

export const contactReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.FREE_QUOTE_PRICE:
			console.log("leads reducer", action.payload);
			return {
				...state,
				freeQuotePrice: action.payload.data.data,
			};
		default:
			return state;
	}
};
