import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import "./style.css";
import ToolTip from "../../DashboardModules/tooltip/Tooltip";
import { ValidateRequestForm } from "./buyerValidation";
import { useDispatch, useSelector } from "react-redux";
import {
  buyerSubmitReview,
  getRatingReviewRequestById,
} from "../../ReduxStore/Actions/ratingReviewActions";
import queryString from "query-string";
import { IonSpinner } from "@ionic/react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Tooltip from "../../DashboardModules/tooltip/Tooltip";

const BuyersLanding = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.commonReducer.loading);
  const ratingReviewRequestById = useSelector(
    (state) => state.RatingReviewReducer.ratingReviewRequestById
  );
  console.log("ratingReviewRequestById", ratingReviewRequestById);
  const authData = useSelector((state) => state.authReducer.authData);

  if (
    authData &&
    Object.keys(authData).length > 0 &&
    ratingReviewRequestById &&
    ratingReviewRequestById.length > 0 &&
    (authData.email.toLowerCase() !==
      ratingReviewRequestById[0].reviewer_email.toLowerCase() ||
      authData.role !== 0)
  ) {
    history.push("/invalid-user-review");
  }

  const ratingAndReviewLimit = 500;
  const { requestId } = queryString.parse(props.location.search);
  const reviewHeadlineLimit = 75;
  const [state, setState] = React.useState({
    ratingAndReview: "",
    reviewHeadline: "",
    isTncAccepted: false,
  });
  console.log("state in buyer landing", state);

  const [errorData, setError] = React.useState();
  const [message, setMessage] = React.useState();
  const countCharacters = React.useCallback(
    (text = "", limit) => {
      let count = text && text.split(/\S+/).length - 1;
      console.log("textlimit", text, limit, count);
      return {
        isExceeded: count > limit,
        count: count,
      };
    },
    [state?.ratingAndReview, state?.reviewHeadline]
  );
  // console.log(
  //   "countCharacters(state?.reviewHeadline)",
  //   countCharacters(state?.reviewHeadline, reviewHeadlineLimit)
  // );

  useEffect(() => {
    dispatch(getRatingReviewRequestById(requestId));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if (errorData && errorData[name][0]) {
    //   setError({ ...errorData, [name]: null });
    // }
    console.log("isTncAccepted", e.target.value);
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeCheckbox = (e) => {
    const { name, checked, id } = e.target;
    console.log("checked", e.target.checked, e.target.id);
    if (name === "isTncAccepted") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else if (id === "nonanonymity") {
      setState((prev) => ({ ...prev, [name]: !checked }));
    } else if (id === "anonymity") {
      setState((prev) => ({ ...prev, [name]: checked }));
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const { requestId, product } = queryString.parse(props.location.search);
    const { isFormValid, error } = await ValidateRequestForm({
      ...state,
      rating_requested_id: requestId,
      productId: product,
    });
    console.log("VALIDATION", isFormValid);
    if (isFormValid && requestId && product) {
      const data = await dispatch(
        buyerSubmitReview(
          {
            ...state,
            rating_requested_id: requestId,
            productId: product,
          },
          history
        )
      );
      if (data && data.message) {
        setMessage(data.message);
      }
    } else {
      setError(error);
      console.log(
        "error in rating request form validation",
        JSON.stringify(error)
      );
    }
  };

  const ratingAndReviewCountData = countCharacters(
    state?.ratingAndReview,
    ratingAndReviewLimit
  );
  const reviewHeadlineCountData = countCharacters(
    state?.reviewHeadline,
    reviewHeadlineLimit
  );

  return (
    <>
      <div className="buyerLandingContainer buyerLandingPage">
        <div className="innerContainer">
          <h2>RATING & REVIEW FORM</h2>
          <form className="formContainer">
            <div className="formRow">
              <div>
                {ratingReviewRequestById &&
                  ratingReviewRequestById.length > 0 && (
                    <>
                      <p>Dear {ratingReviewRequestById[0].reviewer_name},</p>

                      <p>
                        You have been invited by{" "}
                        {ratingReviewRequestById[0].firstName}{" "}
                        {ratingReviewRequestById[0].lastName} to submit a review
                        based on your experience of using product:{" "}
                        {ratingReviewRequestById[0].name}.
                      </p>
                    </>
                  )}
                <div className="formLabelContainer">
                  <label>Write a Review</label>
                  <Tooltip
                    message={
                      <div>
                        <p>Use our "RATE" framework for writing a review:</p>
                        <p>
                          <strong>Readable.</strong>
                          Please don't use excessive capitalisation or
                          punctuation (and we recommend to quickly check your
                          grammar and spelling).
                        </p>
                        <p>
                          <strong>Added Value.</strong> Try to highlight
                          positives and/or negatives in your review, even if
                          your experience skews heavily in one direction so that
                          your review adds value to others.
                        </p>
                        <p>
                          <strong>Timely.</strong> The best reviews are those
                          written about the latest version of a product /
                          service, and within no longer than 1 year of using it.
                        </p>
                        <p>
                          <strong>Examples.</strong> What aspects do you love?
                          Use clear examples of specific experiences - the more
                          details, the better.
                        </p>
                      </div>
                    }
                  />
                </div>
                <p
                  style={{
                    fontSize: "12px",
                    color: ratingAndReviewCountData?.isExceeded
                      ? "red"
                      : "#7a7a7a",
                    margin: "0",
                    marginLeft: "1px",
                    marginTop: "-15px",
                  }}
                >
                  Check out our tips for writing a review
                </p>

                <textarea
                  id="ratingAndReview"
                  name="ratingAndReview"
                  rows="4"
                  cols="50"
                  value={state?.ratingAndReview}
                  onChange={handleChange}
                />
                <p
                  style={{
                    fontSize: "12px",
                    color: ratingAndReviewCountData?.isExceeded
                      ? "red"
                      : "#7a7a7a",
                    margin: "0",
                  }}
                >
                  Maximum word limit is{" "}
                  {ratingAndReviewCountData.count > 0
                    ? ratingAndReviewCountData.count
                    : 0}
                  /{ratingAndReviewLimit}
                </p>
                {errorData && errorData?.ratingAndReview[0] && (
                  <p className="errorTxt">
                    {errorData && errorData?.ratingAndReview[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="formLabelContainer">
                  <label>Rate the Product</label>
                  <ToolTip message="Rate your experience on a scale of 1 to 5, where, 1= Very Dissatisfied and 5= Very Satisfied" />
                </div>
                <div>
                  <div class="rate">
                    <input
                      type="radio"
                      id="star5"
                      name="rate"
                      value="5"
                      onChange={handleChange}
                      checked={state.rate === "5"}
                    />
                    <label for="star5" title="text"></label>
                    <input
                      type="radio"
                      id="star4"
                      name="rate"
                      value="4"
                      onChange={handleChange}
                      checked={state.rate === "4"}
                    />
                    <label for="star4" title="text"></label>
                    <input
                      type="radio"
                      id="star3"
                      name="rate"
                      value="3"
                      onChange={handleChange}
                      checked={state.rate === "3"}
                    />
                    <label for="star3" title="text"></label>
                    <input
                      type="radio"
                      id="star2"
                      name="rate"
                      value="2"
                      onChange={handleChange}
                      checked={state.rate === "2"}
                    />
                    <label for="star2" title="text"></label>
                    <input
                      type="radio"
                      id="star1"
                      name="rate"
                      value="1"
                      onChange={handleChange}
                      checked={state.rate === "1"}
                    />
                    <label for="star1" title="text"></label>
                  </div>
                  {errorData && errorData?.rate[0] ? (
                    <p className="errorTxt">
                      {errorData && errorData?.rate[0]}
                    </p>
                  ) : null}
                  {/* <div className="reviewContainer approveReview">
                  </div> */}
                </div>
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="formLabelContainer">
                  <label>Review Headline</label>
                  <ToolTip message="Write a headline for your review (ideally this should be a short statement that summarises your review and a maximum of 75 characters / 15 words)." />
                </div>
                <input
                  type="text"
                  name="reviewHeadline"
                  value={state?.reviewHeadline}
                  onChange={handleChange}
                />

                <p
                  style={{
                    fontSize: "12px",
                    color: reviewHeadlineCountData?.isExceeded
                      ? "red"
                      : "#7a7a7a",
                    margin: "0",
                  }}
                >
                  Maximum word limit is{" "}
                  {reviewHeadlineCountData.count > 0
                    ? reviewHeadlineCountData.count
                    : 0}
                  /{reviewHeadlineLimit}
                </p>
                {errorData && errorData?.reviewHeadline[0] && (
                  <p className="errorTxt">
                    {errorData && errorData?.reviewHeadline[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="formLabelContainer">
                  <label>Anonymity</label>
                  <ToolTip message="While we will never reveal your name or personal information in association with this review it can be useful context to associate your organisation name to the review. We also understand that you may want to retain your organisation's anonymity. Please indicate your preference below (we will use 'Organisation Type / HQ Country' if you choose not to associate your organisation with the review)." />
                </div>
                <fieldset id="anonymity" className="radioGrp">
                  <div>
                    <input
                      type="radio"
                      id="nonanonymity"
                      value={state?.anonymity}
                      name="anonymity"
                      onChange={handleChangeCheckbox}
                    />
                    <label>Associate my organisation name to the review</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="anonymity"
                      value={state?.isAssociate}
                      name="anonymity"
                      onChange={handleChangeCheckbox}
                    />
                    <label>Keep my organisation name anonymous</label>
                  </div>
                </fieldset>
                {errorData && errorData?.anonymity[0] ? (
                  <p className="errorTxt">
                    {errorData && errorData?.anonymity[0]}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="formRow noBold">
              <div>
                <label>
                  Finally, please give us your permission to use your Customer
                  Review: I give my permission for the Customer Review that I
                  provided, including all text, as well as the Rating given, to
                  be used in marketing and promotions of the Vendor and
                  SportsTech Match.
                </label>
                <fieldset id="isTncAccepted" className="radioGrp">
                  <div>
                    <input
                      type="checkbox"
                      value={state?.isTncAccepted}
                      name="isTncAccepted"
                      onChange={handleChangeCheckbox}
                    />
                    <label>
                      I have read, understood, and agree to all of the above.
                    </label>
                  </div>
                </fieldset>
                {errorData && errorData?.isTncAccepted[0] ? (
                  <p className="errorTxt">
                    {errorData && errorData?.isTncAccepted[0]}
                  </p>
                ) : null}
              </div>
            </div>
            {errorData && errorData?.productId[0] ? (
              <p className="errorTxt">{errorData && errorData?.productId[0]}</p>
            ) : errorData && errorData?.rating_requested_id[0] ? (
              <p className="errorTxt">
                {errorData && errorData?.rating_requested_id[0]}
              </p>
            ) : null}
            {message ? <p className="errorTxt">{message}</p> : null}
            <div className="formBtnContainer buyerLandingBtns">
              <Link
                to={{
                  pathname: `/rfi-dashboard`,
                }}
              >
                Cancel
              </Link>
              {loading ? (
                <IonSpinner />
              ) : (
                <button onClick={onSubmit} type="submit">
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BuyersLanding;
