import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonModal,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./AdminPage.css";
import { useSelector, useDispatch } from "react-redux";

import {
  AdminAction,
  HomeAction,
  ProfileAction,
} from "../../ReduxStore/Actions/index";
import SideNav from "../../adminPanel/sidebar/sideNav";
import ChangePassword from "./changePassword";

const VendorProfile = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [profileImagePreview, setProfileImagePreview] = useState();
  const authData = useSelector((state) => state.authReducer.authData);
  const credData = useSelector((state) => state.AdminReducer.CredContact);

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastError, setToastError] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    const {
      id,
      firstName,
      lastName,
      email,
      companyName,
      companyHq,
      yearFounded,
      website,
      logoUrl,
    } = authData;
    setState({
      id,
      firstName,
      lastName,
      email,
      companyName,
      companyHq,
      yearFounded,
      website,
      logoUrl,
      contactEmail: credData.email,
      contactEmailPassword: credData.password,
    });
    setProfileImagePreview(authData?.logoUrl);
  }, [authData, credData]);

  const handleChange = (event, type, data) => {
    const { name, value } = event.target;

    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    (async () => {
      await dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(AdminAction.getCreds());
    })();
  }, []);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const fileToUpload = selectedFile;

    const formData = new FormData();
    formData.append("image", fileToUpload);

    for (var key in state) {
      formData.append(key, state[key]);
    }
    formData.append("user_id", authData.id);

    const data = await dispatch(ProfileAction.updateProfileDetails(formData));

    setToastMessage(data.message);
    setToastError(data.status);
    setToast(true);
  };

  const showPreview = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(authData?.logoUrl);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedFile) {
      setProfileImagePreview(authData?.logoUrl);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setProfileImagePreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <IonContent>
      <IonModal isOpen={showChangePassword} backdropDismiss={false}>
        <ChangePassword
          handleToast={setToast}
          handleToastMsg={setToastMessage}
          showChangePassword
          handleModal={setShowChangePassword}
        />
      </IonModal>
      <div className=" vendorProfile1">
        <>
          <SideNav />

          <div className="col-md-9 col-sm-12 innerAdminprofile">
            {toast === true ? (
              <IonToast
                isOpen={toast}
                message={toastMessage}
                onDidDismiss={() => setToast(false)}
                buttons={[
                  {
                    text: "Close",
                    role: "cancel",
                    handler: () => {
                      setToast(false);
                    },
                  },
                ]}
              />
            ) : null}
            <div className="vendorprofilehead">
              <h2>Admin Profile</h2>
            </div>

            <div className="vendorprofilebody">
              <div className="formname">
                <h4> Details:</h4>
              </div>
              <form>
                <IonGrid className="body">
                  <IonRow>
                    <IonCol size="12" size-md="6">
                      <div className="formgroup">
                        <IonLabel className="labletext">First Name*</IonLabel>
                        <IonInput
                          type="text"
                          name="firstName"
                          value={state?.firstName}
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="formgroup">
                        <IonLabel className="labletext">Last name*</IonLabel>
                        <IonInput
                          type="text"
                          name="lastName"
                          value={state?.lastName}
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="12">
                      <div className="formgroup">
                        <div className=" d-flex">
                          <IonLabel className="labletext">
                            Email address
                          </IonLabel>
                        </div>
                        <IonInput
                          type="email"
                          name="email"
                          value={state?.email}
                          disabled
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                      </div>
                    </IonCol>

                    <IonCol size="12" size-md="6">
                      <div className="formgroup">
                        <IonLabel className="labletext">Contact Email</IonLabel>
                        <IonInput
                          type="text"
                          name="contactEmail"
                          value={state?.contactEmail}
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="formgroup">
                        <IonLabel className="labletext">
                          Contact Email Password
                        </IonLabel>
                        <IonInput
                          type="password"
                          name="contactEmailPassword"
                          value={state?.contactEmailPassword}
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <div className="button-name text-center">
                  <IonButton
                    color="none"
                    className="btnsave"
                    onClick={() => {
                      setShowChangePassword(true);
                    }}
                  >
                    Change Password
                    {/* {(() => {
                    if (loading) {
                      return (
                        <IonSpinner name="bubbles" className="spinner-loader" />
                      );
                    }
                  })()} */}
                  </IonButton>
                  <IonButton
                    color="none"
                    className="btnsave"
                    onClick={onSubmitForm}
                  >
                    save
                  </IonButton>
                </div>
              </form>
            </div>
          </div>
        </>
        )
      </div>
    </IonContent>
  );
};

export default VendorProfile;
