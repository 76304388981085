import {
  Divider,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import "./ViewVendor.css";
import SideNav from "../sideBar/sideNav";
// import Search from "../SearchBar/searchbar";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeAction,
  AdminAction,
  AuthActions,
} from "../../ReduxStore/Actions/index";
import { ROUTES } from "../../Services";

const ViewBuyers = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const list = useSelector((state) => state.AdminReducer.approvedBuyerList);
  console.log(list, "klllll");

  const authData = useSelector((state) => state.authReducer.authData);

  const loginAsUser = async (user) => {
    console.log("user in view vendor as support", user);

    // dispatch an action to login as user

    const status = await dispatch(AuthActions.loginAsUser(user));

    console.log("status in view vendor support==>", status);

    if (status?.status) {
      console.log("connectedddddd in iffff", status);
      if (status?.user?.role === 1) props.history.push(ROUTES.Vendors);
      else if (status?.user?.role === 0)
        props.history.push(ROUTES.RfiDashboard);
      else if (status?.user?.role === 2)
        props.history.push(ROUTES.AdminProfile);
      else if (status?.user?.role === 4)
        props.history.push(ROUTES.AdminSupportProfile);
    }
  };

  const redirectTo = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return (
      <Redirect
        to={
          userData?.role === 1
            ? `${ROUTES.Vendors}`
            : userData?.role === 0
            ? `${ROUTES.RfiDashboard}`
            : userData?.role === 2
            ? `${ROUTES.AdminProfile}`
            : `${location.pathname}`
        }
      />
    );
  };

  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(AdminAction.getApprovedBuyersList());
    })();
  }, []);
  return (
    <div className="top">
      <div className="mainBody1">
        {authData && authData.role === 4 ? (
          <>
            <SideNav />

            <div className="col-md-9 col-sm-12 viewApprove buyerListSupport vendor-list">
              <h3 className="heading">Buyers</h3>
              {/* <Search type="vendor" /> */}
              <ul style={{ color: "black", listStyle: "none" }}>
                {list &&
                  list.map((user, i) => {
                    return (
                      <li className="vendor-Card" key={i}>
                        <div className="d-flex">
                          <h3>{i + 1}.</h3>
                          <Typography
                            className="content"
                            variant="h6"
                            gutterBottom
                          >
                            <span style={{ color: "#062c56" }}> Name: </span>
                            {`${user.firstName} ${user.lastName}`}
                          </Typography>

                          <Button
                            className="text-right Viewbtn"
                            onClick={() => loginAsUser(user)}
                          >
                            Login As User
                          </Button>
                        </div>
                        <Typography
                          className="content1"
                          variant="body1"
                          gutterBottom
                        ></Typography>
                        <Divider variant="middle" />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50vh",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            {redirectTo()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBuyers;
