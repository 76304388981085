import React from "react";
import img from "../../images/CommonImages/icon.png";
import Footer from "../../components/Footer/Footer";
import "./style.css";
import { Button } from "@material-ui/core";

function InvalidUserReview(props) {
  return (
    <>
      <div className="Rcontainer">
        <div className="innerContainer1 revSubmit">
          <h2 className="heading">
            There is no rating & review invitation associated with this account.
          </h2>
          <p>
            Make sure that you are logged into an account corresponding to the
            email to which you received the Rating & Review invitation. It's
            quick and free to Sign Up if you don't already have an account.
          </p>
          <img src={img} alt="review" />
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniamconsequat.
          </p> */}
          <Button
            onClick={() => {
              props.history.push("/rfi-dashboard");
            }}
            className="text-right gotopageBtn"
          >
            Back to Dashboard
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InvalidUserReview;
