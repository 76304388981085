import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Footer from "../../components/Footer/Footer";
import {
  IonButton,
  IonContent,
  IonImg,
  IonAlert,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";

import { ROUTES } from "../../Services/index";
import "./manageList.css";
import { useDispatch, useSelector } from "react-redux";
import { VendorAction } from "../../ReduxStore/Actions/index";
import { Link } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { GetRFIs, RespondRFI } from "../../ReduxStore/Actions/RFIActions";
import RfiDashboard from "../Dashboard/RfiDashboard/RfiDashboard";
import RenderRfiListItems from "../../components/Table/Table";
import { CircularProgress } from "@material-ui/core";
import { getRatingReviewRequest } from "../../ReduxStore/Actions/ratingReviewActions";

import Tooltip from "../tooltip/Tooltip";
const ManageList = (props) => {
  const [showAlert3, setShowAlert3] = useState(false);
  const [productID, setProductId] = useState();
  const location = useLocation();
  console.log("Listing Active", location.pathname);
  let dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer.authData);
  let content = useSelector((state) => state.vendorReducer.list);
  console.log("content", content);
  const authdata = useSelector((state) => state.authReducer.authData?.id);
  const respondRfi = useSelector((state) => state.RFIReducer.respondRfi);
  const rfiListItems = useSelector((state) => state.RFIReducer.userRfis);
  const loading = useSelector((state) => state.commonReducer.loading);

  console.log("respondRfi", respondRfi);
  //   const rfiListItems = useSelector((state) => state.RFIReducer.userRfis);

  console.log("authData in manage list", authData);

  let vendorId = { vendorId: authdata };
  console.log(vendorId);
  useEffect(() => {
    (async () => {
      if (authdata) {
        await dispatch(VendorAction.getList(vendorId));
        await dispatch(getRatingReviewRequest());
      }
    })();
  }, [authdata]);
  useEffect(() => {
    (async () => {
      await dispatch(RespondRFI());
    })();
  }, []);

  async function handleDeleteChange(productId) {
    console.log("delete", productId);
    let params = {
      vendorId: authdata,
      productId: productId,
    };
    await dispatch(VendorAction.delList(params));
    await dispatch(VendorAction.getList(vendorId));
  }

  return (
    <>
      <div className="paidVendorContainer flexClass paidVendorNewContainer">
        <div className="vendorSidebar">
          <ul>
            <li className={location.pathname === "/vendors" && "active"}>
              Product Listings & RFI’s
              <div className="noteText">
                <p>
                  You will not be notified of RFIs until you create at least one
                  product listing.
                  <span>
                    <ToolTip
                      message="It is important to ensure that
your product(s) is listed in the
correct sector so that you
receive relevant RFI's."
                    />
                  </span>
                </p>
              </div>
            </li>
            {(authData?.is_paid && authData?.is_vendor_admin) ||
            (authData?.is_vendor_admin && authData?.is_assigned_to) ? (
              <li>
                <Link to={ROUTES.ManagePaidVendors}>Vendor Admin Panel</Link>
                <div className="noteText">
                  <p>
                    Manage the user accounts of your colleagues.
                    <span>
                      <ToolTip
                        message="As the Vendor Admin, you can add new users,
					 delete users or edit users names and login details."
                      />
                    </span>
                  </p>
                </div>
              </li>
            ) : null}
            <li>
              <Link to={ROUTES.ViewTenders}>External Tenders</Link>
              <div className="noteText">
                <p>
                  Discover relevant Tenders / RFPs / RFIs from 3rd party
                  sources.
                  <span>
                    <ToolTip
                      message="Tenders are sourced by STM directly
					 from buyers as well as discovered by
					 STM's own watch service which covers hundreds
					  of relevant websites worldwide."
                    />
                  </span>
                </p>
              </div>
            </li>
            {authData?.is_paid ||
            authData?.is_vendor_admin ||
            (authData?.is_vendor_admin && authData?.is_assigned_to) ? (
              <li>
                <Link to={`${ROUTES.RequestReview}`}>
                  Request Rating & Review
                </Link>
                <div className="noteText">
                  <p>
                    Request a rating & review from your customers to enrich your
                    profile.
                    <span>
                      <ToolTip
                        message="Request a rating & review from your customers to enrich your
                    profile."
                      />
                    </span>
                  </p>
                </div>
              </li>
            ) : null}
            <li>
              <Link to={ROUTES.AllReviews}>View All Ratings & Reviews</Link>
              <div className="noteText">
                <p>
                  View the latest published ratings & reviews given to vendors
                  across the platform.
                  <span>
                    <ToolTip
                      message="View the latest published ratings & reviews given to vendors
                  across the platform."
                    />
                  </span>
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="paidVendorBlock userPanel__container">
          <div className="uploadVendorBlock">
            {authData?.logoUrl ===
              "https://sportstechbucket.s3.us-east-2.amazonaws.com/profile-images/vendor-placeholder-logo.png" && (
              <button className="uploadVendorBtn">
                <Link to={ROUTES.VendorProfile}>
                  Upload vendor/company logo
                </Link>
              </button>
            )}
          </div>

          <div className="vendorPagesContainer">
            {/* {authData?.is_paid && authData?.is_vendor_admin ? (
              <div>
                <button>All Products</button>
                <button>
                  <Link to={ROUTES.ManagePaidVendors}>Vendor Admin Panel</Link>
                </button>
                <button>
                  <Link to={ROUTES.ViewTenders}>External Tenders</Link>
                </button>
              </div>
            ) : null} */}
            <div className="addProductHeader">
              <h2 style={{ marginBottom: "20px" }}>Product Listings</h2>
              <button>
                <Link to={ROUTES.AddVendor}>+ Add Product</Link>
              </button>
            </div>
          </div>
          {/* <div className="noteText">
            <p>
              You will not be notified of RFIs until you create at least one
              product listing.
              <span>
                <ToolTip
                  message="It is important to ensure that
your product(s) is listed in the
correct sector so that you
receive relevant RFI's."
                />
              </span>
            </p>
          </div> */}
          <div className="productListContainer">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : content.length === 0 ? (
              <h5 style={{ textAlign: "center" }}>
                You have no products currently listed
              </h5>
            ) : (
              content &&
              content.map((product) => {
                return (
                  <div className="productListItem">
                    <div>
                      <img src={authData && authData.logoUrl} />
                    </div>
                    <div>
                      <h2>{product.productName}</h2>
                      <h3>
                        {product.companyName !== "undefined"
                          ? product.companyName
                          : null}
                      </h3>
                      <p>{product.description}</p>
                      <p>
                        Status:{" "}
                        {product.isApproved === 0 ? (
                          <span style={{ color: "orange" }}>
                            Pending Approval
                          </span>
                        ) : product.isApproved === 1 ? (
                          <span style={{ color: "green" }}>Approved</span>
                        ) : product.isApproved === 2 ? (
                          <span style={{ color: "red" }}>Rejected</span>
                        ) : null}
                      </p>
                    </div>
                    <div className="listBtnContainer">
                      <button>
                        <Link
                          to={{
                            pathname: `/edit-vendor/${product.id}`,
                          }}
                        ></Link>
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          setProductId(product.id);
                          setShowAlert3(true);
                        }}
                      >
                        Delete
                      </button>
                      <button>
                        <Link
                          to={{
                            pathname: `/product/${product.id}`,
                          }}
                        ></Link>
                        View Listing
                      </button>
                      {authData?.is_paid ||
                      authData?.is_vendor_admin ||
                      (authData?.is_vendor_admin &&
                        authData?.is_assigned_to) ? (
                        <button>
                          <Link
                            to={{
                              pathname: `/create-request/n${product.id}`,
                            }}
                          ></Link>
                          Request a Rating & Review
                        </button>
                      ) : null}
                      <IonAlert
                        isOpen={showAlert3}
                        onDidDismiss={() => setShowAlert3(false)}
                        cssClass="my-custom-class"
                        header={"Are you sure you want to delete this listing?"}
                        message="<h1>Are you sure?</h1>"
                        buttons={[
                          {
                            text: "Yes",
                            role: "cancel",
                            cssClass: "secondary",
                            handler: () => {
                              handleDeleteChange(productID);
                            },
                          },
                          {
                            text: "No",
                            handler: () => {
                              setShowAlert3(false);
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="rfiResponseContainer">
            <RfiDashboard></RfiDashboard>

            <div
              className="refiResponseHeader rfiHeader rfiRes"
              style={{ marginTop: "30px" }}
            >
              <h2>RFI Responses</h2>
              {/* {!loading && rfiListItems.length === 0 && (
                <button>
                  <Link to="/form-one">+ Create RFI</Link>
                </button>
              )} */}
            </div>
            <div className="rfiResponseTable">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : respondRfi.length === 0 ? (
                // <div className="rfi-parent">
                <div class="productListContainerh5">
                  <h5 style={{ textAlign: "center" }}>
                    You have not responded to any RFI’s
                  </h5>
                  {/* </div> */}
                </div>
              ) : (
                <table>
                  <tr className="rfi-table-head">
                    <th style={{ width: "40%" }}>Title</th>
                    <th>
                      <span className="toolTipParentRelative">
                        Deadline
                        <div>
                          <Tooltip message="RFI's expire at 12:00CET on the deadline day" />
                        </div>
                      </span>
                    </th>
                    <th>Status</th>
                  </tr>
                  <RenderRfiListItems
                    rfiListItems={respondRfi}
                    type="respond"
                  />
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ManageList;
