import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonImg,
  IonButtons,
  IonPopover,
} from "@ionic/react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { APPLICATION_CONSTANTS, ROUTES } from "../../Services/index";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import "./Header.css";
import "../AuthHeader/AuthHeader.css";
import { menuOutline } from "ionicons/icons";
import { menuController } from "@ionic/core";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions } from "../../ReduxStore/Actions";
const Header = (props) => {
  const menuToggle = () => {
    menuController.toggle();
  };
  let dispatch = useDispatch();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const authData = useSelector((state) => state.authReducer.authData);
  // const headerClass = useSelector((state) => state.homeReducer.headerClass);

  //console.log("authData in header ===>", authData);

  const localAuth = JSON.parse(localStorage.getItem("userData"));

  //console.log("local auth in header", localAuth);

  const history = useHistory();

  let { location, headerClass } = props;

  const logoutHandler = async () => {
    if (
      localAuth &&
      localAuth.support &&
      localAuth.support.supportUserData &&
      localAuth.support.supportUserData.role === 4
    ) {
      const status = await dispatch(AuthActions.logoutAsUser(localAuth));
     // console.log("status in header for support logout ==>", status);
      if (status?.status) {
      //  console.log("connectedddddd in iffff", status);
        if (status?.user?.role == 1) history.push(ROUTES.Vendors);
        else if (status?.user?.role == 0) history.push(ROUTES.RfiDashboard);
        else if (status?.user?.role == 2) history.push(ROUTES.AdminProfile);
        else if (status?.user?.role == 4)
          history.push(ROUTES.AdminSupportProfile);
        else history.push("/");
      } else {
        dispatch({ type: "LOGOUT_USER" });
        await localStorage.removeItem(APPLICATION_CONSTANTS.USER_DATA);
        await localStorage.removeItem(APPLICATION_CONSTANTS.TOKEN);
        await history.push("/");
      }
    } else {
      dispatch({ type: "LOGOUT_USER" });
      await localStorage.removeItem(APPLICATION_CONSTANTS.USER_DATA);
      await localStorage.removeItem(APPLICATION_CONSTANTS.TOKEN);
      await props.history.push("/");
    }
  };

  return (
    // <IonContent>
    <IonHeader
      id={headerClass === "trans" ? "headerTrans" : "headerBlue"}
      className="fixed-top headerWeb"
    >
      <IonToolbar className="hdr">
        <div className="mainHeaderWeb">
          <div className="container1">
            <IonButtons
              slot="start"
              className="kalyug"
              onClick={(event) => {
                menuToggle();
              }}
            >
              <IonIcon className="barIcon" icon={menuOutline} />
            </IonButtons>

            <div className="logoTitle ">
              <Link to={ROUTES.Home}>
                <IonImg
                  className="logo-blue logoImg "
                  src={require("../../images/CommonImages/logo.png")}
                />
                <sup>BETA</sup>
              </Link>
              <ul className="col-md-12" style={{ listStyle: "none" }}>
                {localStorage.getItem("token") ? (
                  <li className="text-center Account d-none d-lg-block">
                    {/* <img
                      style={{ width: "25%" }}
                      src={require("../../images/CommonImages/UserIcon.png")}
                    ></img> */}
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.persist();
                        setShowPopover({ showPopover: true, event: e });
                      }}
                    >
                      <img
                        style={{ width: "25%" }}
                        alt="icon"
                        src={require("../../images/CommonImages/icon.png")}
                      ></img>
                    </div>
                    <IonPopover
                      cssClass="my-custom-class"
                      event={popoverState.event}
                      isOpen={popoverState.showPopover}
                      onDidDismiss={() =>
                        setShowPopover({ showPopover: false, event: undefined })
                      }
                    >
                      <ul style={{ listStyle: "none", padding: 0 }}>
                        <li className="Fullname">{`${
                          authData && authData["firstName"]
                        } ${authData && authData.lastName}`}</li>
                        {authData &&
                        (authData.role == 2 || authData.role == 4) ? null : (
                          <li
                            onClick={() =>
                              setShowPopover({
                                showPopover: false,
                                event: undefined,
                              })
                            }
                            className="Sublinks"
                          >
                            <Link to={ROUTES.VendorProfile}>Profile</Link>
                          </li>
                        )}
                        <li
                          onClick={() =>
                            setShowPopover({
                              showPopover: false,
                              event: undefined,
                            })
                          }
                          className="Sublinks"
                          style={{ borderBottom: "2px solid black" }}
                        >
                          <Link
                            to={
                              authData?.role === 0
                                ? ROUTES.RfiDashboard
                                : authData?.role === 1 ||
                                  (authData?.role === 3 &&
                                    authData?.is_vendor_admin === 1)
                                ? ROUTES.Vendors
                                : authData?.role === 2
                                ? ROUTES.AdminProfile
                                : authData?.role === 4
                                ? ROUTES.AdminSupportProfile
                                : null
                            }
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li
                          onClick={() =>
                            setShowPopover({
                              showPopover: false,
                              event: undefined,
                            })
                          }
                          className="text-center mt-2"
                        >
                          {" "}
                          <div className=" Logoutbtn" onClick={logoutHandler}>
                            {authData &&
                            authData.firstName &&
                            authData.lastName &&
                            localAuth &&
                            localAuth.support &&
                            localAuth.support.supportUserData &&
                            localAuth.support.supportUserData.role === 4
                              ? `Logout from ${authData.firstName} ${authData.lastName}`
                              : "Logout"}
                          </div>
                        </li>
                      </ul>
                    </IonPopover>
                  </li>
                ) : (
                  <li className="Account d-none d-lg-block">
                    <p
                      style={{
                        padding: "8px 35px 0px",
                        // background: "#ffffff",
                        // borderColor: "#0D6763",
                        color: "#ffffff",

                        fontWeight: 600,
                        fontSize: "18px",
                        marginLeft: " 20px",
                        marginBottom: "0px",
                        textAlign: "center",
                        marginTop: "-17px",
                      }}
                    >
                      {/* Vendor Area */}
                      <br />
                    </p>
                    <Link to={ROUTES.Login} style={{ padding: "0px 10px" }}>
                      <Button
                        style={{
                          padding: "4px 35px",
                          background: "#ffffff",
                          borderColor: "#0D6763",
                          color: "#1d2373",
                          borderRadius: "26px",

                          fontWeight: 600,
                          // marginTop: "30px",
                        }}
                      >
                        Login
                      </Button>
                    </Link>
                    <Link to={ROUTES.SignUp} style={{ padding: "0px 10px" }}>
                      <Button
                        style={{
                          borderRadius: "26px",
                          padding: "4px 35px",
                          background: "#ffffff",
                          borderColor: "#0D6763",
                          color: "#1d2373",
                          fontWeight: 600,
                          // marginTop: "30px",
                        }}
                      >
                        Sign Up
                      </Button>
                    </Link>
                  </li>
                )}
                {/* {localStorage.getItem("token") &&
                authData &&
                authData.role === 2 ? (
                  <li
                    className={
                      location.pathname === ROUTES.Approve
                        ? "col-md-2 admin d-none d-lg-block active"
                        : "col-md-2 admin d-none d-lg-block"
                    }
                  >
                    <Link to={ROUTES.AdminProfile}>Admin Dashboard</Link>
                  </li>
                ) : null} */}
              </ul>
            </div>
            {authData &&
              authData.firstName &&
              authData.lastName &&
              localAuth &&
              localAuth.support &&
              localAuth.support.supportUserData &&
              localAuth.support.supportUserData.role === 4 && (
                <div className="support loggedInTxt">
                  <h5>{`You are logged in as ${authData.firstName} ${authData.lastName}`}</h5>
                </div>
              )}
            <nav className="main-nav  d-none d-lg-block">
              <ul className="mainNav">
                <div className="mainNav1">
                  <li
                    className={
                      location.pathname === ROUTES.Home ? "active" : ""
                    }
                  >
                    <Link to={ROUTES.Home}>Home</Link>
                  </li>
                  <li
                    className={
                      location.pathname === ROUTES.Search ? "active" : ""
                    }
                  >
                    <Link to={ROUTES.Search}>Marketplace</Link>{" "}
                  </li>

                  <li
                    className={
                      location.pathname === ROUTES.VendorPage ? "active" : ""
                    }
                  >
                    <Link to={ROUTES.VendorPage}>Vendors</Link>{" "}
                  </li>

                  <li
                    className={
                      location.pathname === ROUTES.Buyer ? "active" : ""
                    }
                  >
                    <Link to={ROUTES.Buyer}>Buyers</Link>{" "}
                  </li>

                  <li
                    className={
                      location.pathname === ROUTES.About ? "active" : ""
                    }
                  >
                    <Link to={ROUTES.About}>About Us</Link>{" "}
                  </li>
                  <li
                  // className={
                  //   location.pathname === ROUTES.ContactUs ? "active" : ""
                  // }
                  >
                    {/* <Link to={ROUTES.Home}>Blog</Link>{" "} */}
                    <a
                      href="https://sportstechmatch.medium.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </a>
                  </li>

                  <li
                    className={
                      location.pathname === ROUTES.ContactUs ? "active" : ""
                    }
                  >
                    <Link to={ROUTES.ContactUs}>Contact Us</Link>{" "}
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </IonToolbar>
    </IonHeader>
    // </IonContent>
  );
};

export default withRouter(Header);
