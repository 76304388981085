import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  isRegistered: "",
  isEmailVerified: "",
  isLoggedIn: "",
  message: "",
  authData: {},
  status: null,
  insertId: undefined,
  checkIsPayment: localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData")).is_payment
    : null,
};

export const authReducer = (state = initialState, action) => {
  // console.log("action",action)
  switch (action.type) {
    case ACTION_TYPE.SIGNUP:
      return {
        ...state,
        status: action.payload.data.status,
        message: action.payload.data.message,
        insertId: action.payload.data.insertId,
      };
    case "EMAILVERIFICATION":
      return {
        ...state,
        isEmailVerified: action.payload.data.message,
        isRegistered: "",
      };
    case ACTION_TYPE.VERIFY_EMAIL:
      return {
        ...state,
        message: action.payload.data.message,
        status: action.payload.data.status,
      };
    case ACTION_TYPE.LOGIN:
      localStorage.setItem(
        APPLICATION_CONSTANTS.USER_DATA,
        JSON.stringify(action.payload.data.user)
      );
      localStorage.setItem(
        APPLICATION_CONSTANTS.TOKEN,
        action.payload.data.user.token
      );
      console.log("action.payload.data.user", action.payload.data.user);
      return {
        ...state,
        authData: action.payload.data.user,
      };

    case ACTION_TYPE.LOGIN_AS_USER:
      localStorage.setItem(
        APPLICATION_CONSTANTS.USER_DATA,
        JSON.stringify(action.payload.data.user)
      );
      localStorage.setItem(
        APPLICATION_CONSTANTS.TOKEN,
        action.payload.data.user.token
      );
      console.log("action.payload.data.user", action.payload.data.user);
      return {
        ...state,
        authData: action.payload.data.user,
      };

    case "CHANGE_PASSWORD":
      return {
        ...state,
        message: action.payload.data.message,
        status: action.payload.data.status,
      };
    case "CHECK_PAYMENT":
      return {
        ...state,
        checkIsPayment: action.checkIsPayment,
      };

    case ACTION_TYPE.FORGET_PASSWORD:
      return {
        message: action.payload.data.message,
        status: action.payload.data.status,
      };
    case ACTION_TYPE.RESET_PASSWORD:
      return {
        message: action.payload.data.message,
        status: action.payload.data.status,
      };
    case ACTION_TYPE.CONTACT_US:
      return {
        message: action.payload.data.message,
        status: action.payload.data.status,
      };

    case "CLEAR_LOGIN":
      return {
        ...state,
        message: "",
        status: null,
      };
    case "LOGOUT_USER":
      // let cartItemsRestore = localStorage.getItem("cartItems");
      // localStorage.clear();
      // localStorage.setItem("cartItems", cartItemsRestore);
      return {
        ...state,
        // isRegistered: "",
        // isEmailVerified: "",
        // isLoggedIn: "",
        message: "",
        // authData: {},
        cartItems: [],
      };
    default:
      return state;
  }
};
