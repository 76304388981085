// export const BASE_URL =
//   "https://wxhivybbw4.execute-api.us-east-2.amazonaws.com/development"; //staging old

// export const BASE_URL =
//   "https://9upep677h0.execute-api.us-east-1.amazonaws.com/development"; //staging new

// export const BASE_URL =
//   "https://4e86-2401-4900-1c8f-3a1c-959d-56eb-515a-b45b.ngrok-free.app";

// export const BASE_URL = "http://localhost:5000";

//
// export const BASE_URL = "http://192.168.1.21:5000";
// export const BASE_URL = "http://3.80.152.175:5000";

export const BASE_URL =
  "https://dvtcmro9ch.execute-api.us-east-1.amazonaws.com/development"; //production

export const ENDPOINTS = {
  LOGIN: "/api/v1/userAuth/login",
  SIGNUP: "/api/v1/userAuth/signup",
  VERIFY_EMAIL: "/api/v1/userAuth/verifyCode",
  FORGET_PASSWORD: "/api/v1/userAuth/forgotPassword",
  RESET_PASSWORD: "/api/v1/userAuth/resetPassword",
  EDIT_PROFILE: "/api/v1/profile",

  MATCH_TOKEN: "/api/v1/userAuth/matchToken",
  USER_POSTS: "api/v1/post/getMyPosts/",
  DELETE_POSTS: "api/v1/post/disable/",

  // support admin
  LOGIN_AS_USER: "/api/v1/userAuth/loginAsUser",
  LOGOUT_AS_USER: "/api/v1/userAuth/logoutAsUser",

  //Profile APIs
  GET_PROFILE_DETAILS: "/api/v1/profile/getProfileDetails/",
  UPDATE_PROFILE_DETAILS: "/api/v1/profile/profileDetails/",
  CHANGE_COVER_IMAGE: "/api/v1/profile/uploadCoverImage/",
  CHANGE_PROFILE_IMAGE: "/api/v1/profile/uploadProfileImage/",
  UPLOAD_POST: "api/v1/post/upload",

  //Get Dynamic Pages
  GET_PAGES_CONTENT: "api/v1/admin/pages/slug/",
  GET_RFI_BY_ID: "api/v1/admin/searchRFIById",

  CONTACT_US: "/api/v1/contact/contact-us",
  NEWSLETTER: "/api/v1/contact/newsletter",
  //Search
  GET_FILTERS: "/api/v1/getNewFilterValues",
  GET_PRODUCTS: "/api/v1/newSearch",

  GET_BUYER_PRODUCT_BY_ID: "/api/v1/buyer/productByID",
  //vender manage list
  GET_LIST: "/api/v1/manageListing",
  ADD_EDIT_VENDOR_USER: "/api/v1/addEditVendorUser",
  DELETE_VENDOR_USER: "/api/v1/deleteVendorUser",
  GET_VENDOR_USERS: "/api/v1/getVendorUsers",
  GET_LATEST: "/api/v1/latestProducts",
  DEL_LIST: "/api/v1/deleteListing",
  GET_FILTER: "/api/v1/getNewFilterValues",
  GET_PRODUCTS_BY_CATEGORIES: "/api/v1/getProductsByCategories",
  PRODUCT_BY_ID: "/api/v1/vendor/productByID",
  UPDATE_LIST: "/api/v1/updateProduct",
  ADD_LIST: "/api/v1/addProduct",
  CONTACT_VENDOR: "/api/v1/contact/contact-vendor",
  UPDATE_PROFILE: "/api/v1/profile/updateProfile",
  // PRODUCT_BY_ID: "/api/v1/vendor/productByID",
  //adminPanel
  GET_VENDOR_LIST: "/api/v1/admin/newVendor",
  GET_APPROVED_VENDOR_LIST: "/api/v1/admin",
  GET_APPROVED_PAID_VENDOR_LIST: "/api/v1/admin/paidVendorsList",
  GET_APPROVED_BUYER_LIST: "/api/v1/admin/buyersList",
  SEARCH_ID: "/api/v1/admin/searchById",
  ACCEPT: "/api/v1/admin/updateVendor",
  CHANGE_USER_STATE: "/api/v1/admin/changeUserState",
  GET_PRODUCT: "/api/v1/admin/newProduct",
  GET_CONTACT: "/api/v1/admin/contactCreds",
  SEARCH_ADMIN: "/api/v1/admin/by/search",

  MARK_VENDOR_PAID: "/api/v1/admin/markVendorPaid",
  CHANGE_VENDOR_ADMIN: "/api/v1/admin/changeVendorAdmin",

  APPROVE_PRODUCT: "/api/v1/admin/updateProduct",
  GET_PRODUCT_BY_ID: "/api/v1/admin/searchProductById",
  GET_RFI_ADMIN: "/api/v1/admin/getRFI",

  GET_APPROVED_RFI_ADMIN: "/api/v1/admin/getApprovedRFI",
  APPROVE_RFI_ADMIN: "/api/v1/admin/rfi/approve",
  REJECT_RFI_ADMIN: "/api/v1/admin/rfi/reject",

  CREATE_RFI: "/api/v1/rfi/createRFI",
  UPDATE_RFI: "/api/v1/rfi/updateRFI",
  GET_RFI: "/api/v1/rfi/getRFI",
  GET_RFI_RESPONSE: "/api/v1/rfi/getRFIResponse",
  QUESTION_LIST: "/api/v1/rfi/questionsList",
  RESPOND_RFI: "/api/v1/rfi/RFIRespond",
  GET_ALL_PRODUCTS: "/api/v1/rfi/getProducts",
  SAVE_RFI_RESPONSE: "/api/v1/rfi/saveRFIResponse",
  GET_SAVED_RESPONSE: "/api/v1/rfi/getSavedResponse",
  GET_BUYER_EVALUATION_DATA: "/api/v1/rfi/getSavedResponses",
  CONNECT_TO_VENDORS: "/api/v1/rfi/connectToVendors",
  GET_APPROVED_VENDORS: "/api/v1/rfi/getApprovedVendorList",
  GET_APPROVED_VENDORS_COMPANY: "/api/v1/rfi/getApprovedVendorCompanyList",

  // tenders
  ADD_EDIT_TENDER: "/api/v1/tenders/addEditTender",
  GET_ALL_TENDERS: "/api/v1/tenders/getAllTenders",
  UPDATE_VISITED_TENDERS: "/api/v1/tenders/updateVisitedTenders",
  PUBLISH_TENDER: "/api/v1/tenders/publishTender",
  GET_SECTORS: "/api/v1/tenders/getSectors",
  GET_CATEGORIES: "/api/v1/tenders/getCategories",
  GET_PUBLISHED_TENDERS: "/api/v1/tenders/getPublishedTenders",
  GET_ACCESSED_TENDERS: "/api/v1/tenders/getAccessedTenders",

  // Rating & Review
  ADD_EDIT_RATING_REVIEW_REQUEST:
    "/api/v1/ratingReview/addEditRatingReviewRequest",
  GET_RATING_REVIEW_REQUEST: "/api/v1/ratingReview/getRatingReviewRequest",
  GET_MY_RATING_REVIEW: "/api/v1/ratingReview/getUserRatingReview",
  GET_ALL_RATING_REVIEW: "/api/v1/ratingReview/getAllRatingReviews",
  GET_BUYER_RATING_REVIEW: "/api/v1/ratingReview/getBuyerRatingReviews",
  SUBMIT_RATING_REVIEW: "/api/v1/ratingReview/saveReviewAndRate",
  GET_PENDING_RATING_REVIEW: "/api/v1/ratingReview/getPendingRatingReview",
  GET_RATING_REVIEW_INFO: "/api/v1/ratingReview/getRatingReviewInfo",
  GET_RATING_REVIEW_GIVEN_INFO: "/api/v1/ratingReview/getRatingReviewGivenInfo",
  RATING_REVIEWS_BY_PORDUCT:
    "/api/v1/ratingReview/getAllRatingReviews/byProduct",
  GET_TOP_RATING_REVIEW: "/api/v1/ratingReview/getTop10reviews",
  UPDATE_RATING_REVIEW_STATUS: "/api/v1/ratingReview/updateRatingReviewStatus",
  GET_APPROVED_RATING_REVIEW: "/api/v1/ratingReview/getApprovedRatingReview",
  GET_RATING_REVIEW_REQUEST_BY_ID: "/api/v1/ratingReview/getRatingReviewById",
};

export const ROUTES = {
  //   <<-- Website Routes -->>

  Home: "/",
  About: "/about-us",
  Buyer: "/buyer",
  VendorList: "/vendor-list/:id",
  formOne: "/form-one",
  formTwo: "/form-two",
  formThree: "/form-three",
  formFour: "/form-four",
  RfiDashboard: "/rfi-dashboard",
  RfiResponse: "/rfi-response",
  UpdateRFI_Admin: "/edit-rfi-admin/:id",
  UpdateRFI_AdminNotId: "/edit-rfi-admin/",
  BuyerLanding: "/buyer-landing",
  ReviewSubmitted: "/review-submitted",
  InvalidUserReview: "/invalid-user-review",

  BuyerDashboard: "/buyer-dashboard",
  ContactUs: "/contact-us",

  Blog: "/blog",
  BlogDes: "/blog/:id",
  DYNAMIC_PAGE: "/pages/:pageId",
  TERMS_AND_CONDITION: "/terms-and-condition",
  PRIVATE_POLICY: "/privacy-policy",

  // <<-- Auth Routes -->>

  SignUp: "/sign-up",
  SignUpNew: "/sign-up-new",
  Login: "/login",
  ForgetPassword: "/forgot-password",
  EmailVerify: "/verify",
  ResetPassword: "/reset-password",

  //  <<-- APP Routes -->>

  Profile: "/profile",
  Payment: "/payment",

  Vendors: "/vendors",
  ManagePaidVendors: "/manage-paid-vendors",
  Search: "/marketplace",
  VendorProfile: "/vendor-profile",
  ProductDetails: "/product/:id",
  ManageList: "/manage-list",
  CategorySearch: "/category/:id",
  EditVendor: "/edit-vendor/:id",
  Saved: "/saved-rfi/:id",
  AddVendor: "/add-vendor",
  ContactVendor: "/contact-vendor",
  // ContactVendor: "/contact/vendor/:id/product/:id",
  VendorPage: "/vendor-page",
  MultiStepForm: "/multi-step-form",

  // paid vendors
  ViewTenders: "/view-tenders",
  //ADMINPANEL
  Approve: "/approve-vendor",
  ApproveRFI: "/approve-rfi",
  ViewVendor: "/view-vendor",
  ViewRFI: "/view-rfi",
  Tenders: "/tenders",

  ViewProduct: "/view-product",
  Vendor: "/admin-vendor/:id",
  Product: "/admin-product/:id",
  AdminProfile: "/admin-profile",
  CREATE_RFI: "/createRFI",
  BUYER_RFI_EVALUATION: "/buyer-rfi-evaluation",
  VENDOR_JOURNEY_ONE: "/vendor-journey-one",
  RFI_DETAILS: "/rfi-details/:id",
  RFI_DETAILSnotId: "/rfi-details/",

  // ADMIN SUPPORT PANEL
  AdminSupportProfile: "/admin-support-profile",
  ViewVendors: "/view-vendors",
  ViewBuyers: "/view-buyers",
  RequestReview: "/request-review",
  CreateRequest: "/create-request",
  ViewRating: "/view-rating",
  ApproveNewReview: "/approve-review",
  BuyerReview: "/buyer-review",
  ViewAllReview: "/view-all-review",
  RatingInfo: "/rating-info",
  AllReviews: "/all-reviews",
  ProductRating: "/product-rating",
  AdminRatingInfo: "/admin-rating-info",
};

export const Countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const MultiStepFormLabels = {
  BACKGROUND: "/form-one",
  REQUIREMENT: "/form-two",
  SELECT_VENDORS: "/form-three",
  REVIEW_AND_SUBMIT: "/form-four",
};

export const RATING_REQUEST_STATUS = {
  EMAIL_SENT: {
    enumVal: "EMAIL_SENT",
    text: "Email Sent",
    style: {
      color: "green",
    },
  },
  SAVED: {
    enumVal: "SAVED",
    text: "Saved as Draft",
    style: {
      color: "green",
    },
  },
  APPROVED: {
    enumVal: "APPROVED",
    text: "Approved",
    style: {
      color: "green",
    },
  },
  REJECTED: {
    enumVal: "REJECTED",
    text: "Rejected",
    style: {
      color: "red",
    },
  },
  PENDING: {
    enumVal: "PENDING",
    text: "Pending",
    style: {
      color: "green",
    },
  },
  HIDDEN: {
    enumVal: "HIDDEN",
    text: "Deleted",
    style: {
      color: "red",
    },
  },
};
