function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

function wordCount(str) {
  return str.split(" ").length;
}

export const ValidateRequestForm = (formData) => {
  console.log("formData", formData);
  const {
    ratingAndReview,
    reviewHeadline,
    rate,
    anonymity,
    rating_requested_id,
    productId,
    isTncAccepted,
  } = formData || {};

  let error = {
    ratingAndReview: [],
    reviewHeadline: [],
    rate: [],
    anonymity: [],
    rating_requested_id: [],
    productId: [],
    isTncAccepted: [],
  };
  let isFormValid = true;

  if (isBlank(ratingAndReview) === true) {
    isFormValid = false;
    error.ratingAndReview = ["This field is required!"];
  } else if (wordCount(ratingAndReview) > 500) {
    isFormValid = false;
    error.ratingAndReview = ["Maximum word limit is 500"];
  }

  if (isBlank(reviewHeadline) === true) {
    isFormValid = false;
    error.reviewHeadline = ["This field is required!"];
  } else if (wordCount(reviewHeadline) > 75) {
    isFormValid = false;
    error.reviewHeadline = ["Maximum word limit is 75"];
  }
  if (isBlank(rate) === true) {
    isFormValid = false;
    error.rate = ["This field is required!"];
  }
  if (!Object.keys(formData).includes("anonymity")) {
    isFormValid = false;
    error.anonymity = ["This field is required!"];
  }
  if (isBlank(rating_requested_id) === true) {
    isFormValid = false;
    error.rating_requested_id = ["Invalid Review URL"];
  }
  if (isBlank(productId) === true) {
    isFormValid = false;
    error.productId = ["Invalid Review URL"];
  }
  if (isBlank(isTncAccepted) === true) {
    isFormValid = false;
    error.isTncAccepted = ["This field is required!"];
  }

  return { isFormValid, error };
};
