import { IonContent, IonImg } from "@ionic/react";
import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";
import { Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction, VendorAction } from "../../ReduxStore/Actions";
import Slider from "react-slick";
import { getTopReviews } from "../../ReduxStore/Actions/ratingReviewActions";
import logoStm from "../../images/logoStm.png";

const Home = () => {
  let dispatch = useDispatch();
  const { topRatingsAndReview } = useSelector(
    (state) => state.RatingReviewReducer
  );
  console.log("topRating And Review", topRatingsAndReview);
  const handleScroll = (e) => {
    console.log("scrolled", e.detail.scrollTop);

    if (e.detail.scrollTop > 10) {
      dispatch(HomeAction.setHeaderClass("blue"));
    } else {
      dispatch(HomeAction.setHeaderClass("trans"));
    }
  };
  let latestProdData = useSelector((state) => state.vendorReducer.latestProd);
  console.log("latestProdData", latestProdData);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("trans"));
    (async () => {
      await dispatch(VendorAction.getLatestProducts());
      await dispatch(getTopReviews());
    })();
  }, []);

  console.log("topRatingsAndReview", topRatingsAndReview);
  var settings = {
    dots: true,
    infinite: topRatingsAndReview && topRatingsAndReview.length > 3,
    // infinite: false,
    autoplay: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite:
            topRatingsAndReview && topRatingsAndReview.length > 2 && true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  // };
  return (
    <IonContent scrollEvents={true} onIonScroll={handleScroll}>
      <section className="HomeTop loginHomePage">
        <div className="homeContent">
          <h2 className="headingMain">SIMPLIFYING SPORTS TECH PROCUREMENT</h2>
          <div className="subTagline">
            <h4>
              We help organisations at all levels of sport to find
              <br /> the right solutions. Submit your requirements anonymously
              and <br /> save time speaking to the wrong vendors.
            </h4>
          </div>
          <div className="bannerBtn">
            <Link to={ROUTES.Buyer}>
              {" "}
              <button className="marketplace">Find Right Vendor</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="HomeSection3 ">
        <div className="buyersVenders">
          <h2>
            Are you a sports tech vendor? Get your solution listed.
            <br /> Get discovered
          </h2>

          <div className="text-center buyerVenderbutton">
            <Link to={ROUTES.VendorPage}>
              <button className="buyersVendersBtn">Vendors</button>
            </Link>
          </div>
        </div>
      </section>

      <section className="HomeSection32 ">
        <h2 className="heading" style={{ textAlign: "center" }}>
          Benefits
        </h2>
        <div className="col-md-12 col col-sm-12 Benefits d-block d-sm-flex d-md-flex d-lg-flex mb-5">
          <div className="text-center col-md-3 col-sm-3  ">
            <img className=" ben" src={require("../../images/Spam.png")}></img>
            <h3>Buyers don't get spammed</h3>
          </div>
          <div className="text-center col-md-3 col-sm-3">
            <img className=" ben" src={require("../../images/time.png")}></img>
            <h3>Buyers save time</h3>
          </div>
          <div className="text-center col-md-3 col-sm-3 ">
            <img
              className=" ben"
              src={require("../../images/newbusiness.png")}
            ></img>
            <h3>Vendors win new business</h3>
          </div>
          <div className="text-center col-md-3 col-sm-3">
            <img
              className=" ben"
              src={require("../../images/feedback.png")}
            ></img>
            <h3>Vendors get product feedback</h3>
          </div>
        </div>
      </section>
      <section className="HomeSection4">
        <div className="container4">
          <h2 className="heading">Featured Vendors</h2>
          <div className="container featrContainer newContainer">
            {/* <div className="col-md-12 col col-sm-12 info justify-content-space-between d-flex"> */}
            <div className="info ">
              {latestProdData &&
                latestProdData.data &&
                latestProdData.data.map((data) => {
                  return (
                    <div className="latest_info text-center">
                      <div className="block">
                        <div className="blockhead">
                          <img src={data.logoUrl} alt="company" />
                          <h3>{data.companyName}</h3>
                        </div>
                        <Divider variant="middle" />
                        <div className="blockbody">
                          <h4>{data.productName}</h4>
                          <div>
                            <p>{data.description}</p>
                          </div>
                          <Link to={`/product/${data.id}`}>
                            <button className="boxBtn">More Info</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      {topRatingsAndReview && topRatingsAndReview.length > 0 && (
        <section className="homeSectionFive homePageSlider">
          <div className="container">
            <h2 className="heading">Latest Ratings and Reviews</h2>
            {
              <Slider
                {...{
                  ...settings,
                  slidesToShow:
                    topRatingsAndReview.length < 3
                      ? topRatingsAndReview.length
                      : 3,
                }}
              >
                {topRatingsAndReview.map((data, index) => {
                  return (
                    <div key={index}>
                      <div className="reviewBox">
                        <div className="innerReviewContainer">
                          <div className="productImg">
                            <IonImg
                              src={
                                data?.logoUrl.includes(
                                  "vendor-placeholder-logo.png"
                                )
                                  ? logoStm
                                  : data.logoUrl
                              }
                              alt="company-img"
                            />
                            {/* <IonImg src={logoStm} alt="company-img" /> */}
                          </div>
                          {/* <h2>{data?.review_headline}</h2> */}
                          <h2>
                            <Link
                              to={{
                                pathname: `/product/${data?.product_id}`,
                              }}
                            >
                              {data?.product_name}
                            </Link>
                          </h2>
                          <div className="reviewContainer">
                            <div className="reviewStar">
                              {Array.from(Array(data?.rating).keys()).map(
                                (d) => {
                                  return (
                                    <div>
                                      <svg
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                          // fill="#D9D9D9"
                                          fill="yellow"
                                        />
                                      </svg>
                                    </div>
                                  );
                                }
                              )}
                              {Array.from(Array(5 - data?.rating).keys()).map(
                                (d) => {
                                  return (
                                    <div>
                                      <svg
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                                          fill="#D9D9D9"
                                        />
                                      </svg>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                          <div className="verifiedContainer">
                            <div>
                              <div>
                                <img src="/assets/verified.png" />
                              </div>
                              <p>Verified by</p>
                            </div>
                            <div>
                              <img src="/assets/stmLogo.png" />
                            </div>
                          </div>
                          {/* <div className="reviewInfoContainer">
                            <div>
                              <p>Vendor Name</p>
                              <p>:</p>
                              <p>{data?.vendor_name}</p>
                            </div>
                          </div> */}
                          <div className="btmPart">
                            <h2 style={{ fontSize: "1rem" }}>
                              {data ? data.review_headline : "Anonymous"}
                            </h2>
                            <p>
                              {/* <span>{data?.review}</span>{" "} */}
                              {data?.review.substring(0, 50) + "... "}
                              <Link to={`/product/${data?.product_id}`}>
                                <button className="moreReviewBtn"> more</button>
                              </Link>
                              {/* <ScrollLink
                                className="moreReviewBtn"
                                smooth={true}
                                // duration={500}
                                to={`/product/${data?.product_id}`}
                              >
                                More page
                              </ScrollLink> */}
                            </p>
                            <p>
                              {data?.anonymity !== "true"
                                ? data?.companyName
                                : `${data?.organisationType}, ${data?.companyHq}`}{" "}
                              (
                              {(() => {
                                const date = new Date(data?.rating_given_at);
                                // dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                                return `${date.toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                })}`;
                              })()}
                              )
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            }
          </div>
        </section>
      )}

      <section className="HomeSection2 abtusdiv">
        <div className="container newContainer">
          <h2 className="heading">About Us</h2>
          <div className="col-md-7 col-sm-12 col1">
            <p className="abtP">
              {/* We are on a mission to connect sport with technology to drive
              innovation​. We help sports executives working in both the
              business and performance side of sport to find and acquire the
              right digital technology solutions to solve their problems. */}
              We are on a mission to simplify sports tech procurement. We exist
              to help sports federations, leagues, clubs, broadcasters, brands
              and venues at all levels to quickly find and connect with the
              right tech vendors to deliver on their requirements.
            </p>
            <p className="abtP">
              {" "}
              {/* We do this together with the best sports technology vendors
              available in the market. Once approved by our team, vendors manage
              their own listings and use SportsTech Match to keep sports
              executives updated as they evolve their product offerings. */}
              We eliminate the time wasted searching for and speaking to the
              wrong vendors and we eliminate spam emails (all searches you run
              through SportsTech Match are conducted anonymously).
            </p>

            <Link to={ROUTES.About}>
              <button className="theme">Read More</button>
            </Link>
          </div>
          <div className="col-md-5 col-sm-12 col2">
            <img
              alt="image"
              className="abtImg"
              src={require("../../images/HomeImages/Aboutusimage.png")}
            />
          </div>
        </div>
      </section>

      <Footer></Footer>
    </IonContent>
  );
};

export default Home;
