import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonToast,
  IonSpinner,
} from "@ionic/react";
import {
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  TextField,
  Link,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import "./Vendors.css";
import ToolTip from "../tooltip/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { VendorAction, HomeAction } from "../../ReduxStore/Actions/index";
import { ValidateVendorForm } from "./Validation";

import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

import { ROUTES } from "../../Services";

const EditVendors = (props) => {
  const dispatch = useDispatch();

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastError, setToastError] = useState();
  const [product, setProduct] = useState({
    name: "",
    description: "",
    selected_clients: "",
    additional_info: "",
  });
  const [sectorData, setSectorData] = React.useState();
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [AllSports, setAllSports] = useState(false);

  const [isHardware, setIsHardware] = useState(false);

  const [errorData, setError] = React.useState();
  const [redirectToast, setRedirectToast] = useState(false);
  const [state, setState] = useState({
    productType: [],
    productStage: [],
    timeToImplement: [],
    category: [],
    softwaredev: [],
    sector: [],
    Sports: [],
    levelOfSports: [],
    targetCustomer: [],
    proofOfConcept: {
      value: "",
      checked: "false",
    },
  });
  const productDetails = useSelector(
    (state) => state.vendorReducer.productDetails
  );
  let CategoryDetails = useSelector(
    (state) => state.vendorReducer.categoryDetails
  );

  // remove sport non tech and non sport from category selection
  // temporary fix because production database cannot be changed to remove
  // product category relationship
  CategoryDetails = CategoryDetails &&
    Object.keys(CategoryDetails).length > 0 && {
      ...CategoryDetails,
      Categories: CategoryDetails.Categories.filter(
        (cat) => cat.filterValueId !== 122 && cat.filterValueId !== 123
      ),
    };
  console.log("category details in edit product page", CategoryDetails);
  const getFilters = useSelector((state) => state.vendorReducer.filter);
  const getpoc = useSelector(
    (state) => state.vendorReducer.categoryDetails["Pilot Project"]
  );

  const getSports = useSelector(
    (state) => state.vendorReducer.categoryDetails["Sports"]
  );
  const getTimeToImplement = useSelector(
    (state) =>
      state.vendorReducer.categoryDetails["Development & Implementation time"]
  );
  const getSector = useSelector(
    (state) => state.vendorReducer.categoryDetails["Sectors"]
  );

  const getProductType = useSelector(
    (state) => state.vendorReducer.categoryDetails["Product Type"]
  );
  const getProductStage = useSelector(
    (state) => state.vendorReducer.categoryDetails["Stage"]
  );
  const getSportsTier = useSelector(
    (state) => state.vendorReducer.categoryDetails["Sports Tier"]
  );
  const getStage = useSelector(
    (state) => state.vendorReducer.categoryDetails["Stage"]
  );
  const getSoftwareDeployment = useSelector(
    (state) => state.vendorReducer.categoryDetails["Software Deployment"]
  );
  const getTargetCustomer = useSelector(
    (state) => state.vendorReducer.categoryDetails["Target Customer"]
  );

  let filter = useSelector((state) => state.vendorReducer.filter);

  // remove sport non tech and non sport from category selection
  filter = filter &&
    Object.keys(filter).length > 0 && {
      ...filter,
      Categories: filter.Categories.filter(
        (cat) => cat.id !== 122 && cat.id !== 123
      ),
    };

  console.log("filter disguise category", filter);

  const authData = useSelector((state) => state.authReducer.authData);

  const proofOfConcept = useSelector(
    (state) => state.vendorReducer.filter["Proof Of Concept"]
  );

  useEffect(() => {
    setState({
      ...state,
    });
  }, [state.proofOfConcept]);
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(VendorAction.getfilter());
      await dispatch(
        VendorAction.ProductbyId({ productId: props.match.params.id })
      );
    })();

    return () => {
      setState({});
      setProduct({
        ...product,
        name: "",
        description: "",
        selected_clients: "",
        additional_info: "",
      });
    };
  }, []);
  useEffect(() => {
    setProduct({
      ...product,
      name: productDetails?.productName,
      description: productDetails?.description,
      selected_clients: productDetails?.selected_clients,
      additional_info: productDetails?.additional_info,
    });

    console.log("nameand description uppdate", productDetails, product);
  }, [productDetails]);
  let parray = [];
  let sportsarray = [];
  let productarray = [];
  let productStageArray = [];
  let levelOfSportsarray = [];
  let softwarearray = [];
  let targetCustomerarray = [];
  let timeToImplement = [];
  let sectorarray = {
    value: [],
    name: [],
  };
  let stagearray = [];
  let Pocarray;
  let pocValue;
  let Tacarray;
  let taCValue;
  let privacyPolicyarray;
  let privacyPolicyValue;
  let newsLetterArray;
  let newsLetterValue;
  let k = "Yes";
  useEffect(() => {
    CategoryDetails["Categories"] &&
      CategoryDetails["Categories"].map(
        (user) => (parray = [...parray, user.filterValueId])
      );
    getProductType &&
      getProductType.map(
        (user) => (productarray = [...productarray, user.filterValueId])
      );
    getProductStage &&
      getProductStage.map(
        (user) =>
          (productStageArray = [...productStageArray, user.filterValueId])
      );
    getSports &&
      getSports.map(
        (user) => (sportsarray = [...sportsarray, user.filterValueId])
      );
    getSportsTier &&
      getSportsTier.map(
        (user) =>
          (levelOfSportsarray = [...levelOfSportsarray, user.filterValueId])
      );
    getSoftwareDeployment &&
      getSoftwareDeployment.map(
        (user) => (softwarearray = [...softwarearray, user.filterValueId])
      );
    getTimeToImplement &&
      getTimeToImplement.map(
        (user) => (timeToImplement = [...timeToImplement, user.filterValueId])
      );
    getStage &&
      getStage.map(
        (user) => (stagearray = [...stagearray, user.filterValueId])
      );
    getSector &&
      getSector.map(
        (user) =>
          (sectorarray = {
            name: [...sectorarray.name, user.filterValue],
            value: [...sectorarray.value, user.filterValueId],
          })

        // (user) => (sectorarray = [...sectorarray, user.filterValueId])
      );
    getTargetCustomer &&
      getTargetCustomer.map(
        (user) =>
          (targetCustomerarray = [...targetCustomerarray, user.filterValueId])
      );
    console.log(getpoc, "poc");
    getpoc &&
      getpoc.map(
        (user) => (
          user.filterValue == "Yes"
            ? ((Pocarray = "true"), (pocValue = user.filterValueId))
            : ((Pocarray = "false"), (pocValue = user.filterValueId)),
          console.log("llp", user.filterValue, k)
        )
      );

    // console.log(Pocarray, "komal");

    setState({
      ...state,
      category: parray,
      productType: productarray,
      Sports: sportsarray,
      productStage: stagearray,
      softwaredev: softwarearray,
      timeToImplement: timeToImplement,
      levelOfSports: levelOfSportsarray,
      sector: sectorarray.value,

      targetCustomer: targetCustomerarray,
      productStage: productStageArray,
      proofOfConcept: {
        value: pocValue,
        checked: Pocarray,
      },
    });
    setSectorData(sectorarray.name);
  }, [getFilters, getpoc, proofOfConcept, Pocarray]);
  console.log(sectorData, "l0l");

  let sectorArray = {
    value: [],
    name: [],
  };
  let j = 0;
  const handleClose = () => {
    state.category?.includes(72) ||
    state.category?.includes(73) ||
    state.category?.includes(74) ||
    state.category?.includes(75)
      ? (sectorArray = {
          name: [...sectorArray.name, "Athletes & Performance"],
          value: [...sectorArray.value, 22],
        })
      : (j = 5);
    state.category?.includes(76) ||
    state.category?.includes(77) ||
    state.category?.includes(78) ||
    state.category?.includes(79) ||
    state.category?.includes(80)
      ? (sectorArray = {
          name: [...sectorArray.name, "Fans & Digital Media"],
          value: [...sectorArray.value, 23],
        })
      : (j = 7);

    state.category?.includes(81) ||
    state.category?.includes(82) ||
    state.category?.includes(83) ||
    state.category?.includes(84)
      ? (sectorArray = {
          name: [...sectorArray.name, "Commercial"],
          value: [...sectorArray.value, 24],
        })
      : (j = 8);

    state.category?.includes(88) ||
    state.category?.includes(87) ||
    state.category?.includes(86)
      ? (sectorArray = {
          name: [...sectorArray.name, "Management & Operations"],
          value: [...sectorArray.value, 25],
        })
      : (j = 7);
    console.log(sectorArray, "sectorArrayjjjjjjjjjjjjjjjjjjjjjjj");
    setSectorData(sectorArray.name);
    setState({
      ...state,
      sector: sectorArray.value,
    });
  };
  const handleAllSports = () => {
    setAllSports(!AllSports);
    setState({
      ...state,
      ["Sports"]: [],
    });
    let e = [];
    if (!AllSports) {
      console.log("alllllsports");

      filter["Sports"] &&
        filter["Sports"].map((data) => {
          e.push(data.id);
        });
      console.log("all", e);
      setState({
        ...state,
        ["Sports"]: e,
      });
    } else {
      console.log("else of all sports");

      setState({
        ...state,
        ["Sports"]: [],
      });
    }
  };
  const handleChange = (event) => {
    console.log("ALLLLLLLLLLLLLL", event.target.value, AllSports);
    if (event.target.value.includes(119)) {
      return;
    }
    if (AllSports && event.target.name === "Sports") {
      return;
    }

    console.log(
      event.target.value.length,
      event.target.value,
      state.productType.length,
      "sell"
    );
    let k = 9;
    event.target.name === "category"
      ? state.category.length < 3
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 2
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 4);
    event.target.name === "productType"
      ? state.productType.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "productStage"
      ? state.productStage.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "timeToImplement"
      ? state.timeToImplement.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);

    if (
      event.target.name !== "productType" &&
      event.target.name !== "timeToImplement" &&
      event.target.name !== "productStage" &&
      event.target.name !== "category"
    ) {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleProductClose = () => {
    console.log("happy", state.productType);
    const k = state.productType;
    const j = k[0];
    console.log(j, k, "ppp");
    if (j === 18 || j == 19) {
      setIsEnabled(false);
      setIsHardware(false);
    } else {
      setIsEnabled(true);
      setIsHardware(true);

      setState({ ...state, softwaredev: [] });
    }
    console.log(isEnabled, "lalala");
  };

  const handleCheckbox1 = (event, e) => {
    let value;
    let eventcheck = "";
    console.log(e.detail.checked, "pocevent");
    event === true ? (eventcheck = "Yes") : (eventcheck = "No");
    proofOfConcept &&
      proofOfConcept.map(
        (user) => (
          console.log("mao worked1"),
          eventcheck === user.name ? (value = user.id) : null
        )
      );
    console.log(value, "value", e.target.name, "ayushi2");
    setState({
      ...state,

      [e.target.name]: { value: value, checked: event },
    });
    console.log(state, "pocstate");
  };

  const handleChangeDetails = (event) => {
    console.log("eventtt", event);
    setProduct({
      ...product,
      [event.target.name]: event.target.value,
    });
  };
  console.log(state, "ss");
  let pocValue1 = state.proofOfConcept.value;

  async function onSubmitForm(event) {
    event.preventDefault();
    event.stopPropagation();
    setRedirectToast(true);
    let newState = { ...state, ...product };
    const result = await ValidateVendorForm(newState);

    if (result?.isFormValid) {
      console.log("state", state);
      let params = {
        companyId: authData.companyId,
        vendorId: authData.id,
        productId: props.match.params.id,
        productDetails: {
          company_id: authData.companyId,
          name: product.name,
          description: product.description,
          selected_clients: product.selected_clients,
          additional_info: product.additional_info,
        },

        categoryIds: [
          ...state.productType,
          ...state.softwaredev,
          ...state.category,
          ...state.Sports,
          ...state.levelOfSports,
          ...state.targetCustomer,
          ...state.sector,
          ...state.timeToImplement,
          ...state.productStage,
          pocValue1,
        ],
      };
      const data = await dispatch(VendorAction.updateList(params));
      setToastMessage(data.message);
      setToastError(data.status);
      setToast(true);

      props.history.push(ROUTES.Vendors);
    } else {
      setRedirectToast(false);
      setError(result?.error);
      console.log("ERRRROORRRR DETAILS", result?.error);
      for (let i = 0; i < Object.keys(result.error).length; i++) {
        console.log("inside", Object.keys(result.error)[i]);
        if (Object.values(result.error)[i].length) {
          console.log("inside");
          // document
          //   .getElementById(`${Object.keys(result.error)[i]}1`)
          //   .scrollIntoView();
          break;
        }
      }
    }

    event.preventDefault();
  }

  return (
    <IonContent>
      <div className="quote">
        <div className="login-inner">
          <div className="login-head ml-5">
            <h2 className="relative">
              Edit Product Details
              <span className="tooltp-vd">
                <ToolTip message="If you are using ad blocking software, please turn it off to enable you to complete this form" />
              </span>
            </h2>
          </div>
          {toast === true ? (
            <IonToast
              isOpen={toast}
              message={toastMessage}
              onDidDismiss={() => setToast(false)}
              buttons={[
                {
                  text: "Close",
                  role: "cancel",
                  handler: () => {
                    setToast(false);
                  },
                },
              ]}
            />
          ) : null}
          <div className="login-body editProductsForm ">
            <div className="form-name">
              <h4>Basic product / service information:</h4>
            </div>
            <form>
              <IonGrid>
                <div style={{ display: "flex" }}>
                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <div className="form-group">
                        <IonLabel id="name1"> Name*</IonLabel>
                        <IonInput
                          type="text"
                          name="name"
                          value={product.name}
                          className="form-control"
                          onIonChange={(event) => {
                            handleChangeDetails(event);
                          }}
                        ></IonInput>
                        {errorData?.name[0] ? (
                          <p>{errorData?.name[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="12">
                      <div className="form-group">
                        <div className="d-flex">
                          <IonLabel id="description1">Description*</IonLabel>
                          <div
                            style={{ width: 25 }}
                            className="decriptionToolTip"
                          >
                            <ToolTip message="Please describe your product / service within 500 characters." />
                          </div>
                        </div>
                        <div className="edithea text-center">
                          <TextField
                            id="outlined-multiline-static"
                            className="messageBody"
                            multiline
                            name="description"
                            value={product.description}
                            inputProps={{ maxLength: 500 }}
                            onChange={(event) => {
                              handleChangeDetails(event);
                            }}
                            rows={4}
                            variant="outlined"
                          />

                          {errorData?.description[0] ? (
                            <p>{errorData?.description[0]}</p>
                          ) : null}
                        </div>
                      </div>
                    </IonCol>

                    <IonCol size-md="6" sizeSm="12" size-sm="12">
                      <div className="form-group">
                        <div className="d-flex">
                          <IonLabel id="productStage1">Stage*</IonLabel>
                          <div className="stageToolTip">
                            <ToolTip message="Please select the option which best reflects the product / service stage." />
                          </div>
                        </div>
                        <Select
                          placeholder="Type"
                          // className="p"
                          multiple
                          name="productStage"
                          value={state.productStage}
                          onChange={(e) => handleChange(e)}
                          input={<Input />}
                          renderValue={(selected) => {
                            let l = selected.length + " Product Stage Selected";

                            return l;
                          }}
                        >
                          {filter["Stage"] &&
                            filter["Stage"].map((name) => (
                              <MenuItem
                                key={name.id}
                                id={name.id}
                                value={name.id}
                              >
                                <Checkbox
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<RadioButtonCheckedIcon />}
                                  checked={
                                    state &&
                                    state.productStage &&
                                    // state.productStage.length > 1 &&
                                    state.productStage.indexOf(name.id) > -1
                                  }
                                />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {errorData?.productStage[0] ? (
                          <p>{errorData?.productStage[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                    <IonCol size-md="6" sizeSm="12" size-sm="12">
                      <div className="form-group">
                        <div className="d-flex">
                          <IonLabel id="productType1">Product Type*</IonLabel>
                          <div style={{ width: 25 }} className="productToolTip">
                            <ToolTip message="Please select the option which best reflects the product / service type. " />
                          </div>
                        </div>
                        <Select
                          placeholder="Type"
                          multiple
                          className="productType"
                          name="productType"
                          value={state.productType}
                          onChange={(e) => handleChange(e)}
                          onClose={handleProductClose}
                          input={<Input />}
                          renderValue={(selected) => {
                            let l = selected.length + " Product Type Selected";
                            console.log("opppp", selected);
                            return l;
                          }}
                        >
                          {filter["Product Type"] &&
                            filter["Product Type"].map((name) => (
                              <MenuItem
                                key={name.id}
                                id={name.id}
                                value={name.id}
                              >
                                <Checkbox
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<RadioButtonCheckedIcon />}
                                  checked={
                                    state &&
                                    state.productType &&
                                    state.productType.indexOf(name.id) > -1
                                  }
                                />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {errorData?.productType[0] ? (
                          <p>{errorData?.productType[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                    <IonCol size-md="6" sizeSm="12" size-sm="12">
                      <div className="form-group">
                        <div className="d-flex">
                          <IonLabel id="category1">Category* </IonLabel>
                          <div
                            style={{ width: 25 }}
                            className="categoryToolTip"
                          >
                            <ToolTip message="Please select a maximum of 3 categories per product / service" />
                          </div>
                        </div>

                        <Select
                          multiple
                          className="category"
                          name="category"
                          onClose={handleClose}
                          value={state.category}
                          onChange={(e) => handleChange(e)}
                          input={<Input />}
                          renderValue={(selected) => {
                            const l = selected.length + " category Selected";
                            return l;
                          }}
                        >
                          {filter["Categories"] &&
                            filter["Categories"].map((name) => (
                              <MenuItem
                                className="menutype"
                                key={name.id}
                                id={name.id}
                                value={name.id}
                              >
                                <Checkbox
                                  checked={
                                    state &&
                                    state.category &&
                                    state.category.indexOf(name.id) > -1
                                  }
                                />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {errorData?.category[0] ? (
                          <p>{errorData?.category[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                    <IonCol size-md="6" sizeSm="12" size-sm="12">
                      <div className="form-group">
                        <div className="d-flex">
                          <IonLabel id="sector1">Sector* </IonLabel>
                          <div style={{ width: 25 }} className="sectorToolTip">
                            <ToolTip message="Sector is pre-defined based on your category choices" />
                          </div>
                        </div>

                        <input className="sector" value={sectorData} disabled />
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </IonGrid>
            </form>
            <form>
              <IonGrid>
                <div style={{ display: "flex" }}>
                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <div className="form-name advance-info ">
                        <h4>Advanced product / service information:</h4>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="form-group">
                        <div className="d-flex">
                          <IonLabel id="softwaredev1">
                            Software Deployment*
                          </IonLabel>
                          <div style={{ width: 25 }}>
                            <ToolTip message="Please select relevant software deployment options if you indicated that your type of product / service is software" />
                          </div>
                        </div>
                        <Select
                          multiple
                          name="softwaredev"
                          value={state.softwaredev}
                          disabled={isEnabled}
                          // value={
                          //   isHardware ? ["Not Applicable"] : state.softwaredev
                          // }
                          onChange={
                            isHardware
                              ? console.log("")
                              : (e) => handleChange(e)
                          }
                          input={<Input />}
                          renderValue={
                            isEnabled
                              ? (selected) => {
                                  const l = "Not Applicable";
                                  return l;
                                }
                              : (selected) => {
                                  const l =
                                    selected.length +
                                    " Deployment Type Selected";
                                  return l;
                                }
                          }
                        >
                          {filter["Software Deployment"] &&
                            filter["Software Deployment"].map((name) => (
                              <MenuItem
                                key={name.id}
                                id={name.id}
                                value={name.id}
                              >
                                <Checkbox
                                  checked={
                                    state &&
                                    state.softwaredev &&
                                    state.softwaredev.indexOf(name.id) > -1
                                  }
                                />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {errorData?.softwaredev[0] ? (
                          <p>{errorData?.softwaredev[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>

                    <IonCol size="12" size-md="6">
                      <div className="form-group">
                        <IonLabel id="Sports1">Sports* </IonLabel>

                        <Select
                          multiple
                          name="Sports"
                          value={state.Sports}
                          onChange={(e) => handleChange(e)}
                          input={<Input />}
                          renderValue={(selected) => {
                            const l = selected.length + "  Sports Selected";
                            return l;
                          }}
                        >
                          <MenuItem value={119}>
                            <Checkbox
                              checked={
                                AllSports ||
                                (state &&
                                  state.Sports &&
                                  state.Sports.indexOf(119) > -1)
                              }
                              onClick={() => handleAllSports()}
                            />
                            <ListItemText primary="All Sports" />
                          </MenuItem>
                          {filter["Sports"] &&
                            filter["Sports"].map((name) => {
                              if (name.id === 119) {
                              } else
                                return (
                                  <MenuItem key={name.id} value={name.id}>
                                    <Checkbox
                                      checked={
                                        state &&
                                        state.Sports &&
                                        state.Sports.indexOf(name.id) > -1
                                      }
                                      disabled={AllSports}
                                    />
                                    <ListItemText primary={name.name} />
                                  </MenuItem>
                                );
                            })}
                        </Select>
                        {errorData?.Sports[0] ? (
                          <p>{errorData?.Sports[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>

                    <IonCol size-md="6" sizeSm="12" size-sm="12">
                      <div className="form-group">
                        <IonLabel id="levelOfSports1">
                          Level of Sports*
                        </IonLabel>

                        <Select
                          multiple
                          name="levelOfSports"
                          value={state.levelOfSports}
                          onChange={(e) => handleChange(e)}
                          input={<Input />}
                          renderValue={(selected) => {
                            const l =
                              selected.length + " Level Of Sports Selected";
                            return l;
                          }}
                        >
                          {filter["Sports Tier"] &&
                            filter["Sports Tier"].map((name) => (
                              <MenuItem key={name.id} value={name.id}>
                                <Checkbox
                                  checked={
                                    state &&
                                    state.levelOfSports &&
                                    state.levelOfSports.indexOf(name.id) > -1
                                  }
                                />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {errorData?.levelOfSports[0] ? (
                          <p>{errorData?.levelOfSports[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>

                    <IonCol size-md="6" sizeSm="12" size-sm="12">
                      <div className="form-group">
                        <IonLabel id="targetCustomer1">
                          Target Customer*{" "}
                        </IonLabel>

                        <Select
                          multiple
                          name="targetCustomer"
                          value={state.targetCustomer}
                          onChange={(e) => handleChange(e)}
                          input={<Input />}
                          renderValue={(selected) => {
                            const l = selected.length + " Target Type Selected";
                            return l;
                          }}
                        >
                          {filter["Target Customer"] &&
                            filter["Target Customer"].map((name) => (
                              <MenuItem
                                key={name.id}
                                id={name.id}
                                value={name.id}
                              >
                                <Checkbox
                                  checked={
                                    state &&
                                    state.targetCustomer &&
                                    state.targetCustomer.indexOf(name.id) > -1
                                  }
                                />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {errorData?.targetCustomer[0] ? (
                          <p>{errorData?.targetCustomer[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                  </IonRow>
                </div>

                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <div className="d-flex align-items-center">
                        <IonLabel>Existing Clients</IonLabel>
                        <div style={{ width: 25 }} className="clientsToolTip">
                          <ToolTip message="Please include a selection of your clients (optional) and separate each one with a comma." />
                        </div>
                      </div>
                      <IonInput
                        type="text"
                        name="selected_clients"
                        value={product.selected_clients}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChangeDetails(event);
                        }}
                      ></IonInput>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel id="timeToImplement1">
                        Time to Implement *
                      </IonLabel>

                      <Select
                        multiple
                        name="timeToImplement"
                        value={state.timeToImplement}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          const l = selected.length + "   Selected";
                          return l;
                        }}
                      >
                        {filter["Development & Implementation time"] &&
                          filter["Development & Implementation time"].map(
                            (name) => (
                              <MenuItem key={name.id} value={name.id}>
                                <Checkbox
                                  checked={
                                    state &&
                                    state.timeToImplement &&
                                    state.timeToImplement.length > 0 &&
                                    state.timeToImplement.indexOf(name?.id) > -1
                                  }
                                />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {errorData?.timeToImplement[0] ? (
                        <p style={{ position: "inherit" }}>
                          {errorData?.timeToImplement[0]}
                        </p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <div
                        className="d-flex align-items-center"
                        style={{ position: "relative" }}
                      >
                        <IonLabel>Additional information</IonLabel>
                        <div
                          style={{ width: 25 }}
                          className="additionalToolTip addToolTip"
                        >
                          <ToolTip message="Please use this to add information not yet shared that can be useful to buyers (optional). Character limit is 1000." />
                        </div>
                      </div>
                      <div className="edithead text-center">
                        <TextField
                          id="outlined-multiline-static"
                          className="messageBody"
                          multiline
                          name="additional_info"
                          value={product.additional_info}
                          onChange={(event) => {
                            handleChangeDetails(event);
                          }}
                          rows={4}
                          inputProps={{ maxLength: 1001 }}
                          // defaultValue=""
                          variant="outlined"
                        />
                        {errorData?.additional_info[0] ? (
                          <p>{errorData?.additional_info[0]}</p>
                        ) : null}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                <div id="checkboxforios" className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <IonCheckbox
                      className="custom-checkbox"
                      name="proofOfConcept"
                      value={state?.ProofofConcept}
                      checked={state?.proofOfConcept?.checked}
                      onIonChange={(e) => handleCheckbox1(e.detail.checked, e)}
                    />

                    <div
                      className="d-flex align-items-center "
                      style={{ width: "300px" }}
                    >
                      <IonLabel style={{ color: "blue" }} className="labelled">
                        {" "}
                        <a
                          href="https://sportstechmatch.medium.com/sportstech-match-pilot-proj ect-programme-47980578f62c"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <u>Pilot Project</u>
                        </a>
                      </IonLabel>
                      <p className="relative newPilotTooltip">
                        <span className="tooltp-vd">
                          <ToolTip message="Show buyers that you are open to running a pilot project with them." />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </IonGrid>

              <div className="button-name text-center">
                {redirectToast === false ? (
                  <IonButton
                    color="none"
                    className="btnsave"
                    onClick={(event) => {
                      onSubmitForm(event);
                    }}
                  >
                    save
                  </IonButton>
                ) : (
                  <IonSpinner></IonSpinner>
                )}
              </div>
              {errorData !== undefined &&
              errorData !== {} &&
              errorData !== null &&
              errorData !== [] &&
              !errorData?.additional_info[0] ? (
                <p className="text-center errormsg">
                  Please complete all mandatory fields
                </p>
              ) : null}
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default EditVendors;
