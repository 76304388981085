import { IonContent, IonLabel, IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction, RFIAction } from "../../ReduxStore/Actions";
import "./VendorJourneyOne.css";
import ToolTip from "../tooltip/Tooltip";
import { useParams } from "react-router";
import VendorJourneySecond from "../VendorJourneyFromTwo/VendorJourneyFromTwo";
import Modal from "../Dashboard/Modal/Modal";
import { ValidateSignUpForm } from "./Validation";
import { GetSavedResponse } from "../../ReduxStore/Actions/RFIActions";
import { ACTION_TYPE } from "../../Services";

const VendorJourneyOne = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [response, setResponse] = useState({});
  const [errorData, setError] = React.useState();

  console.log("formdata", response);

  let { rfiId } = useParams();
  const { rfiData } = useSelector((state) => state.RFIReducer);
  const { savedData } = useSelector((state) => state.RFIReducer);
  console.log("savedData savedData", savedData);

  let question = rfiData[0]?.questions?.split("|");

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
    dispatch(GetSavedResponse(rfiId));
    return () => {
      dispatch({ type: ACTION_TYPE.GET_SAVED_RESPONSE, payload: [] });
    };
  }, []);
  useEffect(() => {
    if (savedData && savedData.length > 0) {
      console.log(" iffffffffffffsavedData savedData", savedData);

      setFormData({
        productName: savedData[0]?.productId,
        open_question: savedData[0]?.open_question,
      });
      setResponse(savedData[0]?.question_response);
    }
  }, [savedData]);

  useEffect(() => {
    (async () => {
      dispatch(RFIAction.GetRFIResponse(rfiId));
    })();
  }, [rfiId]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleNext = async (event) => {
    event.preventDefault();
    let resp = { response };
    const result = await ValidateSignUpForm(resp, question);

    if (!result?.isFormValid) {
      setError(result?.error);
      console.log("result errorrr", result.error);
      return;
    }
    setCount(2);
  };
  const handleResponse = (event) => {
    setResponse({
      ...response,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = (val) => {
    setShowModal(val);
  };
  return (
    <IonContent>
      <div className="responseHeaderWrapper">
        <div className="headerLeft"></div>
        <div className="headerMiddle">
          <h1>
            <b>RFI Response</b>
          </h1>
        </div>
        <div className="headerRight"></div>
      </div>
      {count === 1 && (
        <div className="responseContentWrapper">
          <div className="responseRfiTitle">
            <h3>
              <b>RFI Title :</b> {rfiData[0]?.title}
            </h3>
          </div>
          <div className="responseRfiDeadline">
            <h3>
              <b>RFI Deadline :</b> {rfiData[0]?.rfiDeadline.split("T")[0]}
            </h3>
          </div>
          <div className="responseRfiOrgDesc">
            <h3>
              <b>Organisation Description:</b> {rfiData[0]?.organisation_desc}
            </h3>
          </div>
          <div className="responseRfiOppDesc">
            <h3>
              <b>Opportunity Description :</b>
              <div
                style={{ marginTop: "0.5rem" }}
                dangerouslySetInnerHTML={{
                  __html: rfiData[0]?.opportunity_desc,
                }}
              />
            </h3>
          </div>
          <div className="filterResponseHeading">
            <h3>
              <b>Filter Questions:</b>
            </h3>
            <p>
              <span>
                <ToolTip message="These questions represent the buyers key requirements and will  be used by the buyer to decide whether or not to connect with you." />
              </span>
            </p>
          </div>
          {question &&
            question.map((val, index) => {
              {
                console.log("response data", val, response[val]);
              }
              return (
                <div className="responseRfiFilterQues">
                  <p>
                    Q{index + 1}: {val}
                  </p>
                  <div>
                    <div className="responseRFICheckBoxes">
                      <input
                        type="radio"
                        className="custom-checkbox multi-custom-checkbox"
                        name={val}
                        value={"yes"}
                        checked={response[val] === "yes" ? true : false}
                        onChange={(e) => handleResponse(e)}
                      />
                      <IonLabel>Yes</IonLabel>
                    </div>
                    <div className="responseRFICheckBoxes">
                      <input
                        type="radio"
                        className="custom-checkbox multi-custom-checkbox"
                        name={val}
                        value={"no"}
                        checked={response[val] === "no" ? true : false}
                        onChange={(e) => handleResponse(e)}
                      />
                      <IonLabel>No</IonLabel>
                    </div>
                  </div>
                </div>
              );
            })}
          <div>
            {errorData?.response ? (
              <p className="setError">{errorData?.response[0]}</p>
            ) : null}
          </div>
          <div className="button-login d-flex justify-content-center">
            {/* <div>
              <IonButton
                color="none"
                className="vendorJourneyButton"
                // onClick={(event) => {
                // 	onSubmitForm(event);
                // 	props.history.goBack();
                // }}
                onClick={() => setShowModal(true)}
              >
                Save
              </IonButton>
            </div> */}
            <div>
              <IonButton
                color="none"
                className="vendorJourneyButton"
                onClick={(e) => handleNext(e)}
              >
                Next
              </IonButton>
            </div>
          </div>
        </div>
      )}
      {count === 2 && (
        <VendorJourneySecond
          setCount={setCount}
          handleChange={handleChange}
          rfiData={rfiData}
          formData={formData}
          response={response}
        />
      )}
      {showModal && (
        <div>
          <Modal
            message={
              "Your RFI response has been saved,Don’t forget to submit before the deadline."
            }
            handleClose={handleClose}
          />
        </div>
      )}
    </IonContent>
  );
};

export default VendorJourneyOne;
