import React from "react";
import { IonToast } from "@ionic/react";
import { useDispatch } from "react-redux";

export const Toast = (props) => {
  const dispatch = useDispatch();

  console.log("in toast", props);

  return (
    <IonToast
      isOpen={props?.isOpen}
      onDidDismiss={() => {
        if (props?.isClearSuccess) {
          dispatch({
            type: props?.actionType ? props?.actionType : "CLEAR_LOGIN",
          });
        }
        if (props?.isClearError) {
          dispatch({ type: "CLEAN_ERROR" });
        }
        if (props?.isRedirect) {
          return props?.redirectProps.history.push(props?.redirectTo);
        }
      }}
      message={props?.message}
      color={props.isSuccess ? "success" : "warning"}
      position="bottom"
      duration={props?.duration ? props.duration : 10000}
      buttons={[
        {
          text: "Close",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ]}
    />
  );
};
