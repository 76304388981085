import Api from "../../Api";
import { handleError } from "./commonAction";
import {
  ENDPOINTS,
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
} from "../../Services/index";

export const loginUser = (body, props) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.LOGIN, body);
    console.log("LOGINNNNN RESSSSS", res.data);
    await dispatch({
      type: ACTION_TYPE.LOGIN,
      payload: { data: res.data },
    });

    if (res?.data?.status) {
      return res?.data;
    }
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const loginAsUser = (user) => async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const body = {
    targetUser: user,
    userData,
  };

  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.LOGIN_AS_USER, body);
    console.log("LOGINNNNN RESSSSS", res.data);
    await dispatch({
      type: ACTION_TYPE.LOGIN_AS_USER,
      payload: { data: res.data },
    });

    if (res?.data?.status) {
      return res?.data;
    }
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const logoutAsUser = (body) => async (dispatch) => {
  try {
    const res = await Api.post(ENDPOINTS.LOGOUT_AS_USER, body);

    await dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });

    if (res?.data?.status) {
      return res?.data;
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const signUpUser = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    const res = await Api.post(ENDPOINTS.SIGNUP, body);
    dispatch({
      type: ACTION_TYPE.SIGNUP,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });

    if (res?.data?.status) {
      return res?.data?.status;
    }
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
export function checkPayment(values) {
  return async function (dispatch) {
    try {
      dispatch({
        type: "CHECK_PAYMENT",
        checkIsPayment: values.isPayment,
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export const matchToken = () => async (dispatch) => {
  try {
    let userData = await JSON.parse(
      localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
    );

    const body = userData;

    let token = await localStorage.getItem(APPLICATION_CONSTANTS.TOKEN);

    if (
      userData &&
      userData.support &&
      userData.support.supportUserData &&
      userData.support.supportUserData.role === 4
    ) {
      const res = await Api.post(
        ENDPOINTS.MATCH_TOKEN +
          `?email=${userData.email}&token=${token}&id=${
            userData.id
          }&attachedUserId=${userData?.is_assigned_to || ""}`,
        body
      );

      await dispatch({
        type: "LOGIN",
        payload: { data: res.data },
      });
    } else {
      console.log("in else condition===>", userData);
      const res = await Api.get(
        ENDPOINTS.MATCH_TOKEN +
          `?email=${userData.email}&token=${token}&id=${
            userData.id
          }&attachedUserId=${userData?.attachedUserId || ""}`
      );
      await dispatch({
        type: "LOGIN",
        payload: { data: res.data },
      });
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const verifyEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.patch(ENDPOINTS.VERIFY_EMAIL, body);
    await dispatch({
      type: ACTION_TYPE.VERIFY_EMAIL,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const changePassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/userAuth/change-password`, body);

    console.log("respass", res);
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: { data: res.data },
    });
    if (res.data) {
      return res.data;
    }

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const forgetPassword = (body, props) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.FORGET_PASSWORD, body);

    dispatch({
      type: ACTION_TYPE.FORGET_PASSWORD,
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const resetPassword = (body) => async (dispatch) => {
  try {
    // console.log("BODYYYYY",body)
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.RESET_PASSWORD, body);

    console.log("RESET_PASSWORD", res);
    dispatch({
      type: ACTION_TYPE.RESET_PASSWORD,
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

// export const contactUs = (body) => async (dispatch) => {
//   try {
//     dispatch({ type: "LOADING_STARTED" });
//     console.log("CONTACT", body);
//     const res = await Api.post(ENDPOINTS.CONTACT_US, body);

//     console.log("CONTACT", res);
//     dispatch({
//       type: ACTION_TYPE.CONTACT_US,
//       payload: { data: res.data },
//     });

//     dispatch({ type: "LOADING_COMPLETED" });
//   } catch (err) {
//     dispatch({ type: "LOADING_FAILURE" });
//     dispatch(handleError(err));
//   }
// };
