import React from "react";
import "./style.css";
const CustomPagination = ({ nPages, onPageChange, currentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  return (
    <nav>
      <ul className="customPagination">
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${currentPage == pgNumber ? "active" : ""} `}
          >
            <p onClick={() => onPageChange(pgNumber)} className="page-link">
              {pgNumber}
            </p>
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default CustomPagination;
