export const filterTenders = (tenders, type, value, slug) => {
  tenders = tenders.map((tender) => ({
    ...tender,
    sector: JSON.stringify(
      JSON.parse(tender.sector).map((sec) => sec.replaceAll("\\", ""))
    ),
  }));
  if (type === "active") {
    const activeTenders = tenders.filter((tender) => {
      console.log("tenders in published", tenders);

      const categoryArray =
        tender &&
        tender.category &&
        tender.category.length &&
        JSON.parse(tender.category);
      const dateOfAddition = new Date(tender.date_of_addition);
      const datePlus7 = dateOfAddition.setDate(dateOfAddition.getDate() + 7);
      if (!value) {
        return new Date(datePlus7) < new Date();
      } else {
        if (slug === "sector") {
          return (
            new Date(datePlus7) < new Date() && tender.sector.includes(value)
          );
        } else if (slug === "category") {
          return (
            new Date(datePlus7) < new Date() &&
            categoryArray &&
            // categoryArray.includes(value)
            findCommonElement(categoryArray, value)
          );
        } else {
          return new Date(datePlus7) < new Date();
        }
      }
    });
    return activeTenders;
  } else if (type === "new") {
    const newTenders = tenders.filter((tender) => {
      const categoryArray =
        tender &&
        tender.category &&
        tender.category.length &&
        JSON.parse(tender.category);
      const dateOfAddition = new Date(tender.date_of_addition);
      const datePlus7 = dateOfAddition.setDate(dateOfAddition.getDate() + 7);
      if (!value) {
        return new Date(datePlus7) >= new Date();
      } else {
        if (slug === "sector") {
          return (
            new Date(datePlus7) >= new Date() && tender.sector.includes(value)
          );
        } else if (slug === "category") {
          return (
            new Date(datePlus7) >= new Date() &&
            categoryArray &&
            findCommonElement(categoryArray, value)
          );
        } else {
          return new Date(datePlus7) >= new Date();
        }
      }
    });
    return newTenders;
  }
};

const findCommonElement = (array1, array2) => {
  if (!array1 || !array2) {
    return false;
  } else {
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (array1[i] === array2[j]) {
          return true;
        }
      }
    }
    return false;
  }
};

export const formatArrayToString = (arr) => {
  let str = "";
  arr.forEach((name, index) => {
    if (index === arr.length - 1) {
      str += `${arr[index]}`;
    } else if (index === 0) {
      str += `${arr[index]}, `;
    } else {
      str += `${arr[index]}, `;
    }
  });
  return str;
};

export const formatDateBritish = (dateStr) => {
  const newDate = new Date(dateStr).toLocaleDateString("sv", {
    timeZone: "Europe/Paris",
  });
  return newDate;
};

export const formatDate = (dateStr) => {
  return new Date(dateStr).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

export const formatDateYearMonth = (dateStr) => {
  const dateFormated = new Date(dateStr);
  return `${dateFormated.toLocaleString("default", {
    month: "long",
  })} ${dateFormated.getFullYear()}`;
};
