import React, { useEffect, useState } from "react";
import { IonContent, IonIcon, IonSearchbar } from "@ionic/react";
import Footer from "../../components/Footer/Footer";
import "./index.css";
import { HomeAction, RFIAction } from "../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";

import { Tab, Tabs } from "react-bootstrap";
import SingleColMultiRowTable from "../../components/SingleColMultiRowTable";
import { searchOutline } from "ionicons/icons";
import { useHistory } from "react-router";

const VendorList = (props) => {
  let dispatch = useDispatch();
  let { approvedVendors } = useSelector((state) => state.RFIReducer);
  const [searchText, setSearchText] = useState();

  const handleSearchTextChange = async (text) => {
    setSearchText(text);
    await dispatch(RFIAction.getApprovedVendors(text));
  };
  const history = useHistory();
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));

      await dispatch(RFIAction.getApprovedVendors());
    })();
  }, []);

  const onTabChange = (value) => {
    history.push(`/vendor-list/${value}`);
  };

  return (
    <IonContent>
      <div className="container listTop">
        <div className="searchBarr d-flex m-auto">
          <IonSearchbar
            className="p-0 ionSearchBar"
            value={searchText}
            onIonChange={(e) => setSearchText(e.target.value)}
            cancelButtonText=""
            animated
            placeholder="What are you looking for?"
          />
          <div className="d-flex flex-row">
            <button
              onClick={() => handleSearchTextChange(searchText)}
              className="searchButton"
            >
              <IonIcon icon={searchOutline} style={{ marginRight: "5px" }} />
              Search
            </button>
          </div>
        </div>
        <Tabs
          defaultActiveKey={"fans-&-digital-media"}
          activeKey={props.match.params.id}
          id="uncontrolled-tab-example"
          className="mt-8"
          onSelect={(value) => onTabChange(value)}
        >
          <Tab eventKey="fans-&-digital-media" title="Fans & Digital Media">
            <SingleColMultiRowTable
              letter="Fans & Digital Media"
              listSectors={approvedVendors.fanDigitalMedia}
            ></SingleColMultiRowTable>
          </Tab>
          <Tab eventKey="commercial" title="Commercial">
            <SingleColMultiRowTable
              letter="Commercial"
              listSectors={approvedVendors?.commercial}
              searchText={searchText}
            ></SingleColMultiRowTable>
          </Tab>
          <Tab eventKey="athletes-&-performance" title="Athletes & Performance">
            <SingleColMultiRowTable
              letter="Athletes & Performance"
              listSectors={approvedVendors.athlete}
            ></SingleColMultiRowTable>
          </Tab>
          <Tab
            eventKey="management-&-operations"
            title="Management & Operations"
          >
            <SingleColMultiRowTable
              letter="Management & Operations"
              listSectors={approvedVendors.managementOperation}
            ></SingleColMultiRowTable>
          </Tab>
        </Tabs>
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default VendorList;
