import React from "react";
import { Button, Typography } from "@material-ui/core";
import "./TendersList.css";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { TenderActions } from "../../ReduxStore/Actions";

const TendersList = ({
  list,
  setShowEditTender,
  getTenderData,
  itemsPerPage,
  page,
  newTendor,
  handleClearFilter,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.commonReducer.loading);

  const [buttonLoader, setButtonLoader] = React.useState(null);

  const handlePublish = async (tender, index) => {
    setButtonLoader(index);
    await dispatch(TenderActions.publishTender(tender));
    await dispatch(TenderActions.getAllTenders());
  };

  console.log("tende 2222", list);

  const dateFormatter = (date) => {
    const splittedDate = date.split("-");
    console.log("111111111", splittedDate);
    return splittedDate[2] + "/" + splittedDate[1] + "/" + splittedDate[0];
  };

  return (
    <ul style={{ color: "black", listStyle: "none", padding: "0px" }}>
      {list &&
        list.length > 0 &&
        list
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((tender, index) => {
            //   let date = new Date(product.created_ts).toDateString().slice(3);
            return (
              <li className="vendor-Card-Product card-shade" key={tender.id}>
                {/* <div className="d-lg-flex d-block card-shade"> */}
                {/* <h3 className="">{index + 1}</h3> */}
                <div className="infoContainer">
                  <Typography
                    className="col-md-12 col-sm-12 contentProduct"
                    variant="h6"
                    gutterBottom
                  >
                    <span style={{ color: "#062c56" }}>Tender Title: </span>
                    {`${tender.title}`}
                  </Typography>

                  <Typography
                    className="col-md-12 col-sm-12 content1Product"
                    variant="body1"
                    gutterBottom
                  >
                    <span style={{ color: "#062c56" }}>Organisation: </span>{" "}
                    {tender.organization_name}
                  </Typography>

                  <Typography
                    className="col-md-12 col-sm-12 content1Product"
                    variant="body1"
                    gutterBottom
                  >
                    <span style={{ color: "#062c56" }}>
                      {tender &&
                      tender.sector &&
                      JSON.parse(tender.sector).length === 1
                        ? "Sector: "
                        : "Sectors: "}
                    </span>{" "}
                    {(tender &&
                      tender.sector &&
                      JSON.parse(tender.sector).map((cat, index) => {
                        if (
                          JSON.parse(tender.sector).length - 1 === index ||
                          JSON.parse(tender.sector).length === 1
                        ) {
                          return cat;
                        } else {
                          return `${cat}, `;
                        }
                      })) ||
                      "N/A"}
                  </Typography>

                  <Typography
                    className="col-md-12 col-sm-12 content1Product"
                    variant="body1"
                    gutterBottom
                  >
                    <span style={{ color: "#062c56" }}>
                      {tender &&
                      tender.category &&
                      JSON.parse(tender.category).length === 1
                        ? "Category: "
                        : "Categories: "}
                    </span>{" "}
                    {(tender &&
                      tender.category &&
                      JSON.parse(tender.category).map((cat, index) => {
                        if (
                          JSON.parse(tender.category).length - 1 === index ||
                          JSON.parse(tender.category).length === 1
                        ) {
                          return cat;
                        } else {
                          return `${cat}, `;
                        }
                      })) ||
                      "N/A"}
                  </Typography>

                  <Typography
                    className="col-md-12 col-sm-12 content1Product"
                    variant="body1"
                    gutterBottom
                  >
                    <span style={{ color: "#062c56" }}> Tender URL: </span>{" "}
                    <a
                      href={tender.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`${tender.url}`}</a>
                  </Typography>

                  <Typography
                    className="col-md-12 col-sm-12 content1Product"
                    variant="body1"
                    gutterBottom
                  >
                    <span style={{ color: "#062c56" }}>
                      Response Deadline:{" "}
                    </span>{" "}
                    {dateFormatter(tender.response_deadline.slice(0, 10))}
                  </Typography>
                </div>

                <div
                  className="tenderBtnContainer"
                  //   style={{ border: "1px solid" }}
                >
                  <Button
                    className="Viewbtn"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      getTenderData(tender);
                      setShowEditTender(true);
                      handleClearFilter();
                    }}
                  >
                    Edit
                  </Button>
                  {!tender.is_published ? (
                    <>
                      <Button
                        className="Viewbtn"
                        color="secondary"
                        variant="contained"
                        //   disabled={loading}
                        onClick={() => handlePublish(tender, index)}
                      >
                        {(!loading || buttonLoader !== index) && "Publish"}
                        {(() => {
                          if (loading && buttonLoader === index) {
                            return <CircularProgress />;
                          }
                        })()}
                      </Button>
                    </>
                  ) : (
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Published
                    </p>
                  )}
                </div>
                {/* </div> */}

                {/* <Divider></Divider> */}
              </li>
            );
          })}
      {list && list.length === 0 && loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      {!loading && list && list.length === 0 && (
        <h2 className="noTender">
          {newTendor ? "No New Tenders Found." : "No Active Tenders Found."}
        </h2>
      )}
    </ul>
  );
};

export default TendersList;
