import React from "react";
import { IonContent } from "@ionic/react";
import Footer from "../../components/Footer/Footer";
import "./pt.css";
import { HomeAction } from "../../ReduxStore/Actions";
import { useDispatch } from "react-redux";

const Privacy = () => {
  let dispatch = useDispatch();
  React.useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
    })();
  }, []);
  return (
    <IonContent>
      <section className="staticpages">
        <div className="container">
          <div className="termd">
            <h2>SportsTech Match Privacy Policy</h2>
            <h3>Dear SportsTech Match user,</h3>
            <p>
              SportsTech Match recognizes the importance of protecting the
              privacy of your personal information, and we have prepared this
              Privacy Policy to provide you with information about our privacy
              practices. This Privacy Policy applies when you use our website,
              register for our email newsletter, and/or apply for our reports
              (the “Services”). Please read this Privacy Policy carefully.
            </p>
            <p>
              This Privacy Policy complies with the European Union’s General
              Data Protection and Regulation (“GDPR”) requirements.
            </p>
            <h4>Summary</h4>
            <p>
              We collect your data only to provide you with access to our
              content, events, and other programs. We do not share your data
              with anyone else without your explicit consent to do so. We
              respect your right to privacy. If you wish to unsubscribe from the
              Services and/or to have us remove your data from our systems, we
              will do so.
            </p>
            <p>
              Consenting to our collection and use of your data is optional, but
              if you do not provide consent, we will be unable to provide you
              with the Services.
            </p>
            <h4>What information do we collect?</h4>
            <p>
              While using our Services, you may be given the opportunity to sign
              up for newsletters, create a user account, register for events,
              and submit information to access content such as reports or
              webinars and more.
            </p>
            <p>
              When you do that, we may collect the following information from
              you:
            </p>
            <p>
              Full name
              <br />
              Email address
              <br />
              Title
              <br />
              Location
              <br />
              Company name
              <br />
              Mailing address
              <br />
            </p>
            <p>
              We may also collect information about you from publicly and
              commercially available sources, as permitted by law, which we may
              combine with other information we receive from or about you or
              your company. The purpose of this is to better provide to you our
              Services. As with all information that we collect from you, we
              will keep it private and for our own internal purposes only
            </p>
            <h4>How do we use information we collect?</h4>
            <p>
              <u>To Provide and Manage the Services You Request.</u> This
              includes, for example, sending youelectronic newsletters and
              enabling you to participate in the features provided by the
              Services. It also may include providing personalized content and
              recommendations to you. Through such features, we are able to
              bring you information and content tailored to your individual
              interests and needs.
            </p>
            <p>
              <u>To Contact You.</u> We may periodically contact you with
              information about the Services and our affiliates and partners.
            </p>
            <p>
              <u>To Better Understand Our Users.</u> The Services conduct
              research on our users’ demographics, interests and behavior based
              on the information we collect. We do this to better understand and
              serve our users, and to improve our Services.
            </p>
            <p>
              <u>Consent.</u> We may otherwise use your information with your
              consent or at your direction.
            </p>
            <h4>How do we share information?</h4>
            <p>
              The following provides information about entities with which we
              may share information. Our practices vary depending on the type of
              information.
            </p>
            <p>
              <u>Business Partners.</u> We may share your information with
              business partners to provide you with services that you request.
              For example, if you sign up for a promotion that runs on our
              Services but that is sponsored or co-sponsored by another company,
              your information may be shared with that sponsor. You will always
              be notified that we intend to share your information with a
              business partner before you elect to sign up for a promotion that
              so requires. SportsTech Match is not responsible for the privacy
              practices of these entities.
            </p>
            <p>
              <u>Service Providers.</u> We may share information with vendors
              providing contractual services to us, such as web hosting
              services.
            </p>
            <p>
              <u>
                Other Parties When Required by Law or As Necessary to Protect
                Our Users and Services.
              </u>{" "}
              We may share your personal information as we believe is necessary
              or appropriate to protect, enforce, or defend the legal rights,
              privacy, safety, or property of the Services, our employees or
              agents or users or to comply with applicable law or legal process,
              including responding to requests from public and government
              authorities.
            </p>
            <p>
              <u>Otherwise With Your Consent or At Your Direction.</u> In
              addition to the sharing described in this Privacy Policy, we may
              share information about you with third parties whenever you
              consent to or direct such sharing.
            </p>
            <p>
              <u>Aggregated Data.</u> We also may provide aggregated data about
              how users are using our Services to third parties.
            </p>
            <h4>Do we provide links to third-party sites?</h4>
            <p>
              Our Services may link to third-party websites and services that
              are outside our control. We are not responsible for the security
              or privacy of any information collected by other websites or other
              services. You should exercise caution, and review the privacy
              statements applicable to the third-party websites and services you
              use.
            </p>
            <p>
              How to access or update your information and other privacy choices
              available to you:
            </p>
            <p>
              <u>Manage Your SportsTech Match Email Newsletter Subscription</u>.
              You may modify your SportsTech Match email newsletter subscription
              and other personally identifiable information we have obtained as
              a result of your use of the Services by sending an email to
              <a href="mailto:info@sportstechmatch.com">
                {" "}
                info@sportstechmatch.com.{" "}
              </a>
            </p>
            <p>
              <u>Email Promotions. </u> You may opt out of receiving email
              messages from us by following the instructions contained in those
              email messages.{" "}
            </p>
            <p>
              <u>Manage Your Account. </u>You may choose to create an account for the purposes of promoting your products and services and/or creating requests for information (RFI's) through SportsTech Match. You may modify your SportsTech Match account details at any time. If you are a vendor you may also choose to remove your product listings from SportsTech Match at any time. To completely remove your account from SportsTech Match, please send an email to info@sportstechmatch.com.{" "}
            </p>
            <h4>Data Security</h4>
            <p>
              We have in place physical, electronic and managerial procedures to
              protect the information we collect online. However, as effective
              as these measures are, no security system is impenetrable. We
              cannot guarantee the security of our database, nor can we
              guarantee that the information you supply will not be intercepted
              while being transmitted to us over the Internet.
            </p>
            <h5>How to ask us to delete your data:</h5>
            <p>
              You can unsubscribe from our communications at any time by
              selecting the “unsubscribe” button in the footer of our emails. If
              you would like us to delete your information entirely, or request
              to see the information we store about you, please send an email to
              info@sportstechmatch.com with the subject line “Data Removal
              Request”. In the absence of a request to unsubscribe and/or delete
              your data, your continued use of the Services gives us permission
              to manage and use your data as described in this Privacy Policy.
            </p>
            <h4>Other Important Information</h4>
            <p>
              {" "}
              <u>Children’s Privacy.</u> We do not knowingly collect any
              personal information from children under the age of 13 without
              parental consent, unless permitted by law. If we learn that a
              child under the age of 13 has provided us with personal
              information, we will delete it in accordance with applicable law.
            </p>
            <h4>Changes to Our Privacy Policy</h4>
            <p>
              We may modify this Privacy Policy from time to time. We will
              notify you of changes by posting changes here, or by other
              appropriate means. Any changes to the Privacy Policy will become
              effective when the updated policy is posted on the Services. Your
              use of the Services or your provision of personal information to
              use the Services following such changes indicates your acceptance
              of the revised Privacy Policy.
            </p>
            <p>Last updated: January 2022</p>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </IonContent>
  );
};

export default Privacy;
