import { IonContent, IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";

import "./PublishedTendersPage.css";

import { useSelector, useDispatch } from "react-redux";

import { HomeAction } from "../../ReduxStore/Actions";
import TendersList from "./TendersList";
import Footer from "../../components/Footer/Footer";
import TendersTab from "./TendersTab";

import { TenderActions } from "../../ReduxStore/Actions";
import { filterTenders } from "../../Utils/helpers";
import { ArrowBack } from "@material-ui/icons";
import PaginationControlled from "../../adminPanel/Tenders/Pagination";
import { useHistory } from "react-router";

const TendersPage = (props) => {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const scrollRef = useRef();
  const history = useHistory();

  const [showNewTenders, setShowNewTenders] = useState(true);
  const [showActiveTenders, setShowActiveTenders] = useState(false);
  //   const [tenders, setTenders] = useState([]);

  // pagination State
  const [newTendersCount, setNewTendersCount] = React.useState(0);
  const [activeTendersCount, setActiveTendersCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const itemPerPage = 10;

  const [newTenders, setNewTenders] = useState([]);
  const [activeTenders, setActiveTenders] = useState([]);
  const [tenders, setTenders] = useState([]);

  // tab
  const [tabValue, setTabValue] = useState(0);

  const fetchedTenders = useSelector(
    (state) => state.TenderReducer.publishedTenders
  );

  console.log("published tenders fetched tenders==>", fetchedTenders);

  //   fetchedTenders = fetchedTenders.filter(
  //     (tender) => tender.is_published === "1"
  //   );
  const sectors = useSelector((state) => state.TenderReducer.sectors);

  const handleSectorChange = (event) => {
    console.log("event.target.value", event.target.value);
    const newTendersArray = filterTenders(
      tenders,
      "new",
      event.target.value,
      "sector"
    );

    setNewTenders(newTendersArray);

    const activeTendersArray = filterTenders(
      tenders,
      "active",
      event.target.value,
      "sector"
    );
    // const activeTendersArray = tenders.filter(
    //   (tender) => tender.sector === event.detail.value
    // );
    setActiveTenders(activeTendersArray);
    // setTenders(filteredSector);
  };

  useEffect(() => {
    if (fetchedTenders.length > 0) {
      setTenders(fetchedTenders);
      const newTendersArray = filterTenders(fetchedTenders, "new", "");

      setNewTenders(newTendersArray);
      const activeTendersArray = filterTenders(fetchedTenders, "active", "");
      setActiveTenders(activeTendersArray);
    }
  }, [fetchedTenders]);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
  }, [dispatch]);

  // fetch all tenders
  useEffect(() => {
    dispatch(TenderActions.getPublishedTenders());
    dispatch(TenderActions.getSectors());
  }, [dispatch]);

  // pagination Effect hook
  useEffect(() => {
    const displayedData = Math.ceil(newTenders?.length / itemPerPage);
    console.log("new Tenders and tenders length", newTenders.length);
    setNewTendersCount(displayedData);
  }, [newTendersCount, newTenders]);

  useEffect(() => {
    const displayedData = Math.ceil(activeTenders?.length / itemPerPage);
    console.log("new Tenders and tenders length", activeTenders.length);
    setActiveTendersCount(displayedData);
  }, [activeTendersCount, activeTenders]);

  useEffect(() => {
    setPage(1);
    if (selectRef && selectRef?.current) {
      selectRef.current.value = undefined;
      dispatch(TenderActions.getPublishedTenders());
      dispatch(TenderActions.getSectors());
    }
  }, [tabValue, dispatch]);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  return (
    <IonContent>
      <div className="container signup contactContainer publishedTenderContainer">
        <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
          <ArrowBack />
        </span>
        <div className="addtender" ref={scrollRef}>
          <h3 style={{ margin: "48px 0 0" }} className="headingTendor">
            {" "}
            External Tenders
          </h3>
          {/* <IonButton className="gradient-btn-blue" onClick={toggleShowForm}>
            Add Tender
          </IonButton> */}
        </div>
        <TendersTab
          setShowActiveTenders={setShowActiveTenders}
          setShowNewTenders={setShowNewTenders}
          value={tabValue}
          setValue={setTabValue}
        />

        {tabValue !== 2 && (
          <IonItem className="selectSectorContainer">
            <IonSelect
              ref={selectRef}
              className="sectorselect"
              placeholder="select sector"
              name="sector"
              onIonChange={(event) => handleSectorChange(event)}
            >
              {sectors &&
                sectors.length > 0 &&
                sectors.map(({ name }, index) => (
                  <IonSelectOption key={index} value={name}>
                    {name}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
        )}
        <button
          onClick={() => {
            console.log("selectRef", selectRef.current.value);
            selectRef.current.value = undefined;
            dispatch(TenderActions.getPublishedTenders());
            dispatch(TenderActions.getSectors());
          }}
          className="clearBtn"
        >
          Clear Filter
        </button>
        {showNewTenders && (
          <>
            <br />
            <TendersList
              list={newTenders}
              itemPerPage={itemPerPage}
              page={page}
            />
            {newTendersCount > 1 && (
              <PaginationControlled
                count={newTendersCount}
                setPage={setPage}
                page={page}
              />
            )}
          </>
        )}
        {showActiveTenders && (
          <>
            <br />
            <TendersList
              list={activeTenders}
              itemPerPage={itemPerPage}
              page={page}
            />
            {activeTendersCount > 1 && (
              <PaginationControlled
                count={activeTendersCount}
                setPage={setPage}
                page={page}
              />
            )}
          </>
        )}
      </div>

      <Footer></Footer>
    </IonContent>
  );
};

export default TendersPage;
