import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
  productInfo: {},
  productRatings: [],
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_BUYER_PRODUCT_BY_ID:
      console.log("GET_BUYER_PRODUCT_BY_ID", action.payload);
      return {
        ...state,
        productInfo: action.payload,
      };
    case ACTION_TYPE.GET_RATINGS_BY_PRODUCT_ID:
      return {
        ...state,
        productRatings: action.payload,
      };

    default:
      return state;
  }
};
