import Api from "../../Api";
import { handleError } from "./commonAction";

import {
  ENDPOINTS,
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
} from "../../Services/index";
export const getList = (vendorId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2", vendorId);
    console.log(ENDPOINTS.GET_LIST);
    dispatch({ type: ACTION_TYPE.LOADING_LIST, payload: true });
    Api.post(ENDPOINTS.GET_LIST, vendorId)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_LIST,
          payload: res.data.data,
        });
        dispatch({ type: ACTION_TYPE.LOADING_LIST, payload: false });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: ACTION_TYPE.LOADING_LIST, payload: false });
        // dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};

export const addEditVendorUser = (vendorData, cb) => async (dispatch) => {
  Api.post(ENDPOINTS.ADD_EDIT_VENDOR_USER, vendorData)
    .then((res) => {
      console.log("vendorData", res);

      cb();
      // dispatch({
      //   type: ACTION_TYPE.GET_LIST,
      //   payload: res.data.data,
      // });
    })
    .catch((err) => {
      dispatch({ type: "LOADING_GET_Fail" });
      dispatch(handleError(err));
    });
};
export const getVendorUsers = (userId) => async (dispatch) => {
  let userData = await JSON.parse(
    localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
  );

  console.log("userData in getVendorUsers", userData);
  let newUserId = userId;
  if (userData && userData.role === 3 && userData.is_vendor_admin) {
    newUserId = userData.is_assigned_to;
  }
  Api.get(ENDPOINTS.GET_VENDOR_USERS + `?userId=${newUserId || ""}`)
    .then((res) => {
      console.log("vendorData", res);
      dispatch({
        type: ACTION_TYPE.GET_VENDOR_USERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({ type: "LOADING_GET_Fail" });
    });
};

export const deleteVendorUser = (vendorData, cb) => async (dispatch) => {
  dispatch({ type: "LOADING_STARTED" });
  Api.post(ENDPOINTS.DELETE_VENDOR_USER, vendorData)
    .then((res) => {
      console.log("vendorData", res);
      dispatch({ type: "LOADING_COMPLETED" });
      cb();
    })
    .catch((err) => {
      dispatch({ type: "LOADING_GET_Fail" });
      dispatch(handleError(err));
    });
};

export const getLatestProducts = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2");
    // console.log(ENDPOINTS.GET_LIST);
    Api.get(ENDPOINTS.GET_LATEST)
      .then((res) => {
        console.log("list", res);

        dispatch({
          type: ACTION_TYPE.GET_LATEST,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_GET_Fail" });
        reject(err);
      });
  });
};
export const delList = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log("hittt2");
    console.log(ENDPOINTS.DEL_LIST);
    Api.post(ENDPOINTS.DEL_LIST, params)
      .then((res) => {
        console.log("delete", res);

        dispatch({
          type: ACTION_TYPE.DEL_ID,
          payload: [{ data: "notfound" }],
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: "LOADING_DEL_Fail" });
        reject(err);
      });
  });
};
export const getfilter = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get(ENDPOINTS.GET_FILTER)
      .then((res) => {
        console.log("filterapihit", res);
        dispatch({
          type: ACTION_TYPE.GET_FILTER,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_FAIL",
        });
        reject(err);
      });
  });
};
export const addList = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log(params, "params");
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.ADD_LIST, params)
      .then((res) => {
        console.log("addapihit", res);
        dispatch({
          type: ACTION_TYPE.ADD_LIST,
          payload: res.data.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_ADDFAIL",
        });
        reject(err);
      });
  });
};
export const ProductbyId = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log(params, "paramsproductId");
    Api.post(ENDPOINTS.PRODUCT_BY_ID, params)
      .then((res) => {
        console.log("resofpp", res);
        dispatch({
          type: ACTION_TYPE.PRODUCT_BY_ID,
          payload: res.data.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_PRODUCTID_FAIL",
        });
        reject(err);
      });
  });
};
export const updateList = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    console.log(params, "params");
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.UPDATE_LIST, params)
      .then((res) => {
        console.log("addapihit", res);
        dispatch({
          type: ACTION_TYPE.UPDATE_LIST,
          payload: res.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_UppFAIL",
        });
        reject(err);
      });
  });
};
