import {
  IonContent,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import React, { useEffect, useCallback } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import "./Vendor.css";
import { ROUTES } from "../../Services/index";
import { AdminAction, VendorAction } from "../../ReduxStore/Actions/index";

import { useSelector, useDispatch } from "react-redux";

import SideNav from "../../adminPanel/sidebar/sideNav";

import { useHistory } from "react-router-dom";
import { radio } from "ionicons/icons";
import { changeUserState } from "../../ReduxStore/Actions/adminAction";
import { markVendorPaid } from "../../ReduxStore/Actions/adminAction";

import { Button } from "@mui/material";
import ConfirmMarkVendorModal from "./ConfirmMarkVendorModal";
import ShowVendorUsersModal from "./ShowVendorUsersModal";
import { CircularProgress } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Toast } from "../../Utils/Toast";

import VendorUserModal from "../manageVendorAdmin/VendorUserModal";
import VendorDeleteModal from "../manageVendorAdmin/VendorDeleteModal";
import { stopLoading } from "../../ReduxStore/Actions/commonAction";

const Vendor = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [markedModal, setMarkedModal] = React.useState(false);
  const [vendorUsersModal, setVendorUsersModal] = React.useState(false);
  const [markLoading, setMarkLoading] = React.useState(false);
  const [selectedMarkEvent, setSelecetedMarkEvent] = React.useState(null);
  const [selectedVendorUser, setSelecetedVendorUser] = React.useState(null);

  const [vendorUserModal, setVendorUserModal] = React.useState(false);
  const [vendorDeleteModal, setVendorDeleteModal] = React.useState(false);
  const [selectedVendor, setSelectedVendor] = React.useState(null);

  const list = useSelector((state) => state.AdminReducer.vendor);
  const authData = useSelector((state) => state.authReducer.authData);
  console.log("listkomal", list);
  let { vendorUsers } = useSelector((state) => state.vendorReducer);

  const parent = [{ ...list }];
  vendorUsers = parent.concat(vendorUsers);
  const swap = (arr, idx1, idx2) => {
    if (idx1 !== idx2 && arr.length > 2) {
      [arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]];
    }
  };
  console.log("vendor USers===>", vendorUsers);
  const indexOfVendorAdmin = vendorUsers.findIndex(
    (rec) => rec.is_vendor_admin === 1
  );

  indexOfVendorAdmin !== 0 && swap(vendorUsers, 1, 0);
  indexOfVendorAdmin !== 0 && swap(vendorUsers, 0, indexOfVendorAdmin);

  vendorUsers = vendorUsers.filter((user) => user);

  console.log("vendorUsers===>", vendorUsers);

  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);

  const switchButton = (event) => {
    const is_fake = event.target.checked ? 0 : 1;

    dispatch(changeUserState(props.match.params.id, is_fake));
  };

  useEffect(() => {
    if (errorMessage) {
      setVendorUserModal(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    (async () => {
      await dispatch(
        AdminAction.getVedorById({
          userId: props.match.params.id,
          type: "vendor",
        })
        // parseInt(props.match.params.id) })
      );
    })();
  }, [props.match.params.id]);
  //to prefilled the page with redux data
  useEffect(() => {
    setState({
      companyHq: list.companyHq,
      firstName: list.firstName,
      companyName: list.companyName,
      email: list.email,
      organisationType: list.organisationType,
      lastName: list.lastName,
      is_vendor_admin: list.is_vendor_admin,
      website: list.website,
      yearFounded: list.yearFounded,
      is_paid: list.is_paid,
    });
  }, [list]);
  const handleChange = async (e, id, value) => {
    console.log("handleChange");
    console.log(e, id, value, "lppp");
    let params = {
      id,
      isApproved: value,
      email: state.email,
      is_fake: list.is_fake,
    };
    await dispatch(AdminAction.accept(params));
    await dispatch(AdminAction.getVendorList());
    history.push(ROUTES.Approve);
  };

  console.log("authData.attachedUser", authData);

  useEffect(() => {
    if (state.is_paid && props.match.params.id) {
      dispatch(VendorAction.getVendorUsers(props.match.params.id));
    }
  }, [state, props.match.params.id]);

  return (
    <div className="top">
      <div className="signup">
        <SideNav />
        <div className="col-md-9 col-sm-12 vendor-view">
          <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
            <ArrowBack />
          </span>
          <div className="login-inner">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="login-head userInfoHead newUserInfo"
            >
              {list?.isApproved == 0 || list?.isApproved == 2 ? (
                <h2 className="col-md-8">New User Info</h2>
              ) : (
                <h2>User Info</h2>
              )}

              {list?.isApproved == 0 || list?.isApproved == 2 ? (
                <div
                  style={{ marginTop: 10, float: "right" }}
                  className="col-md-4 button-vendor "
                >
                  <div className="switchButton">
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={list?.is_fake ? false : true}
                          onChange={switchButton}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Should Receive Email:"
                      labelPlacement="start"
                    />
                  </div>
                  <div className="infoBtnContainer">
                    <Button
                      className="btn"
                      onClick={(e) => handleChange(e, list.vendor_id, 1)}
                    >
                      Accept
                    </Button>
                    {list.isApproved == 0 ? (
                      <Button
                        className="btn"
                        onClick={(e) => handleChange(e, list.vendor_id, 2)}
                      >
                        Reject
                      </Button>
                    ) : (
                      <Button
                        className="btn1"
                        disabled
                        onClick={(e) => handleChange(e, list.vendor_id, 2)}
                      >
                        Rejected
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {/* implement the paid vendor button */}
                  {authData?.role &&
                    authData?.role === 2 &&
                    list?.organisationType === "Technology Vendor" && (
                      <div className="switchButton">
                        <FormControlLabel
                          value="start"
                          control={
                            <Switch
                              color="secondary"
                              checked={state.is_paid ? true : false}
                              onChange={(e) => {
                                console.log("e setSelecetedMarkEvent", e);
                                setSelecetedMarkEvent(e.target.checked);
                                setMarkedModal(true);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                              // disabled={markLoading || state.is_paid}
                              disabled={markLoading}
                            />
                          }
                          label={
                            state.is_paid
                              ? "Paid Vendor"
                              : "Mark as paid Vendor"
                          }
                          labelPlacement="start"
                        />
                      </div>
                    )}

                  <div className="switchButton">
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={list?.is_fake ? false : true}
                          onChange={switchButton}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Receive Email:"
                      labelPlacement="start"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="login-body">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "50vh",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <form>
                  <IonGrid>
                    <IonRow className="d-flex justify-content-between">
                      <IonCol size="6" size-md="6">
                        <div className="form-group">
                          <IonLabel>First Name</IonLabel>
                          <IonInput
                            readOnly={true}
                            type="text"
                            disabled
                            name="firstName"
                            className="form-control"
                            value={state?.firstName}
                          ></IonInput>
                        </div>
                      </IonCol>

                      <IonCol size="6" size-md="6">
                        <div className="form-group">
                          <IonLabel>Last name</IonLabel>
                          <IonInput
                            type="text"
                            name="lastName"
                            disabled
                            className="form-control"
                            value={state?.lastName}
                          ></IonInput>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="6">
                        <div className="form-group">
                          <IonLabel>Email address</IonLabel>
                          <IonInput
                            type="email"
                            disabled
                            name="email"
                            value={state?.email}
                            className="form-control"
                          ></IonInput>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="6">
                        <div className="form-group">
                          <IonLabel>Organisation Type</IonLabel>
                          <IonInput
                            type="text"
                            disabled
                            name="organisationType"
                            value={state?.organisationType}
                            className="form-control"
                          ></IonInput>
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="12" size-md="6">
                        <div className="form-group">
                          <IonLabel>Company Name</IonLabel>
                          <IonInput
                            type="text"
                            name="companyName"
                            disabled
                            value={state?.companyName}
                            className="form-control"
                          ></IonInput>
                          {/* {errorData?.companyName[0] ? (
                          <p>{errorData?.companyName[0]}</p>
                        ) : null} */}
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="6">
                        <div className="form-group">
                          <IonLabel>Company HQ</IonLabel>
                          <IonInput
                            type="text"
                            name="companyHq"
                            disabled
                            value={state?.companyHq}
                            className="form-control"
                          ></IonInput>
                        </div>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="12" size-md="6">
                        <div className="form-group">
                          <IonLabel>Website</IonLabel>
                          <IonInput
                            type="text"
                            name="website"
                            disabled
                            value={
                              state?.website !== "undefined"
                                ? state?.website
                                : "N/A"
                            }
                            className="form-control"
                          ></IonInput>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-md="6">
                        <div className="form-group">
                          <IonLabel>Year Founded</IonLabel>
                          <IonInput
                            type="text"
                            name="yearFounded"
                            disabled
                            value={
                              state?.yearFounded !== "undefined"
                                ? state?.yearFounded
                                : "N/A"
                            }
                            className="form-control"
                          ></IonInput>
                        </div>
                      </IonCol>
                    </IonRow>
                    {state.is_paid ? (
                      <IonRow>
                        <IonCol size="12" size-md="12">
                          <div className="form-group isVendorAdmin">
                            <div>
                              <IonLabel>
                                Is {state.firstName + " " + state.lastName}{" "}
                                <span style={{ textTransform: "none" }}>
                                  a{" "}
                                </span>
                                Vendor Admin?
                              </IonLabel>
                              {state.is_vendor_admin ? "Yes" : "No"}
                            </div>
                            {!state.is_vendor_admin ? (
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSelecetedVendorUser(
                                      props.match.params.id
                                    );
                                    setVendorUsersModal(true);
                                  }}
                                >
                                  Make Vendor Admin
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </IonCol>
                        {vendorUsers.length > 0 && (
                          <IonCol size="12" size-md="12">
                            <div
                              style={{ padding: "10px", borderRadius: "0" }}
                              className="rfi-table"
                            >
                              <table>
                                <tr className="rfi-table-head">
                                  <th style={{ fontWeight: 400 }}>S.No.</th>
                                  <th style={{ fontWeight: 400 }}>ID</th>
                                  <th style={{ fontWeight: 400 }}>Email</th>
                                  <th style={{ fontWeight: 400 }}></th>
                                </tr>

                                {vendorUsers.map((vendor, index) => (
                                  <tr key={vendor.id}>
                                    <td className="pl-3" size={6}>
                                      {index + 1}
                                    </td>
                                    <td size={4} className="pl-3">
                                      {vendor.id}
                                    </td>
                                    <td size={4} className="pl-3">
                                      {vendor.email}
                                    </td>
                                    <td className="pl-3 adminButtons">
                                      {authData &&
                                        (!authData?.attachedUserId ||
                                          (authData?.attachedUserId &&
                                            authData?.attachedUserId !=
                                              vendor.id)) && (
                                          <>
                                            {vendor.is_assigned_to ? (
                                              <IonButton
                                                onClick={() => {
                                                  setVendorUserModal(true);
                                                  setSelectedVendor(vendor);
                                                }}
                                                className="vendorEditBtn"
                                              >
                                                Edit
                                              </IonButton>
                                            ) : null}

                                            {!vendor.is_vendor_admin &&
                                            vendor.is_assigned_to ? (
                                              <IonButton
                                                onClick={() => {
                                                  setVendorDeleteModal(true);
                                                  setSelectedVendor(vendor);
                                                  dispatch(stopLoading());
                                                }}
                                                className="vendorDeleteBtn"
                                              >
                                                Delete
                                              </IonButton>
                                            ) : null}
                                          </>
                                        )}
                                      {vendor.is_vendor_admin ? (
                                        vendor.is_assigned_to ? (
                                          "Vendor Admin"
                                        ) : (
                                          "Vendor Admin"
                                        )
                                      ) : !vendor.is_assigned_to ? (
                                        "Paid Vendor"
                                      ) : (
                                        <Button
                                          variant="contained"
                                          onClick={() => {
                                            setSelecetedVendorUser(vendor.id);
                                            setVendorUsersModal(true);
                                          }}
                                        >
                                          Make Vendor Admin
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </div>
                          </IonCol>
                        )}
                      </IonRow>
                    ) : null}
                  </IonGrid>
                </form>
              )}
            </div>
          </div>

          {selectedMarkEvent != null && (
            <ConfirmMarkVendorModal
              userId={props.match.params.id}
              onHide={() => setMarkedModal(false)}
              show={markedModal}
              setMarkLoading={setMarkLoading}
              selectedMarkEvent={selectedMarkEvent}
            />
          )}
          {state.is_paid ? (
            <ShowVendorUsersModal
              mainUserId={props.match.params.id}
              userId={selectedVendorUser}
              onHide={() => setVendorUsersModal(false)}
              show={vendorUsersModal}
              setLoading={setLoading}
            />
          ) : null}
        </div>

        {vendorUserModal && (
          <VendorUserModal
            show={vendorUserModal}
            mainUserId={props.match.params.id}
            selectedVendor={selectedVendor}
            onHide={() => {
              setVendorUserModal(false);
              setSelectedVendor(null);
            }}
          />
        )}
        {vendorDeleteModal && (
          <VendorDeleteModal
            show={vendorDeleteModal}
            mainUserId={props.match.params.id}
            selectedVendor={selectedVendor}
            onHide={() => {
              setVendorDeleteModal(false);
              setSelectedVendor(null);
            }}
          />
        )}
        {(errorStatus === false || errorStatus === true) && errorMessage && (
          <Toast
            isOpen={errorStatus === false ? true : false}
            message={errorMessage}
            isSuccess={errorStatus}
            isClearSuccess={true}
            isClearError={true}
          />
        )}
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default Vendor;
