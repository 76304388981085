import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
} from "@ionic/react";
import React, { useEffect } from "react";

import "./ContactUs.css";

import { useSelector, useDispatch } from "react-redux";
import { ValidateContactForm } from "./Validation";
import { ContactAction, HomeAction } from "../../ReduxStore/Actions";
import Footer from "../../components/Footer/Footer";
import { Toast } from "../../Utils/Toast";
import { TextField } from "@material-ui/core";

const Signup = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState();
  const [toast, setToast] = React.useState(false);
  const [errorData, setError] = React.useState();
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
  }, []);
  async function onSubmitForm(event) {
    event.preventDefault();
    event.stopPropagation();
    const result = await ValidateContactForm(state);

    if (result?.isFormValid) {
      console.log("AAA", result, state);
      await dispatch(ContactAction.contactUs(state));
      document.getElementById("client").reset();
      setToast(true);
    } else {
      setError(result?.error);
      console.log("Sign up form submitted", JSON.stringify(result));
    }
  }
  return (
    <IonContent>
      {toast === false ? (
        <Toast
          isOpen={errorStatus === false ? true : false}
          message={errorMessage}
          isSuccess={errorStatus}
          isClearSuccess={true}
          isClearError={true}
        />
      ) : (
        <IonToast
          isOpen={toast}
          message="Successfully submitted"
          onDidDismiss={() => setToast(false)}
          buttons={[
            {
              text: "Close",
              role: "cancel",
              handler: () => {
                setToast(false);
              },
            },
          ]}
        />
      )}
      <div className="container signup contactContainer">
        <div className="login-inner">
          <div className="login-logo"></div>

          <div className="login-head">
            <h2>Contact Us </h2>
            <div className="errormsg">{/* <p>{props.errorMsg}</p> */}</div>
          </div>

          <div className="login-body">
            <form id="client">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Name*</IonLabel>
                      <IonInput
                        type="text"
                        name="fullName"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.fullName[0] ? (
                        <p>{errorData?.fullName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Email*</IonLabel>
                      <IonInput
                        type="email"
                        name="email"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.email[0] ? (
                        <p>{errorData?.email[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Message*</IonLabel>
                      <div className="size-md-12">
                        <TextField
                          type="text"
                          name="message"
                          className="messageBody"
                          // value={state.message}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          id="outlined-multiline-static"
                          multiline
                          inputProps={{ maxLength: 300 }}
                          rows={4}
                          defaultValue=""
                          variant="outlined"
                        />
                      </div>
                      {/* <IonTextarea
                        type="text"
                        name="message"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonTextarea> */}
                      {errorData?.message[0] ? (
                        <p>{errorData?.message[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>

                {/* <IonRow>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Logo</IonLabel>
                          <input
                            type="file"
                            accept="image/*"
                            name="teamLogo"
                            className="form-control"
                            onChange={(event)=>{handleChange("teamLogo",event)}}
                          ></input>
                          {errorData?.confirmPassword[0] ? (
                      <p>{errorData?.confirmPassword[0]}</p>
                    ) : null}
                        </div>
                      </IonCol>
                    </IonRow> */}
              </IonGrid>
              {/* <div className="forgot-password">
                    <Link to={"/signup"}>Don't have Account? SignUp</Link>
                    <a className="forgot-text">Forgot Password?</a>
                    <Link to="/login" className="forgot-text">
                      {" "}
                      Have Account? Login
                    </Link>
                  </div> */}
              <div className="button-login">
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={onSubmitForm}
                >
                  Send{" "}
                  {/* {(() => {
                        if (props.loading) {
                          return <IonSpinner name="crescent" className="spinner-loader" />
                        }
                      })()} */}
                </IonButton>
              </div>

              <div className="private-policy"></div>
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default Signup;
