import { IonButton, IonSpinner, IonAlert, useIonAlert } from "@ionic/react";
import React, { useState, useEffect } from "react";

import "./FormFour.css";
import { useDispatch } from "react-redux";

import { HomeAction } from "../../../../ReduxStore/Actions"; //"../../ReduxStore/Actions";
import { useRouteMatch } from "react-router";
import { ROUTES } from "../../../../Services";

const FormFour = (props) => {
  let dispatch = useDispatch();
  const [present] = useIonAlert();

  const handleSubmit = (flag) => {
    props.onSubmit(flag);
  };
  let [showAlert3, setShowAlert3] = useState(false);
  let [sectors, setSectors] = useState([]);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
    let sectorsList = props?.filters?.Sectors?.filter((data, index) => {
      return props.state.sectors.includes(data.id);
    });
    setSectors(sectorsList);
  }, []);

  const match = useRouteMatch();

  return (
    <div>
      <div className="formFour notesContainer">
        <div className="formFourRow">
          <div className="left oddBg">RFI Title</div>
          <div className="right oddBg">
            {props?.state?.rfi_title
              ? props?.state?.rfi_title
              : props?.state?.title}
          </div>
        </div>
        <div className="formFourRow">
          <div className="left">Level of Sport</div>
          <div className="right">
            {props?.state?.level_of_sport?.map((data, i) => {
              if (i === props?.state?.level_of_sport.length - 1) {
                return `${data} `;
              } else {
                return `${data}, `;
              }
            })}
          </div>
        </div>
        <div className="formFourRow">
          <div className="left oddBg">Organisation Description</div>
          <div className="right oddBg">{props?.state?.organisation_desc}</div>
        </div>
        <div className="formFourRow">
          <div className="left">Opportunity Description</div>
          {/* <div className="right">{props?.state?.opportunity_desc}</div> */}
          <div
            className="right"
            dangerouslySetInnerHTML={{ __html: props?.state?.opportunity_desc }}
          />
        </div>
        <div className="formFourRow">
          <div className="left oddBg">Yes / No Filter Questions </div>
          <div className="ques oddBg">
            {props?.state?.questions?.map((data, i) => (
              <div className="mb-1"> {`Q${i + 1}. ${data}`}</div>
            ))}
            {props?.state?.questionsNew?.map((data, i) => (
              <div className="mb-1"> {`Q${i + 6}. ${data}`}</div>
            ))}
          </div>
        </div>
        <div className="formFourRow">
          <div className="left">Open Question </div>
          <div className="right">{props.state?.openQuestion}</div>
        </div>
        <div className="formFourRow">
          <div className="left oddBg">Selected Sectors </div>
          <div className="right oddBg">
            <div>
              {props.state.sectors.includes("Sectors") ||
                sectors.map((data, index) => (
                  <p className="allowListCompany">
                    <b>{index + 1}.</b> {data.name}
                  </p>
                ))}
            </div>
          </div>
        </div>
        <div className="formFourRow">
          <div className="left">Custom Vendors List</div>
          <div className="right">
            <div>
              {props.selectedVendors &&
                props?.selectedVendors.map((item, index) => (
                  <p className="allowListCompany">
                    <b>{index + 1}.</b> {item}
                  </p>
                ))}
            </div>
          </div>
        </div>
        {props?.state?.blockAllow && (
          <div className="formFourRow">
            <div className="left">Vendor Blocklist / Allowlist </div>
            <div className="right">{props?.state?.blockAllow}</div>
          </div>
        )}

        <div className="formFourRow">
          <div className="left oddBg">Invite Non-Registered Vendors List</div>
          <div className="right oddBg">
            <div>
              {props?.allowListVendors.map((item, index) => (
                <p className="allowListCompany">
                  <b>{index + 1}.</b> {item}
                </p>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="formFourRow">
          <div className="left">Vendor Blocklist</div>
          <div className="right">
            <div>
              {props?.blockListVendors.map((item, index) => (
                <p className="allowListCompany">
                  {" "}
                  <b>{index + 1}.</b> {item}
                </p>
              ))}
            </div>
          </div>
        </div> */}

        <div className="formFourRow">
          <div className="left oddBg">Anonymous LinkedIn Promotion</div>
          <div className="right oddBg">
            {props?.state?.linkedInPromotion ? "yes" : "No"}
          </div>
        </div>
        <div className="formFourRow">
          <div className="left">RFI Response Deadline</div>
          <div className="right">{props?.state?.rfiDeadline.split("T")[0]}</div>
        </div>
        <div className="lastRow oddBg">
          <div>Notes for the SportsTech Match team</div>
          <div>{props?.state?.notes}</div>
        </div>
      </div>
      {props.type === "hide" ? null : (
        <div className="button-login formFourRow justify-content-center">
          <div>
            <IonButton
              color="none"
              className="gradient-btn-blue"
              onClick={(event) => {
                props.changeCount(3);
              }}
            >
              Back
            </IonButton>
          </div>
          {match.path !== ROUTES.UpdateRFI_Admin && (
            <div>
              <IonButton
                onClick={() => handleSubmit(1)}
                color="none"
                className="gradient-btn-blue"
                disabled={props.loading1 || props.loading}
              >
                Save & Exit
                {(() => {
                  if (props.loading1) {
                    return (
                      <IonSpinner name="crescent" className="spinner-loader" />
                    );
                  }
                })()}
              </IonButton>
            </div>
          )}

          <div>
            <IonButton
              color="none"
              className="gradient-btn-blue"
              disabled={props.loading || props.loading1}
              onClick={() => {
                setShowAlert3(true);
              }}
            >
              Submit
              {(() => {
                if (props.loading) {
                  return (
                    <IonSpinner name="crescent" className="spinner-loader" />
                  );
                }
              })()}
            </IonButton>
            <div style={{ textAlign: "center" }}>
              <IonAlert
                isOpen={showAlert3}
                onDidDismiss={() => setShowAlert3(false)}
                cssClass="createRfiAlert"
                header={"Are you sure?"}
                message="<h1>Are you sure?</h1>"
                buttons={[
                  {
                    text: "Yes",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: () => {
                      handleSubmit(0);
                    },
                  },
                  {
                    text: "No",
                    handler: () => {
                      setShowAlert3(false);
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormFour;
