export const startLoading = () => async (dispatch) => {
  dispatch({ type: "LOADING_STARTED" });
};

export const stopLoading = () => async (dispatch) => {
  dispatch({ type: "LOADING_COMPLETED" });
};

export const handleError = (error) => async (dispatch) => {
  console.log("ereerrrrrrrrr", error.response);
  dispatch({ type: "LOADING_FAILURE" });
  if (error.response) {
    if (error.response.status === 401) {
      if (error.response.data.isExpire) {
        localStorage.clear();
        window.location.reload();
      }
      dispatch({
        type: "ERROR",
        payload: {
          error: error?.response?.data?.message,
          status: error?.response?.data?.status,
        },
      });
    } else if (error.response.data?.error) {
      dispatch({
        type: "ERROR",
        payload: {
          error: error.response.data.error,
          status: error.response.data.status,
        },
      });
    }
    //  else if (error.response.data?.data ) {
    //   dispatch({
    //     type: "ERROR",
    //     payload: { error: error.response.data?.data.error  },
    //   });
    // }
    else {
      // console.log("DDDDDDDDDDDDDDDD",error)
      dispatch({
        type: "ERROR",
        payload: {
          error: error.response.data.message,
          status: error.response.data.status,
        },
      });
      //   toast.error(error.response.data.error);
    }
  } else
    dispatch({
      type: "ERROR",
      payload: { error: error.message },
    });
};
