import React from "react";
import { IonContent } from "@ionic/react";
import Footer from "../../components/Footer/Footer";
import "./pt.css";
import { HomeAction } from "../../ReduxStore/Actions";
import { useDispatch } from "react-redux";

const Terms = () => {
  let dispatch = useDispatch();
  React.useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
    })();
  }, []);
  return (
    <IonContent>
      <section className="staticpages">
        <div className="container">
          <div className="termd">
            <h2>SportsTech Match Terms of Use</h2>
            <h3>Dear SportsTech Match User,</h3>
            <p>
              These Terms of Use (the “Terms”) govern your access and use of the
              SportsTech Match website, email newsletter, and related services
              (the “Services”) provided by SportsTech Match
            </p>
            <p>
              (collectively “we,” “us,” or “our”). Please read these Terms of
              Use carefully. BY SUBSCRIBING TO OUR NEWSLETTER AND EACH TIME YOU
              ACCESS AND USE THE SERVICES, YOU SIGNIFY THAT YOU HAVE READ AND
              UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS.
            </p>
            <p>
              1. Your Responsibilities.
              <br />
              You are responsible for obtaining and maintaining at your own cost
              all equipment and services needed for access to and use of the
              Services. When you subscribe to our newsletter and each time you
              access the Services, you may be providing certain information
              about yourself or your company. You agree that we may use any
              information that we obtain about you in accordance with the
              provisions of our Privacy Policy. If you elect to create a user
              account and/or subscribe to our newsletter, you agree to: (a)
              provide true, accurate, current, and complete information as
              prompted by the form; and (b) maintain and update such information
              to keep it true, accurate, current, and complete at all times. In
              addition, you agree not to use the Services to: (a) violate any
              local, state, national, or international law or regulation; (b)
              transmit any material that is abusive, harassing, tortious,
              defamatory, vulgar, pornographic, obscene, libelous, invasive of
              another’s privacy, hateful, or racially, ethnically, or otherwise
              objectionable; (c) transmit any unsolicited or unauthorized
              advertising, promotional materials, junk mail, spam, chain
              letters, pyramid schemes, or any other form of solicitation; (d)
              transmit any material that contains adware, malware, spyware,
              software viruses, or any other computer code, files, or programs
              designed to interrupt, destroy, or limit the functionality of any
              computer software or hardware or telecommunications equipment; (e)
              stalk, harass, or harm another individual, including revealing the
              real name of any fellow user that has chosen to use an alias on
              the Services; (f) impersonate any person or entity, or otherwise
              misrepresent your affiliation with a person or entity; (g) use any
              “robot,” ‘spider,” “rover,” ‘scraper” or any other data-mining
              technology or automatic or manual process to monitor, cache,
              frame, mask, extract data from, copy or distribute any data from
              the Services, our network or databases; or (h) interfere with or
              disrupt the Services or servers or our networks, or disobey any
              requirements, procedures, policies, or regulations of networks
              connected to the Services.
            </p>
            <p>
              2. User Submissions.
              <br />
              We do not claim ownership of any information, data, text,
              software, music, sound, photographs, graphics, video, messages,
              tags or other materials you submit for display or distribution to
              others through the Services (collectively, “User Submissions”). As
              between you and us, you own all rights to your User Submissions.
              However, you grant to us and our affiliates, representatives,
              sublicensees, an irrevocable, perpetual, non-exclusive, fully-paid
              license (sublicensable through multiple tiers) throughout the
              universe to use, distribute, syndicate, license, reproduce,
              modify, adapt, publish, translate, publicly perform, create
              derivative works and publicly display your User Submissions (in
              whole or in part) in any format or medium now known or later
              developed; provided, however, that our exercise of our rights
              under the foregoing license shall at all times be subject to the
              limitations imposed on us under our Privacy Policy. We reserve the
              right to display advertisements in connection with User
              Submissions and to use User Submissions for advertising and
              promotional purposes without any compensation to you. These
              advertisements may be targeted to the content or information
              stored on the Services. In consideration for us granting you
              access and use of the Services, you agree that we may place such
              advertisements throughout our Services. You agree that you are
              solely responsible for the content of all of your User
              Submissions. We are not required to host, display, or distribute
              any User Submissions, and may remove at any time or refuse any
              User Submissions. We are not responsible for any loss, theft or
              damage of any kind to any User Submissions. You represent and
              warrant that your User Submissions and our authorized use of such
              submissions do not and will not infringe the rights of any third
              party (including, without limitation, intellectual property
              rights, rights of privacy or publicity, or any other legal or
              moral rights). You, and not we, are responsible for any
              consequences of sharing personal information about yourself on any
              area of the Service. We own all rights, title, and interests in
              any compilation, collective work or other derivative work created
              by us using or incorporating your content (but not your original
              content). When you use a feature on the Services that allows users
              to share, transform, readapt, modify, or combine user content with
              other content, you grant us and our users an irrevocable,
              non-exclusive, royalty free, perpetual, right and license in the
              universe to use, reproduce, modify, display, remix, perform,
              distribute, redistribute, adapt, promote, create derivative works,
              and syndicate your content in any medium and through any form of
              technology or distribution and to permit any derivative works to
              be licensed under these same license terms. The rights granted
              under this Section 2 will survive the termination of these Terms.
              All content and materials provided on the Services are intended
              for general information, general discussion, education, and
              entertainment purposes only and this oes not construe that such
              content is either endorsed or verified by us. THE CONTENT IS
              PROVIDED “AS IS,” AND YOUR USE OR RELIANCE ON SUCH MATERIALS ARE
              SOLELY AT YOUR OWN RISK.
            </p>
            <p>
              3. Copyright Infringement and Trademark Rights.
              <br />
              We respect the intellectual property rights of others.
              Accordingly, we have a policy of removing User Submissions that
              violate copyright law, suspending access to the Services (or any
              portion thereof) to any user who uses the Services in violation of
              copyright law, and/or terminating in appropriate circumstances the
              account of any user who uses the services in violation of
              copyright law. We have implemented procedures for receiving
              written notification of claimed copyright infringement and for
              processing such claims in accordance with such law. If you believe
              a user of the Services is infringing your copyright, please
              provide written notice to our agent at the email address listed
              below for notice of claims of copyright infringement. Email:
              info@SportsTechMatch.com Your written notice must: (a) contain
              your physical or electronic signature; (b) identify the
              copyrighted work alleged to have been infringed; (c) identify the
              allegedly infringing material in a sufficiently precise manner to
              allow us to locate that material; (d) contain adequate information
              by which we can contact you (including postal address, telephone
              number, and/or e-mail address); (e) contain a statement that you
              have a good faith belief that use of the copyrighted material is
              not authorized by the copyright owner, the copyright owner’s
              agent, or the law; (f) contain a statement that the information in
              the written notice is accurate; and (g) contain a statement, under
              penalty of perjury, that you are authorized to act on behalf of
              the copyright owner. Please do not send notices or inquiries
              unrelated to alleged copyright infringement to our designated
              copyright agent. If you believe that your trademark is being used
              somewhere on the Services in a way that constitutes trademark
              infringement, the owner or an agent of the owner may notify us at
              info@sportstechmatch.com. We ask that any complaints provide the
              accurate identity of the owner, how we may contact you, and the
              specific nature of the complaint.
            </p>
            <p>
              4. Termination.
              <br />
              We may terminate your account or suspend your access to all or
              part of the Services, without notice, if you violate these Terms
              or you engage in any conduct that we, in our sole and absolute
              discretion, believe is in violation of any applicable law or
              regulation or is otherwise harmful to the interests of us, any
              other user of the Services, or any third party. YOU AGREE THAT
              SportsTech Match SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR
              REMOVING YOUR USER SUBMISSIONS OR SUSPENDING OR TERMINATING YOUR
              ACCESS TO THE SERVICES (OR ANY PORTION THEREOF). You may
              discontinue your participation in and access to the Services at
              any time. We reserve the right to investigate your use of the
              Services in the event we, in our sole and absolute discretion,
              believe you have violated these Terms. Upon termination, we have
              no obligation to retain, store, or provide you with any data,
              information or other content that you uploaded, stored, or
              transferred on or through the Services.
            </p>
            <p>
              5. Modifications To Terms.
              <br />
              We may, in our sole and absolute discretion, change these Terms
              from time to time. We will post notice of such changes on the
              Services. If you object to any such changes, your sole recourse
              shall be to stop using the Services. Your continued use of the
              Services following notice of any such changes shall indicate your
              acknowledgement of such changes and agreement to be bound by the
              terms and conditions of such changes.
            </p>
            <p>
              6. Modifications To the Services.
              <br />
              We reserve the right to modify or discontinue all or any aspect of
              the Services with or without notice to you. YOU AGREE THAT WE
              SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY SHOULD WE EXERCISE
              OUR RIGHT TO MODIFY OR DISCONTINUE THE SERVICES.
            </p>
            <p>
              7. Fees.
              <br />
              We reserve the right at any time to charge fees for access to the
              Services or to any specific new feature or content that we may
              introduce from time to time. In no event will you be charged for
              access to any Services unless we obtain your prior agreement to
              pay such fees. Details regarding the content or services you will
              receive in exchange for fees, as well as the payment terms and
              conditions that apply, will be disclosed to you prior to your
              agreement to pay such fees. You agree to pay such fees if you sign
              up for any fee-based service. Any such terms and conditions shall
              be deemed to be a part of (and are hereby incorporated by
              reference into) these Terms.
            </p>
            <p>
              8. Password and Security.
              <br />
              SportsTech Match requires a password to access certainServices. If
              you choose to use these Services, you are responsible for
              maintaining the confidentiality of your password, and you are
              solely responsible for all activities performed in connection to
              the use of your password. You agree to immediately notify us of
              any unauthorized use of your password or any other breach of
              security related to the Services. We reserve the right to require
              you to alter your password if we believe that your password is no
              longer secure. YOU AGREE THAT WE WILL NOT BE LIABLE FOR ANY LOSS
              OR DAMAGE ARISING FROM YOUR FAILURE TO ADEQUATELY SAFEGUARD YOUR
              PASSWORD.
            </p>
            <p>
              9. Links.
              <br />
              We may provide links to other Web sites or Internet resources for
              your convenience only, and such links do not signify or imply our
              endorsement of such other Web site or resource or its contents.
              YOU AGREE THAT WE SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR
              ANY INFORMATION, SOFTWARE, OR MATERIALS FOUND AT ANY OTHER WEB
              SITE OR INTERNET RESOURCE.
            </p>
            <p>
              10. Commercial Use.
              <br />
              You may not copy, make derivative works, resell, distribute, or
              make any commercial use of (other than to keep and share
              information for your own non-commercial purposes) any content,
              materials, or databases from our network or systems. You may not
              sell, sublicense or redistribute our software applications or
              incorporate them (or any portion of them) into another product.
              You may not reverse engineer, decompile or disassemble the
              software or otherwise attempt to derive the source code (except
              where expressly permitted by law) or the communications protocol
              for accessing the Services or our networks. You may not modify,
              adapt or create derivative works from the software or remove
              proprietary notices in the software.
            </p>
            <p>
              11. Disclaimer of Warranties.
              <br />
              YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK.
              WE PROVIDE THE SERVICES ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE
              EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, WITH RESPECT TO SportsTech Match (INCLUDING, BUT NOT
              LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR USE OR PURPOSE, AND NON-INFRINGEMENT). WE MAKE NO
              WARRANTY THAT SportsTech Match WILL MEET YOUR REQUIREMENTS, OR
              THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR
              FREE. YOU ACKNOWLEDGE THAT ACCESS TO DATA (INCLUDING, BUT NOT
              LIMITED TO, DOCUMENTS, PHOTOGRAPHS, AND SOFTWARE FILES) STORED BY
              YOU OR OTHERS ON THE SERVICES IS NOT GUARANTEED AND THAT WE SHALL
              NOT BE RESPONSIBLE TO YOU FOR ANY LOSS OF DATA CAUSED BY THE
              SERVICES OR THEIR UNAVAILABILITY. WE MAKE NO WARRANTY AS TO THE
              RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES, OR AS
              TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH
              THE SERVICES, OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED.
              YOU UNDERSTAND AND AGREE THAT ANY MATERIAL AND/OR INFORMATION
              DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES
              IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE
              SOLELY RESPONSIBLE FOR ANY DAMAGE ARISING THEREOF. NO ADVICE OR
              INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
              SportsTechMatch OR THROUGH THE SERVICES SHALL CONSTITUTE ANY
              WARRANTY.
            </p>
            <p>
              12. Limitation of Liability.
              <br />
              YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW,
              IN NO EVENT WILL WE OR OUR OFFICERS, EMPLOYEES, DIRECTORS,
              SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, OR
              LICENSORS BE LIABLE UNDER ANY THEORY OF LIABILITY (WHETHER IN
              CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR ANY DIRECT, INDIRECT,
              INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
              BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES, PROFITS,
              GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH
              PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE
              POSSIBILITY OF SUCH DAMAGES), RESULTING FROM YOUR (OR ANYONE USING
              YOUR ACCOUNT’S) USE OF THE SERVICES. UNLESS OTHERWISE PROVIDED BY
              APPLICABLE LAW, IN NO EVENT SHALL OUR LIABILITY TO YOU EXCEED THE
              AMOUNT OF FEES YOU PAID US (IF APPLICABLE) FOR A PERIOD OF THREE
              MONTHS PRIOR TO THE DATE YOU SUBMIT A CLAIM.
            </p>
            <p>
              13. Exclusions And Limitations.
              <br />
              Some jurisdictions do not allow the exclusion of certain
              warranties or the limitation or exclusion of liability for
              incidental or consequential damages. Accordingly, some of the
              above limitations and disclaimers may not apply to you. To the
              extent that we may not, as a matter of applicable law, disclaim
              any implied warranty or limit its liabilities, the scope and
              duration of such warranty and the extent of our liability shall be
              the minimum permitted under such applicable law.
            </p>
            <p>
              14. Indemnification.
              <br />
              You agree to indemnify, defend, and hold harmless us, our parents,
              subsidiaries, affiliates, officers, directors, employees,
              consultants and agents from and against any and all claims,
              liabilities, damages, losses, costs, expenses, fees (including
              reasonable attorneys fees) that such parties may incur as a result
              of or arising from your (or anyone using your accounts) violation
              of these Terms. We reserve the right, at our own expense, to
              assume the exclusive defense and control of any matter otherwise
              subject to indemnification by you, and in such case, you agree to
              cooperate with our defense of such claim.
            </p>
            <p>
              15. Trademarks & Patents.
              <br />
              “SportsTechMatch”, both the word mark and the design marks, our
              site names and logos, as well as certain other of the names,
              logos, and materials displayed in the Services constitute
              trademarks, trade names, service marks or logos (“Marks”) of us or
              other entities. You are not authorized to use any such Marks.
              Ownership of all such Marks and the goodwill associated therewith
              remains with us or those other entities.
            </p>
            <p>
              16. Copyrights; Restrictions on Use.
              <br />
              The content on the Services (the “Content”), including without
              limitation, video, text, photos, and graphics, is protected under
              Dutch and international copyright laws, is subject to other
              intellectual property and proprietary rights and laws, and is
              owned by us or our licensors. Other than with respect to your own
              User Submissions: (a) the Content may not be copied, modified,
              reproduced, republished, posted, transmitted, sold, offered for
              sale, or redistributed in any way without our prior written
              permission and that of our applicable licensors; and (b) you must
              abide by all copyright notices, information, or restrictions
              contained in or attached to any Content. We give you a personal,
              revocable, non-assignable, non-sublicensable and non-exclusive
              right to access and use the Services in the manner permitted by
              these Terms.
            </p>
            <p>
              17. Electronic Notices.
              <br />
              You agree to transact with us electronically. Your affirmative act
              of registering, using or logging into the Services constitutes
              your acceptance signature to these Terms. WE MAY PROVIDE NOTICES
              TO YOU ELECTRONICALLY (1) VIA E-MAIL IF YOU HAVE PROVIDED US WITH
              A VALID EMAIL ADDRESS OR (2) BY POSTING THE NOTICE ON A WEBSITE
              DESIGNATED BY US FOR THIS PURPOSE. The delivery of any Notice is
              effective when sent or posted by us, regardless of whether you
              read the Notice or actually receive delivery. You can withdraw
              your consent to receive Notices electronically by discontinuing
              your use of the Services.
            </p>
            <p>
              18. Compliance with Local Laws.
              <br />
              The Services are based in The Netherlands . It is not designed or
              customized for any other country. You may use them only if they
              comply with the laws of the country from which you are accessing
              the Services.
            </p>
            <p>
              19. Miscellaneous.
              <br />
              These Terms constitute the entire and exclusive and final
              statement of the agreement between you and us with respect to the
              subject matter hereof, and govern your use of the Services,
              superseding any prior agreements or negotiations between you and
              us with respect to the subject matter hereof. In the event of any
              conflict between these Terms and terms of a specific site within
              the network of Sites, these Terms shall govern. These Terms and
              the relationship between you and us shall be governed by the laws
              of the The Netherlands as applied to agreements made, entered
              into, and performed entirely in the The Netherlands ,
              notwithstanding your actual place of residence. All lawsuits
              arising from or relating to these Terms or your use of the
              Services shall be brought in the courts located in the The
              Netherlands , and you hereby irrevocably submit to the exclusive
              personal jurisdiction of such courts for such purpose. Our failure
              to exercise or enforce any right or provision of these Terms shall
              not constitute a waiver of such right or provision. If any
              provision of these Terms is found by a court of competent
              jurisdiction to be invalid, you nevertheless agree that the court
              should endeavor to give effect to the intentions of us and you as
              reflected in the provision, and that the other provisions of these
              Terms remain in full force and effect. The section titles in these
              Terms are for convenience only and have no legal or contractual
              effect. The terms of Sections 2 and 11 through 19 of these Terms,
              as well as any other limitations on liability explicitly set forth
              herein, shall remain in full force and effect notwithstanding any
              termination of your use of the Services.
            </p>
            <p>Last updated: April 2021</p>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </IonContent>
  );
};

export default Terms;
