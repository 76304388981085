import Api from "../../Api";
import { handleError } from "./commonAction";
import { APPLICATION_CONSTANTS } from "../../Services/Strings";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";

export const CreateRFI = (state) => async (dispatch) => {
  try {
    let userData = await JSON.parse(
      localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
    );
    const res = await Api.post(ENDPOINTS.CREATE_RFI, {
      userId: userData.id,
      ...state,
    });
    console.log("CreateRFI  RESSSSSSSS", res);
    dispatch({
      type: ACTION_TYPE.CREATE_RFI,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const UpdateRFI = (state, rfiUserId) => async (dispatch) => {
  try {
    const res = await Api.post(ENDPOINTS.UPDATE_RFI, {
      userId: rfiUserId,
      ...state,
    });
    console.log("CreateRFI  RESSSSSSSS", res);
    dispatch({
      type: ACTION_TYPE.CREATE_RFI,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getApprovedVendors = (searchKeyword) => async (dispatch) => {
  try {
    const res = await Api.post(ENDPOINTS.GET_APPROVED_VENDORS, {
      searchKeyword,
    });
    console.log("GET_APPROVED_VENDORS", res.data);
    dispatch({
      type: ACTION_TYPE.GET_APPROVED_VENDORS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const getApprovedVendorsCompany =
  (searchKeyword) => async (dispatch) => {
    try {
      const res = await Api.post(ENDPOINTS.GET_APPROVED_VENDORS_COMPANY, {
        searchKeyword,
      });
      console.log("GET_APPROVED_VENDORS", res.data);
      dispatch({
        type: ACTION_TYPE.GET_APPROVED_VENDORS_COMPANY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(err));
    }
  };

export const questionsList = () => async (dispatch) => {
  try {
    const res = await Api.get(ENDPOINTS.QUESTION_LIST);
    console.log("QUESTIONLIST  RESSSSSSSS", res);
    dispatch({
      type: ACTION_TYPE.QUESTION_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
export const GetRFIs = () => async (dispatch) => {
  try {
    let userData = await JSON.parse(
      localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
    );
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.GET_RFI, {
      userId: userData.id,
    });
    console.log("CreateRFI  RESSSSSSSS", res);
    dispatch({
      type: ACTION_TYPE.GET_RFI,
      payload: res.data,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
export const RespondRFI = () => async (dispatch) => {
  try {
    let userData = await JSON.parse(
      localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
    );
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.RESPOND_RFI, {
      userId: userData.id,
    });
    console.log("CreateRFI  RESSSSSSSS", res);
    dispatch({
      type: ACTION_TYPE.RESPOND_RFI,
      payload: res.data,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const GetRFIResponse = (rfiId) => async (dispatch) => {
  try {
    const res = await Api.post(ENDPOINTS.GET_RFI_RESPONSE, {
      rfiId,
    });
    console.log("RFI  RESSSSSSSS", rfiId, res);
    dispatch({
      type: ACTION_TYPE.GET_RFI_RESPONSE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const GetSavedResponse = (rfiId) => async (dispatch) => {
  try {
    let userData = await JSON.parse(
      localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
    );
    const res = await Api.post(ENDPOINTS.GET_SAVED_RESPONSE, {
      rfiId,
      userId: userData.id,
    });

    dispatch({
      type: ACTION_TYPE.GET_SAVED_RESPONSE,
      payload: res.data,
    });
  } catch (err) {
    console.log("catch", err);
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
export const GetProducts = (vendorId) => async (dispatch) => {
  try {
    const res = await Api.post(ENDPOINTS.GET_ALL_PRODUCTS, { vendorId });
    console.log("products  RESSSSSSSS", vendorId, res);
    dispatch({
      type: ACTION_TYPE.GET_ALL_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    console.log("catch", err);
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

//submit the rfi responses
export const saveRFIResponse = (data) => async (dispatch) => {
  try {
    const res = await Api.post(ENDPOINTS.SAVE_RFI_RESPONSE, { ...data });
    console.log("products  RESSSSSSSS", data, res);
    // dispatch({
    //   type: ACTION_TYPE.GET_ALL_PRODUCTS,
    //   payload: res.data,
    // });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

//get data for the evaluation table
export const getBuyerEvalutionData = (rfiId) => async (dispatch) => {
  try {
    const res = await Api.get(
      `${ENDPOINTS.GET_BUYER_EVALUATION_DATA}/${rfiId}`
    );
    console.log("getBuyerEvalutionData  RESSSSSSSS", rfiId, res);
    dispatch({
      type: ACTION_TYPE.GET_BUYER_EVALUATION_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

//send mails to all the vendors whose ids are there in the array
export const connectToVendors =
  (vendorId, NotConnect, title, rfiId) => async (dispatch) => {
    try {
      let userData = await JSON.parse(
        localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
      );
      dispatch({ type: "LOADING_STARTED" });
      const res = await Api.post(ENDPOINTS.CONNECT_TO_VENDORS, {
        vendorId: vendorId,
        NotConnect: NotConnect,
        title,
        rfiId,
        buyerEmail: userData.email,
      });
      console.log("products  RESSSSSSSS", vendorId, res);
      dispatch({ type: "LOADING_COMPLETED" });
      // dispatch({
      //   type: ACTION_TYPE.GET_ALL_PRODUCTS,
      //   payload: res.data,
      // });
    } catch (err) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(err));
    }
  };
