function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData) => {
  console.log("FORMDATAA", formData);
  const {
    firstName,
    lastName,
    email,
    organisationType,
    companyHq,
    companyName,
  } = formData || {};
  let error = {
    firstName: [],
    lastName: [],
    email: [],
    organisationType: [],
    companyHq: [],
    companyName: [],
  };
  let isFormValid = true;
  console.log("TERMS OF USE", formData);

  if (isBlank(firstName) === true) {
    isFormValid = false;
    error.firstName = ["This field is required."];
  } else if (firstName?.trim().length > 0 && firstName?.trim().length > 50) {
    isFormValid = false;
    error.firstName = ["Name cannot be greater than 50."];
  }
  if (isBlank(lastName) === true) {
    isFormValid = false;
    error.lastName = ["This field is required."];
  } else if (lastName?.trim().length > 0 && lastName?.trim().length > 50) {
    isFormValid = false;
    error.lastName = ["Last Name cannot be greater than 50."];
  }

  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  } else if (
    email.trim().length > 0 &&
    /\S+@\S+\.\S+/.test(formData.email) === false
    // /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,8})$/.test(
    //   formData.email
    // ) === false
  ) {
    isFormValid = false;
    error.email = ["Email must be a valid email address."];
  }

  if (isBlank(companyHq) === true) {
    isFormValid = false;
    error.companyHq = ["This field is required."];
  }
  if (isBlank(companyName) === true) {
    isFormValid = false;
    error.companyName = ["This field is required."];
  }
  if (isBlank(organisationType) === true) {
    isFormValid = false;
    error.organisationType = ["This field is required."];
  }

  return { isFormValid, error };
};

export const FormatInterestedCounty = (obj, totalIntrestedCountry) => {
  let formattedResponse = [];

  totalIntrestedCountry.map((data, i) => {
    if (obj.hasOwnProperty(data.county) && obj.hasOwnProperty(data.state)) {
      let valCont = data.county;
      let valState = data.state;
      formattedResponse.push({ county: obj[valCont], state: obj[valState] });
    }
  });
  console.log("formattedResponse", formattedResponse);
  return formattedResponse;
};
