import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { RATING_REQUEST_STATUS } from "../../Services/Contants";
import { useDispatch, useSelector } from "react-redux";
import { addEditRatingReviewRequest } from "../../ReduxStore/Actions/ratingReviewActions";

import { formatDate } from "../../Utils/helpers";
import { CircularProgress } from "@mui/material";
import logoStm from "../../images/logoStm.png";

const RequestCard = ({
  rating_requested_id: ratingRequestedId,
  requested_at: requestedAt,
  message_to_reviewer: messageToReviewer,
  product_id: productId,
  reviewer_company_name: reviewerCompanyName,
  reviewer_email: reviewerEmail,
  reviewer_name: reviewerName,
  status,
  productList,
  logoUrl,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ratingClicked, setRatingClicked] = useState(null);
  console.log("productList", productList);
  const productName = productList.find((prod) => prod.id === productId);
  console.log("product name prod", productName, status);

  const loading = useSelector((state) => state.commonReducer.loading);

  const handleSubmit = async () => {
    setRatingClicked(ratingRequestedId);
    const formData = {
      ratingRequestedId,
      requestedAt,
      messageToReviewer,
      productIds: [productId],
      reviewerCompanyName,
      reviewerEmail,
      reviewerName,
      status: RATING_REQUEST_STATUS.EMAIL_SENT.enumVal,
    };
    await dispatch(
      addEditRatingReviewRequest({
        formData,
        history,
      })
    );
    setRatingClicked(null);
    history.go(0);
  };
  return (
    <div className="productList">
      <div className="productImg">
        <img
          style={{ objectFit: "contain" }}
          src={
            logoUrl.includes("vendor-placeholder-logo.png") ? logoStm : logoUrl
          }
          alt="logo"
        />
      </div>
      <div className="productInfoContainer">
        <h2>{productName && productName.productName}</h2>
        <div className="productInfoTable">
          <div>
            <p>Name of Reviewer</p>
            <p>:</p>
            <p>{reviewerName}</p>
          </div>
          <div>
            <p>Company Name of Reviewer</p>
            <p>:</p>
            <p>{reviewerCompanyName}</p>
          </div>
          <div>
            <p>Email of Reviewer</p>
            <p>:</p>
            <p>{reviewerEmail}</p>
          </div>
          <div>
            <p>Date of Creation</p>
            <p>:</p>
            <p>{formatDate(requestedAt)}</p>
          </div>
          <div>
            <p>Status</p>
            <p>:</p>
            <p style={status && RATING_REQUEST_STATUS[status].style}>
              {status && RATING_REQUEST_STATUS[status].text}
            </p>
          </div>
        </div>
      </div>
      <div className="btnContainers">
        <Link
          className="ratingLink"
          to={{
            pathname: `/product/${productId}`,
          }}
        >
          View Product Details
        </Link>
        {status === RATING_REQUEST_STATUS.SAVED.enumVal && (
          <>
            <Link
              className="ratingLink"
              to={{
                pathname: `/create-request/${productId}`,
              }}
              style={{
                display: "block",
              }}
            >
              Edit
            </Link>
            <button onClick={handleSubmit}>
              {loading && ratingClicked === ratingRequestedId ? (
                <CircularProgress size={20} color="info" />
              ) : (
                "Submit"
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestCard;
