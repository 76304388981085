import {
  Divider,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import "./ViewVendor.css";
import SideNav from "../sidebar/sideNav";
import Search from "../SearchBar/searchbar";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction, AdminAction } from "../../ReduxStore/Actions/index";
import { Redirect, useLocation } from "react-router-dom";
import { ROUTES } from "../../Services";

const ViewVendor = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const authData = useSelector((state) => state.authReducer.authData);
  const list = useSelector((state) => state.AdminReducer.approvedVendorList);
  console.log(list, "klllll");
  const redirectTo = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return (
      <Redirect
        to={
          userData?.role === 1
            ? `${ROUTES.Vendors}`
            : userData?.role === 0
            ? `${ROUTES.RfiDashboard}`
            : userData?.role === 2
            ? `${ROUTES.AdminProfile}`
            : userData?.role === 4
            ? `${ROUTES.AdminSupportProfile}`
            : `${location.pathname}`
        }
      />
    );
  };
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(AdminAction.getApprovedVendorList());
    })();
  }, []);
  return (
    <div className="top">
      <div className="mainBody1">
        {authData && authData.role === 2 ? (
          <>
            <SideNav />

            <div className="col-md-9 col-sm-12 viewApprove vendor-list">
              <h3 className="heading"> View Vendors & Buyers </h3>
              <Search type="vendor" />
              <ul style={{ color: "black", listStyle: "none" }}>
                {list &&
                  list.map((user, i) => {
                    return (
                      <li className="vendor-Card">
                        <div className="d-flex">
                          <h3>{i + 1}.</h3>
                          <Typography
                            className="content"
                            variant="h6"
                            gutterBottom
                          >
                            <span style={{ color: "#062c56" }}> Name: </span>
                            {`${user.firstName} ${user.lastName}`}
                          </Typography>
                          <Link
                            to={{
                              pathname: `/admin-vendor/${user.vendor_id}`,
                            }}
                            className="desktopBtn"
                          >
                            <Button className="text-right Viewbtn">
                              View Info
                            </Button>
                          </Link>
                        </div>
                        <div className="viewRfiBtn">
                          <Link
                            to={{
                              pathname: `/admin-vendor/${user.vendor_id}`,
                            }}
                          >
                            <Button className="text-right Viewbtn">
                              View Info
                            </Button>
                          </Link>
                        </div>
                        <Typography
                          className="content1"
                          variant="body1"
                          gutterBottom
                        ></Typography>
                        <Divider variant="middle" />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50vh",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            {redirectTo()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewVendor;
