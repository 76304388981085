import React from "react";
import "./editProfile.css";
import {
	IonContent,
	IonInput,
	IonButton,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { ValidateChangePassword } from "./Validation";
import { AuthActions } from "../../ReduxStore/Actions/index";

const EditProfile = (props) => {
	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	});
	let userData = JSON.parse(localStorage.getItem("userData"));

	const [errorData, setError] = React.useState();
	//  console.log("propssssssssss",props)
	//   useEffect(() => {
	//     setState(userData);
	//     console.log("propssssssssss", state);
	//   }, []);
	const handleChange = (event) => {
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	async function onSubmitForm(event) {
		// setState((prevState) => ({ ...prevState, email: userData.Email }));
		// console.log("change data ", state);
		let newState = state;
		newState.email = userData.email;
		console.log("change data ");

		const result = await ValidateChangePassword(newState);
		// console.log("RESULTS", result);
		if (result.isFormValid) {
			newState.password = newState.newPassword;
			// console.log("change data ", newState);

			let info = await dispatch(AuthActions.changePassword(newState));
			// props.handleModal(false);
			console.log("info", info);

			props.handleToastMsg(info.message);
			props.handleToast(true);
		} else {
			setError(result?.error);
			props.handleToastMsg("Password Change Failed");
		}

		// props.handleModal(false);
	}
	return (
		<IonContent>
			<h3 className="edithead">Change Password</h3>
			<div className="editBody">
				<form>
					<IonGrid>
						<IonRow>
							<IonCol size="6" size-md="6"  size-xs="12">
								<div className="form-group">
									<IonLabel>Old Password</IonLabel>
									<IonInput
										type="password"
										name="oldPassword"
										className="form-control"
										value={`${state?.oldPassword}`}
										onIonChange={(event) => {
											handleChange(event);
										}}
									></IonInput>
									{errorData?.oldPassword[0] ? (
										<p>{errorData?.oldPassword[0]}</p>
									) : null}
								</div>
							</IonCol>
							<IonCol size="6" size-md="6" size-xs="12">
								<div className="form-group">
									<IonLabel>New Password</IonLabel>
									<IonInput
										type="password"
										name="newPassword"
										className="form-control"
										value={`${state && state.newPassword}`}
										onIonChange={(event) => {
											handleChange(event);
										}}
									></IonInput>
									{errorData?.newPassword[0] ? (
										<p>{errorData?.newPassword[0]}</p>
									) : null}
								</div>
							</IonCol>
							<IonCol size="12" size-md="12">
								<div className="form-group">
									<IonLabel>Confirm Password</IonLabel>
									<IonInput
										type="password"
										name="confirmPassword"
										value={`${state && state.confirmPassword}`}
										className="form-control"
										onIonChange={(event) => {
											handleChange(event);
										}}
									></IonInput>
									{errorData?.confirmPassword[0] ? (
										<p>{errorData?.confirmPassword[0]}</p>
									) : null}
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>

					<div className="text-center">
						<IonButton
							className="gradient-btn-blue"
							onClick={(event) => {
								onSubmitForm(event);
							}}
						>
							Change Password
							{/* {(() => {
                if (loading) {
                  return (
                    <IonSpinner name="crescent" className="spinner-loader" />
                  );
                }
              })()} */}
						</IonButton>
						<IonButton
							className="gradient-btn-blue"
							onClick={() => {
								props.handleModal(false);
							}}
						>
							Close
						</IonButton>
					</div>
				</form>
			</div>
		</IonContent>
	);
};
export default EditProfile;
