function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData, question) => {
  console.log("FORMDATAA", formData);
  const { response } = formData || {};
  let error = {
    response: [],
  };
  let isFormValid = true;

  if (question.length !== Object.keys(response).length) {
    isFormValid = false;
    error.response = ["Please fill all the answers"];
  }
  return { isFormValid, error };
};
