import React, { useState } from "react";
import SideBar from "../../adminPanel/sidebar/sideNav";
import { Link } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import ReviewModal from "../../components/ReviewPopup";
import SideMenu from "../../components/SideMenu";

const BuyerReview = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  // const showHideClassName = show ? "zhigh" : "zlow";
  // console.log(showHideClassName, "showHideClassName")

  console.log("Show Popup", show);

  return (
    <>
      <div className="requestReviewContainer">
        <SideMenu />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>My Ratings & Reviews</h2>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div className="productList">
              <div className="productImg">
                <img src="/assets/test.png" />
              </div>
              <div className="productInfoContainer">
                <h2>Test 01_05</h2>
                <div className="productInfoTable">
                  <div>
                    <p>Name of Reviewer</p>
                    <p>:</p>
                    <p>John Tale</p>
                  </div>
                  <div>
                    <p>Company Name of Reviewer</p>
                    <p>:</p>
                    <p>Loreum Ipsum</p>
                  </div>
                  <div>
                    <p>Email of Reviewer</p>
                    <p>:</p>
                    <p>John@Lorem.Ipsum </p>
                  </div>
                  <div>
                    <p>Date of Creation</p>
                    <p>:</p>
                    <p>03/30/2023</p>
                  </div>
                  <div>
                    <p>Status</p>
                    <p>:</p>
                    <p>Email Sent</p>
                  </div>
                </div>
              </div>
              <div className="btnContainers">
                <button onClick={() => handleShow()}>
                  View Rating & Review
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={showHideClassName}> */}
      {show && <ReviewModal closeModal={() => setShow(false)} />}
      {/* </div> */}
      <Footer />
    </>
  );
};

export default BuyerReview;
