import React, { useEffect, useState } from "react";

import "./Rfi.css";
import { IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { Link } from "react-router-dom";

import { HomeAction } from "../../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";
import { GetRFIs } from "../../../ReduxStore/Actions/RFIActions";

import RenderRfiListItems from "../../../components/Table/Table";
import { CircularProgress } from "@material-ui/core";
import {
  getAllRatingsAndReview,
  getBuyerRatingsAndReview,
} from "../../../ReduxStore/Actions/ratingReviewActions";
import CustomPagination from "../../../components/CustomPagination";
import Tooltip from "../../tooltip/Tooltip";
// import { IonButton } from "@ionic/react";
const RfiDashboard = () => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [buyerCurrentPage, setBuyerCurrentPage] = useState(1);
  const recordsPerPage = 4;
  const authData = useSelector((state) => state.authReducer.authData);
  console.log("authdData in rfi dashboard", authData);

  const { id } = authData;

  const loading = useSelector((state) => state.commonReducer.loading);

  const { allRatingsAndReview } = useSelector(
    (state) => state.RatingReviewReducer
  );
  console.log("all rating and review buyer", allRatingsAndReview);

  const { buyerRatingsAndReview } = useSelector(
    (state) => state.RatingReviewReducer
  );

  console.log("buyer rating and review buyer", buyerRatingsAndReview);

  const [showCreateRfi, setShowCreateRfi] = useState(false);

  const nPagesAll =
    allRatingsAndReview && allRatingsAndReview.COUNT
      ? Math.ceil(allRatingsAndReview.COUNT / recordsPerPage)
      : 0;

  const nPagesBuyer =
    buyerRatingsAndReview && buyerRatingsAndReview.COUNT
      ? Math.ceil(buyerRatingsAndReview.COUNT / recordsPerPage)
      : 0;

  const onPageChange = (pgNumber) => {
    console.log("onPageChange", pgNumber);
    setCurrentPage(pgNumber);

    dispatch(
      getAllRatingsAndReview({
        OFFSET: (pgNumber - 1) * recordsPerPage,
        LIMIT: recordsPerPage,
      })
    );
  };

  const onBuyerPageChange = (pgNumber) => {
    console.log("onBuyerPageChange", pgNumber);
    setBuyerCurrentPage(pgNumber);

    dispatch(
      getBuyerRatingsAndReview({
        id,
        OFFSET: (pgNumber - 1) * recordsPerPage,
        LIMIT: recordsPerPage,
      })
    );
  };

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
    dispatch(GetRFIs());

    dispatch(
      getAllRatingsAndReview({
        OFFSET: 0,
        LIMIT: recordsPerPage,
      })
    );

    dispatch(
      getBuyerRatingsAndReview({
        id,
        OFFSET: 0,
        LIMIT: recordsPerPage,
      })
    );
  }, [id, dispatch]);

  const rfiListItems = useSelector((state) => state.RFIReducer.userRfis);
  console.log("rfiListItems", rfiListItems);
  useEffect(() => {
    // if (
    //   authData &&
    //   (authData.role == 0 || (authData.role == 1 && authData.is_paid == 1))
    // )

    if (
      (authData.role === 1 &&
        authData.is_paid === 1 &&
        authData.is_vendor_admin === 1) ||
      (authData.is_vendor_admin === 1 && authData.is_assigned_to !== null) ||
      authData.role === 0
    ) {
      setShowCreateRfi(true);
    }
  }, [authData]);
  return (
    <>
      <div className="rfi-parent1 dashRfimain">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : rfiListItems.length ? (
          <>
            <div className="rfi-parent rfiCreateNew mainContentRFI">
              <h4 className="rfi-heading">RFI's Created</h4>
              <p>
                Add, edit and manage your Requests For Information here at any
                time.
              </p>
              <div
                className="rfi-table productReviewContainer"
                style={{ padding: "0" }}
              >
                <table>
                  <tr className="rfi-table-head">
                    <th style={{ width: "40%" }}>Title</th>
                    <th>
                      <span className="toolTipParentRelative">
                        Deadline
                        <div>
                          <Tooltip message="RFI's expire at 12:00CET on the deadline day" />
                        </div>
                      </span>
                    </th>
                    <th>Status</th>
                  </tr>{" "}
                  <RenderRfiListItems
                    rfiListItems={rfiListItems}
                    type="create"
                  />
                </table>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="productListContainer1">
              <h4 className="rfi-heading">RFI's Created</h4>
              <h5
                className="noRfiFlexDiv rfi-table1"
                style={{ textAlign: "center" }}
              >
                You have not created any RFI’s
              </h5>
              {showCreateRfi && (
                <Link to="/form-one">
                  <button className="createRfiBtn">
                    <IonIcon
                      className="create-icon"
                      icon={addOutline}
                    ></IonIcon>
                    <span>Create RFI</span>
                  </button>
                </Link>
              )}
            </div>
          </>
        )}

        {showCreateRfi && rfiListItems.length ? (
          <Link to="/form-one">
            <button className="createRfiBtn">
              <IonIcon className="create-icon" icon={addOutline}></IonIcon>
              <span>Create RFI</span>
            </button>
          </Link>
        ) : null}
      </div>
      {authData.role === 0 && (
        <>
          <div className="mainContentRFI">
            <div className="titleContainerRFI">
              <h2 className="titleRating">View All Ratings & Reviews</h2>
            </div>
            <div className="productReviewContainer">
              <table>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Vendor Name</th>
                    <th>Reviewer Company Name</th>
                    <th>Date Submitted</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allRatingsAndReview && allRatingsAndReview.COUNT > 0 && (
                    <>
                      {allRatingsAndReview.DATA.map(
                        ({
                          product_name,
                          companyName,
                          vendor_name,
                          rating_given_at,
                          rating_given_id,
                          product_id,
                          vendor_company_name,
                          organisationType,
                          companyHq,
                          anonymity,
                        }) => (
                          <tr>
                            <td>{product_name || "N/A"}</td>
                            <td>{vendor_company_name || "N/A"}</td>
                            <td>
                              {anonymity === "true"
                                ? `${organisationType}, ${companyHq}`
                                : companyName || "N/A"}
                            </td>
                            <td>
                              {(() => {
                                const date = new Date(rating_given_at);
                                // dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                                return `${date.toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                })}`;
                              })()}
                            </td>
                            <td>
                              <Link to={`/product/${product_id}`}>View</Link>
                            </td>
                          </tr>
                        )
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {allRatingsAndReview.COUNT === 0 && !loading ? (
                <p style={{ textAlign: "center", marginTop: "10px" }}>
                  No Reviews Available
                </p>
              ) : null}
            </div>
            {allRatingsAndReview && nPagesAll > 1 && (
              <CustomPagination
                nPages={nPagesAll}
                onPageChange={onPageChange}
                currentPage={currentPage}
              />
            )}
          </div>

          <div className="mainContentRFI">
            <div className="titleContainerRFI">
              <h2 className="titleRating">My Ratings & Reviews</h2>
            </div>
            <div className="productReviewContainer">
              <table>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Vendor Name</th>

                    <th>Date Submitted</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {buyerRatingsAndReview && buyerRatingsAndReview.COUNT > 0 && (
                    <>
                      {buyerRatingsAndReview?.DATA.map(
                        ({
                          product_name,
                          companyName,
                          vendor_name,
                          rating_given_at,
                          rating_given_id,
                          vendor_company_name,
                          status,
                        }) => (
                          <tr>
                            <td>{product_name || "N/A"}</td>
                            <td>{vendor_company_name || "N/A"}</td>

                            <td>
                              {(() => {
                                const date = new Date(rating_given_at);
                                // dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                                return `${date.toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                })}`;
                              })()}
                            </td>
                            <td>
                              <Link to={`/product-rating/${rating_given_id}`}>
                                View
                              </Link>
                            </td>
                          </tr>
                        )
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {buyerRatingsAndReview.COUNT === 0 && !loading ? (
                <p style={{ textAlign: "center", marginTop: "10px" }}>
                  No Reviews Available
                </p>
              ) : null}
            </div>
            {buyerRatingsAndReview && nPagesBuyer > 1 && (
              <CustomPagination
                nPages={nPagesBuyer}
                onPageChange={onBuyerPageChange}
                currentPage={buyerCurrentPage}
              />
            )}
          </div>
        </>
      )}

      {/* <Footer></Footer> */}
    </>
  );
};

export default RfiDashboard;
