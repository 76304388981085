import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import SideMenu from "../../components/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRatingsAndReview,
  getMyRatingsAndReview,
} from "../../ReduxStore/Actions/ratingReviewActions";
import PaginationControlled from "../../adminPanel/Tenders/Pagination";
import CustomPagination from "../../components/CustomPagination";

const PublishedReviews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(4);
  const [tab, setTab] = useState("MY_RATING");
  const location = useLocation();
  const dispatch = useDispatch();
  const { myRatingsAndReview, allRatingsAndReview } = useSelector(
    (state) => state.RatingReviewReducer
  );
  console.log("myRatingsAndReview", myRatingsAndReview);
  console.log("allRatingsAndReview", allRatingsAndReview);
  const loading = useSelector((state) => state.commonReducer.loading);

  useEffect(() => {
    dispatch(
      getMyRatingsAndReview({
        OFFSET: 0,
        LIMIT: recordsPerPage,
      })
    );
    dispatch(
      getAllRatingsAndReview({
        OFFSET: 0,
        LIMIT: recordsPerPage,
      })
    );
  }, []);
  const onChangeTab = (tab) => {
    setTab(tab);
    setCurrentPage(1);
    if (tab === "MY_RATING") {
      dispatch(
        getMyRatingsAndReview({
          OFFSET: 0,
          LIMIT: recordsPerPage,
        })
      );
    } else if (tab === "ALL_RATING") {
      dispatch(
        getAllRatingsAndReview({
          OFFSET: 0,
          LIMIT: recordsPerPage,
        })
      );
    }
  };

  const nPages =
    myRatingsAndReview && myRatingsAndReview.COUNT
      ? Math.ceil(myRatingsAndReview.COUNT / recordsPerPage)
      : 0;

  const nPagesAll =
    allRatingsAndReview && allRatingsAndReview.COUNT
      ? Math.ceil(allRatingsAndReview.COUNT / recordsPerPage)
      : 0;

  console.log("myRatingsAndReview", myRatingsAndReview);
  const onPageChange = (pgNumber) => {
    console.log("onPageChange", pgNumber);
    setCurrentPage(pgNumber);
    if (tab === "MY_RATING") {
      dispatch(
        getMyRatingsAndReview({
          OFFSET: (pgNumber - 1) * recordsPerPage,
          LIMIT: recordsPerPage,
        })
      );
    } else if (tab === "ALL_RATING") {
      dispatch(
        getAllRatingsAndReview({
          OFFSET: (pgNumber - 1) * recordsPerPage,
          LIMIT: recordsPerPage,
        })
      );
    }
  };

  const authData = useSelector((state) => state.authReducer.authData);
  console.log("authData in header ===>", authData);
  const localAuth = JSON.parse(localStorage.getItem("userData"));
  console.log("local auth in header", localAuth);
  return (
    <>
      <div
        className={`requestReviewContainer ${
          authData.email == "admin@sportstechmatch.com" ? "adminReqeview" : ""
        }`}
      >
        <SideMenu location={location.pathname} />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>View All Ratings & Reviews</h2>
          </div>
          <div className="reviewTabContainer">
            <button
              onClick={() => onChangeTab("MY_RATING")}
              className={tab === "MY_RATING" ? "active" : null}
            >
              My Ratings & Reviews
            </button>
            <button
              onClick={() => onChangeTab("ALL_RATING")}
              className={tab === "ALL_RATING" ? "active" : null}
            >
              All Ratings & Reviews
            </button>
          </div>
          {tab === "MY_RATING" && (
            <>
              <div className="productReviewContainer blueBorderAllRatings">
                <table>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      {/* <th>Vendor Name</th> */}
                      <th>Reviewer Company Name</th>
                      <th>Date Submitted</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myRatingsAndReview && myRatingsAndReview.COUNT > 0 ? (
                      <>
                        {myRatingsAndReview.DATA.map(
                          ({
                            product_name,
                            companyName,
                            vendor_name,
                            rating_given_at,
                            rating_given_id,
                            anonymity,
                            companyHq,
                            organisationType,
                          }) => (
                            <tr>
                              <td>{product_name || "N/A"}</td>
                              {/* <td>{vendor_name || "N/A"}</td> */}
                              <td>
                                {anonymity === "true"
                                  ? `${organisationType}, ${companyHq}`
                                  : companyName || "N/A"}
                              </td>
                              <td>
                                {(() => {
                                  const date = new Date(rating_given_at);
                                  // dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                                  return `${date.toLocaleDateString("en-GB", {
                                    year: "numeric",
                                    month: "short",
                                    day: "2-digit",
                                  })}`;
                                })()}
                              </td>
                              <td>
                                <Link to={`/product-rating/${rating_given_id}`}>
                                  View
                                </Link>
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    ) : loading ? (
                      <p>Loading...</p>
                    ) : null}
                  </tbody>
                </table>

                {myRatingsAndReview.COUNT === 0 &&
                !loading &&
                (authData.is_paid === 1 || authData.is_assigned_to !== null) ? (
                  <p style={{ textAlign: "center", marginTop: "10px" }}>
                    No Reviews Available
                  </p>
                ) : null}
                {authData.is_paid === 0 && !loading && (
                  <p style={{ textAlign: "center", marginTop: "10px" }}>
                    Upgrade your account to request ratings & reviews.
                  </p>
                )}
              </div>
              {myRatingsAndReview && nPages > 1 && (
                <CustomPagination
                  nPages={nPages}
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                />
              )}
            </>
          )}
          {tab === "ALL_RATING" && (
            <>
              <div className="productReviewContainer blueBorderAllRatings">
                <table>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Vendor Name</th>
                      <th>Reviewer Company Name</th>
                      <th>Date Submitted</th>
                      <th>Product Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRatingsAndReview && allRatingsAndReview.COUNT > 0 ? (
                      <>
                        {allRatingsAndReview.DATA.map(
                          ({
                            product_name,
                            companyName,
                            vendor_name,
                            rating_given_at,
                            rating_given_id,
                            product_id,
                            productIsApproved,
                            vendor_company_name,
                            companyHq,
                            organisationType,
                            anonymity,
                          }) => (
                            <tr>
                              <td>{product_name || "N/A"}</td>
                              <td>{vendor_company_name || "N/A"}</td>
                              <td>
                                {anonymity === "true"
                                  ? `${organisationType}, ${companyHq}`
                                  : companyName || "N/A"}
                              </td>
                              <td>
                                {(() => {
                                  const date = new Date(rating_given_at);
                                  // dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
                                  return `${date.toLocaleDateString("en-GB", {
                                    year: "numeric",
                                    month: "short",
                                    day: "2-digit",
                                  })}`;
                                })()}
                              </td>
                              <td>
                                {productIsApproved === 1
                                  ? "Approved"
                                  : productIsApproved === 0
                                  ? "Pending"
                                  : "Rejected"}
                              </td>
                              <td>
                                <Link to={`/product/${product_id}`}>View</Link>
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    ) : loading ? (
                      <p>Loading...</p>
                    ) : null}
                  </tbody>
                </table>
                {allRatingsAndReview.COUNT === 0 && !loading ? (
                  <p style={{ textAlign: "center", marginTop: "10px" }}>
                    No Reviews Available
                  </p>
                ) : null}
              </div>
              {allRatingsAndReview && nPagesAll > 1 && (
                <CustomPagination
                  nPages={nPagesAll}
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PublishedReviews;
