import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";
import { IonImg } from "@ionic/react";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#ffffff !important",
		padding: "2px",
		borderRadius: "15px",
		boxShadow: "2px 3px 8px grey",
	},
	media: {
		height: 0,
		paddingTop: "56.25%", // 16:9
	},

	expandOpen: {
		transform: "rotate(180deg)",
	},

	cardAction: {
		justifyContent: "center",
	},
	productName: {
		color: "#1b1464",
		fontWeight: "500",
	},
	companyName: {
		fontWeight: "600",
	},

	actionButton: {
		backgroundColor: "#062c56",
		color: "#ffffff",
		padding: " 6px 60px",
		borderRadius: "15px",
		"&:hover": {
			color: "#000000",
		},
	},
}));

const Results = ({ products, handleViewMore, offset }) => {
	const classes = useStyles();

	return (
		<div>
			{products.length > 0 ? (
				products.map((product) => {
					return (
						<Card className={classes.root}>
							<CardContent>
								<div className="d-flex">
									<IonImg
										className="logoC"
										src={product && product.productUrl}
										alt="company-img"
									/>

									<div className="d-flex flex-column align-self-center">
										<Typography
											className={classes.productName}
											variant="h6"
											gutterBottom
										>
											{product.product_name}
										</Typography>
										<Typography
											className={classes.companyName}
											variant="subtitle2"
											gutterBottom
										>
											{product.companyName}
										</Typography>
									</div>
								</div>
								<Divider variant="middle" />
								<div className="m-4">
									<Typography variant="body1" gutterBottom>
										{product.description}
									</Typography>
								</div>
							</CardContent>

							<CardActions className={classes.cardAction}>
								<Link
									to={{
										pathname: `/product/${product.product_id}`,
									}}
								>
									{" "}
									<Button className={classes.actionButton}>More info</Button>
								</Link>
							</CardActions>
						</Card>
					);
				})
			) : (
				<div className="noNew">
					Oops. Didn’t find what you’re looking for? Contact us.
				</div>
			)}
			{products.length > 24 && offset <= products.length ? (
				<div className="text-center">
					<button className="viewMore" onClick={handleViewMore}>
						View More
					</button>
				</div>
			) : null}
		</div>
	);
};

export default Results;
