import React from "react";
import SideBar from "../../adminPanel/sidebar/sideNav";
import { Link } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import { ROUTES } from "../../Services/index";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import SideMenu from "../../components/SideMenu";

const ViewRating = () => {
  return (
    <>
      <div className="requestReviewContainer ratingContainerRes">
        <SideMenu />
        <div className="mainContent">
          <div className="titleContainer">
            <h2>View All Rating & Review</h2>
          </div>
          <div className="viewRatingContainer">
            <div>
              <div className="innerReviewContainer">
                <div className="productImg">
                  <img src="/assets/test.png" alt="rating" />
                </div>
                <h2>Test 01_05</h2>
                <div className="reviewContainer">
                  <div className="reviewStar">
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="verifiedContainer">
                  <div>
                    <div>
                      <img src="/assets/verified.png" />
                    </div>
                    <p>Verified by</p>
                  </div>
                  <div>
                    <img src="/assets/stmLogo.png" />
                  </div>
                </div>
                <div className="reviewInfoContainer">
                  <div>
                    <p>Vendor Name</p>
                    <p>:</p>
                    <p>Test Company</p>
                  </div>
                  {/* <div>
                    <p>Sector</p>
                    <p>:</p>
                    <p>
                      Athletes & Performance, Fans & Digital Media, Commercial
                    </p>
                  </div> */}
                  {/* <div>
                    <p>Category</p>
                    <p>:</p>
                    <p>
                      Trackers / Wearables , Ticketing / E-Commerce / Payments,
                      Fan Data / Management
                    </p>
                  </div> */}
                </div>
                <div className="btmPart">
                  <img src="/assets/quotesIcon.png" />
                  <p>
                    Great engagement tools with a great customer support and
                    team behind it!
                  </p>
                  <p>
                    The engagement approach is highly valuable, as well as in
                    big stadiums but even more in indoor sports events.
                  </p>
                  <p>Agency, Germany (March 2023)</p>
                </div>
              </div>
            </div>
            <div>
              <div className="innerReviewContainer">
                <div className="productImg">
                  <img src="/assets/test.png" />
                </div>
                <h2>Test 01_05</h2>
                <div className="reviewContainer">
                  <div className="reviewStar">
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0.00878906L12.2451 6.91862H19.5106L13.6327 11.1891L15.8779 18.099L10 13.8284L4.12215 18.099L6.36729 11.1891L0.489435 6.91862H7.75486L10 0.00878906Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="verifiedContainer">
                  <div>
                    <div>
                      <img src="/assets/verified.png" />
                    </div>
                    <p>Verified by</p>
                  </div>
                  <div>
                    <img src="/assets/stmLogo.png" />
                  </div>
                </div>
                <div className="reviewInfoContainer">
                  <div>
                    <p>Vendor Name</p>
                    <p>:</p>
                    <p>Test Company</p>
                  </div>
                  {/* <div>
                    <p>Sector</p>
                    <p>:</p>
                    <p>
                      Athletes & Performance, Fans & Digital Media, Commercial
                    </p>
                  </div>
                  <div>
                    <p>Category</p>
                    <p>:</p>
                    <p>
                      Trackers / Wearables , Ticketing / E-Commerce / Payments,
                      Fan Data / Management
                    </p>
                  </div> */}
                </div>
                <div className="btmPart">
                  <img src="/assets/quotesIcon.png" />
                  <p>
                    Great engagement tools with a great customer support and
                    team behind it!
                  </p>
                  <p>
                    The engagement approach is highly valuable, as well as in
                    big stadiums but even more in indoor sports events.
                  </p>
                  <p>Agency, Germany (March 2023)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewRating;
