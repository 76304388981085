import React from "react";
import { Route } from "react-router-dom";
import { IonPage } from "@ionic/react";
import Home from "./Home/Home";
import Buyer from "./buyer/Home";
import VendorList from "./VendorList";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./WebsiteModule.css";

import { ROUTES } from "../Services/index";

import AboutUs from "./AboutUs/AboutUs";

import ContactUs from "./ContactUs/ContactUs";
import DynamicPages from "./DynamicPages/Dynamic";

import Header from "../components/Header/Header";
import "./WebsiteModule.css";

import Vendors from "../DashboardModules/Vendors/Vendors";
import EditVendor from "../DashboardModules/Vendors/editVendor";
import Search from "../DashboardModules/Search";
import ProductDetails from "../DashboardModules/ProductDetails";
import ContactVendor from "../DashboardModules/ContactVendor";
import VendorPage from "../DashboardModules/vendorpage/Index";
import AdminProfile from "../adminPanel/AdminPage/AdminPage";
import AdminSupportProfile from "../adminSupportPanel/adminSupportPage/AdminSupportPage";
import BuyersLanding from "./buyersLanding/BuyersLanding";

// adminPanel

import { useSelector } from "react-redux";
import Terms from "./PrivacyTerms/Terms";
import Privacy from "./PrivacyTerms/Privacy";

import { SignUpNew } from "../DashboardModules";

import BuyerRfiEvaluation from "../DashboardModules/Dashboard/BuyerRfiEvaluation/BuyerRfiEvaluation";
import VendorJourneyOne from "../DashboardModules/VendorJourneyOne/VendorJourneyOne";
// import RequestReview from "../DashboardModules/requestReview/RequestReview";
/* Theme variables */

const WebsiteModule = () => {
  const headerClass = useSelector((state) => state.homeReducer.headerClass);
  return (
    <IonPage>
      <Header headerClass={headerClass} />
      <Route path={ROUTES.Home} component={Home} exact />
      <Route path={ROUTES.About} component={AboutUs} exact />
      <Route path={ROUTES.ContactUs} component={ContactUs} exact />
      {/* <Route path={ROUTES.MultiStepForm} component={FormFour} exact /> */}
      <Route path={ROUTES.DYNAMIC_PAGE} component={DynamicPages} exact />
      <Route
        path={[ROUTES.Search, ROUTES.CategorySearch]}
        component={Search}
        exact
      />
      <Route path={ROUTES.ProductDetails} component={ProductDetails} exact />
      <Route path={ROUTES.ContactVendor} component={ContactVendor} exact />
      <Route path={ROUTES.EditVendor} component={EditVendor} />
      <Route path={ROUTES.AddVendor} component={Vendors} />
      <Route path={ROUTES.TERMS_AND_CONDITION} component={Terms} exact />
      <Route path={ROUTES.PRIVATE_POLICY} component={Privacy} />
      <Route path={ROUTES.VendorPage} component={VendorPage} />
      <Route path={ROUTES.VendorList} component={VendorList} />
      <Route path={ROUTES.AdminProfile} component={AdminProfile} />
      <Route
        path={ROUTES.AdminSupportProfile}
        component={AdminSupportProfile}
      />

      {/* new Routes */}
      <Route path={ROUTES.Buyer} component={Buyer} exact />
      <Route
        path={`${ROUTES.BUYER_RFI_EVALUATION}/:rfiId`}
        component={BuyerRfiEvaluation}
        exact
      />
      <Route
        path={ROUTES.VENDOR_JOURNEY_ONE}
        component={VendorJourneyOne}
        exact
      />

      <Route path={ROUTES.SignUpNew} component={SignUpNew} exact />
      {/* <Route path={ROUTES.BuyerLanding} component={BuyersLanding} exact /> */}
    </IonPage>
  );
};

export default WebsiteModule;
