import { IonContent } from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./MultiStepForm.css";
import { withRouter } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import MultiStepFormTabs from "../../components/MultiStepFormTabs";
import {
  AdminAction,
  HomeAction,
  RFIAction,
  SearchAction,
} from "../../ReduxStore/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import FormOne from "./Components/FormOne/FormOne";
import FormTwo from "./Components/FormTwo/FormTwo";
import FormThree from "./Components/FormThree/FormThree";
import FormFour from "./Components/FormFour/FormFour";
import { CreateRFI, UpdateRFI } from "../../ReduxStore/Actions/RFIActions";
import { APPLICATION_CONSTANTS, ROUTES } from "../../Services";
import { useHistory } from "react-router";
import { ArrowBack } from "@material-ui/icons";

const MultiStepForm = (props) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer);
  let listQuestions = ["", "", "", "", ""];
  let userData = JSON.parse(
    localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
  );
  let history = useHistory();
  let { approvedVendors } = useSelector((state) => state.RFIReducer);
  const [state, setState] = useState({
    opportunity_desc: "",
    organisation_desc: ``,
    rfi_title: "",
    level_of_sport: [],
    sectors: [],
    blockAllow: "",
    // customVendors:"",
    linkedInPromotion: false,
    openQuestion:
      "Would you like to add any further information, comments or clarifications to support your response to this RFI?",
    rfiDeadline: "",
    notes: "",
    questions: [],
    questionsNew: [],
  });
  let { id } = props.match.params;
  const currentPath = props.match.path;
  const [count, setCount] = useState(1);
  const [typeQuestion, setTypeQuestion] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
  });
  const [questionList, setQuestionList] = useState([]);
  const [selectVendor, setSelectVendor] = useState(false);
  const [selectCustom, setSelectCustom] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [inputList, setInputList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditRFI, setIsEditRFI] = useState(false);
  const [allApprovedVendors, setAllApprovedVendors] = useState([]);
  const [isRfiExpired, setIsRfiExpired] = useState(false);
  const [allowListVendors, setAddAllowListVendors] = useState([]);
  const [blockListVendors, setBlockListVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [customVendor, setCustomVendor] = useState([]);

  const [loading1, setLoading1] = useState(false);
  let questions = useSelector((state) => state.RFIReducer.questionList);
  let filters = useSelector((state) => state.searchReducer.filters);
  let { rfiData } = useSelector((state) => state.AdminReducer);

  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm("All of your data will be lost.Do you want to go back?")
      ) {
        setfinishStatus(true);
        // your logic
        history.goBack();
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  const handleQuestions = (name, index, type) => {
    console.log("handle name", name, index, typeQuestion);
    setTypeQuestion((prevState) => ({ ...prevState, [name]: true }));

    setTypeQuestion((prevState) => ({ ...prevState, [name]: true }));
    if (type === "oldQuestions") {
      state.questions[index] = "";
    }
    if (type === "newQuestions") {
      state.questionsNew[index] = "";
    }
  };
  const handleAddQuestions = (e, type, index) => {
    e.preventDefault();

    const values = [...inputList];
    if (type === "remove") {
      values.pop();
      state.questionsNew.splice(index, 1);
      setInputList(values);
      return;
    }
    values.push({ value: null });
    setInputList(values);
  };
  useEffect(() => {
    (async () => {
      await dispatch(RFIAction.getApprovedVendors());
    })();
  }, []);

  useEffect(() => {
    if (rfiData && rfiData.length > 0 && allApprovedVendors.length > 0) {
      if (rfiData[0].customVendors) {
        const vendors = rfiData[0].customVendors.split(",");
        vendors.forEach((vendorId) => {
          const selectedData = allApprovedVendors.find(
            (item) => item.vendor_id === Number(vendorId)
          );
          console.log("selectedData", selectedData);
          selectedData &&
            setSelectedVendors((prev) =>
              !prev.includes(selectedData.companyName)
                ? [...prev, selectedData.companyName]
                : [...prev]
            );
        });
      }
    }
  }, [allApprovedVendors, rfiData]);

  useEffect(() => {
    if (
      approvedVendors &&
      approvedVendors?.athlete &&
      rfiData &&
      rfiData.length > 0
    ) {
      setIsRfiExpired(rfiData[0].isExpired ? true : false);
      if (rfiData[0].customVendors) {
        const { athlete, fanDigitalMedia, commercial, managementOperation } =
          approvedVendors;
        const allVendors = [
          ...athlete,
          ...fanDigitalMedia,
          ...commercial,
          ...managementOperation,
        ];
        setAllApprovedVendors(allVendors);
      }
    }
  }, [approvedVendors, rfiData]);

  useEffect(() => {
    setQuestionList(questions);
  }, [questions]);
  useEffect(() => {
    document.getElementById("form-top").scrollIntoView({ behavior: "smooth" });
  }, [count]);
  useEffect(() => {
    if (rfiData && rfiData.length > 0) {
      console.log("rfiData", rfiData);
      setCustomVendor(
        rfiData[0].customVendors ? rfiData[0].customVendors.split(",") : []
      );
      setAddAllowListVendors(
        rfiData[0].allowList ? rfiData[0].allowList.split(",") : []
      );
      setBlockListVendors(
        rfiData[0].blockList ? rfiData[0].blockList.split(",") : []
      );
      let sectors;
      if (rfiData[0]?.sectors === "Custom") sectors = ["Custom"];
      else {
        sectors = rfiData[0]?.sectors
          .split(",")
          .map((data) => (!isNaN(parseInt(data)) ? parseInt(data) : data));
        setSelectVendor(true);
      }
      let questions = rfiData[0]?.questions
        .split("|")
        .filter((data, i) => i <= 4);
      let questionLists = questionList.map((data) => data.question);
      let inputQ = rfiData[0]?.questions
        .split("|")
        .map((data, index) => {
          if (!questionLists.includes(data.trim())) return index;
        })
        .filter((data) => data !== undefined);
      if (inputQ.length > 0) {
        for (let i = 0; i < inputQ.length; i++) {
          typeQuestion[`${Object.keys(typeQuestion)[inputQ[i]]}`] = true;
        }
      }
      setTypeQuestion(typeQuestion);

      if (rfiData[0]?.questions.split("|").length > 5) {
        let x = [];
        let len = rfiData[0]?.questions.split("|").length - 5;
        for (let i = 0; i < len; i++) {
          x.push({ value: null });
        }
        console.log("input list", x);
        setInputList(x);
      }

      setState({
        opportunity_desc: rfiData[0]?.opportunity_desc,
        organisation_desc: rfiData[0]?.organisation_desc,
        rfi_title: rfiData[0]?.title,
        level_of_sport: rfiData[0]?.level_of_sport.split(","),
        sectors: sectors,
        blockAllow: rfiData[0]?.blockAllow,
        linkedInPromotion: rfiData[0]?.linkedInPromotion ? true : false,
        openQuestion: rfiData[0]?.openQuestion,
        rfiDeadline: rfiData[0]?.rfiDeadline.split("T")[0],
        notes: rfiData[0]?.notes,
        questions: questions,
        questionsNew: rfiData[0]?.questions
          .split("|")
          .filter((data, i) => i > 4),
      });
    }
  }, [rfiData]);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);

    (async () => {
      await dispatch(RFIAction.questionsList());
      await dispatch(SearchAction.getFilters());
      if (id) await dispatch(AdminAction.getRFIbyId(id));
    })();
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      dispatch({ type: "GET_RFI_BY_ID", payload: [] });
    };
  }, []);

  useEffect(() => {
    console.log("authData", authData);
    if (
      authData &&
      authData?.authData &&
      authData?.authData.role == 1 &&
      authData?.authData.is_paid != 1
    ) {
      history.push(ROUTES.Vendors);
      return;
    }

    if (currentPath === ROUTES.UpdateRFI_Admin) {
      setIsEditRFI(true);
    }
  }, [authData]);

  const changeCount = (newCount) => {
    setCount(newCount);
  };
  const onSubmit = async (flag) => {
    // console.log("RFI_STATE", , flag);
    // return;
    if (flag === 0) setLoading(true);
    else {
      setLoading1(true);
    }
    if (state.questionsNew.length > 0) {
      state.questionsNew.forEach((item) => state.questions.push(item));
    }
    state.flag = flag;
    state.rfiId = id;
    if (isEditRFI) {
      await dispatch(
        UpdateRFI(
          { ...state, customVendor, allowListVendors, blockListVendors },
          rfiData && rfiData.length > 0 ? rfiData[0].userId : null
        )
      );
    } else {
      await dispatch(
        CreateRFI({
          ...state,
          customVendor,
          allowListVendors,
          blockListVendors,
        })
      );
    }
    if (localStorage.getItem("token") && authData.authData.role === 2) {
      history.push({
        pathname: ROUTES.RFI_DETAILSnotId + id,
        state: { type: "Approve" },
      });
    }
    if (localStorage.getItem("token") && authData.authData.role === 1) {
      history.push(ROUTES.Vendors);
    }
    if (localStorage.getItem("token") && authData.authData.role === 0) {
      history.push(ROUTES.RfiDashboard);
    }

    if (flag === 0) setLoading(false);
    else setLoading1(false);
  };

  const handleChange = (event, label) => {
    let name = undefined,
      value = undefined;
    if (event?.target) {
      name = event.target.name;
      value = event.target.value;
    }
    // { name, value } = event?.target;
    console.log("Calledddd", "name", "value", label, name, value);
    if (name === "level_of_sport") {
      let clubData = [...state.level_of_sport];
      if (event.target.checked === true) {
        clubData.push(label);
      } else {
        clubData = clubData.filter((item) => item !== label);
      }
      setState((prevState) => ({ ...prevState, [name]: clubData }));
      setState((prevState) => ({
        ...prevState,
        organisation_desc:
          userData.companyHq === "undefined"
            ? `${
                userData.organisationType
              } looking for a solution to be implemented at the ${clubData.map(
                (data) => ` ${data} `
              )} level of the sport.`
            : `${userData.organisationType} with HQ in ${
                userData.companyHq
              } looking for a solution to be implemented at the ${clubData.map(
                (data) => ` ${data} `
              )} level of sport.`,
      }));

      // ${userData.organisationType}  looking for a solution to be implemented at the ${state?.level_of_sport} level of sport.
    } else if (name === "linkedInPromotion") {
      if (event.target.checked === true) {
        setState((prevState) => ({ ...prevState, [name]: true }));
      } else setState((prevState) => ({ ...prevState, [name]: undefined }));
    } else if (name === "sectors") {
      let clubData;

      if (event.target.checked === true && label !== "Custom") {
        clubData = [...state.sectors];
        clubData.push(label);
        // state.blockAllow = "";
        // clubData = clubData.filter((item) => item !== "Custom");
      } else if (event.target.checked === false) {
        clubData = [...state.sectors];
        clubData = clubData.filter((item) => item !== label);
        setModalShow(false);
        if (label === "Custom") {
          setCustomVendor([]);
          setSelectedVendors([]);
          setState((prevState) => ({ ...prevState, blockAllow: "" }));
        }
      } else if (label === "Custom") {
        clubData = [...state.sectors, "Custom"];
        state.sectors = [...state.sectors, "Custom"];
        setModalShow(true);
      }
      setState((prevState) => ({ ...prevState, [name]: clubData }));
    } else if (name === "questions") {
      console.log("************VALUEEE", value);
      state.questions[label] = value;
      setState({ ...state });
    } else if (name === "questionsNew") {
      console.log(
        "questionsNew",
        state.questionsNew,
        state.questionsNew.length
      );
      const questionsNew = state.questionsNew;
      questionsNew[label] = value;
      setState((prev) => ({
        ...prev,
        questionsNew: questionsNew,
      }));
    } else if (!name && !value) {
      setState((prevState) => ({ ...prevState, opportunity_desc: event }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  if ((authData.authData.role !== 2 && isEditRFI) || isRfiExpired) {
    return (
      <div style={{ marginTop: 120, textAlign: "center" }}>Invalid Url</div>
    );
  }

  return (
    <IonContent>
      <div
        className="formTopArr"
        style={{
          width: "100%",
          maxWidth: "80%",
          margin: " 0 auto",
          paddingTop: "100px",
          cursor: "pointer",
        }}
      >
        <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
          <ArrowBack />
        </span>
      </div>
      <MultiStepFormTabs id={id} count={count} />
      <div id="form-top" className="multiStepFormOuterWrapper">
        {count === 1 && (
          <FormOne
            state={state}
            handleChange={handleChange}
            changeCount={changeCount}
          />
        )}
        {count === 2 && (
          <FormTwo
            state={state}
            questions={questionList}
            handleChange={handleChange}
            changeCount={changeCount}
            handleQuestions={handleQuestions}
            typeQuestion={typeQuestion}
            listQuestions={listQuestions}
            setTypeQuestion={setTypeQuestion}
            inputList={inputList}
            handleAddQuestions={handleAddQuestions}
          />
        )}
        {count === 3 && (
          <FormThree
            state={state}
            handleChange={handleChange}
            changeCount={changeCount}
            filters={filters}
            setState={setState}
            selectVendor={selectVendor}
            setSelectVendor={setSelectVendor}
            selectCustom={selectCustom}
            setSelectCustom={setSelectCustom}
            setModalShow={setModalShow}
            modalShow={modalShow}
            customVendor={customVendor}
            setCustomVendor={setCustomVendor}
            selectedVendors={selectedVendors}
            setSelectedVendors={setSelectedVendors}
            setAllowListVendors={setAddAllowListVendors}
            setBlockListVendors={setBlockListVendors}
            blockListVendors={blockListVendors}
            allowListVendors={allowListVendors}
          />
        )}
        {count === 4 && (
          <FormFour
            state={state}
            handleChange={handleChange}
            changeCount={changeCount}
            onSubmit={onSubmit}
            filters={filters}
            loading={loading}
            loading1={loading1}
            customVendor={customVendor}
            selectedVendors={selectedVendors}
            blockListVendors={blockListVendors}
            allowListVendors={allowListVendors}
          />
        )}
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default withRouter(MultiStepForm);
