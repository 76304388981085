import React, { useEffect, useState } from "react";
import "./BuyerRfiEvaluation.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useHistory, useParams } from "react-router";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { IonContent, IonToggle } from "@ionic/react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DashboardLink from "../Components/DashboardLink/DashboardLink";
import { HomeAction, RFIAction } from "../../../ReduxStore/Actions";
import ToolTip from "../../tooltip/Tooltip";
import Modal2 from "../Modal/Modal2";
import Modal from "../Modal/Modal";
import { ACTION_TYPE, ROUTES } from "../../../Services";
import { CircularProgress, Slider } from "@material-ui/core";
import { Button } from "@mui/material";
const BuyerRfiEvaluation = (props) => {
  let { rfiId } = useParams();
  let history = useHistory();

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showImg1, setShowImg1] = useState(false);
  const [showImg2, setShowImg2] = useState(false);
  const { buyerData } = useSelector((state) => state.RFIReducer);
  const loading = useSelector((state) => state.commonReducer.loading);

  const { rfiData } = useSelector((state) => state.RFIReducer);
  const [questions, setQuestions] = useState([]);
  let [connectTo, setConnectTo] = useState([]);
  let [dontConnectTo, setDontConnectTo] = useState([]);

  console.log("BuyerRfiEvaluation", buyerData, rfiData);

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
    return () => {
      dispatch({ type: ACTION_TYPE.GET_BUYER_EVALUATION_DATA, payload: [] });
    };
  }, []);

  useEffect(() => {
    (async () => {
      dispatch(RFIAction.GetRFIResponse(rfiId));
      dispatch(RFIAction.getBuyerEvalutionData(rfiId));
    })();
  }, [rfiId]);

  useEffect(() => {
    (async () => {
      if (buyerData && buyerData[0]?.question_response) {
        setQuestions(Object.keys(buyerData[0]?.question_response));
      }
    })();
  }, [buyerData]);

  const handleChange = (event, newValue, id) => {
    if (newValue === 0) {
      connectTo.push(id);
      setConnectTo(connectTo);
      dontConnectTo = dontConnectTo.filter((data) => data !== id);
      setDontConnectTo(dontConnectTo);
    } else if (newValue === 100) {
      dontConnectTo.push(id);
      setDontConnectTo(dontConnectTo);
      connectTo = connectTo.filter((data) => data !== id);
      setConnectTo(connectTo);
    } else if (newValue === 0) {
      connectTo = connectTo.filter((data) => data !== id);
      setConnectTo(connectTo);
      dontConnectTo = dontConnectTo.filter((data) => data !== id);
      setDontConnectTo(dontConnectTo);
    }
    console.log("CONNECT or DON", connectTo, dontConnectTo);
  };

  const handleClose = (show) => {
    setShow(show);
    setShowImg1(false);
    setShowImg2(false);
    setShow1(false);
  };

  // const handleChecked = (vId) => {
  //   setConnectTo([...connectTo, vId]);
  // };

  // const handleUnchecked = (vId) => {
  //   const vendId = connectTo.filter((id) => id !== vId);
  //   setConnectTo(vendId);
  // };

  const handleSubmit = async () => {
    await dispatch(
      RFIAction.connectToVendors(
        connectTo,
        dontConnectTo,
        rfiData[0]?.title,
        rfiId
      )
    );
    setShow1(true);
    history.goBack();
  };

  return (
    <IonContent>
      {questions && questions.length > 0 ? (
        <div className="rfi-evaluation-parent">
          <div className="rfi-evaluation-head">
            <DashboardLink />
            <p>
              <strong>RFI Title: {rfiData[0]?.title}</strong>
            </p>
            <p>
              <strong>RFI Deadline:</strong>{" "}
              {rfiData[0]?.rfiDeadline.split("T")[0]}
            </p>
          </div>

          <div className="rfi-evaluation-table">
            <TableContainer component={Paper}>
              <Table aria-label="simple table " className="tableborder">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <div className="connectVendors">
                        Email To Vendors
                        <span className="giveZINDEX ">
                          <ToolTip
                            message={`Toggle to 'Connect' or 'Don't Connect' to send an automated email to successful and unsuccessful vendors and click on submit to send.`}
                            // message={`Your questions should focus on your "must-have" requirements in terms of, for example, the key features that you require, the budget that you have and the time to implement the solution.`}
                          />
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell align="center">Solution</TableCell>
                    <TableCell align="center">Yes Response</TableCell>

                    {questions?.map((response) => {
                      return <TableCell align="center">{response}</TableCell>;
                    })}
                    <TableCell align="center">
                      {rfiData && rfiData[0]?.openQuestion}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {buyerData?.map((response) => {
                    // console.log("response", Object.entries(response.question_response));
                    return (
                      <TableRow
                        key={response?.companyName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          scope="row"
                          style={{
                            minWidth: 300,

                            border: "none",
                          }}
                        >
                          <div className="connectToggle">
                            <span
                              style={
                                response?.connected === 1
                                  ? {
                                      color: "green",
                                      padding: 8,
                                      textDecoration: "underline",
                                      fontWeight: 700,
                                    }
                                  : { color: "green", padding: 8 }
                              }
                            >
                              {" "}
                              Connect
                            </span>
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={50}
                              step={50}
                              disabled={
                                response?.connected !== 0 ? true : false
                              }
                              onChangeCommitted={(event, newValue) =>
                                handleChange(
                                  event,
                                  newValue,
                                  response?.vendor_id
                                )
                              }
                            />
                            <span
                              style={
                                response?.connected === 2
                                  ? {
                                      color: "red",
                                      padding: 8,
                                      textDecoration: "underline",
                                      fontWeight: 700,
                                    }
                                  : { color: "red", padding: 8 }
                              }
                            >
                              Dont'Connect{" "}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {response?.website !== "undefined" ||
                          response?.website !== "" ? (
                            <a
                              href={response?.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <u> {response?.companyName} </u>
                            </a>
                          ) : (
                            response?.companyName
                          )}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          <Link
                            to={`/product/${response?.proID}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u> {response?.name}</u>
                          </Link>
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {response?.yesCount}/{questions?.length}
                        </TableCell>
                        {Object.entries(response.question_response).map(
                          (entry) => {
                            return (
                              <TableCell
                                align="center"
                                scope="row"
                                style={{
                                  backgroundColor:
                                    entry[1].toLocaleLowerCase() == "no"
                                      ? "#FF6E6E"
                                      : "transparent",
                                }}
                              >
                                {entry[1]}
                              </TableCell>
                            );
                          }
                        )}
                        <TableCell align="center" scope="row">
                          {response?.open_question}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="rfi-evaluation-foot">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <p>
                <strong>
                  <div className="unsuccessFulVendors">
                    View email to unsuccessful vendors{" "}
                    {/* <IonIcon icon={informationCircle}></IonIcon> */}
                    <p>
                      <span>
                        <ToolTip
                          message={`This is the standard email that we send to vendors that you toggle to "Don't Connect". Please note that each unsuccessful vendor will be offered the opportunity to request a short feedback from you (which is given anonymously via STM). Vendors rely on feedback to help them grow. Therefore we strongly encourage all buyers to take a few minutes to respond to such requests.`}
                        />
                      </span>
                    </p>
                  </div>
                </strong>
              </p>
              <button
                onClick={() => setShowImg2(true)}
                className="rfi-foot-submit-button"
              >
                View Email
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 26,
              }}
            >
              {" "}
              <p>
                <strong>
                  <div className="successfulVendors">
                    View email to successful vendors{" "}
                    <p>
                      <span>
                        <ToolTip
                          message={`This is the standard email that we send to vendors you toggle to "Connect" (you also receive these emails).`}
                        />
                      </span>
                    </p>
                  </div>
                </strong>
              </p>
              <button
                onClick={() => setShowImg1(true)}
                className="rfi-foot-submit-button"
              >
                View Email
              </button>
            </div>
            <div className="rfi-evaluation-foot-submit">
              <p style={{ textAlign: "center", marginTop: "30px" }}>
                <strong>
                  <div className="submitVendors">
                    Contact the SportsTech Match team if you need support
                    <p></p>
                  </div>
                </strong>
              </p>
              <Link
                to={ROUTES.ContactUs}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="rfi-foot-submit-button">Contact Us</button>
              </Link>
            </div>
            <div className="rfi-evaluation-foot-submit">
              <p style={{ textAlign: "center", marginTop: "30px" }}>
                <strong>
                  <div className="submitVendors">
                    Click on submit to generate the automated email to the
                    vendors.
                    <p>
                      <span>
                        <ToolTip
                          message={`Please check that you have toggled to the correct setting (connect or don't connect). Once you click submit, the RFI responses will be available to view in your dashboard. However, you will be unable to return to this screen to change the toggle settings.`}
                        />
                      </span>
                    </p>
                  </div>
                </strong>
              </p>
              <button
                className="rfi-foot-submit-button"
                onClick={() => {
                  setShow(true);
                  // handleSubmit();
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress /> : "Submit"}
              </button>
              {show && (
                <Modal2
                  message="Are you sure you want to proceed to generate the automated emails to vendors that you just selected?"
                  handleClose={handleClose}
                  button
                  handleSubmit={handleSubmit}
                  open={show}
                />
              )}
              {/*  {show && (
                <Modal
                  message="Are you sure you want to proceed to generate the automated emails to both the successful and unsuccessful vendors?"
                  handleClose={handleClose}
                  button
                  handleSubmit={handleSubmit}
                />
              )}
               {show1 && (
                <Modal
                  message="SUCCESS!"
                  handleClose={handleClose}
                  subtitle="Your emails are being sent. You will
                 be copied on emails to the vendors
                 that you wanted to connect with
                 (check your spam folder)"
                  handleSubmit={handleSubmit}
                />
              )}
              {showImg1 && <Modal handleClose={handleClose} image1 />}
              {showImg2 && <Modal handleClose={handleClose} image2 />} 
              */}
              {show1 && (
                <Modal2
                  message="SUCCESS!"
                  subtitle="Your emails are being sent. You will
                 be copied on emails to the vendors
                 that you wanted to connect with
                 (check your spam folder)"
                  handleSubmit={handleSubmit}
                />
              )}
              {showImg1 && (
                <Modal2 handleClose={handleClose} open={showImg1} image1 />
              )}
              {showImg2 && (
                <Modal2 handleClose={handleClose} open={showImg2} image2 />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="noResponse noResp">
          <h1>No Responses</h1>

          <Button
            variant="contained"
            color="primary"
            onClick={() => history.goBack()}
          >
            Go Back
          </Button>
        </div>
      )}
    </IonContent>
  );
};

export default BuyerRfiEvaluation;
