function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

function wordCount(str) {
  return str.split(" ").length;
}

export const ValidateRequestForm = (formData) => {
  console.log("formData", formData);
  const {
    reviewerName,
    reviewerCompanyName,
    reviewerEmail,
    productIds,
    messageToReviewer,
  } = formData || {};

  let error = {
    reviewerName: [],
    reviewerCompanyName: [],
    reviewerEmail: [],
    productIds: [],
    messageToReviewer: [],
  };
  let isFormValid = true;

  if (productIds.length <= 0) {
    isFormValid = false;
    error.productIds = ["Please select a product!"];
  }
  if (isBlank(reviewerName) === true) {
    isFormValid = false;
    error.reviewerName = ["This field is required!"];
  } else if (/^[A-Za-z ]+$/.test(reviewerName) === false) {
    isFormValid = false;
    error.reviewerName = ["Name cannot contain numbers or special characters!"];
  }

  if (isBlank(reviewerCompanyName) === true) {
    isFormValid = false;
    error.reviewerCompanyName = ["This field is required!"];
  } else if (!isNaN(reviewerCompanyName.trim().replace(/\s/g, ""))) {
    isFormValid = false;
    error.reviewerCompanyName = ["Company name cannot be only numbers."];
  } else if (/^[A-Za-z0-9 ]+$/.test(reviewerCompanyName) === false) {
    isFormValid = false;
    error.reviewerCompanyName = [
      "Company name cannot contain special characters!",
    ];
  }
  if (isBlank(reviewerEmail) === true) {
    isFormValid = false;
    error.reviewerEmail = ["This field is required!"];
  } else if (
    reviewerEmail.trim().length > 0 &&
    /^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\.([a-zA-Z]{2,})$/.test(
      formData.reviewerEmail
    ) === false
  ) {
    isFormValid = false;
    error.reviewerEmail = ["Please enter a valid Email!"];
  }
  if (isBlank(messageToReviewer) === true) {
    isFormValid = false;
    error.messageToReviewer = ["This field is required!"];
  } else if (wordCount(messageToReviewer) > 200) {
    isFormValid = false;
    error.messageToReviewer = ["Word limit exceeded!"];
  }

  return { isFormValid, error };
};
