import { IonInput, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { matchToken } from "../../ReduxStore/Actions/authActions";
import {
  deleteVendorUser,
  getVendorUsers,
} from "../../ReduxStore/Actions/vendorAction";

import "./ManageVendorAdmin.css";

const VendorUserModal = (props) => {
  const authData = useSelector((state) => state.authReducer.authData);
  const loading = useSelector((state) => state.commonReducer.loading);

  const [vendorUserId, setVendorUserId] = useState("");

  const dispatch = useDispatch();
  const onDeleteVendorUser = () => {
    dispatch(
      deleteVendorUser({ id: vendorUserId }, () => {
        dispatch(
          getVendorUsers(props.mainUserId ? props.mainUserId : authData?.id)
        );
        dispatch(matchToken());
        props.onHide();
      })
    );
  };

  useEffect(() => {
    if (props.selectedVendor) {
      setVendorUserId(props.selectedVendor.id);
    }
  }, []);

  return (
    <Modal
      {...props}
      size="md"
      onHide={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="vendorUserModalContainer"
    >
      {/* <Modal.Header > */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Vendor Account
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "25vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Modal.Body>
            <div className="customListTop">
              <h4>Are you sure you want to delete this vendor account?</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Cancel</Button>
            <Button variant="danger" onClick={onDeleteVendorUser}>
              Delete
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default VendorUserModal;
