import {
	IonContent,
	IonInput,
	IonButton,
	IonLabel,
	IonSpinner,
	IonIcon,
} from "@ionic/react";

import React, { useEffect } from "react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { ValidateSignUpForm } from "./Validation";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { AuthActions } from "../../ReduxStore/Actions/index";
import { Toast } from "../../Utils/Toast";

const ResetPassword = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [state, setState] = React.useState();
	const [errorData, setError] = React.useState();
	const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
	const [isShowPassword, setPasswordType] = React.useState(false);
	const [isShowConfirmPassword, setConfirmPasswordType] = React.useState(false);
	const errorStatus = useSelector((state) => state.commonReducer.status);
	const authData = useSelector((state) => state.authReducer);
	const loading = useSelector((state) => state.commonReducer.loading);

	useEffect(() => {
		const resetKey = queryString.parse(props.location.search).accessKey;
		setState((prevState) => ({ ...prevState, resetKey: resetKey }));
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	async function onSubmitForm(event) {
		event.preventDefault();
		const result = await ValidateSignUpForm(state);
		// console.log("RESUL", result);
		if (result?.isFormValid) {
			setError({});
			await dispatch(AuthActions.resetPassword(state));
			setState({ password: "", confirmPassword: "" });
			history.push("/login");
		} else {
			setError(result?.error);
		}
	}

	return (
		<IonContent>
			<div className="login">
				<div className="login-inner">
					{(errorStatus === false || errorStatus === true) && errorMessage ? (
						<Toast
							isOpen={errorStatus === false ? true : false}
							message={errorMessage}
							isSuccess={errorStatus}
							isClearSuccess={true}
							isClearError={true}
						/>
					) : authData?.status ? (
						<Toast
							isOpen={authData?.status}
							message={authData?.message}
							isSuccess={authData?.status}
							isClearSuccess={true}
							isClearError={true}
							duration={10000}
						/>
					) : null}
					<div className="login-head">
						<h2>Reset Password</h2>
					</div>
					<div className="login-body">
						<form>
							<div className="form-group">
								<IonLabel>
									Password<sup>*</sup>
								</IonLabel>
								<div className="showpassword-input">
									<IonInput
										type={isShowPassword == true ? "text" : "password"}
										value={state?.password ? state?.password : ""}
										name="password"
										className="form-control"
										onIonChange={(event) => {
											handleChange(event);
										}}
									></IonInput>
									{isShowPassword === true ? (
										<a onClick={() => setPasswordType(false)}>
											<IonIcon icon={eyeOutline} />
										</a>
									) : (
										<a onClick={() => setPasswordType(true)}>
											<IonIcon icon={eyeOffOutline} />
										</a>
									)}
								</div>
								<p> {errorData?.password && errorData.password[0]}</p>
							</div>

							<div className="form-group">
								<IonLabel>
									Confirm Password<sup>*</sup>
								</IonLabel>
								<div className="showpassword-input">
									<IonInput
										type={isShowConfirmPassword == true ? "text" : "password"}
										value={state?.confirmPassword ? state?.confirmPassword : ""}
										name="confirmPassword"
										className="form-control"
										onIonChange={(event) => {
											handleChange(event);
										}}
									></IonInput>
									{isShowConfirmPassword === true ? (
										<a onClick={() => setConfirmPasswordType(false)}>
											<IonIcon icon={eyeOutline} />
										</a>
									) : (
										<a onClick={() => setConfirmPasswordType(true)}>
											<IonIcon icon={eyeOffOutline} />
										</a>
									)}
								</div>
								<p>
									{" "}
									{errorData?.confirmPassword && errorData.confirmPassword[0]}
								</p>
							</div>

							<div className="button-login">
								<IonButton
									className="gradient-btn-blue"
									color="none"
									onClick={(event) => {
										onSubmitForm(event);
									}}
								>
									Reset Password
									{(() => {
										if (loading) {
											return (
												<IonSpinner
													name="crescent"
													className="spinner-loader"
												/>
											);
										}
									})()}
								</IonButton>
							</div>

							{/* <div class="haveaccount">
                <p>
                  Already have an account?{" "}
                  <Link to={ROUTES.Login}> Log In</Link>
                </p>
              </div> */}
						</form>
					</div>
				</div>
			</div>
		</IonContent>
	);
};

export default withRouter(ResetPassword);
