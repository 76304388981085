import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const TendersTab = ({
  value,
  setValue,
  setShowActiveTenders,
  setShowForm,
  setShowNewTenders,
  setTendersAccessed,
}) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        className="tablabelContainer"
      >
        <Tab
          label="New Tenders"
          selected
          aria-selected
          onClick={() => {
            setShowForm(false);
            setShowActiveTenders(false);
            setTendersAccessed(false);
            setShowNewTenders(true);
          }}
        />
        <Tab
          label="Active Tenders"
          onClick={() => {
            setShowForm(false);
            setShowNewTenders(false);
            setTendersAccessed(false);
            setShowActiveTenders(true);
          }}
        />
        <Tab
          label="Add Tender"
          onClick={() => {
            setShowActiveTenders(false);
            setShowNewTenders(false);
            setTendersAccessed(false);
            setShowForm(true);
          }}
        />
        <Tab
          label="Tenders Accessed"
          onClick={() => {
            setShowActiveTenders(false);
            setShowNewTenders(false);
            setShowForm(false);
            setTendersAccessed(true);
          }}
        />
      </Tabs>
    </Paper>
  );
};

export default TendersTab;
