import {
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  TextField,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import Input from "@material-ui/core/Input";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonToast,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import "./Vendors.css";
import { useSelector, useDispatch } from "react-redux";
import { VendorAction, HomeAction } from "../../ReduxStore/Actions/index";
import ToolTip from "../tooltip/Tooltip";
import { ValidateVendorForm } from "./Validation";
import { ROUTES } from "../../Services";
import Footer from "../../components/Footer/Footer";
import { Link, useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

const Vendors = (props) => {
  const dispatch = useDispatch();
  const [sectorData, setSectorData] = React.useState();
  const [errorData, setError] = React.useState();
  const history = useHistory();
  console.log(
    "histoyr in vendors",
    history?.location?.state?.prevPath?.includes("/create-request")
  );

  const redirect = useSelector((state) => state.commonReducer.errorMessage);
  // const errorMessage = useSelector((state) => state.commonReducer.errorMessage);

  const [toast, setToast] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [AllSports, setAllSports] = useState(false);
  const [isHardware, setIsHardware] = useState(false);

  const [toastMessage, setToastMessage] = useState();
  const [toastError, setToastError] = useState();
  const [state, setState] = React.useState({
    name: "",
    description: "",
    selected_clients: "",
    additional_info: "",
    productType: [],
    category: [],
    softwaredev: [],
    Sports: [],
    sector: [],
    timeToImplement: [],
    productStage: [],
    levelOfSports: [],
    targetCustomer: [],
    productSector: [],
    ProofofConcept: {
      value: 56,
      checked: "false",
    },
    TaC: { value: 67, checked: "false" },
    PrivacyPolicy: { value: 69, checked: "false" },
    Newsletter: { value: 71, checked: "false" },
  });

  console.log("Error Message", redirect);

  const [redirectToast, setRedirectToast] = useState(false);

  const targetCustomer = useSelector(
    (state) => state.vendorReducer.filter["Target Customer"]
  );
  const productType = useSelector(
    (state) => state.vendorReducer.filter["Product Type"]
  );
  const softwaredev = useSelector(
    (state) => state.vendorReducer.filter["Software Deployment"]
  );
  let categories = useSelector(
    (state) => state.vendorReducer.filter["Categories"]
  );

  // remove sport non tech and non sport from category selection
  categories =
    categories &&
    categories.length > 0 &&
    categories.filter((cat) => cat.id !== 122 && cat.id !== 123);

  const sports = useSelector((state) => state.vendorReducer.filter["Sports"]);
  const levelOfSports = useSelector(
    (state) => state.vendorReducer.filter["Sports Tier"]
  );
  const authData = useSelector((state) => state.authReducer.authData);
  const stage = useSelector((state) => state.vendorReducer.filter["Stage"]);
  const timeToImplement = useSelector(
    (state) => state.vendorReducer.filter["Development & Implementation time"]
  );
  const sector = useSelector((state) => state.vendorReducer.filter["Sector"]);

  const tac = useSelector(
    (state) => state.vendorReducer.filter["Terms of Use"]
  );
  const privacypolicy = useSelector(
    (state) => state.vendorReducer.filter["Privacy Policy"]
  );
  const poc = useSelector(
    (state) => state.vendorReducer.filter["Pilot Project"]
  );
  const newsletter = useSelector(
    (state) => state.vendorReducer.filter["Newsletter Opt In"]
  );
  // console.log("Sectors", data.name);
  // console.log("Sectors", data.Sectors.id);Sectors
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));
      await dispatch(VendorAction.getfilter());
    })();
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userData")).role !== 1) {
      history.replace("/");
    }
  }, [history]);

  const handleAllSports = () => {
    setAllSports(!AllSports);
    setState({
      ...state,
      ["Sports"]: [],
    });
    let e = [];
    if (!AllSports) {
      console.log("alllllsports");

      sports &&
        sports.map((data) => {
          e.push(data.id);
        });
      console.log("all", e);
      setState({
        ...state,
        ["Sports"]: e,
      });
    } else {
      console.log("else of all sports");

      setState({
        ...state,
        ["Sports"]: [],
      });
    }
  };
  const handleChangeDetails = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  let sectorArray = {
    value: [],
    name: [],
  };
  let j = 0;
  const handleFocus = () => {
    state.category?.includes(72) ||
    state.category?.includes(73) ||
    state.category?.includes(74) ||
    state.category?.includes(75)
      ? (sectorArray = {
          name: [...sectorArray.name, "Athletes & Performance"],
          value: [...sectorArray.value, 22],
        })
      : (j = 5);
    state.category?.includes(76) ||
    state.category?.includes(77) ||
    state.category?.includes(78) ||
    state.category?.includes(79) ||
    state.category?.includes(80)
      ? (sectorArray = {
          name: [...sectorArray.name, "Fans & Digital Media"],
          value: [...sectorArray.value, 23],
        })
      : (j = 7);

    state.category?.includes(81) ||
    state.category?.includes(82) ||
    state.category?.includes(83) ||
    state.category?.includes(84)
      ? (sectorArray = {
          name: [...sectorArray.name, "Commercial"],
          value: [...sectorArray.value, 24],
        })
      : (j = 8);
    state.category?.includes(88) ||
    state.category?.includes(87) ||
    state.category?.includes(86)
      ? (sectorArray = {
          name: [...sectorArray.name, "Management & Operations"],
          value: [...sectorArray.value, 25],
        })
      : (j = 7);
    console.log(sectorArray, "sectorArrayjjjjjjjjjjjjjjjjjjjjjjj");
    setSectorData(sectorArray.name);
    setState({
      ...state,
      sector: sectorArray.value,
    });
  };
  console.log(sectorArray, "sectorArrayjjjjjjjjjjjjjjjjjjjjjjj");
  const handleCheckbox1 = (event, e) => {
    let value;
    let eventcheck = "";
    console.log(event, true, "komalagarwal");
    event === true ? (eventcheck = "Yes") : (eventcheck = "No");
    poc &&
      poc.map(
        (user) => (
          console.log("mao worked1"),
          console.log(eventcheck, "eventcheck", user.name, "username"),
          eventcheck === user.name ? (value = user.id) : null
        )
      );
    setState({
      ...state,

      [e.target.name]: { value: value, checked: event },
    });
  };

  const handleChange = (event) => {
    console.log("ALLLLLLLLLLLLLL", event.target.value, AllSports);
    console.log("event.target", event.target);
    console.log("state for category===>", state);
    if (event.target.value.includes(119)) {
      return;
    }
    if (AllSports && event.target.name === "Sports") {
      return;
    }
    let k = 9;
    event.target.name === "category"
      ? state.category.length < 3
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 2
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 4);
    // event.target.name === "category"
    //   ? state.category.length < 3
    //     ? event.target.value.includes(72) ||
    //       event.target.value.includes(73) ||
    //       event.target.value.includes(74) ||
    //       event.target.value.includes(75)
    //       ? setState({
    //           ...state,
    //           [event.target.name]: event.target.value,
    //           // productSector: state.productSector.push("Athletes & Performance"),
    //           // sector: [...sector, "Athletes & Performance"],

    //         })+
    //         sectorArray= [...sectorArray,"Athletes & Performance"],
    //       : event.target.value.includes(76) ||
    //         event.target.value.includes(77) ||
    //         event.target.value.includes(78) ||
    //         event.target.value.includes(79) ||
    //         event.target.value.includes(80)
    //       ? setState({
    //           ...state,
    //           // sector: sector.push("Fans & Digital Media"),
    //           productSector: state.productSector("Fans & Digital Media"),
    //           [event.target.name]: event.target.value,
    //         })
    //       : event.target.value.includes(76) ||
    //         event.target.value.includes(81) ||
    //         event.target.value.includes(82) ||
    //         event.target.value.includes(83) ||
    //         event.target.value.includes(84)
    //       ? setState({
    //           ...state,
    //           [event.target.name]: event.target.value,
    //           productSector: state.productSector.push("Commercial"),
    //           // sector: sector.push("Commercial"),
    //         })
    //       : event.target.value.includes(76) ||
    //         event.target.value.includes(88) ||
    //         event.target.value.includes(87) ||
    //         event.target.value.includes(86)
    //       ? setState({
    //           ...state,
    //           [event.target.name]: event.target.value,
    //           productSector: [state.productSector.push(
    //             "Management & Operations"
    //           ),
    //           // sector: [...sector, "Management & Operations"],
    //         })
    //       : (k = 3)
    //     : event.target.value.length == 2
    //     ? setState({
    //         ...state,

    //         [event.target.name]: event.target.value,
    //       })
    //     : (k = 0)
    //   : (k = 4);
    event.target.name === "productType"
      ? state.productType.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "productStage"
      ? state.productStage.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);
    event.target.name === "timeToImplement"
      ? state.timeToImplement.length < 1
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : event.target.value.length == 0
        ? setState({
            ...state,

            [event.target.name]: event.target.value,
          })
        : (k = 0)
      : (k = 3);

    if (
      event.target.name !== "productType" &&
      event.target.name !== "timeToImplement" &&
      event.target.name !== "productStage" &&
      event.target.name !== "category"
    ) {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
    console.log(isEnabled, "hehehe");
    console.log("hiiiii", event.target.name, event.target.value);
    console.log("All Sports", state?.Sports);

    // if (
    //   event.target.name === "productType" &&
    //   (event.target.value.includes(18) || event.target.value.includes(19))
    // ) {
    //   console.log("yo");
    //   setIsEnabled(false);
    // }
    // setState({
    //   ...state,
    //   [event.target.name]: event.target.value,
    // });
  };
  console.log(state, "state4");

  const handleProductClose = (event) => {
    console.log(event, event.target, "happy", state.productType);
    const k = state.productType;
    const j = k[0];
    console.log(j, k, "ppp");
    if (j === 18 || j == 19) {
      setIsEnabled(false);
      setIsHardware(false);
      // document.getElementById("softwaredeploy").label = "";
    } else {
      setIsEnabled(true);
      setIsHardware(true);

      setState({ ...state, softwaredev: [] });
    }
    console.log(isEnabled, event.target.value, "lalala");
  };
  async function onSubmitForm(event) {
    setRedirectToast(true);
    let newState = state;
    const result = await ValidateVendorForm(newState);
    let poc = state.ProofofConcept.value;
    let news = state.Newsletter.value;
    let pp = state.PrivacyPolicy.value;
    let termsOfUse = state.TaC.value;

    if (result?.isFormValid) {
      let params = {
        companyId: authData.companyId,
        vendorId: authData.id,
        productDetails: {
          company_id: authData.companyId,
          name: state.name,
          description: state.description,
          selected_clients: state.selected_clients,
          additional_info: state.additional_info,
        },

        categoryIds: [
          state.productType,
          ...state.softwaredev,
          ...state.category,
          ...state.Sports,
          ...state.levelOfSports,
          ...state.targetCustomer,
          ...state.sector,
          state.timeToImplement,
          state.productStage,
          poc,
          pp,
          news,
          termsOfUse,
          // state.ProofofConcept["value"],
          // state.TaC["value"],
          // state.privacyPolicy.value,
          // state.Newsletter.value,
        ],
      };
      let getListParams = {
        vendorId: authData.id,
      };
      const data = await dispatch(VendorAction.addList(params));
      setToastMessage(data.message);
      setToastError(data.status);
      setToast(true);

      await dispatch(VendorAction.getList(getListParams));

      setTimeout(() => {
        if (history?.location?.state?.prevPath?.includes("/create-request")) {
          props.history.push(`${ROUTES.CreateRequest}/null`);
        } else {
          props.history.push(ROUTES.Vendors);
        }
      }, 7000);
      // setNewLoader(false);
    } else {
      setError(result?.error);
      setRedirectToast(false);
      for (let i = 0; i < Object.keys(result.error).length; i++) {
        console.log("inside", Object.keys(result.error)[i]);
        if (Object.values(result.error)[i].length) {
          console.log("inside");
          document
            .getElementById(Object.keys(result.error)[i])
            .scrollIntoView();
          break;
        }
      }
    }
  }

  return (
    <IonContent>
      <div className="quote">
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
            paddingTop: "100px",
          }}
        >
          <span onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
            <ArrowBack />
          </span>
        </div>
        <div className="login-inner">
          <div className="login-head ml-3">
            <h2 className="relative">
              Add Product details
              <span className="tooltp-vd">
                <ToolTip message="If you are using ad blocking software, please turn it off to enable you to complete this form" />
              </span>
            </h2>
          </div>
          {toast === true ? (
            <IonToast
              isOpen={toast}
              message={toastMessage}
              onDidDismiss={() => setToast(false)}
              buttons={[
                {
                  text: "Close",
                  role: "cancel",
                  handler: () => {
                    setToast(false);
                  },
                },
              ]}
            />
          ) : null}
          <div className="login-body">
            <div className="form-name">
              <h4>Basic product / service information:</h4>
            </div>
            <form>
              <IonGrid className="vendorsGrid">
                {/* <div style={{ display: "flex" }}> */}
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel> Name*</IonLabel>
                      <IonInput
                        type="text"
                        name="name"
                        id="name"
                        value={state.name}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChangeDetails(event);
                        }}
                      ></IonInput>
                      {errorData?.name[0] ? <p>{errorData?.name[0]}</p> : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <div className="d-flex">
                        <IonLabel id="description">Description*</IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Please describe your product / service within 500 characters." />
                        </div>
                      </div>

                      <div className="edithea text-center">
                        <TextField
                          id="outlined-multiline-static"
                          className="messageBody"
                          multiline
                          name="description"
                          value={state.description}
                          inputProps={{ maxLength: 501 }}
                          onChange={(event) => {
                            handleChangeDetails(event);
                          }}
                          rows={4}
                          defaultValue=""
                          variant="outlined"
                        />
                        {errorData?.description[0] ? (
                          <p>{errorData?.description[0]}</p>
                        ) : null}
                      </div>
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <div className="d-flex">
                        <IonLabel id="productStage">Stage*</IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Please select the option which best reflects the product / service stage." />
                        </div>
                      </div>
                      <Select
                        placeholder="Type"
                        // className="p"
                        multiple
                        name="productStage"
                        value={state.productStage}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          let l = selected.length + " Product Stage Selected";

                          return l;
                        }}
                      >
                        {stage &&
                          stage.map((name) => (
                            <MenuItem
                              key={name.id}
                              id={name.id}
                              value={name.id}
                            >
                              <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={
                                  state &&
                                  state.productStage &&
                                  // state.productStage.length > 1 &&
                                  state.productStage.indexOf(name.id) > -1
                                }
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {errorData?.productStage[0] ? (
                        <p>{errorData?.productStage[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <div className="d-flex">
                        <IonLabel id="productType">Product Type*</IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Please select the option which best reflects the product / service type. " />
                        </div>
                      </div>
                      <Select
                        placeholder="Type"
                        multiple
                        className="productType"
                        name="productType"
                        value={state.productType}
                        onClose={(e) => handleProductClose(e)}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          let l = selected.length + " Product Type Selected";
                          console.log("opppp", selected);
                          return l;
                        }}
                      >
                        {productType &&
                          productType.map((name) => (
                            <MenuItem
                              key={name.id}
                              id={name.id}
                              value={name.id}
                            >
                              <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={
                                  state &&
                                  state.productType &&
                                  state.productType.indexOf(name.id) > -1
                                }
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {errorData?.productType[0] ? (
                        <p>{errorData?.productType[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <div className="d-flex">
                        <IonLabel id="category">Category* </IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Please select a maximum of 3 categories per product / service" />
                        </div>
                      </div>

                      <Select
                        multiple
                        className="category"
                        name="category"
                        onClose={handleFocus}
                        value={state.category}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          console.log("hooo", selected);
                          const l = selected.length + " category Selected";
                          return l;
                        }}
                      >
                        {categories &&
                          categories?.map((name) => (
                            <MenuItem
                              className="menutype"
                              key={name.id}
                              id={name.id}
                              value={name.id}
                            >
                              <Checkbox
                                checked={
                                  state &&
                                  state.category &&
                                  state.category.indexOf(name.id) > -1
                                }
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {errorData?.category[0] ? (
                        <p>{errorData?.category[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <div className="d-flex">
                        <IonLabel>Sector* </IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Sector is pre-defined based on your category choices" />
                        </div>
                      </div>
                      <input
                        className="form-control"
                        value={sectorData}
                        disabled
                      />
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="12">
                    <div className="advance-info">
                      <p>Advanced product / service information:</p>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <div className="d-flex">
                        <IonLabel id="softwaredev">
                          Software Deployment*
                        </IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Please select relevant software deployment options if you indicated that your type of product / service is software" />
                        </div>
                      </div>
                      {console.log(isEnabled, "lplplp")}
                      <Select
                        multiple
                        name="softwaredev"
                        disabled={isEnabled}
                        value={
                          isHardware ? ["Not Applicable"] : state.softwaredev
                        }
                        onChange={
                          isHardware ? console.log("") : (e) => handleChange(e)
                        }
                        input={<Input id="softwaredeploy" />}
                        renderValue={
                          isEnabled
                            ? (selected) => {
                                const l = "Not Applicable";
                                return l;
                              }
                            : (selected) => {
                                const l =
                                  selected.length + " Deployment Type Selected";
                                return l;
                              }
                        }
                      >
                        {softwaredev &&
                          softwaredev.map((name) => (
                            <MenuItem
                              key={name.id}
                              id={name.id}
                              value={name.id}
                            >
                              <Checkbox
                                checked={
                                  state &&
                                  state.softwaredev &&
                                  state.softwaredev.indexOf(name.id) > -1
                                }
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {errorData?.softwaredev[0] ? (
                        <p>{errorData?.softwaredev[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel id="Sports">Sports* </IonLabel>

                      <Select
                        multiple
                        name="Sports"
                        value={state.Sports}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          console.log("selected", selected);
                          const l = selected.length + "  Sports Selected";
                          return l;
                        }}
                      >
                        <MenuItem value={119}>
                          <Checkbox
                            checked={AllSports}
                            onClick={() => handleAllSports()}
                          />
                          <ListItemText primary="All Sports" />
                        </MenuItem>
                        {sports &&
                          sports.map((name) => {
                            if (name.id === 119) {
                            } else {
                              return (
                                <MenuItem key={name.id} value={name.id}>
                                  <Checkbox
                                    checked={
                                      (state &&
                                        state.Sports &&
                                        state.Sports.indexOf(name.id) > -1) ||
                                      AllSports
                                    }
                                    disabled={AllSports}
                                  />
                                  <ListItemText primary={name.name} />
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                      {errorData?.Sports[0] ? (
                        <p>{errorData?.Sports[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel id="levelOfSports">Level of Sports*</IonLabel>

                      <Select
                        multiple
                        name="levelOfSports"
                        value={state.levelOfSports}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          const l =
                            selected.length + " Level Of Sports Selected";
                          return l;
                        }}
                      >
                        {levelOfSports &&
                          levelOfSports.map((name) => (
                            <MenuItem key={name.id} value={name.id}>
                              <Checkbox
                                checked={
                                  state &&
                                  state.levelOfSports &&
                                  state.levelOfSports.indexOf(name.id) > -1
                                }
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {errorData?.levelOfSports[0] ? (
                        <p>{errorData?.levelOfSports[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel id="targetCustomer">Target Customer* </IonLabel>

                      <Select
                        multiple
                        name="targetCustomer"
                        value={state.targetCustomer}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          const l = selected.length + " Target Type Selected";
                          return l;
                        }}
                      >
                        {targetCustomer &&
                          targetCustomer.map((name) => (
                            <MenuItem
                              key={name.id}
                              id={name.id}
                              value={name.id}
                            >
                              <Checkbox
                                checked={
                                  state &&
                                  state.targetCustomer &&
                                  state.targetCustomer.indexOf(name.id) > -1
                                }
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {errorData?.targetCustomer[0] ? (
                        <p>{errorData?.targetCustomer[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  {/* <IonCol size="12" size-md="6">
                      <div className="form-group">
                        <div className="d-flex">
                          <IonLabel>Software Deployment*</IonLabel>
                          <ToolTip message="Please select relevant software deployment options if you indicated that your type of product / service is software " />
                        </div>

                        <IonSelect
                          id="soft"
                          placeholder="Software Deployment"
                          disabled={isEnabled}
                          name="softwaredev"
                          multiple
                          onIonChange={(e) => handleChange(e)}
                        >
                          {softwaredev &&
                            softwaredev?.map((user) => {
                              return (
                                <IonSelectOption value={user.id} key={user.id}>
                                  {user.name}
                                </IonSelectOption>
                              );
                            })}
                        </IonSelect>
                        {errorData?.softwaredev[0] ? (
                          <p>{errorData?.softwaredev[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="6">
                      <div className="form-group">
                        <IonLabel>Sports* </IonLabel>

                        <IonSelect
                          name="Sports"
                          placeholder="Sports"
                          multiple
                          onIonChange={(e) => handleChange(e)}
                        >
                          {sports &&
                            sports?.map((user) => {
                              return (
                                <IonSelectOption value={user.id}>
                                  {user.name}
                                </IonSelectOption>
                              );
                            })}
                        </IonSelect>
                        {errorData?.Sports[0] ? (
                          <p>{errorData?.Sports[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                    <IonCol size="6" size-md="6">
                      <div className="form-group">
                        <IonLabel>Level of Sports*</IonLabel>

                        <IonSelect
                          placeholder="Level Of Sports"
                          name="levelOfSports"
                          multiple
                          onIonChange={(e) => handleChange(e)}
                        >
                          {levelOfSports &&
                            levelOfSports?.map((user) => {
                              return (
                                <IonSelectOption value={user.id}>
                                  {user.name}
                                </IonSelectOption>
                              );
                            })}
                        </IonSelect>
                        {errorData?.levelOfSports[0] ? (
                          <p>{errorData?.levelOfSports[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                    <IonCol size="6" size-md="6">
                      <div className="form-group">
                        <IonLabel>Target Customer* </IonLabel>

                        <IonSelect
                          placeholder="Target Customer"
                          name="targetCustomer"
                          multiple
                          onIonChange={(e) => handleChange(e)}
                        >
                          {targetCustomer &&
                            targetCustomer?.map((user) => {
                              return (
                                <IonSelectOption value={user.id} key={user.id}>
                                  {user.name}
                                </IonSelectOption>
                              );
                            })}
                        </IonSelect>
                        {errorData?.targetCustomer[0] ? (
                          <p>{errorData?.targetCustomer[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                  </IonRow> */}
                  {/* </div> */}
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <div className="d-flex align-items-center">
                        <IonLabel>Existing Clients</IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Please include a selection of your clients (optional) and separate each one with a comma." />
                        </div>
                      </div>
                      <IonInput
                        type="text"
                        name="selected_clients"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChangeDetails(event);
                        }}
                      ></IonInput>
                    </div>
                  </IonCol>
                  {/* <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <div className="d-flex align-items-center">
                        <IonLabel>Developmentand Implementation Time</IonLabel>
                        <ToolTip message="How long would it typically take to start implementing your product / service (include development time, if relevant)" />
                      </div>

                      <IonSelect
                        placeholder="Development & implementation time"
                        name="timeToImplement"
                        onIonChange={(e) => handleChange(e)}
                      >
                        {timeToImplement &&
                          timeToImplement?.map((user) => {
                            return (
                              <IonSelectOption value={user.id} key={user.id}>
                                {user.name}
                              </IonSelectOption>
                            );
                          })}
                      </IonSelect>
                      {errorData?.timeToImplement[0] ? (
                        <p style={{ position: "inherit" }}>
                          {errorData?.timeToImplement[0]}
                        </p>
                      ) : null}
                    </div>
                  </IonCol> */}
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel id="timeToImplement">
                        Time to Implement*
                      </IonLabel>

                      <Select
                        multiple
                        name="timeToImplement"
                        value={state.timeToImplement}
                        onChange={(e) => handleChange(e)}
                        input={<Input />}
                        renderValue={(selected) => {
                          const l = selected.length + "   Selected";
                          return l;
                        }}
                      >
                        {timeToImplement &&
                          timeToImplement.map((name) => (
                            <MenuItem key={name.id} value={name.id}>
                              <Checkbox
                                checked={
                                  state &&
                                  state.timeToImplement &&
                                  state.timeToImplement.length > 0 &&
                                  state.timeToImplement.indexOf(name?.id) > -1
                                }
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                      </Select>
                      {errorData?.timeToImplement[0] ? (
                        <p style={{ position: "inherit" }}>
                          {errorData?.timeToImplement[0]}
                        </p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <div className="d-flex align-items-center">
                        <IonLabel>Additional information</IonLabel>
                        <div style={{ width: 25 }}>
                          <ToolTip message="Please use this to add information not yet shared that can be useful to buyers (optional). Character limit is 1000." />
                        </div>
                      </div>
                      <TextField
                        id="outlined-multiline-static"
                        className="messageBody"
                        multiline
                        name="additional_info"
                        value={state.additional_info}
                        inputProps={{ maxLength: 1001 }}
                        onChange={(event) => {
                          handleChangeDetails(event);
                        }}
                        rows={4}
                        defaultValue=""
                        variant="outlined"
                      />
                      {errorData?.additional_info[0] ? (
                        <p>{errorData?.additional_info[0]}</p>
                      ) : null}
                      {/* <IonItem>
                        <IonTextarea
                          id="text-area"
                          autoGrow={true}
                          type="text"
                          name="Additional information"
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonTextarea>
                      </IonItem> */}
                    </div>
                  </IonCol>

                  <div id="checkboxforios" className="d-flex flex-column">
                    {" "}
                    <div className="d-flex flex-row">
                      <IonCheckbox
                        className="custom-checkbox"
                        value={state.ProofofConcept}
                        name="ProofofConcept"
                        checked={state.ProofofConcept.checked}
                        onIonChange={(e) =>
                          handleCheckbox1(e.detail.checked, e)
                        }
                      />

                      <div
                        className="d-flex flex-row align-items-center"
                        style={{ width: "400px" }}
                      >
                        <IonLabel
                          style={{ color: "blue" }}
                          className="labelled"
                        >
                          <a
                            href="https://sportstechmatch.medium.com/sportstech-match-pilot-proj ect-programme-47980578f62c"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>Pilot Project</u>
                          </a>
                        </IonLabel>
                        <p className="relative newPilotTooltip">
                          <span className="tooltp-vd">
                            <ToolTip message="Show buyers that you are open to running a pilot project with them." />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </IonRow>
              </IonGrid>

              <div className="button-name text-center">
                <Link to={ROUTES.Vendors}>
                  <IonButton color="none" className="btnsave">
                    Cancel
                  </IonButton>
                </Link>
                {redirectToast === false ? (
                  <IonButton
                    color="none"
                    className="btnsave"
                    onClick={(event) => {
                      {
                        onSubmitForm(event);
                      }
                    }}
                    disabled={redirectToast}
                  >
                    save
                  </IonButton>
                ) : (
                  <IonSpinner></IonSpinner>
                )}
              </div>
              {errorData !== undefined &&
              errorData !== {} &&
              errorData !== null &&
              errorData !== [] ? (
                <p className="text-center errormsg">
                  Please complete all mandatory fields
                </p>
              ) : null}
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default Vendors;
