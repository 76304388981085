import { IonCheckbox, IonContent, IonLabel, IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeAction, RFIAction } from "../../ReduxStore/Actions";
import "./VendorJourneyFromTwo.css";
import ToolTip from "../tooltip/Tooltip";
import { useParams } from "react-router";
import { TextField } from "@material-ui/core";
import Modal from "../Dashboard/Modal/Modal";
import { ValidateSignUpForm } from "./Validation";
import { useHistory } from "react-router";
import { ROUTES } from "../../Services";

const products = ["one", "two"];

const VendorJourneyOne = ({
  setCount,
  handleChange,
  formData,
  response,
  rfiData,
}) => {
  let history = useHistory();

  const [showSubmitModal, setshowSubmitModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  let { rfiId } = useParams();
  const { authData } = useSelector((state) => state.authReducer);
  const { products } = useSelector((state) => state.RFIReducer);
  const [errorData, setError] = React.useState();

  useEffect(() => {
    dispatch(HomeAction.setHeaderClass("headerBlue"));
  }, []);

  useEffect(() => {
    (async () => {
      dispatch(RFIAction.GetProducts(authData.id));
    })();
  }, []);

  const handleClose = (val) => {
    setshowSubmitModal(val);
    setShowSaveModal(val);
  };

  const handleSubmit = async (e, status) => {
    e.preventDefault();
    e.stopPropagation();

    const result = await ValidateSignUpForm(formData);
    if (!result?.isFormValid) {
      setError(result?.error);
      console.log("result errorrr", result.error);
      return;
    }
    if (status === 1) setShowSaveModal(true);
    else if (status === 2) setshowSubmitModal(true);

    setLoading(true);
    const payload = {
      answers: response,
      ...formData,
      vendorId: authData.id,
      rfiId: rfiId,
      status: status,
      title: rfiData[0]?.title,
    };

    await dispatch(RFIAction.saveRFIResponse(payload));
    setTimeout(() => {
      history.push(ROUTES.Vendors);
    }, 4000);

    setLoading("false");
  };
  return (
    <>
      <div className="responseContentWrapper">
        <div className="responseRfiTitle d-flex">
          <h3>Open Question: </h3>
          <p className="">
            <span>
              <ToolTip message="Please use this section to add any context to the above responses or to highlight any further information you would like the buyer to consider (limited to 1,000 characters)." />
            </span>
          </p>{" "}
        </div>
        <div className="responseRfiDeadline">
          <p>{rfiData[0]?.openQuestion}</p>
        </div>
        <div className="responseRfiOrgDesc">
          <TextField
            id="outlined-multiline-static"
            className="messageBody"
            multiline
            name="open_question"
            value={formData?.open_question}
            onChange={(event) => {
              handleChange(event);
            }}
            placeholder="Enter text here"
            rows={6}
            variant="outlined"
          />
          {errorData?.open_question ? (
            <p className="setError">{errorData?.open_question[0]}</p>
          ) : null}
        </div>
        <div className="responseRfiOppDescVendor">
          <h3>Relevant Product Listing:</h3>
        </div>
        <div className="filterResponseHeading">
          <p>
            {" "}
            As part of your RFI response, we share with the buyer a link to your
            relevant product listing that corresponds to your response. This
            helps buyers to understand and compare vendors. Select the relevant
            product from the drop down below (if you need to add or edit a
            listing, go to your dashboard).
          </p>
        </div>
        <div className="SelectToLeft">
          <select
            name="productName"
            value={formData?.productName}
            className="form-control"
            onChange={(event) => {
              handleChange(event);
            }}
          >
            <option value="Select Product" disabled selected="selected">
              Select Product
            </option>
            {products &&
              products.map((prod) => {
                return <option value={prod.id}>{prod.name}</option>;
              })}
          </select>
          {errorData?.productName ? (
            <p className="setError">{errorData?.productName[0]}</p>
          ) : null}
        </div>

        <div className="button-login d-flex justify-content-center">
          <div>
            <IonButton
              color="none"
              className="vendorJourneyButton"
              // onClick={(event) => {
              // 	onSubmitForm(event);
              // 	props.history.goBack();
              // }}
              onClick={() => setCount(1)}
            >
              Previous
            </IonButton>
          </div>
          <div>
            <IonButton
              color="none"
              className="vendorJourneyButton"
              // onClick={(event) => {
              // 	onSubmitForm(event);
              // 	props.history.goBack();
              // }}
              onClick={(e) => {
                handleSubmit(e, 1);
              }}
              // disabled={loading ? true : false}
            >
              Save
            </IonButton>
          </div>
          <div>
            <IonButton
              color="none"
              className="vendorJourneyButton"
              onClick={(e) => {
                handleSubmit(e, 2);
              }}
              // disabled={loading ? true : false}
            >
              Submit
            </IonButton>
          </div>
        </div>
      </div>
      {showSubmitModal && (
        <div>
          <Modal
            message={"Your RFI Response has been successfully submitted."}
            handleClose={handleClose}
            subtitle={
              "You will recieve an email once the buyer has decided whether to connect with you or not."
            }
          />
        </div>
      )}

      {showSaveModal && (
        <div>
          <Modal
            message={
              "Your RFI response has been saved, Don’t forget to submit before the deadline."
            }
            handleClose={handleClose}
          />
        </div>
      )}
    </>
  );
};

export default VendorJourneyOne;
