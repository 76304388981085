import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	userProfileDetail: {},
	message: "",
	status: null,
};

export const ProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.USER_PROFILE_DETAILS:
			return {
				...state,
				userProfileDetail: action.payload.data?.data,
			};
		case ACTION_TYPE.UPDATE_PROFILE:
			return {
				...state,
				status: action.payload?.status,
				message: action.payload?.message,
				userProfileUpdated: action.payload.user[0],
			};
		case ACTION_TYPE.CLEAR_PROFILE_STATE:
			return {
				...state,
				status: null,
				message: "",
			};

		default:
			return state;
	}
};
