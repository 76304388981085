import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { IonContent, IonIcon, IonSearchbar } from "@ionic/react";

import { HomeAction, RFIAction } from "../../../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";

// import { Tab, Tabs, Button } from "react-bootstrap";

import SingleColMultiRowTable from "../../../../components/SingleColMultiRowTable";

import { searchOutline } from "ionicons/icons";
import "./CustomVendorModal.css";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomVendorRow from "../../../../components/CustomVendorRow";

export function CustomVendorListModal(props) {
  let dispatch = useDispatch();
  let { approvedVendors } = useSelector((state) => state.RFIReducer);
  const [searchText, setSearchText] = useState();
  const [currentTab, setCurrentTab] = useState("digitalMedia");

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleSearchTextChange = async (text) => {
    setSearchText(text);
    await dispatch(RFIAction.getApprovedVendors(text));
  };
  useEffect(() => {
    (async () => {
      dispatch(HomeAction.setHeaderClass("blue"));

      await dispatch(RFIAction.getApprovedVendors(searchText));
    })();
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      onHide={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header > */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Custom Vendors List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="selectSectorTitle">Select Sectors</h6>
        <div className="customListTop">
          <TabContext value={currentTab}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="customListTabs"
            >
              <Tab
                label="Fans & Digital Media"
                className="vertBorder"
                value="digitalMedia"
              />
              <Tab
                label="Commercial"
                className="vertBorder"
                value="commercial"
              />
              <Tab
                label="Athletes & Performance"
                className="vertBorder"
                value="athelete"
              />
              <Tab label="Management & Operations" value="management" />
            </TabList>
            <div className="container customTableHead">
              <h4>Select Vendors</h4>
            </div>
            <div className="customSearchBar d-flex m-auto">
              <IonSearchbar
                className="p-0 ionSearchBar"
                value={searchText}
                onIonChange={(e) => setSearchText(e.target.value)}
                cancelButtonText=""
                animated
                placeholder="What are you looking for?"
              />
              <div className="d-flex flex-row">
                <button
                  onClick={() => handleSearchTextChange(searchText)}
                  className="searchButton"
                >
                  <IonIcon
                    icon={searchOutline}
                    style={{ marginRight: "5px" }}
                  />
                  Search
                </button>
              </div>
            </div>
            <TabPanel value="digitalMedia">
              <CustomVendorRow
                letter="Fans & Digital Media"
                className={"customList"}
                isCustom={true}
                listSectors={approvedVendors.fanDigitalMedia}
                customVendor={props.customVendor}
                setCustomVendor={props.setCustomVendor}
                selectedVendors={props.selectedVendors}
                setSelectedVendors={props.setSelectedVendors}
              ></CustomVendorRow>
            </TabPanel>
            <TabPanel value="commercial">
              {" "}
              <CustomVendorRow
                letter="Commercial"
                className={"customList"}
                isCustom={true}
                listSectors={approvedVendors.commercial}
                customVendor={props.customVendor}
                setCustomVendor={props.setCustomVendor}
                selectedVendors={props.selectedVendors}
                setSelectedVendors={props.setSelectedVendors}
              ></CustomVendorRow>
            </TabPanel>
            <TabPanel value="athelete">
              {" "}
              <CustomVendorRow
                letter="Fans & Digital Media"
                className={"customList"}
                isCustom={true}
                listSectors={approvedVendors.athlete}
                customVendor={props.customVendor}
                setCustomVendor={props.setCustomVendor}
                selectedVendors={props.selectedVendors}
                setSelectedVendors={props.setSelectedVendors}
              ></CustomVendorRow>
            </TabPanel>
            <TabPanel value="management">
              {" "}
              <CustomVendorRow
                letter="Fans & Digital Media"
                className={"customList"}
                isCustom={true}
                listSectors={approvedVendors.managementOperation}
                customVendor={props.customVendor}
                setCustomVendor={props.setCustomVendor}
                selectedVendors={props.selectedVendors}
                setSelectedVendors={props.setSelectedVendors}
              ></CustomVendorRow>
            </TabPanel>
          </TabContext>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Save {"&"} Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

//           <div className="tabAlign">
//             <Tabs
//               defaultActiveKey="fans-&-digital-media"
//               activeKey={activeTab}
//               id="uncontrolled-tab-example"
//               className="mt-8 "
//               onSelect={(key) => setActiveTab(key)}
//             >
//               <Tab eventKey="fans-&-digital-media" title="Fans & Digital Media">
//                 <SingleColMultiRowTable
//                   letter="Fans & Digital Media"
//                   className={"customList"}
//                   isCustom={true}
//                   listSectors={approvedVendors.fanDigitalMedia}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//               <Tab eventKey="commercial" title="Commercial">
//                 <SingleColMultiRowTable
//                   letter="Commercial"
//                   isCustom={true}
//                   listSectors={approvedVendors?.commercial}
//                   searchText={searchText}
//                   className={"customList"}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//               <Tab
//                 eventKey="athletes-&-performance"
//                 title="Athletes & Performance"
//               >
//                 <SingleColMultiRowTable
//                   letter="Athletes & Performance"
//                   isCustom={true}
//                   className={"customList"}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   listSectors={approvedVendors.athlete}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//               <Tab
//                 eventKey="management-&-operations"
//                 isCustom={true}
//                 title="Management & Operations"
//               >
//                 <SingleColMultiRowTable
//                   letter="Management & Operations"
//                   isCustom={true}
//                   className={"customList"}
//                   customVendor={props.customVendor}
//                   setCustomVendor={props.setCustomVendor}
//                   listSectors={approvedVendors.managementOperation}
//                   selectedVendors={props.selectedVendors}
//                   setSelectedVendors={props.setSelectedVendors}
//                 ></SingleColMultiRowTable>
//               </Tab>
//             </Tabs>
//           </div>
