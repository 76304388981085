import React, { useState } from "react";
import { ROUTES } from "../../Services";
import "./Footer.css";
// import { logoLinkedin, logoTwitter } from "ionicons/icons";
import { useDispatch } from "react-redux";

import { IonImg } from "@ionic/react";
import { Link } from "react-router-dom";
import { ContactAction } from "../../ReduxStore/Actions";
import linkedInLogo from "../../images/HomeImages/linkedInlogo.png";
import logoTwitter from "../../images/HomeImages/twitterLogo.png";

const Footer = () => {
  let dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      document.getElementById("Newsletter").value = "Thanks";
      await dispatch(ContactAction.Newsletter({ email }));
      setTimeout(() => {
        document.getElementById("Newsletter").value = "Subscribe!";
      }, 3000);
    } else {
      document.getElementById("emailcheck").innerText =
        "Please enter a valid email address";
    }

    setEmail("");
  };
  return (
    <footer>
      <div className="InFoot" id="footer">
        <div className="container1">
          <div className="footIn">
            <div className="in-block">
              <div className="col-md-9 col col-sm-12 footerLogo">
                <Link to={ROUTES.Home}>
                  <img
                    className="logoo"
                    src={require("../../images/CommonImages/logo.png")}
                    alt="logo"
                  ></img>
                </Link>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-12 col relative newslttr mt-4">
              <div className="social">
                <a
                  href="https://twitter.com/SportsTechMatch"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonImg
                    src={logoTwitter}
                    style={{
                      // borderRadius: "25px",
                      // backgroundColor: "#ffffff",
                      // color: "#0f93fe",
                      // padding: 5,
                      width: 40,
                      alt: "Twitter_logo",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/sportstechmatch/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IonImg
                    src={linkedInLogo}
                    style={{
                      width: 40,

                      marginLeft: 22,
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="col-md-9 col-sm-9 col-xs-12 col relative newslttr ">
              <div className="col-md-4 col-sm-6 col-xs-12 col ">
                <ul className="newsList">
                  <li>
                    <Link to={ROUTES.Search}>Marketplace</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.About}>About Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 col">
                <ul className="newsList">
                  <li>
                    <Link to={ROUTES.VendorPage}>Vendors</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.Buyer}>Buyers</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.TERMS_AND_CONDITION}>Terms Of Use</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 col">
                <ul className="newsList">
                  <li>
                    <Link to={ROUTES.PRIVATE_POLICY}>Privacy Policy</Link>
                  </li>
                  <li>
                    {/* <Link to={ROUTES.Home}>Blog</Link> */}
                    <a
                      href="https://sportstechmatch.medium.com/"
                      target="_blank"
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="subscribeForm">
              <h3>Get marketplace updates & insights direct to your inbox</h3>
              <div className="newsl">
                <form onSubmit={handleSubmit}>
                  {" "}
                  <input
                    className="nmail"
                    type="email"
                    required
                    placeholder="Business email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    className="nsub"
                    type="submit"
                    id="Newsletter"
                    value="Subscribe!"
                  />
                </form>
                <p
                  id="emailcheck"
                  style={{ color: "red", textAlign: "center" }}
                ></p>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRight">
          <p>© SportsTech Match 2021 </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
