import Api from "../../Api";

import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";

export const getProductById = (params) => async (dispatch) => {
  console.log("property type hittt");
  return new Promise((resolve, reject) => {
    Api.post(ENDPOINTS.GET_BUYER_PRODUCT_BY_ID, params)
      .then((res) => {
        console.log("product list", res);

        dispatch({
          type: ACTION_TYPE.GET_BUYER_PRODUCT_BY_ID,
          payload: res.data.data,
        });
        resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
        // dispatch(handleError(error));
      });
  });
};

export const getRatingsByProductId = (productId) => async (dispatch) => {
  console.log("property type hittt");
  return new Promise((resolve, reject) => {
    Api.get(`${ENDPOINTS.RATING_REVIEWS_BY_PORDUCT}/${productId}`)
      .then((res) => {
        console.log("product list", res);
        dispatch({
          type: ACTION_TYPE.GET_RATINGS_BY_PRODUCT_ID,
          payload: res.data.data,
        });
        resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
        // dispatch(handleError(error));
      });
  });
};
