import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../Services/index";
import { IonPage } from "@ionic/react";

import Header from "../components/Header/Header";

let dontShowAfterLogin = [
  ROUTES.SignUp,
  ROUTES.Login,
  ROUTES.EmailVerify,
  ROUTES.ForgetPassword,
  ROUTES.ResetPassword,
];
class PrivateGuard extends Component {
  dontShowAfterLogin = [
    ROUTES.SignUp,
    ROUTES.Login,
    ROUTES.EmailVerify,
    ROUTES.ForgetPassword,
    ROUTES.ResetPassword,
  ];
  render() {
    const route = this.props.location?.pathname;
    console.log("this.props", this.props.location.search.split("=")[1]);
    console.log("this.props full ", this.props.location.search);

    let returnData = "";

    if (localStorage.getItem("token") && localStorage.getItem("userData")) {
      if (!this.dontShowAfterLogin.includes(route)) {
        returnData = (
          <>
            <Header />
            <Route {...this.props} />
          </>
        );
      } else {
        console.log(
          `localStorage.getItem("userData")`,
          localStorage.getItem("token"),
          JSON.parse(localStorage.getItem("userData"))["role"]
        );
        returnData = (
          <>
            <Header />
            <Redirect
              to={
                JSON.parse(localStorage.getItem("userData"))["role"] === 1 &&
                this.props.location.search.split("=")[1] === "tenderemail"
                  ? {
                      pathname: ROUTES.ViewTenders,
                      state: { from: this.props.location },
                    }
                  : JSON.parse(localStorage.getItem("userData"))["role"] === 1
                  ? {
                      pathname: ROUTES.Vendors,
                      state: { from: this.props.location },
                    }
                  : JSON.parse(localStorage.getItem("userData"))["role"] === 0
                  ? {
                      pathname: ROUTES.RfiDashboard,
                      state: { from: this.props.location },
                    }
                  : JSON.parse(localStorage.getItem("userData"))["role"] === 2
                  ? {
                      pathname: ROUTES.AdminProfile,
                      state: { from: this.props.location },
                    }
                  : JSON.parse(localStorage.getItem("userData"))["role"] === 4
                  ? {
                      pathname: ROUTES.AdminSupportProfile,
                      state: { from: this.props.location },
                    }
                  : {
                      pathname: "/",
                      state: { from: this.props.location },
                    }
              }
            />
          </>
        );
      }
    } else {
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = (
          <>
            <Header />
            {/* <AuthHeader /> */}
            <Route {...this.props} />
          </>
        );
      } else {
        returnData = (
          <>
            <Header />
            {/* <AuthHeader /> */}
            {this.props.location.search.split("=")[1] === "tenderemail" ? (
              <Redirect
                to={{
                  pathname: "/view-tenders",
                  state: { from: this.props.location },
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: this.props.location },
                }}
              />
            )}
            {/* {this.props.location.search.includes("requestId") ? (
              <Redirect
                to={{
                  pathname: `/buyer-landing${this.props.location.search}`,
                  state: { from: this.props.location },
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: this.props.location },
                }}
              />
            )} */}
            {/* <Redirect
              to={{
                pathname: "/",
                state: { from: this.props.location },
              }}
            /> */}
          </>
        );
      }
    }

    return <IonPage>{returnData}</IonPage>;
  }
}

export default PrivateGuard;
