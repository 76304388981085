import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AdminAction, VendorAction } from "../../ReduxStore/Actions";
import { changeVendorAdmin } from "../../ReduxStore/Actions/adminAction";

const ShowVendorUsersModal = (props) => {
  const dispatch = useDispatch();

  const switchVendorPaid = () => {
    props.setLoading(true);
    dispatch(
      changeVendorAdmin(
        { mainUserId: props.mainUserId, userId: props.userId },
        () => {
          (async () => {
            await dispatch(
              AdminAction.getVedorById({
                userId: props.mainUserId,
                type: "vendor",
              })
              // parseInt(props.match.params.id) })
            );
            dispatch(VendorAction.getVendorUsers(props.mainUserId));
            props.setLoading(false);
          })();
        }
      )
    );
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      onHide={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="vendorUserModalContainer"
    >
      {/* <Modal.Header > */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Vendor Users
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="customListTop">
          <form>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to make this User a Vendor Admin?
            </p>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}> Cancel</Button>
        <Button onClick={switchVendorPaid}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowVendorUsersModal;
