import { checkbox } from "ionicons/icons";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";

function CustomVendorRow(props) {
  const {
    letter,
    listSectors,
    className,
    isCustom,
    customVendor,
    setCustomVendor,
    setSelectedVendors,
    selectedVendors,
  } = props;
  function handleChange(e) {
    e.persist();
    console.log("e.target.value", e.target.value);
    console.log("e.target.checked", e.target.checked);
    // console.log("ecustomVendor", customVendor);
    if (!e.target.value) {
      return;
    }
    if (e.target.checked) {
      setCustomVendor((state) => [...state, e.target.value.toString()]);
      const selectedData = listSectors.find(
        (item) => item.vendor_id === Number(e.target.value)
      );
      setSelectedVendors((prev) =>
        !prev.includes(selectedData.companyName)
          ? [...prev, selectedData.companyName]
          : [...prev]
      );
    } else {
      const updatedVendor = customVendor.filter(
        (item) => item.toString() !== e.target.value.toString()
      );
      setCustomVendor(updatedVendor);
      const selectedData = listSectors.find(
        (item) => item.vendor_id === Number(e.target.value)
      );
      setSelectedVendors((prev) =>
        prev.filter((item) => item !== selectedData.companyName)
      );
    }
  }
  useEffect(() => {
    console.log("listSectors", listSectors);
  }, [listSectors]);

  return (
    <div className="mt-3 mb-3">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
        className={className ? className : ""}
      >
        {listSectors?.map((data, i) => {
          return (
            <div className="customVendorCheck horizontalSectors">
              {isCustom && (
                <div className="vendorCheckbox">
                  <input
                    type="checkbox"
                    value={data.vendor_id.toString()}
                    checked={
                      customVendor.includes(data.vendor_id.toString())
                        ? true
                        : false
                    }
                    onChange={handleChange}
                  ></input>
                </div>
              )}

              <div className="">
                <h6>
                  Company Name:{" "}
                  {data.companyName === "undefined" || data.companyName == ""
                    ? "None"
                    : data.companyName}
                </h6>
                <h6>
                  Product Name: {`${data.name} `}{" "}
                  <Link
                    style={{ color: "#0056b3" }}
                    to={`/product/${data?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (<u>{`View Details`}</u>)
                  </Link>{" "}
                </h6>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CustomVendorRow;
